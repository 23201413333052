import React, { useRef, useState, useEffect } from "react";
import { CloudUploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { Option } from "antd/es/mentions";
import {
  Button,
  Col,
  Radio,
  InputNumber,
  Collapse,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Modal,
  Space,
  Table,
  message,
  Spin,
} from "antd";
import add from "../../../Assets/Images/add-button1.jpeg";
import deleteicon from "../../../Assets/Images/Svg/deleteicon.svg";
import { generateUniqueId } from "../../../utils/generateUID";

import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import ConsultationService from "../../../Services/ConsultationService";
import DrugServices from "../../../Services/DrugServices";
import moment from "moment";
import dayjs from "dayjs";


function ConsultationNotes({ followUpDate, symptoms, consulationData, recommendation, handleAddMedicines, handleDiagonsisArr, handleAddLabTests, handleChiefComplaint, handleSymptoms, handleRecommendation, handleFollowUpDate, disabledDate }) {
  const onChange = (key) => {
    setActiveKey(key);
  };

  const [diagnosisType, setDiagnosisType] = useState("primary");

  const handleDiagnosisChange = (e) => {
    setDiagnosisType(e.target.value);
  };
  console.log(symptoms, "consultationsymtoms")

  const formRef = useRef();
  const InputRef = useRef();
  const [selectedRadio, setSelectedRadio] = useState("primary");
  const [diagnosticList, setDiagnosticList] = useState([]);
  const [currPrimaryDiagList, setCurrPrimaryDiagList] = useState([]);
  const [currSecondaryDiagList, setCurrSecondaryDiagList] = useState([]);
  const [selectedPrimaryDiag, setSelectedPrimaryDiag] = useState({});
  const [selectedSecondaryDiag, setSelectedSecondaryDiag] = useState({});
  const [selectedPrimaryDiagVal, setSelectedPrimaryDiagVal] = useState("");
  const [selectedSecondaryDiagVal, setSelectedSecondaryDiagVal] = useState("");
  const [selectedDiagArr, setSelectedDiagArr] = useState([]);
  const [selectedOriginalDiagArr, setSelectedOriginalDiagArr] = useState([]);
  const [selectedCode, setSelectedCode] = useState(null);
  const [selectedSecondaryCode, setSelectedSecondaryCode] = useState(null);
  const [secondaryDiag, setSecondaryDiag] = useState([]);
  const [icdCode, setIcdCode] = useState();
  const [primdiag, setPrimdiag] = useState();
  const [noOptionFound, setNoOptionFound] = useState(false);
  const [tabledata, setTabledata] = useState([]);
  const [labTableArr, setLabTableArr] = useState([]);
  const [medTableArr, setMedTableArr] = useState([]);
  const [showTable, setShowTable] = useState({
    diagnosisTable: false,
    medicinesTable: false,
    labTable: false
  });
  const [isLoading, setIsLoading] = useState(false);
  const [secKey, setSecKey] = useState(0);
  const { state } = useLocation();
  const history = useHistory();
  const location = useLocation();
  const [searchedValue, setSearchedValue] = useState("");
  const [activeKey, setActiveKey] = useState("");
  const [isFinished, setIsFinished] = useState(true);
  const [key, setKey] = useState(0);
  const selectedPatient = location?.state?.selectedPatient;
  const [form] = useForm();

  const [currTableData, setCurrTableData] = useState([]);
  const [medicines, setMedicines] = useState([]);
  const [selectedMedicine, setSelectedMedicine] = useState(null);
  const [compositionVal, setCompositionVal] = useState('');
  const [manualMedicine, setManualMedicine] = useState('');
  const [searchedManualMed, setSearchedManualMed] = useState('');
  const [manualSearchVal, setManualSearchVal] = useState('')
  const [manualEditSearchVal, setManualEditSearchVal] = useState('');
  const [manualMedEditSearchVal, setManualMedEditSearchVal] = useState('');
  const [manualEditEnabled, setManualEditEnabled] = useState(false);
  const [currLabEditIndex, setCurrLabEditIndex] = useState();
  const [currMedEdit, setCurrMedEdit] = useState({
    index: -1,
    type: ''
  });
  const [manualMedEditEnabled, setManualMedEditEnabled] = useState(false);
  const [selectDiagEditEnabled, setSelectDiagEditEnabled] = useState(false);
  const [selectTagEditEnabled, setSelectTagEditEnabled] = useState(false);
  const [medSelectTagEditEnabled, setMedSelectTagEditEnabled] = useState(false);
  const [labTests, setLabTests] = useState([]);
  const [selectedLabTest, setSelectedLabTest] = useState({});
  const [isSelected, setIsSelected] = useState(false);
  const [dosage, setDosage] = useState()
  const [duration, setDuration] = useState();
  const [quantity, setQuantity] = useState();
  const [timeTaken, setTimeTaken] = useState();
  const [instruction, setInstruction] = useState();


  useEffect(() => {
    if (consulationData?.patientDiagnosisDetails?.length) {
      setSelectedDiagArr(consulationData?.patientDiagnosisDetails);
      setSelectedOriginalDiagArr(consulationData?.patientDiagnosisDetails);
      setShowTable((prevState) => ({
        ...prevState,
        diagnosisTable: true,
      }));
    }
  }, [consulationData]);

  useEffect(() => {
    if (searchedManualMed && InputRef.current) InputRef.current.focus();
  }, [searchedManualMed]);


  // useEffect(() => {
  //   fetchICDData();
  // }, []);


  // const fetchICDData = async () => {
  //   try {
  //     const response = await ConsultationService.ICDData();
  //     setDiagnosticList(response.data);
  //     setCurrPrimaryDiagList(response.data);
  //     setSecondaryDiag(response.data);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };


  const handleDelete = (record, type) => {

    if (record?.consultationId) {
      // const updatedList = selectedLabtestList.filter((item) => item.status !== 0 && item.id !== record.id);
      const itemToBeDeletedIdx = selectedDiagArr.findIndex((item) => item.id === record.id && item.diagnosisType === type);
      const currDiagList = selectedDiagArr;
      if (itemToBeDeletedIdx !== -1) {
        currDiagList[itemToBeDeletedIdx].status = 0;
      }
      console.log(currDiagList, "     after deletion ");
      setSelectedDiagArr([...currDiagList]);
      handleDiagonsisArr([...currDiagList]);
    }
    else {
      const updatedList = selectedDiagArr.filter((item) => !(item.id === record.id && item.diagnosisType === type));
      // const updatedListForTable = selectedLabtestList.filter((item) => item.status !== 0 && item.labTestId !== value.labTestId);
      console.log(updatedList, "     after deletion ");
      setSelectedDiagArr([...updatedList]);
      handleDiagonsisArr([...updatedList]);

    }
    // const updatedTableData = selectedDiagArr.filter((item) => item.id !== record.id);
    // const itemToBeDeletedIdx = selectedDiagArr.findIndex((item) => item.id === record.id);
    // if (itemToBeDeletedIdx !== -1) {
    //   diagOrgArr[itemToBeDeletedIdx].status = 0;
    // }
    // setSelectedDiagArr(updatedTableData);
    // setSelectedOriginalDiagArr(diagOrgArr);
  };

  const handleSecDelete = (record) => {
    const updatedTableData = selectedDiagArr.filter(
      (item) => item.id !== record.id
    );
    setSelectedDiagArr(updatedTableData);
  };

  // const Columns = [
  //   {
  //     title: "Type",
  //     dataIndex: "diagnosisType",
  //     key: "diagnosisType",
  //   },
  //   {
  //     title: "Description",
  //     dataIndex: "diagnosisDescription",
  //     key: "diagnosisDescription",
  //     render: (_, record) => (
  //       <Space className="text-left">
  //         {record?.diagnosisDescription?.length > 20
  //           ? ` ${record?.diagnosisDescription?.slice(0, 20)}..`
  //           : record.diagnosisDescription}
  //       </Space>
  //     ),
  //   },
  //   {
  //     title: "ICD Code",
  //     dataIndex: "diagnosisCode",
  //     key: "diagnosisCode",
  //   },
  //   {
  //     title: "",
  //     dataIndex: "delete",
  //     render: (_, record) => (
  //       <Space size="middle" className="">
  //         <div className="flex items-center gap-2 ">
  //           <div className="">
  //             <img
  //               src={deleteicon}
  //               alt="img"
  //               className="w-6 h-6"
  //               onClick={() => handleDelete(record)}
  //             />
  //           </div>
  //         </div>
  //       </Space>
  //     ),
  //   },
  // ];

  // const customHeaderContent = (
  //   <thead className="">
  //     <tr>
  //       <th className="bg-blue-100 text-left px-3 py-3">Type</th>
  //       <th className="bg-blue-100 py-3 px-3 text-left">ICD Description</th>
  //       <th className="bg-blue-100 py-3 px-3 text-left">ICD Code</th>
  //       <th className="bg-blue-100 py-3 px-3 text-left"></th>
  //     </tr>
  //   </thead>
  // );


  const handlePrimaryDiagVal = (value) => {
    setSelectedPrimaryDiagVal(value);
    const selectedDiag = diagnosticList?.find(
      (diagnosis) => diagnosis.code === value
    );
    setSelectedCode(selectedDiag);
  };

  const handleSecondaryDiagVal = (value) => {
    setSelectedSecondaryDiagVal(value);
    const selectedDiag = diagnosticList?.find(
      (diagnosis) => diagnosis.code === value
    );
    setSelectedCode(selectedDiag);
  };

  const [handlediagstate, setHandlediagstate] = useState(true)

  const handleDiag = (type) => {
    setHandlediagstate(true)
    const checkDuplicate = (arr, code, diagnosisType) =>
      arr.some((item) => item.diagnosisCode === code && item.diagnosisType === diagnosisType);

    if (type === "Primary") {
      if (selectedPrimaryDiagVal && selectedPrimaryDiagVal.length !== 0) {
        const selected = diagnosticList.filter(
          (item) => item.code === selectedPrimaryDiagVal
        )[0];

        if (checkDuplicate(selectedDiagArr, selected.code, "Primary")) {
          message.error("This diagnosis has already been added as a Primary diagnosis.");
          return;
        }
        console.log(type, ' type ', selected)
        setSelectedPrimaryDiag(null);
        selected.diagnosisType = type;
        selected.diagnosisCode = selected.code;
        selected.diagnosisDescription = selected.description;
        const newItem = selected; //new item going to add in the list.
        const currDiagArr = selectedDiagArr; // already selected items.
        currDiagArr.push({ ...newItem, diagnosisType: type });
        setSelectedDiagArr(currDiagArr);
        console.log('after adding prim ', currDiagArr)
        // setSelectedOriginalDiagArr(currDiagArr);
        handleDiagonsisArr(currDiagArr);
        // setSelectedDiagArr((prevState) => [...prevState, selected]);
        // setSelectedOriginalDiagArr((prevState) => [...prevState, selected]);
        // handleDiagonsisArr([...selectedOriginalDiagArr, selected]);

        setShowTable((prevState) => ({
          ...prevState,
          diagnosisTable: true,
        }));

        setSelectedCode(null);
        setKey(key + 1);
      } else {
        setSelectedPrimaryDiag(null);
      }
    } else {
      if (selectedSecondaryDiagVal && selectedSecondaryDiagVal.length !== 0) {
        const selected = diagnosticList.filter(
          (item) => item.code === selectedSecondaryDiagVal
        )[0];

        if (checkDuplicate(selectedDiagArr, selected.code, "Secondary")) {
          message.error("This diagnosis has already been added as a Secondary diagnosis.");
          return;
        }

        setSelectedSecondaryDiag(null);
        console.log(type, ' type ', selected)
        selected.diagnosisType = type;
        selected.diagnosisCode = selected.code;
        selected.diagnosisDescription = selected.description;
        const newItem = selected; //new item going to add in the list.
        const currDiagArr = selectedDiagArr; // already selected items.
        currDiagArr.push({ ...newItem, diagnosisType: type });
        setSelectedDiagArr(currDiagArr);
        console.log('after adding sec ', currDiagArr)
        // setSelectedOriginalDiagArr(currDiagArr);
        handleDiagonsisArr(currDiagArr);
        // setSelectedDiagArr((prevState) => [...prevState, selected]);
        // setSelectedOriginalDiagArr((prevState) => [...prevState, selected]);
        // handleDiagonsisArr([...selectedOriginalDiagArr, selected]);

        setSelectedCode(null);
        setShowTable((prevState) => ({
          ...prevState,
          diagnosisTable: true,
        }));

        setSecKey(secKey + 1);
      } else {
        setSelectedSecondaryDiag(null);
      }
    }
  };


  // const handleDiag = (type) => {

  //   if (type === "Primary") {
  //     if (selectedPrimaryDiagVal && selectedPrimaryDiagVal.length !== 0) {
  //       console.log(selectedPrimaryDiagVal, diagnosticList);
  //       const selected = diagnosticList.filter(
  //         (item) => item.code === selectedPrimaryDiagVal
  //       )[0];
  //       console.log("selected ", selected);
  //       setSelectedPrimaryDiag(null);
  //       selected.diagnosisType = type;
  //       selected.diagnosisCode = selected.code;
  //       selected.diagnosisDescription = selected.description;
  //       console.log("final   ", selected);
  //       setSelectedDiagArr((prevState) => [...prevState, selected]);
  //       setSelectedOriginalDiagArr((prevState) => [...prevState, selected]);
  //       handleDiagonsisArr([...selectedOriginalDiagArr, selected]);

  //       setShowTable((prevState) => ({
  //         ...prevState,
  //         diagnosisTable: true,
  //       }));
  //       //setCurrPrimaryDiagList([])

  //       setSelectedCode(null);
  //       setKey(key + 1);
  //     } else {
  //       setSelectedPrimaryDiag(null);
  //     }
  //   } else {
  //     if (selectedSecondaryDiagVal && selectedSecondaryDiagVal.length !== 0) {
  //       const selected = diagnosticList.filter(
  //         (item) => item.code === selectedSecondaryDiagVal
  //       )[0];
  //       setSelectedSecondaryDiag(null);
  //       console.log(" selected ", selected);
  //       selected.diagnosisType = type;
  //       selected.diagnosisCode = selected.code;
  //       selected.diagnosisDescription = selected.description;
  //       setSelectedDiagArr((prevState) => [...prevState, selected]);
  //       setSelectedOriginalDiagArr((prevState) => [...prevState, selected]);
  //       handleDiagonsisArr([...selectedOriginalDiagArr, selected]);
  //       setSelectedCode(null);
  //       setShowTable((prevState) => ({
  //         ...prevState,
  //         diagnosisTable: true,
  //       }));
  //       // setCurrSecondaryDiagList([])

  //       setSecKey(secKey + 1);
  //     } else {
  //       setSelectedSecondaryDiag(null);
  //     }
  //   }
  // };

  const onSearch = async (value) => {
    if (value) {
      let icdPayload = {
        description: value,
        status: 1
      }
      try {
        setIsLoading(true);
        const response = await ConsultationService.ICDData(icdPayload);
        setDiagnosticList(response.data);
        setIsLoading(false);
        // setCurrPrimaryDiagList(response.data);
        // setSecondaryDiag(response.data);
      } catch (e) {
        if (e.response.status === 404) {
          setDiagnosticList([]);
        }
        console.log(e);
      }
    }
    // try {

    //   const filtered = diagnosticList.filter((item) =>
    //     item.description.includes(value)
    //   );
    //   setCurrPrimaryDiagList(filtered);
    //   if (filtered && filtered.length !== 0) {
    //     setIsLoading(false);
    //   }
    // } catch (e) {
    //   console.log(e);
    // }
  };

  const primaryIcdCodeSearch = async (value) => {
    if (value) {
      let icdPayload = {
        code: value.toUpperCase(),
        status: 1
      }
      try {
        setIsLoading(true);
        const response = await ConsultationService.ICDData(icdPayload);
        setDiagnosticList(response.data);
        setIsLoading(false);
        // setCurrPrimaryDiagList(response.data);
        // setSecondaryDiag(response.data);
      } catch (e) {
        if (e.response.status === 404) {
          setDiagnosticList([]);
          setIsLoading(false);
        }
        console.log(e);
      }
    }
    // try {
    //   setIsLoading(true);
    //   const filtered = diagnosticList.filter((item) =>
    //     item.code.includes(value.toUpperCase())
    //   );
    //   setCurrPrimaryDiagList(filtered);
    //   if (filtered && filtered.length !== 0) {
    //     setIsLoading(false);
    //   }
    // } catch (e) {
    //   console.log(e);
    // }
  };

  const handlePrimaryCode = (value) => {
    if (value && value.length !== 0) {
      const selected = diagnosticList.filter((item) => item.code === value)[0];
      setSelectedPrimaryDiag(selected);
      setSelectedDiagArr((prevState) => [...prevState, selected]);
    } else {
      setSelectedPrimaryDiag({});
    }
  };

  const onSearch2 = async (value) => {
    if (value) {
      let icdPayload = {
        description: value,
        status: 1
      }
      try {
        setIsLoading(true);
        const response = await ConsultationService.ICDData(icdPayload);
        setDiagnosticList(response.data);
        setIsLoading(false);
        // setCurrPrimaryDiagList(response.data);
        // setSecondaryDiag(response.data);
      } catch (e) {
        if (e.response.status === 404) {
          setDiagnosticList([]);
        }
        console.log(e);
      }
    }
    // try {
    //   setIsLoading(true);
    //   const filtered = diagnosticList.filter((item) =>
    //     item.description.includes(value)
    //   );
    //   // const filtered = await ConsultationService.ICDCode(value);
    //   setCurrSecondaryDiagList(filtered);
    //   if (filtered && filtered.length !== 0) {
    //     setIsLoading(false);
    //   }
    // } catch (e) {
    //   console.log(e);
    // }
  };

  const secondaryIcdCodeSearch = async (value) => {
    if (value) {
      let icdPayload = {
        code: value.toUpperCase(),
        status: 1
      }
      try {
        setIsLoading(true);
        const response = await ConsultationService.ICDData(icdPayload);
        setDiagnosticList(response.data);
        setIsLoading(false);
        // setCurrPrimaryDiagList(response.data);
        // setSecondaryDiag(response.data);
      } catch (e) {
        if (e.response.status === 404) {
          setDiagnosticList([]);
          setIsLoading(false);
        }
        console.log(e);
      }
    }
    // try {
    //   setIsLoading(true);
    //   const filtered = diagnosticList.filter((item) =>
    //     item.code.includes(value.toUpperCase())
    //   );
    //   // const filtered = await ConsultationService.ICDCode(value.toUpperCase());
    //   console.log("filtered ", filtered);
    //   setCurrSecondaryDiagList(filtered);
    //   if (filtered && filtered.length !== 0) {
    //     setIsLoading(false);
    //   }
    // } catch (e) {
    //   console.log(e);
    // }
  };

  const handleSecondaryCode = (value) => {
    const selected = diagnosticList.filter((item) => item.code === value)[0];
    setSelectedSecondaryDiag(selected);
  };

  const handleRadioChange = (e) => {
    setSelectedRadio(e.target.value);
  };

  useEffect(() => {
    console.log(manualMedicine)
  }, [manualMedicine]);

  useEffect(() => {
    if (consulationData) {
      setShowTable((prevState) => ({
        ...prevState,
        medicinesTable: true,
      }));
      setMedTableArr(consulationData?.drugPrescription)
    }
  }, [consulationData]);

  const onSearchmedicine = async (value, isSearchFromSelected) => {
    setSearchedValue(value);
    if (isSearchFromSelected) {
      if (value.length !== 0) {
        try {
          setIsLoading(true);
          const res = await DrugServices.fetchDrugList(value);
          if (res) {
            if (res.data.length > 0) {
              setMedicines(res?.data);
            }
          }
          setIsLoading(false);
        }
        catch (e) {
          console.error(e);
        }
      }
    }
    else {
      if (value.length !== 0) {
        try {
          setIsLoading(true);
          const res = await DrugServices.fetchDrugList(value);
          if (res) {
            if (res.data.length > 0) {
              setMedicines(res?.data);
            }
            else {
              setSearchedManualMed(value);
            }
          }
          setIsLoading(false);
        }
        catch (e) {
          console.error(e);
        }
      }
      else {
        setSearchedManualMed('');
      }
    }



  };

  const handleChangeMedicine = (label, value) => {

    if (value) {
      const isValueThere = Object.keys(value).length === 0 ? true : false;
      if (!isValueThere) {
        const selectedMed = medicines.find((item) => item.code === label);
        if (selectedMed && Object.keys(selectedMed)?.length > 0) {
          setSelectedMedicine(selectedMed);
          setCompositionVal(selectedMed?.composition);
        }
        else {
          value.medicineName = value.label;
          value.composition = value.label;
          setSelectedMedicine(value);
          setCompositionVal(value.label);
        }

        // DrugServices.fetchDrugList(value.label).then((res) => {
        // if (res.data?.length !== 0) {
        //   setSelectedMedicine(res.data[0]);
        //   setCompositionVal(res.data[0]?.composition);
        // }
        // else {
        //   value.medicineName = value.label;
        //   value.composition = value.label;
        //   setSelectedMedicine(value);
        //   setCompositionVal(value.label);
        // }

        // }).catch((e) => {
        //   console.log(e);
        // })
      }
      else {
        setManualMedicine(label[0]);
      }
    }
    else {
      setSelectedMedicine(null);
      setCompositionVal('');
    }
  };
  const handlemedicine = (value, data) => {
    console.log(value, data, "vsa");

    if (value) {
      const isValIdx = medicines?.findIndex((med) => med.code === value);

      if (isValIdx === -1) {
        const payload = [...currTableData];
        const newMedicine = { name: value, code: value };
        payload.push(newMedicine);
        setSearchedManualMed(newMedicine);
        setCurrTableData(payload);
        setIsSelected(true);
        console.log(value);
      } else {
        const editedItemIdx = currTableData.findIndex((item) => item.id === data.id);
        const updatedMedicine = medicines.find((med) => med.code === value);

        if (editedItemIdx !== -1) {
          const updatedTableData = [...currTableData];
          updatedTableData[editedItemIdx] = {
            ...updatedTableData[editedItemIdx],
            drugCode: updatedMedicine.drugCode,
            drugName: updatedMedicine.drugName,
            dosage: updatedMedicine.dosage,
            duration: updatedMedicine.duration,
            composition: updatedMedicine.composition,
            instruction: updatedMedicine.instruction,
            timeTaken: updatedMedicine.timeTaken,
            status: 1,
          };
          setCurrTableData(updatedTableData.filter((item) => item.status !== 0));
          handleAddMedicines(updatedTableData);
          setIsSelected(true);
        }
      }
    }
  };

  const manualSearch = async (e) => {
    if (e.target.value) {
      setSearchedManualMed(e.target.value);
    }
    else {
      setSearchedManualMed('');
    }
  }

  const [handleSubmitState, sethandleSubmit] = useState(true)

  const handleComposition = (value) => {
    setCompositionVal(value)
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    sethandleSubmit(true)
    let { drugName, composition,quantity, dosage, duration, timeTaken, notes } = formRef.current.getFieldValue();
    let isMedicineExist;
    if (!searchedManualMed) {
      isMedicineExist = medTableArr?.findIndex((med) => med.drugCode === selectedMedicine.code);
    }
    if (!searchedManualMed && isMedicineExist !== -1) {
      message.error("Medicine already exist in the prescription!");
      return;
    }
    let medicineData = {
      drugCode: selectedMedicine ? selectedMedicine?.code : searchedManualMed,
      drugName: selectedMedicine ? selectedMedicine?.medicineName : searchedManualMed,
      composition: selectedMedicine?.composition ? selectedMedicine?.composition : compositionVal,
      dosage,
      duration,
      timeTaken,
      quantity,
      instruction: notes,
      status: 1
    };

    medicineData.id = generateUniqueId();
    const medicinesList = medTableArr; // already selected medicines
    medicinesList.push(medicineData); // adding newly added medicine
    console.log('medicinesList ......', medicinesList);
    setMedTableArr(medicinesList);
    handleAddMedicines(medicinesList);
    formRef.current.resetFields();
    setManualMedicine('');
    setSearchedManualMed('');
    setCompositionVal('');
    setShowTable((prevState) => ({
      ...prevState,
      medicinesTable: true,
    }));
    setSelectedMedicine(null);

  };
  const options = [
    "0-0-1",
    "0-1-1",
    "0-1-0",
    "1-1-1",
    "1-0-0",
    "1-0-1",
    "1-1-0",
    "1-1-1-1",
    "1-0-0-1",
    "1-0-0-0",
    "1-1-0-0",
    "1-1-1-0",
    "0-0-0-1",
    "0-0-1-1",
    "0-0-1-0",
    "0-1-0-0",
    "0-1-1-1",
    "s.o.s",
    "q.wk.",
    "b.i.d.",
    "t.i.d.",
    "q.i.d.",
    "q.h.s.",
    "5X a day",
    "q.4h",
    "q.6h",
    "q.0.d.",
    "prn.",
    "q.d.",
    "I.M.",
    "Subq.",
    "Rectally",
    "I.V.",
    "O.D.",
    "O.S.",
    "O.U.",
    "2 ml",
    "2.5 ml",
    "5 ml",
    "10 ml",
  ];
  const columnsMedicine = [
    {
      title: "Medicine Name",
      dataIndex: "drugName",
      key: "drugName",
      render: (_, record) => {
        return (
          <div className="flex flex-col justify-center">
            <span>{record.drugName}</span>
            <span className="font-light">({record?.composition?.length > 20 ? `${record?.composition?.slice(0, 20)}...` : record.composition})</span>
          </div>
        )
      }
    },
    // {
    //   title: "Composition",
    //   dataIndex: "composition",
    //   key: "composition",
    //   render: (_, record) => {
    //     return record?.composition?.length > 20 ?
    //       `${record?.composition?.slice(0, 20)}...` :
    //       record.composition
    //   }
    // },
    {
      title: "Dosage",
      dataIndex: "dosage",
      key: "dosage",
      render: (_, record) => {
        return <div className="flex flex-col justify-center">
          <span>{record?.dosage}</span>
          <span className="font-light">({record.timeTaken === "afterfood" ? "After Food" : "Before Food"})</span>
        </div>

      }
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      render: (_, record) => {
        return <div className="flex flex-col justify-center">
          <span>{record.duration} Days</span>
          <span className="font-light">{record?.instruction?.length > 20
            ? `(${record?.instruction?.slice(0, 20)}...)`
            : (record?.instruction)}
          </span>
        </div>
      }
    },
    // {
    //   title: "Taken Time",
    //   dataIndex: "timeTaken",
    //   key: "timeTaken",
    //   render: (text, record) => (
    //     <span>
    //       {record.timeTaken === "afterfood" ? "After Food" : "Before Food"}
    //     </span>
    //   ),
    // },
    // {
    //   title: "Instruction",
    //   dataIndex: "instruction",
    //   key: "instruction",
    // },
    {
      title: "",
      dataIndex: "delete",
      render: (_, record) => (
        <Space size="middle" className="">
          <div className="flex items-center gap-2 ">
            <div className="">
              <img
                src={deleteicon}
                alt="img"
                className="w-6 h-6 cursor-pointer"
                onClick={() => handleDeleteMedicine(record)}
              />
            </div>
          </div>
        </Space>
      ),
    },
  ];
  const handleDeleteMedicine = (record) => {
    console.log(record);
    if (record?.consultationId) {
      // const updatedTableData = currTableData.filter((item) => item.status !== 0 && item.id !== record.id);
      const itemToBeDeletedIdx = medTableArr.findIndex((item) => item.drugCode === record.drugCode);
      const medicinesList = medTableArr;
      if (itemToBeDeletedIdx !== -1) {
        medicinesList[itemToBeDeletedIdx].status = 0;
      }
      console.log(medicinesList);
      setMedTableArr([...medicinesList])
      handleAddMedicines([...medicinesList]);
    }
    else {
      const updatedList = medTableArr.filter((item) => item.drugCode !== record.drugCode);
      setMedTableArr([...updatedList]);
      handleAddMedicines([...updatedList]);
    }
  };
  const customHeaderContentMedicine = (
    <thead className="">
      <tr>
        <th className="bg-blue-100 py-3 text-B">Medicine Name</th>
        {/* <th className="bg-blue-100 py-3 text-B">Composition</th> */}
        <th className="bg-blue-100 py-3 text-B">Dosage</th>
        <th className="bg-blue-100 py-3 text-B">Duration</th>
        {/* <th className="bg-blue-100 py-3 text-B">Taken Time</th> */}
        {/* <th className="bg-blue-100 py-3 text-B">Notes</th> */}
        <th className="bg-blue-100 py-3 text-B"></th>
      </tr>
    </thead>
  );
  const manualTestSearch = (e) => {
    console.log(e.target.value)
    if (e.target.value) {
      setManualSearchVal(e.target.value);
      let manualTestVal = { labTestId: e.target.value, labTestName: e.target.value };
      console.log(manualTestVal)
      setSelectedLabTest(manualTestVal);
    }
    else {
      setManualSearchVal('');
    }

  }

  const handleManualEdit = (e, index) => {
    setManualEditEnabled(true); // this will enable edit in manual input edit of added lab tests
    setCurrLabEditIndex(index);
    if (e.target.value) {
      setManualEditSearchVal(e.target.value);
      let manualTestVal = { labTestId: e.target.value, labTestName: e.target.value };
      console.log(manualTestVal)
      setSelectedLabTest(manualTestVal);
    }
    else {
      setManualEditSearchVal('');
    }
  }

  const handleManualMedEdit = (e, type, index) => {
    setManualMedEditEnabled(true); // this will enable edit in manual input edit of added lab tests

    if (type === 'input') {
      setCurrMedEdit({
        index: index,
        type: 'input'
      })
      if (e) {
        setManualMedEditSearchVal(e.target.value);
      }
      else {
        setManualMedEditSearchVal('');
      }
    }
    else if (type === 'composition') {
      setCurrMedEdit({
        index: index,
        type: 'composition'
      })
      setCompositionVal(e.target.value);
    }
    else if (type === 'dosage') {
      setCurrMedEdit({
        index: index,
        type: 'dosage'
      })
      setDosage(e);
    }
    else if (type === 'duration') {
      setCurrMedEdit({
        index: index,
        type: 'duration'
      })
      setDuration(e);
    }
    else if (type === 'quantity') {
      setCurrMedEdit({
        index: index,
        type: 'quantity'
      })
      setQuantity(e);
    }
    else if (type === 'timetaken') {
      setCurrMedEdit({
        index: index,
        type: 'timetaken'
      })
      setTimeTaken(e);
    }
    else if (type === 'instruction') {
      setCurrMedEdit({
        index: index,
        type: 'instruction'
      })
      setInstruction(e.target.value);
    }

    // let { dosage, duration, timeTaken, notes } = formRef.current.getFieldValue();
    // let isMedicineExist;
    // if (!searchedManualMed) {
    //   isMedicineExist = medTableArr?.findIndex((med) => med.drugCode === selectedMedicine.code);
    // }
    // if (!searchedManualMed && isMedicineExist !== -1) {
    //   message.error("Medicine already exist in the prescription!");
    //   return;
    // }
    // let medicineData = {
    //   code: e.target.value,
    //   drugName: e.target.value,
    //   composition: compositionVal,
    //   dosage,
    //   duration,
    //   timeTaken,
    //   instruction: notes,
    //   status: 1
    // };
    // setSelectedLabTest(manualTestVal);
  }


  useEffect(() => {
    if (consulationData) {
      setShowTable((prevState) => ({
        ...prevState,
        labTable: true,
      }));
      setLabTableArr(consulationData?.labPrescription)
      setLabTableArr(consulationData?.labPrescription);
    }
  }, [consulationData])
  useEffect(() => {
    console.log(tabledata, " table Data");
    console.log(currTableData, "currTableData")
  }, [tabledata, currTableData])

  const handleChange = (value) => {
    console.log(value);
    if (value) {
      const isValIdx = labTests?.findIndex((lab) => lab.code === value);
      const item = labTests?.find((lab) => lab.code === value);
      console.log("item ", item, isValIdx);
      if (isValIdx === -1) {
        let payload = []
        let x = { labTestName: value, labTestId: value }
        payload.push(x)
        setSelectedLabTest(x)
        setIsSelected(true);
        console.log(value)
      }
      else {
        item.labTestId = item.code;
        item.labTestName = item.name;
        setSelectedLabTest(item);
        setIsSelected(true);
      }
    }

  }
  //This method is for lab
  //data argument is currItem in the array
  //value argument is new item to be added in the array
  //manual is boolean to check wheather changing item manual test or db present item
  const handleSelectedChange = (manual, value, data) => {
    setManualEditEnabled(false); // this will disable edit in manual input edit of added lab tests
    setSelectTagEditEnabled(true);

    if (manual) {
      let isMedicineExist;
      if (value) {
        isMedicineExist = labTableArr?.findIndex((lab) => lab.labTestId === value?.labTestId);
      }
      if (!manualSearchVal && isMedicineExist !== -1) {
        message.error("Lab Test already exist in the prescription!");
        return;
      }

      if (data.consultationId) {
        const editedItemIdx = labTableArr.findIndex((test) => test.labTestId === data.labTestId);
        const newItem = value;
        const addedLabtests = labTableArr;
        addedLabtests[editedItemIdx].status = 0;
        addedLabtests.push({ ...newItem, status: 1 });
        console.log(addedLabtests);
        setLabTableArr([...addedLabtests]);
        handleAddLabTests([...addedLabtests]);
        // setIsSelected(true);
        setSelectTagEditEnabled(false);
      }
      else {
        const filteredItemsArr = labTableArr.filter((test) => test.labTestId !== data.labTestId);  //removing previous item
        const newItem = value;
        const addedLabtests = filteredItemsArr;
        addedLabtests.push({ ...newItem, status: 1 });
        console.log(addedLabtests);
        setLabTableArr([...addedLabtests]);
        handleAddLabTests([...addedLabtests]);
        // setIsSelected(true);
        setSelectTagEditEnabled(false);
      }
    }
    else {
      let isMedicineExist;
      if (value) {
        isMedicineExist = labTableArr?.findIndex((lab) => lab.labTestId === value);
      }
      if (!manualSearchVal && isMedicineExist !== -1) {
        message.error("Lab Test already exist in the prescription!");
        return;
      }

      if (value) {
        if (data.consultationId) {
          const editedItemIdx = labTableArr.findIndex((test) => test.id === data.id);
          const newItem = labTests.find((test) => test.code === value);
          const addedLabtests = labTableArr; // already selected tests array
          // newItem.labTestId = newItem.code
          // newItem.labTestName = newItem.name
          addedLabtests[editedItemIdx].status = 0;
          addedLabtests.push({ ...newItem, labTestId: newItem.code, labTestName: newItem.name });
          console.log(addedLabtests);
          setLabTableArr([...addedLabtests]);
          handleAddLabTests([...addedLabtests]);
          // setIsSelected(true);
          setSelectTagEditEnabled(false);
        }
        else {
          const filteredItemsArr = labTableArr.filter((test) => test.id !== data.id); //removing previous item
          const newItem = labTests.find((test) => test.code === value);
          const addedLabtests = filteredItemsArr; // filtered selected tests array
          // newItem.labTestId = newItem.code
          // newItem.labTestName = newItem.name
          addedLabtests.push({ ...newItem, labTestId: newItem.code, labTestName: newItem.name });
          console.log(addedLabtests);
          setLabTableArr([...addedLabtests]);
          handleAddLabTests([...addedLabtests]);
          // setIsSelected(true);
          setSelectTagEditEnabled(false);
        }

      }
    }
  }

  //This method is for medicine
  //data argument is currItem in the array
  //value argument is new item to be added in the array
  //type specifies which field is going to edit
  //manual is boolean to check wheather changing item manual medicine or db present item
  const handleSelectedMedChange = (manual, type, value, data) => {
    setManualMedEditEnabled(false); // this will disable edit in manual input edit of added medicine
    if (type === 'select') {
      setMedSelectTagEditEnabled(true); //only enable if the selected element is Select which is used for medicine
    }

    if (value) {
      const editedItemIdx = medTableArr.findIndex((test) => test.drugCode === data.drugCode);
      const filteredItemsArr = medTableArr.filter((test) => test.drugCode !== data.drugCode); //removing previous item
      const newItem = medicines.find((test) => test.code === value);
      const currMedArr = medTableArr;
      let addedMedicines;


      // this will handle select drop down medicine
      if (type === 'select') {
        if (data.consultationId) {
          addedMedicines = medTableArr; // already selected tests array
        }
        else {
          addedMedicines = filteredItemsArr; // filtered selected tests array
        }

        const medicineData = {
          ...medTableArr[editedItemIdx],
          drugCode: newItem.code,
          drugName: newItem.medicineName,
          composition: newItem.composition,
          status: 1
        };
        if (data.consultationId) {
          addedMedicines[editedItemIdx].status = 0;
        }
        addedMedicines.push({ ...medicineData });
        setMedTableArr([...addedMedicines]);
        handleAddMedicines([...addedMedicines]);
      }
      //this will handle manual medicine
      else if (type === 'input') {
        if (data.consultationId) {
          addedMedicines = medTableArr; // already selected tests array
        }
        else {
          addedMedicines = filteredItemsArr; // filtered selected tests array
        }

        const medicineData = {
          ...medTableArr[editedItemIdx],
          code: value,
          drugName: value,
          status: 1
        };

        if (data.consultationId) {
          addedMedicines[editedItemIdx].status = 0;
        }

        addedMedicines.push({ ...medicineData });
        setMedTableArr([...addedMedicines]);
        handleAddMedicines([...addedMedicines]);

      }
      else if (type === 'composition') {
        currMedArr[editedItemIdx].composition = value;
        setMedTableArr(currMedArr);
        handleAddMedicines(currMedArr);
        setCompositionVal('');
      }
      else if (type === 'dosage') {
        currMedArr[editedItemIdx].dosage = value;
        setMedTableArr(currMedArr);
        handleAddMedicines(currMedArr);
        setDosage('');
      }
      else if (type === 'duration') {
        currMedArr[editedItemIdx].duration = value;
        setMedTableArr(currMedArr);
        handleAddMedicines(currMedArr);
        setDuration('');
      }
      else if (type === 'quantity') {
        currMedArr[editedItemIdx].quantity = value;
        setMedTableArr(currMedArr);
        handleAddMedicines(currMedArr);
        setQuantity('');
      }
      else if (type === 'timetaken') {
        currMedArr[editedItemIdx].timeTaken = value;
        setMedTableArr(currMedArr);
        handleAddMedicines(currMedArr);
        setTimeTaken('');
      }
      else if (type === 'instruction') {
        currMedArr[editedItemIdx].instruction = value;
        setMedTableArr(currMedArr);
        handleAddMedicines(currMedArr);
        setInstruction('');
      }

      console.log(addedMedicines, '  addedOne ', ' med tab arr', medTableArr);
      setMedSelectTagEditEnabled(false);

    }

    setCurrMedEdit({
      index: -1,
      type: ''
    })

  }


  const handleFinish = (e) => {
    e.preventDefault();
    setIsFinished(true);
    const data = selectedLabTest;
    let isMedicineExist;
    if (data) {
      isMedicineExist = labTableArr?.findIndex((lab) => lab.labTestId === data?.labTestId);
    }
    if (!manualSearchVal && isMedicineExist !== -1) {
      message.error("Lab Test already exist in the prescription!");
      return;
    }
    //it checks wheather adding item manual or db present one
    if (data.labTestId !== data.labTestName) {
      const labArr = labTableArr;
      labArr.push(data);
      setLabTableArr(labArr);
      formRef.current.resetFields();
      handleAddLabTests(labArr);
      setShowTable((prevState) => ({
        ...prevState,
        labTable: true,
      }));
      setIsSelected(false);
    }
    else {
      const labArr = labTableArr;
      const newItem = { ...selectedLabTest, status: 1 } //new adding item
      labArr.push(newItem);
      setManualSearchVal('');
      setLabTableArr(labArr)
      formRef.current.resetFields();
      handleAddLabTests(labArr);
      setShowTable((prevState) => ({
        ...prevState,
        labTable: true,
      }));
      setIsSelected(false);
    }

    // try {
    //   ConsultationService.LabTestCode(value).then((res) => {
    //     console.log("from handle search", res.data[0]);
    //     if (res?.data[0]) {
    //       setSelectedLabTest(res.data[0]);
    //     }

    //   });
    // }
    // catch (e) {
    //   console.log(e);
    // }

  };
  // const customHeaderContentLap = (
  //   <thead className="">
  //     <tr>
  //       <th className="bg-blue-100 py-3 text-B">Code</th>
  //       <th className="bg-blue-100 py-3 text-B">Name</th>
  //       <th className="bg-blue-100 py-3 text-B"></th>
  //     </tr>
  //   </thead>
  // );
  // const columnsLap = [
  //   {
  //     title: "Code",
  //     dataIndex: "labTestId",
  //     key: "labTestId",
  //   },
  //   {
  //     title: "Name",
  //     dataIndex: "labTestName",
  //     key: "labTestName"
  //   },
  //   {
  //     title: "",
  //     dataIndex: "delete",
  //     render: (_, record) => (
  //       <Space size="middle" className="">
  //         <div className="flex items-center gap-">
  //           <div className="">
  //             <img
  //               src={deleteicon}
  //               alt="img"
  //               className="w-6 h-6 cursor-pointer"
  //               onClick={() => handleDeleteLap(record)}
  //             />
  //           </div>
  //         </div>
  //       </Space>
  //     ),
  //   },
  // ];
  const handleDeleteLap = (value) => {

    console.log("value", value);
    if (value?.consultationId) {
      // const updatedList = selectedLabtestList.filter((item) => item.status !== 0 && item.labTestId !== value.labTestId);
      const itemToBeDeletedIdx = labTableArr.findIndex((item) => item.labTestId === value.labTestId);
      const LabTestListOrg = labTableArr;
      if (itemToBeDeletedIdx !== -1) {
        LabTestListOrg[itemToBeDeletedIdx].status = 0;
      }
      console.log(LabTestListOrg, "     after deletion ");
      setLabTableArr([...LabTestListOrg]);
      handleAddLabTests([...LabTestListOrg]);
    }
    else {
      const updatedList = labTableArr.filter((item) => item.labTestId !== value.labTestId);
      // const updatedListForTable = selectedLabtestList.filter((item) => item.status !== 0 && item.labTestId !== value.labTestId);
      console.log(updatedList, "     after deletion ");
      setLabTableArr([...updatedList]);
      handleAddLabTests([...updatedList]);

    }

  };
  const onSearchLap = (value) => {

    if (value) {
      setIsLoading(true);
      ConsultationService.LabTest(value).then((res) => {
        if (res.data.length > 0) {
          setLabTests(res.data);
        }
        else {
          // let manualTestVal = { name: value, code: value };
          // setManualTest(manualTestVal);
          setManualSearchVal(value);
          let manualTestVal = { labTestId: value, labTestName: value };
          setSelectedLabTest(manualTestVal);
        }
        // else {
        //   let payload = []
        //   let x = { name: value, code: value }

        //   payload.push(x)
        //   console.log(payload)
        //   setLabTests(payload)
        //   setSelectedLabTest(x)
        // }
        setIsLoading(false);
      }).catch((err) => {
        console.log(err);
      })
    }
  };

  const handleSelectedDiagChange = (value, data, type) => {
    setSelectDiagEditEnabled(true); // this will enable edit in edit of the select element of the added diagnosis

    const checkDuplicate = (arr, code, diagnosisType) =>
      arr.some((item) => item.diagnosisCode === code && item.diagnosisType === diagnosisType);


    if (checkDuplicate(selectedDiagArr, value, type)) {
      message.error(`This diagnosis has already been added as a ${type} diagnosis.`);
      return;
    }

    if (data.consultationId) {
      const editedItemIdx = selectedDiagArr.findIndex((item) => (item.id === data.id && item.diagnosisType === type));
      const newItem = diagnosticList.filter((item) => item.code === value)[0];
      const addedDiagList = selectedDiagArr; // already selected tests array
      // newItem.diagnosisType = type;
      // newItem.diagnosisCode = newItem.code;
      // newItem.diagnosisDescription = newItem.description;
      addedDiagList[editedItemIdx].status = 0;
      addedDiagList.push({ ...newItem, diagnosisType: type, diagnosisCode: newItem.code, diagnosisDescription: newItem.description });
      console.log(addedDiagList);
      setSelectedDiagArr(addedDiagList);
      handleDiagonsisArr(addedDiagList);
      // setIsSelected(true);
      setSelectTagEditEnabled(false);
    }
    else {
      const filteredItemsArr = selectedDiagArr.filter((item) => (item.id === data.id && item.diagnosisType === type)); //removing previous item
      const newItem = diagnosticList.find((test) => test.code === value);
      const addedDiagList = filteredItemsArr; // filtered selected tests array
      // newItem.diagnosisType = type;
      // newItem.diagnosisCode = newItem.code;
      // newItem.diagnosisDescription = newItem.description;
      addedDiagList.push({ ...newItem, diagnosisType: type, diagnosisCode: newItem.code, diagnosisDescription: newItem.description });
      console.log(addedDiagList);
      setSelectedDiagArr(addedDiagList);
      handleDiagonsisArr(addedDiagList);
      // setIsSelected(true);
      setSelectTagEditEnabled(false);
    }


    // let selectedData = [...selectedDiagArr];

    // if (value) {
    //   selectedData[key][keyName] = value;
    // } else {
    //   selectedData[key]['diagnossDescription'] = ''
    //   selectedData[key]['code'] = ''
    // }

    // setSelectedDiagArr(selectedData)
  }

  return (
    <div className="border border-brand-textGray rounded-xl mb-4 relative p-3 bg-white">
      <div className=" items-center">
        <div className="font-bold mt-2 ml-2 text-blue-500 text-base">
          Consultation Notes
        </div>
      </div>
      <Form
        key={consulationData}
        layout="vertical"
        ref={formRef}
        form={form}
        initialValues={{
          // followupVisitDate:consulationData?moment(consulationData?.followupVisitDate):null,
          symptoms: consulationData?.symptoms,
          complaint: state?.selectedPatient.appointmentReason,
        }}
      // onFinish={handleFormSubmit}
      >
        <Row gutter={24} className="mt-2">
          <Col span={12}>
            <Form.Item
              label="Chief Complaint"
              name="complaint"
              className="mx-2"
            >
              <Input className="h-10" onChange={handleChiefComplaint} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Symptoms" className="mx-2">  <Input className="h-10" value={symptoms} onChange={handleSymptoms} placeholder="Enter Symptoms" /></Form.Item>
          </Col>
        </Row>
        <div className="container">
          <h3 className="font-bold mx-2 text-base">Diagnosis</h3>
          {/* <div
            className={`w-full mx-4 ${selectedDiagArr.length === 0 ? 'hidden' : 'block'}`}
          >
            <Row gutter={24} className="label-row">
              <Col span={12}>
                <label>Disease Condition</label>
              </Col>
              <Col span={12}>
                <label>ICD-10 code</label>
              </Col>
            </Row>
          </div> */}
          {/* <Row gutter={24}>
            <Col span={24} className="mx-3">
              <div
                className={`border-brand-textGray w-[98%] ${showTable?.diagnosisTable && selectedDiagArr.length !== 0 ? "block" : "hidden"
                  } rounded-lg my-6 relative`}
              >
                {selectedDiagArr.length && selectedDiagArr.filter(item => item.status !== 0).map((data, key) => {

                  return (
                    <>
                      {data?.diagnosisType?.toLowerCase() === "primary" ? (

                        <Row gutter={24} className="mt-2" key={selectedDiagArr}>
                          <Col span={10}>
                            <Form.Item

                              //name={`${diagnosisType}Diagnosis`}
                              className="mx-2"
                            >
                              <Select
                                //key={`primary-${key}`}
                                showSearch

                                name="diagnosisDescription"
                                className="h-10"
                                onSearch={onSearch}
                                onChange={(value) => handleSelectedDiagChange(value, data, 'Primary')}
                                value={data?.diagnosisDescription}
                                options={diagnosticList?.map((des) => {
                                  return {
                                    label: des.description,
                                    value: des.code,
                                  };
                                })}
                                filterOption={(input, option) =>
                                  option &&
                                  option.label &&
                                  option.label
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                notFoundContent={
                                  isLoading ? (
                                    <div className="text-center">
                                      <Spin size="small" />
                                    </div>
                                  ) : null
                                }

                              />
                            </Form.Item>
                          </Col>
                          <Col span={10}>
                            <Form.Item className="mx-2">
                              <Select
                                showSearch
                                //key={`primary-icd-${key}`}

                                name="code"
                                className="h-10"
                                onSearch={primaryIcdCodeSearch}
                                onChange={(value) => handleSelectedDiagChange(value, data, 'Primary')}
                                value={data?.diagnosisCode}
                                options={diagnosticList?.map((des) => {
                                  return {
                                    label: des.code,
                                    value: des.code,
                                  };
                                })}
                                filterOption={(input, option) =>
                                  option &&
                                  option.label &&
                                  option.label
                                    .toUpperCase()
                                    .indexOf(input.toUpperCase()) >= 0
                                }
                                notFoundContent={
                                  isLoading ? (
                                    <div className="text-center">
                                      <Spin size="small" />
                                    </div>
                                  ) : null
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <div className="flex items-center gap-2 ">
                              <div className="" style={{ marginTop: "7px" }}>
                                <img
                                  src={deleteicon}
                                  alt="img"
                                  className="w-6 h-6 cursor-pointer"
                                  onClick={() => handleDelete(data, "Primary")}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        <Row gutter={24} className="mt-2">
                          <Col span={10}>
                            <Form.Item

                              //name={`${diagnosisType}Diagnosis`}
                              className="mx-2"
                            >
                              <Select
                                // key={`secondary-${secKey}`}
                                showSearch
                                className="h-10"
                                name="diagnosisDescription"
                                placeholder="Select Secondary Diagnosis"
                                onChange={(value) => handleSelectedDiagChange(value, data, 'Secondary')}
                                value={data?.diagnosisDescription}
                                // onChange={(value) => handleDiag(value, "Secondary")}
                                onSearch={onSearch2}
                                options={diagnosticList?.map((des) => {
                                  return {
                                    label: des.description,
                                    value: des.code,
                                  };
                                })}
                                filterOption={(input, option) =>
                                  option &&
                                  option.label &&
                                  option.label
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                notFoundContent={
                                  isLoading ? (
                                    <div className="text-center">
                                      <Spin size="small" />
                                    </div>
                                  ) : null
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={10}>
                            <Form.Item className="mx-2">
                              <Select
                                // key={`secondary-icd-${secKey}`}
                                showSearch
                                placeholder="Select ICD Code"
                                className="h-10"
                                name="code"
                                onSearch={secondaryIcdCodeSearch}
                                onChange={(value) => handleSelectedDiagChange(value, data, 'Secondary')}
                                value={data?.diagnosisCode}
                                options={diagnosticList?.map((des) => {
                                  return {
                                    label: des.code,
                                    value: des.code,
                                  };
                                })}
                                filterOption={(input, option) =>
                                  option &&
                                  option.label &&
                                  option.label
                                    .toUpperCase()
                                    .indexOf(input.toUpperCase()) >= 0
                                }
                                notFoundContent={
                                  isLoading ? (
                                    <div className="text-center">
                                      <Spin size="small" />
                                    </div>
                                  ) : null
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <div className="flex items-center gap-2 ">
                              <div className="" style={{ marginTop: "7px" }}>
                                <img
                                  src={deleteicon}
                                  alt="img"
                                  className="w-6 h-6"
                                  onClick={() => handleDelete(data, "Secondary")}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </>
                  )
                })
                }
              </div>
            </Col>
          </Row> */}
          {/* {handlediagstate && ( */}
          <>
            <Form.Item className="mx-2 mb-0 mt-2">
              <Radio.Group value={selectedRadio} onChange={handleRadioChange} className="gap-3">
                <Radio value="primary">Primary</Radio>
                <Radio value="secondary">Secondary</Radio>
              </Radio.Group>
            </Form.Item>
            {selectedRadio === "primary" ? (
              <>
                <Row gutter={24} className="mt-2 flex items-end">
                  <Col span={11}>
                    <div
                      // label="Disease Condition"
                      name={`${diagnosisType}Diagnosis`}
                      className="mx-3"
                    >Disease Condition</div>
                    <Select
                      key={`primary-${key}`}
                      showSearch
                      placeholder="Select Primary Diagnosis"
                      className="h-10 w-[94%] mt-2 mx-2"
                      onSearch={onSearch}
                      onChange={handlePrimaryDiagVal}
                      value={selectedCode?.description}
                      options={diagnosticList?.map((des) => {
                        return {
                          label: des.description,
                          value: des.code,
                        };
                      })}
                      filterOption={(input, option) =>
                        option &&
                        option.label &&
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                      notFoundContent={
                        isLoading ? (
                          <div className="text-center">
                            <Spin size="small" />
                          </div>
                        ) : (!isLoading && diagnosticList.length === 0) ? (<span>No Item Found</span>) : null
                      }

                    />

                  </Col>
                  <Col span={11} >
                  
                    <div className="mx-3">ICD-10 code</div>
                    <Select
                      showSearch
                      key={`primary-icd-${key}`}
                      placeholder="Select ICD Code"
                      className="h-10 w-[96%] mx-3 mt-2"
                      onSearch={primaryIcdCodeSearch}
                      onChange={handlePrimaryDiagVal}
                      value={selectedCode?.code}
                      options={diagnosticList?.map((des) => {
                        return {
                          label: des.code,
                          value: des.code,
                        };
                      })}
                      filterOption={(input, option) =>
                        option &&
                        option.label &&
                        option.label
                          .toUpperCase()
                          .indexOf(input.toUpperCase()) >= 0
                      }
                      allowClear
                      notFoundContent={
                        isLoading ? (
                          <div className="text-center">
                            <Spin size="small" />
                          </div>
                        ) : (!isLoading && diagnosticList.length === 0) ? (<span>No Item Found</span>) : null
                      }
                    />
                    </Col>
                  
                  <div>
                     <button
                     onClick={() => handleDiag("Primary")}
                      title="Add New"
                      class="group cursor-pointer ml-6 bg-transparent outline-none hover:rotate-90 duration-300"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30px"
                        height="30px"
                        viewBox="0 0 24 24"
                        class="stroke-blue-400 fill-none group-hover:fill-blue-500 group-active:stroke-blue-200 group-active:fill-blue-600 group-active:duration-0 duration-300"
                      >
                        <path
                          d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                          stroke-width="1.5"
                        ></path>
                        <path
                          d="M8 12H16"
                          stroke-width="1.5"
                          className="group-hover:stroke-white"
                        ></path>
                        <path
                          d="M12 16V8"
                          stroke-width="1.5"
                          className="group-hover:stroke-white"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </Row>

              </>
            ) : (
              <>
                <Row gutter={24} className="mt-2 flex items-end">
                  <Col span={11}>
                    <div
                      // label="Disease Condition"
                      name={`${diagnosisType}Diagnosis`}
                      className="mx-3"
                    >Disease Condition</div>
                    <Select
                      key={`secondary-${secKey}`}
                      showSearch
                      className="h-10 w-[94%] mx-2 mt-2"
                      allowClear
                      value={selectedCode?.description}
                      placeholder="Select Secondary Diagnosis"
                      onChange={handleSecondaryDiagVal}
                      // onChange={(value) => handleDiag(value, "Secondary")}
                      onSearch={onSearch2}
                      options={diagnosticList?.map((des) => {
                        return {
                          label: des.description,
                          value: des.code,
                        };
                      })}
                      filterOption={(input, option) =>
                        option &&
                        option.label &&
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      notFoundContent={
                        isLoading ? (
                          <div className="text-center">
                            <Spin size="small" />
                          </div>
                        ) : (!isLoading && diagnosticList.length === 0) ? (<span>No Item Found</span>) : null
                      }
                    />

                  </Col>
                  <Col span={11}>
                    <div className="mx-3">ICD-10 code </div>
                    <Select
                      key={`secondary-icd-${secKey}`}
                      showSearch
                      placeholder="Select ICD Code"
                      className="h-10 w-[96%] mx-3 mt-2"
                      onSearch={secondaryIcdCodeSearch}
                      onChange={handleSecondaryDiagVal}
                      // onChange={(value) => handleDiag(value, "Secondary")}
                      value={selectedCode?.code}
                      options={diagnosticList?.map((des) => {
                        return {
                          label: des.code,
                          value: des.code,
                        };
                      })}
                      filterOption={(input, option) =>
                        option &&
                        option.label &&
                        option.label
                          .toUpperCase()
                          .indexOf(input.toUpperCase()) >= 0
                      }
                      allowClear
                      notFoundContent={
                        isLoading ? (
                          <div className="text-center">
                            <Spin size="small" />
                          </div>
                        ) : (!isLoading && diagnosticList.length === 0) ? (<span>No Item Found</span>) : null
                      }
                    />

                  </Col>
                  <div >
                     <button
                      onClick={() => handleDiag("Secondary")}
                      title="Add New"
                      class="group cursor-pointer ml-6 bg-transparent outline-none hover:rotate-90 duration-300"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30px"
                        height="30px"
                        viewBox="0 0 24 24"
                        class="stroke-blue-400 fill-none group-hover:fill-blue-500 group-active:stroke-blue-200 group-active:fill-blue-600 group-active:duration-0 duration-300"
                      >
                        <path
                          d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                          stroke-width="1.5"
                        ></path>
                        <path
                          d="M8 12H16"
                          stroke-width="1.5"
                          className="group-hover:stroke-white"
                        ></path>
                        <path
                          d="M12 16V8"
                          stroke-width="1.5"
                          className="group-hover:stroke-white"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </Row>

              </>

            )}
          </>
          {/* )} */}
          <Row gutter={24}>
            <Col span={24} className="mx-3">
              <div
                className={`border-brand-textGray w-[98%] ${showTable?.diagnosisTable && selectedDiagArr.length !== 0 ? "block" : "hidden"
                  } rounded-lg my-6 relative`}
              >
                <div
                  className={`w-full`}
                >
                  <Row gutter={24} className="label-row">
                    <Col span={4}>
                      <label>Type</label>
                    </Col>
                    <Col span={9} className="ml-3">
                      <label>Disease Condition</label>
                    </Col>
                    <Col span={9}>
                      <label>ICD-10 code</label>
                    </Col>
                  </Row>
                </div>
                {selectedDiagArr.length && selectedDiagArr.filter(item => item.status !== 0).map((data, key) => {

                  return (
                    <>
                      {data?.diagnosisType?.toLowerCase() === "primary" ? (

                        <Row gutter={24} className="mt-2" key={selectedDiagArr}>
                          <Col span={4}>
                            <Input value={data?.diagnosisType} disabled={true} className="w-full h-10 disabled:opacity-80 cursor-not-allowed" />
                          </Col>
                          <Col span={9}>
                            <Form.Item

                              //name={`${diagnosisType}Diagnosis`}
                              className="mx-3"
                            >
                              <Select
                                //key={`primary-${key}`}
                                showSearch

                                name="diagnosisDescription"
                                className="h-10"
                                onSearch={onSearch}
                                onChange={(value) => handleSelectedDiagChange(value, data, 'Primary')}
                                value={data?.diagnosisDescription}
                                options={diagnosticList?.map((des) => {
                                  return {
                                    label: des.description,
                                    value: des.code,
                                  };
                                })}
                                filterOption={(input, option) =>
                                  option &&
                                  option.label &&
                                  option.label
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                notFoundContent={
                                  isLoading ? (
                                    <div className="text-center">
                                      <Spin size="small" />
                                    </div>
                                  ) : null
                                }

                              />
                            </Form.Item>
                          </Col>
                          <Col span={9}>
                            <Form.Item className="mx-2">
                              <Select
                                showSearch
                                //key={`primary-icd-${key}`}

                                name="code"
                                className="h-10"
                                onSearch={primaryIcdCodeSearch}
                                onChange={(value) => handleSelectedDiagChange(value, data, 'Primary')}
                                value={data?.diagnosisCode}
                                options={diagnosticList?.map((des) => {
                                  return {
                                    label: des.code,
                                    value: des.code,
                                  };
                                })}
                                filterOption={(input, option) =>
                                  option &&
                                  option.label &&
                                  option.label
                                    .toUpperCase()
                                    .indexOf(input.toUpperCase()) >= 0
                                }
                                notFoundContent={
                                  isLoading ? (
                                    <div className="text-center">
                                      <Spin size="small" />
                                    </div>
                                  ) : null
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={2}>
                            <div
                                className="h-6 w-6 mt-3 ml-6 border  group cursor-pointer  p-2 rounded  flex relative justify-center items-center overflow-hidden"
                                onClick={() => handleDelete(data, "Primary")}
                              >
                                <div className="   ">
                                  <svg
                                    viewBox="0 0 1.625 1.625"
                                    class="absolute -top-5 fill-red-700 delay-100 group-hover:top-2 group-hover:animate-[spin_1.4s] group-hover:duration-1000"
                                    height="10"
                                    width="10"
                                  >
                                    <path d="M.471 1.024v-.52a.1.1 0 0 0-.098.098v.618c0 .054.044.098.098.098h.487a.1.1 0 0 0 .098-.099h-.39c-.107 0-.195 0-.195-.195"></path>
                                    <path d="M1.219.601h-.163A.1.1 0 0 1 .959.504V.341A.033.033 0 0 0 .926.309h-.26a.1.1 0 0 0-.098.098v.618c0 .054.044.098.098.098h.487a.1.1 0 0 0 .098-.099v-.39a.033.033 0 0 0-.032-.033"></path>
                                    <path d="m1.245.465-.15-.15a.02.02 0 0 0-.016-.006.023.023 0 0 0-.023.022v.108c0 .036.029.065.065.065h.107a.023.023 0 0 0 .023-.023.02.02 0 0 0-.007-.016"></path>
                                  </svg>
                                  <svg
                                    width="10"
                                    fill="none"
                                    viewBox="0 0 39 7"
                                    class="origin-right  duration-500 group-hover:rotate-90"
                                  >
                                    <line
                                      stroke-width="4"
                                      stroke="blue"
                                      y2="5"
                                      x2="39"
                                      y1="5"
                                      class="group-hover:stroke-red-500 stroke-blue-400" 
                                    ></line>
                                    <line
                                      stroke-width="3"
                                      stroke="blue"
                                      y2="1.5"
                                      x2="26.0357"
                                      y1="1.5"
                                      x1="12"
                                      class="group-hover:stroke-red-500 stroke-blue-400" 
                                    ></line>
                                  </svg>
                                  <svg
                                    width="10"
                                    fill="none"
                                    viewBox="0 0 33 39"
                                    class=""
                                  >
                                    <mask
                                      fill="white"
                                      id="path-1-inside-1_8_19"
                                    >
                                      <path   d="M0 0H33V35C33 37.2091 31.2091 39 29 39H4C1.79086 39 0 37.2091 0 35V0Z"></path>
                                    </mask>
                                    <path
                                      mask="url(#path-1-inside-1_8_19)" 
                                      fill="blue-400"
                                      class="group-hover:fill-red-500 fill-blue-400"
                                      d="M0 0H33H0ZM37 35C37 39.4183 33.4183 43 29 43H4C-0.418278 43 -4 39.4183 -4 35H4H29H37ZM4 43C-0.418278 43 -4 39.4183 -4 35V0H4V35V43ZM37 0V35C37 39.4183 33.4183 43 29 43V35V0H37Z"
                                    ></path>
                                    <path
                                      stroke-width="4"
                                      stroke="blue-400"
                                      d="M12 6L12 29"
                                      class="group-hover:stroke-red-500 stroke-blue-400" 
                                    ></path>
                                    <path
                                      stroke-width="4"
                                      stroke="blue-400"
                                      d="M21 6V29"
                                      class="group-hover:stroke-red-500 stroke-blue-400" 
                                    ></path>
                                  </svg>
                                </div>
                              </div>   

                          </Col>
                        </Row>
                      ) : (
                        <Row gutter={24} className="mt-2">
                          <Col span={4}>
                            <Input value={data?.diagnosisType} disabled={true} className="w-full h-10 disabled:opacity-80 cursor-not-allowed" />
                          </Col>
                          <Col span={9}>
                            <Form.Item

                              //name={`${diagnosisType}Diagnosis`}
                              className="mx-2"
                            >
                              <Select
                                // key={`secondary-${secKey}`}
                                showSearch
                                className="h-10"
                                name="diagnosisDescription"
                                placeholder="Select Secondary Diagnosis"
                                onChange={(value) => handleSelectedDiagChange(value, data, 'Secondary')}
                                value={data?.diagnosisDescription}
                                // onChange={(value) => handleDiag(value, "Secondary")}
                                onSearch={onSearch2}
                                options={diagnosticList?.map((des) => {
                                  return {
                                    label: des.description,
                                    value: des.code,
                                  };
                                })}
                                filterOption={(input, option) =>
                                  option &&
                                  option.label &&
                                  option.label
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                notFoundContent={
                                  isLoading ? (
                                    <div className="text-center">
                                      <Spin size="small" />
                                    </div>
                                  ) : null
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={9}>
                            <Form.Item className="mx-2">
                              <Select
                                // key={`secondary-icd-${secKey}`}
                                showSearch
                                placeholder="Select ICD Code"
                                className="h-10"
                                name="code"
                                onSearch={secondaryIcdCodeSearch}
                                onChange={(value) => handleSelectedDiagChange(value, data, 'Secondary')}
                                value={data?.diagnosisCode}
                                options={diagnosticList?.map((des) => {
                                  return {
                                    label: des.code,
                                    value: des.code,
                                  };
                                })}
                                filterOption={(input, option) =>
                                  option &&
                                  option.label &&
                                  option.label
                                    .toUpperCase()
                                    .indexOf(input.toUpperCase()) >= 0
                                }
                                notFoundContent={
                                  isLoading ? (
                                    <div className="text-center">
                                      <Spin size="small" />
                                    </div>
                                  ) : null
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={2}>
                            <div
                                className="h-6 w-6 mt-3 ml-6 border  group cursor-pointer  p-2 rounded  flex relative justify-center items-center overflow-hidden"
                                onClick={() => handleDelete(data, "Secondary")}
                              >
                                <div className="   ">
                                  <svg
                                    viewBox="0 0 1.625 1.625"
                                    class="absolute -top-5 fill-red-700 delay-100 group-hover:top-2 group-hover:animate-[spin_1.4s] group-hover:duration-1000"
                                    height="10"
                                    width="10"
                                  >
                                    <path d="M.471 1.024v-.52a.1.1 0 0 0-.098.098v.618c0 .054.044.098.098.098h.487a.1.1 0 0 0 .098-.099h-.39c-.107 0-.195 0-.195-.195"></path>
                                    <path d="M1.219.601h-.163A.1.1 0 0 1 .959.504V.341A.033.033 0 0 0 .926.309h-.26a.1.1 0 0 0-.098.098v.618c0 .054.044.098.098.098h.487a.1.1 0 0 0 .098-.099v-.39a.033.033 0 0 0-.032-.033"></path>
                                    <path d="m1.245.465-.15-.15a.02.02 0 0 0-.016-.006.023.023 0 0 0-.023.022v.108c0 .036.029.065.065.065h.107a.023.023 0 0 0 .023-.023.02.02 0 0 0-.007-.016"></path>
                                  </svg>
                                  <svg
                                    width="10"
                                    fill="none"
                                    viewBox="0 0 39 7"
                                    class="origin-right  duration-500 group-hover:rotate-90"
                                  >
                                    <line
                                      stroke-width="4"
                                      stroke="blue"
                                      y2="5"
                                      x2="39"
                                      y1="5"
                                      class="group-hover:stroke-red-500 stroke-blue-400" 
                                    ></line>
                                    <line
                                      stroke-width="3"
                                      stroke="blue"
                                      y2="1.5"
                                      x2="26.0357"
                                      y1="1.5"
                                      x1="12"
                                      class="group-hover:stroke-red-500 stroke-blue-400" 
                                    ></line>
                                  </svg>
                                  <svg
                                    width="10"
                                    fill="none"
                                    viewBox="0 0 33 39"
                                    class=""
                                  >
                                    <mask
                                      fill="white"
                                      id="path-1-inside-1_8_19"
                                    >
                                      <path   d="M0 0H33V35C33 37.2091 31.2091 39 29 39H4C1.79086 39 0 37.2091 0 35V0Z"></path>
                                    </mask>
                                    <path
                                      mask="url(#path-1-inside-1_8_19)" 
                                      fill="blue-400"
                                      class="group-hover:fill-red-500 fill-blue-400"
                                      d="M0 0H33H0ZM37 35C37 39.4183 33.4183 43 29 43H4C-0.418278 43 -4 39.4183 -4 35H4H29H37ZM4 43C-0.418278 43 -4 39.4183 -4 35V0H4V35V43ZM37 0V35C37 39.4183 33.4183 43 29 43V35V0H37Z"
                                    ></path>
                                    <path
                                      stroke-width="4"
                                      stroke="blue-400"
                                      d="M12 6L12 29"
                                      class="group-hover:stroke-red-500 stroke-blue-400" 
                                    ></path>
                                    <path
                                      stroke-width="4"
                                      stroke="blue-400"
                                      d="M21 6V29"
                                      class="group-hover:stroke-red-500 stroke-blue-400" 
                                    ></path>
                                  </svg>
                                </div>
                              </div>                         
                          </Col>
                        </Row>
                      )}
                    </>
                  )
                })
                }
              </div>
            </Col>
          </Row>

        </div>
        <div className="container my-6">
          <h3 className="font-bold mx-2 text-base">Medicines</h3>
          <div className="w-full">
            <div>
              <div className={`w-full ${medTableArr.length !== 0 && !showTable?.medicinesTable ? 'hidden' : 'block'}`}>
                <Row gutter={24}>
                  <Col span={5}>
                    <label className="ml-3">Medicine Name</label>
                  </Col>
                  <Col span={3}>
                    <label>Composition</label>
                  </Col>
                  <Col span={3}>
                    <label>Dosage</label>
                  </Col>
                  <Col span={3}>
                    <label>Duration</label>
                  </Col>
                  <Col span={2}>
                    <label>Qty</label>
                  </Col>
                  <Col span={3}>
                    <label>To be Taken</label>
                  </Col>
                  <Col span={2}>
                    <label>Notes</label>
                  </Col>
                </Row>
              </div>
              <div
                className={`border-brand-textGray ${showTable?.medicinesTable && medTableArr.length !== 0 ? "block" : "hidden"
                  } rounded-lg my-3 relative`}
              >
                <div className={`w-full ${medTableArr.length !== 0 && showTable?.medicinesTable ? 'hidden' : 'block'}`}>
                  <Row gutter={24}>
                    <Col span={5}>
                      <label>Medicine Name</label>
                    </Col>
                    <Col span={3}>
                      <label>Composition</label>
                    </Col>
                    <Col span={3}>
                      <label>Dosage</label>
                    </Col>
                    <Col span={2}>
                      <label>Duration</label>
                    </Col>
                    <Col span={2}>
                      <label>Qty</label>
                    </Col>
                    <Col span={3}>
                      <label>To be Taken</label>
                    </Col>
                    <Col span={2}>
                      <label>Notes</label>
                    </Col>
                  </Row>
                </div>
                {medTableArr.length && medTableArr.filter(item => item.status !== 0).map((data, index) => {
                  return (
                    <Row key={data.id || index} gutter={24} className="mt-2">
                      <Col span={5}>
                        <Form.Item
                          className="mx-2"
                        >
                          <Select
                            showSearch
                            placeholder="Enter Medicine Name"
                            className={`w-full h-10 medicineSearch ${data.drugName !== data.drugCode ? 'block' : 'hidden'}`}
                            onSearch={(value) => onSearchmedicine(value, true)}
                            value={!medSelectTagEditEnabled && data.drugName}
                            // onChange={handleChangeMedicine}
                            onChange={(value) => handleSelectedMedChange(false, 'select', value, data)}
                            options={
                              medicines && medicines.map((medicine) => ({
                                label: medicine.medicineName,
                                value: medicine.code,
                              }))
                            }
                            filterOption={(input, option) =>
                              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            notFoundContent={isLoading ? <div className="text-center"><Spin size="small" /></div> : medicines.length === 0 ? (<span>No Item Found</span>) : null}
                          />
                          <Input type="text"
                            className={`${data.drugName === data.code ? 'block' : 'hidden'} w-full h-10`}
                            value={(manualMedEditEnabled && (currMedEdit.index === index && currMedEdit.type === 'input')) ? manualMedEditSearchVal : data.drugName}
                            onChange={(e) => handleManualMedEdit(e, 'input', index)}
                            onBlur={(e) => handleSelectedMedChange(true, 'input', manualMedEditSearchVal, data)}

                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item>
                          {/* <input
                            key={selectedMedicine}
                            onChange={(e) => setCompositionVal(e.target.value)}
                            className={`h-10 rounded-md border-gray-200 outline-none border ${searchedManualMed ? 'block' : 'hidden'}`}
                          /> */}
                          <Input
                            value={(currMedEdit.index === index && currMedEdit.type === 'composition') ? compositionVal : data?.composition}
                            disabled={data.drugName !== data.drugCode}
                            className={`h-10 rounded-md border-gray-200 outline-none border disabled:opacity-80 disabled:cursor-not-allowed`}
                            onChange={(e) => handleManualMedEdit(e, 'composition', index)}
                            onBlur={() => handleSelectedMedChange(data.drugName === data.drugCode, 'composition', compositionVal, data)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item>
                          <Select
                            className="h-10"
                            value={data?.dosage}
                            onChange={(e) => handleManualMedEdit(e, 'dosage', index)}
                            onBlur={(e) => handleSelectedMedChange(true, 'dosage', dosage, data)}
                          >
                            {options.map((option, index) => (
                              <Option key={index} value={option}>
                                {option}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item>
                          <InputNumber className="h-10 w-full duration"
                          suffix={<span className="text-[10px]  text-brand-textGray">Days</span>}
                            value={data.duration}
                            onChange={(e) => handleManualMedEdit(e, 'duration', index)}
                            onBlur={(e) => handleSelectedMedChange(true, 'duration', duration, data)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item>
                          <InputNumber className="h-10 w-full"
                            value={data.quantity}
                            onChange={(e) => handleManualMedEdit(e, 'quantity', index)}
                            onBlur={(e) => handleSelectedMedChange(true, 'quantity', quantity, data)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item>
                          <Select
                            placeholder="Select"
                            className="h-10"
                            value={(currMedEdit.type === 'timetaken') ? timeTaken : data.timeTaken ? data.timeTaken : data.afterBeforeFood === 'AF' ? 'afterfood' : 'beforefood'}
                            onChange={(e) => handleManualMedEdit(e, 'timetaken', index)}
                            onBlur={(e) => handleSelectedMedChange(true, 'timetaken', timeTaken, data)}
                          >
                            <Select.Option value="afterfood">After Food</Select.Option>
                            <Select.Option value="beforefood">Before Food</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item>
                          <Input
                            className="h-10 w-32"
                            //here what we have put a check to make sure we are editing correct instruction and its not affecting other instructions
                            value={(currMedEdit.index === index && currMedEdit.type === 'instruction') ? instruction : data.instruction}
                            onChange={(e) => handleManualMedEdit(e, 'instruction', index)}
                            onBlur={(e) => handleSelectedMedChange(true, 'instruction', instruction, data)}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                           <div
                                className="h-6 w-6 mt-3 ml-16 border  group cursor-pointer  p-2 rounded  flex relative justify-center items-center overflow-hidden"
                                onClick={() => handleDeleteMedicine(data)}
                              >
                                <div className="   ">
                                  <svg
                                    viewBox="0 0 1.625 1.625"
                                    class="absolute -top-5 fill-red-700 delay-100 group-hover:top-2 group-hover:animate-[spin_1.4s] group-hover:duration-1000"
                                    height="10"
                                    width="10"
                                  >
                                    <path d="M.471 1.024v-.52a.1.1 0 0 0-.098.098v.618c0 .054.044.098.098.098h.487a.1.1 0 0 0 .098-.099h-.39c-.107 0-.195 0-.195-.195"></path>
                                    <path d="M1.219.601h-.163A.1.1 0 0 1 .959.504V.341A.033.033 0 0 0 .926.309h-.26a.1.1 0 0 0-.098.098v.618c0 .054.044.098.098.098h.487a.1.1 0 0 0 .098-.099v-.39a.033.033 0 0 0-.032-.033"></path>
                                    <path d="m1.245.465-.15-.15a.02.02 0 0 0-.016-.006.023.023 0 0 0-.023.022v.108c0 .036.029.065.065.065h.107a.023.023 0 0 0 .023-.023.02.02 0 0 0-.007-.016"></path>
                                  </svg>
                                  <svg
                                    width="10"
                                    fill="none"
                                    viewBox="0 0 39 7"
                                    class="origin-right  duration-500 group-hover:rotate-90"
                                  >
                                    <line
                                      stroke-width="4"
                                      stroke="blue"
                                      y2="5"
                                      x2="39"
                                      y1="5"
                                      class="group-hover:stroke-red-500 stroke-blue-400" 
                                    ></line>
                                    <line
                                      stroke-width="3"
                                      stroke="blue"
                                      y2="1.5"
                                      x2="26.0357"
                                      y1="1.5"
                                      x1="12"
                                      class="group-hover:stroke-red-500 stroke-blue-400" 
                                    ></line>
                                  </svg>
                                  <svg
                                    width="10"
                                    fill="none"
                                    viewBox="0 0 33 39"
                                    class=""
                                  >
                                    <mask
                                      fill="white"
                                      id="path-1-inside-1_8_19"
                                    >
                                      <path   d="M0 0H33V35C33 37.2091 31.2091 39 29 39H4C1.79086 39 0 37.2091 0 35V0Z"></path>
                                    </mask>
                                    <path
                                      mask="url(#path-1-inside-1_8_19)" 
                                      fill="blue-400"
                                      class="group-hover:fill-red-500 fill-blue-400"
                                      d="M0 0H33H0ZM37 35C37 39.4183 33.4183 43 29 43H4C-0.418278 43 -4 39.4183 -4 35H4H29H37ZM4 43C-0.418278 43 -4 39.4183 -4 35V0H4V35V43ZM37 0V35C37 39.4183 33.4183 43 29 43V35V0H37Z"
                                    ></path>
                                    <path
                                      stroke-width="4"
                                      stroke="blue-400"
                                      d="M12 6L12 29"
                                      class="group-hover:stroke-red-500 stroke-blue-400" 
                                    ></path>
                                    <path
                                      stroke-width="4"
                                      stroke="blue-400"
                                      d="M21 6V29"
                                      class="group-hover:stroke-red-500 stroke-blue-400" 
                                    ></path>
                                  </svg>
                                </div>
                              </div>
                      </Col>
                    </Row>
                  );
                })}
              </div>
            </div>
          </div>
          {/* {handleSubmitState && ( */}
          <>
            <Row gutter={24} className="mt-2 flex items-center">
              <Col span={5}>
                <Form.Item
                  // label={medTableArr.length > 0 ? '' : "Medicine Name"}
                  name="drugName"
                  className="mx-2"
                >

                  <Select
                    showSearch
                    placeholder="Enter Medicine Name"
                    className={`w-full h-10 medicineSearch ${!searchedManualMed ? 'block' : 'hidden'}`}
                    onSearch={(value) => onSearchmedicine(value, false)}
                    onChange={handleChangeMedicine}
                    options={
                      medicines && medicines.map((medicine) => {
                        return {
                          label: medicine.medicineName,
                          value: medicine.code,
                        };
                      })
                    }
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }

                    notFoundContent={isLoading ? <div className="text-center"><Spin size="small" /></div> : null}
                    allowClear
                  />
                  <Input type="text"
                    value={searchedManualMed}
                    className={`${searchedManualMed ? 'block' : 'hidden'} w-full h-10`}
                    onChange={manualSearch}
                    ref={InputRef}
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item
                  // label={medTableArr.length > 0 ? '' : "Composition"}
                  className="">
                  {/* <input
                    key={selectedMedicine}
                    onChange={(e) => setCompositionVal(e.target.value)}
                    className={`h-10 rounded-md border-gray-200 outline-none border ${searchedManualMed ? 'block' : 'hidden'} `}
                  /> */}
                  <Input
                    value={!searchedManualMed ? selectedMedicine?.composition : compositionVal}
                    disabled={!searchedManualMed}
                    className={`h-10 rounded-md border-gray-200 outline-none border disabled:opacity-80 disabled:cursor-not-allowed`}
                    onChange={(e) => setCompositionVal(e.target.value)} />

                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item
                  // label="Dosage" 
                  name="dosage" className="">
                  <Select
                    showSearch
                    className="h-10"
                    options={options.map((option) => ({
                      label: option,
                      value: option,
                    }))}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  />

                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item
                  // label="Duration" 
                  name="duration" className="">
                  <InputNumber className="h-10 w-full  duration" suffix={<span className="text-[10px]  text-brand-textGray">Days</span>}/>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item
                  // label="Quantity" 
                  name="quantity" className="">
                  <InputNumber className="h-10 w-full" />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item
                  // label="To be Taken" 
                  name="timeTaken" className="">
                  <Select placeholder="Select" className="h-10">
                    <Select.Option value="afterfood">After Food</Select.Option>
                    <Select.Option value="beforefood">Before Food</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item
                  // label="Notes" 
                  name="notes" className="">
                  <Input className="h-10 w-32" />
                </Form.Item>
              </Col>
              <Col >
              <button
                  disabled={!selectedMedicine && !searchedManualMed}
                  onClick={handleFormSubmit}
                  title="Add New"
                  alt="button"
                  class="group ml-16 cursor-pointer bg-transparent outline-none hover:rotate-90 duration-300 "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30px"
                    height="30px"
                    viewBox="0 0 24 24"
                    class="stroke-blue-400 fill-none group-hover:fill-blue-500 group-active:stroke-blue-200 group-active:fill-blue-600 group-active:duration-0 duration-300"
                  >
                    <path
                      d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                      stroke-width="1.5"
                    ></path>
                    <path
                      d="M8 12H16"
                      stroke-width="1.5"
                      className="group-hover:stroke-white"
                    ></path>
                    <path
                      d="M12 16V8"
                      stroke-width="1.5"
                      className="group-hover:stroke-white"
                    ></path>
                  </svg>
                </button>
                </Col>
              
              {/* <div className="ml-[4.25rem]">
                <img
                  src={add}
                  disabled={(!selectedMedicine && !searchedManualMed)}
                  onClick={handleFormSubmit}
                  alt="button"
                  className="w-8 h-8 text-right mb-2 cursor-pointer"
                  title="Add"
                />
              </div> */}
            </Row>
            {/* <img
              src={add}
              disabled={(!selectedMedicine && !searchedManualMed)}
              onClick={handleFormSubmit}
              alt="button"
              className="w-8 h-8 mx-4 mb-2 cursor-pointer"
              title="Add"
            /> */}
          </>
          {/* )} */}


        </div>

        <div className="container my-6">
          <div className="w-full">
            <div className="w-[98%]">
              <h3 className="font-bold text-base mb-2 mx-2">Lab Tests</h3>
              <div
                className={`w-full mx-4 ${labTableArr.length === 0 ? 'hidden' : 'block'}`}
              >
                <Row gutter={24} className="label-row">
                  <Col span={11}>
                    <label>Lab Test</label>
                  </Col>
                </Row>
              </div>
              <div
                className={`w-full border-brand-textGray ${showTable?.labTable && labTableArr.length !== 0 ? "block" : "hidden"
                  } rounded-lg my-2`}
              >
                {/* <div className={`w-full`}>
                  <Row gutter={24} className="label-row">
                    <Col span={12}>
                      <label>Select Lab Test</label>
                    </Col>
                  </Row>
                </div> */}
                {labTableArr.length &&
                  labTableArr.filter((item) => item.status !== 0).map((data, index) => {
                    return (
                      <>

                        <Row gutter={24} key={index}>
                          <Col span={11}>
                            <Form.Item
                              className="ml-2"
                              style={{ marginTop: "9px" }}
                            >
                              <Select
                                showSearch
                                placeholder="Select Lab Test Name"
                                className={`w-full h-10 labSearch ${(data.labTestName !== data.labTestId) ? "block" : "hidden"
                                  }`}
                                onSearch={onSearchLap}
                                onChange={(value) => handleSelectedChange(false, value, data)}
                                value={(!selectTagEditEnabled) && data.labTestName}
                                options={labTests?.map((test) => ({
                                  label: test.name,
                                  value: test.code,
                                }))}
                                filterOption={(input, option) =>
                                  option?.label
                                    ?.toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                notFoundContent={
                                  isLoading ? (
                                    <div className="text-center">
                                      <Spin size="small" />
                                    </div>
                                  ) : null
                                }
                              />
                              <Input
                                placeholder="Select Lab Test"
                                type="text"
                                className={`w-full h-10 ${(data.labTestName === data.labTestId) ? "block" : "hidden"
                                  }`}
                                value={(manualEditEnabled && currLabEditIndex === key) ? manualEditSearchVal : data.labTestName}
                                onChange={(e) => handleManualEdit(e, key)}
                                onBlur={(e) => handleSelectedChange(true, selectedLabTest, data)}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={2}>
                             <div
                                className="h-6 w-6 mt-5 ml-3 border  group cursor-pointer  p-2 rounded  flex relative justify-center items-center overflow-hidden"
                                onClick={() => handleDeleteLap(data, key)}
                              >
                                <div className="   ">
                                  <svg
                                    viewBox="0 0 1.625 1.625"
                                    class="absolute -top-5 fill-red-700 delay-100 group-hover:top-2 group-hover:animate-[spin_1.4s] group-hover:duration-1000"
                                    height="10"
                                    width="10"
                                  >
                                    <path d="M.471 1.024v-.52a.1.1 0 0 0-.098.098v.618c0 .054.044.098.098.098h.487a.1.1 0 0 0 .098-.099h-.39c-.107 0-.195 0-.195-.195"></path>
                                    <path d="M1.219.601h-.163A.1.1 0 0 1 .959.504V.341A.033.033 0 0 0 .926.309h-.26a.1.1 0 0 0-.098.098v.618c0 .054.044.098.098.098h.487a.1.1 0 0 0 .098-.099v-.39a.033.033 0 0 0-.032-.033"></path>
                                    <path d="m1.245.465-.15-.15a.02.02 0 0 0-.016-.006.023.023 0 0 0-.023.022v.108c0 .036.029.065.065.065h.107a.023.023 0 0 0 .023-.023.02.02 0 0 0-.007-.016"></path>
                                  </svg>
                                  <svg
                                    width="10"
                                    fill="none"
                                    viewBox="0 0 39 7"
                                    class="origin-right  duration-500 group-hover:rotate-90"
                                  >
                                    <line
                                      stroke-width="4"
                                      stroke="blue"
                                      y2="5"
                                      x2="39"
                                      y1="5"
                                      class="group-hover:stroke-red-500 stroke-blue-400" 
                                    ></line>
                                    <line
                                      stroke-width="3"
                                      stroke="blue"
                                      y2="1.5"
                                      x2="26.0357"
                                      y1="1.5"
                                      x1="12"
                                      class="group-hover:stroke-red-500 stroke-blue-400" 
                                    ></line>
                                  </svg>
                                  <svg
                                    width="10"
                                    fill="none"
                                    viewBox="0 0 33 39"
                                    class=""
                                  >
                                    <mask
                                      fill="white"
                                      id="path-1-inside-1_8_19"
                                    >
                                      <path   d="M0 0H33V35C33 37.2091 31.2091 39 29 39H4C1.79086 39 0 37.2091 0 35V0Z"></path>
                                    </mask>
                                    <path
                                      mask="url(#path-1-inside-1_8_19)" 
                                      fill="blue-400"
                                      class="group-hover:fill-red-500 fill-blue-400"
                                      d="M0 0H33H0ZM37 35C37 39.4183 33.4183 43 29 43H4C-0.418278 43 -4 39.4183 -4 35H4H29H37ZM4 43C-0.418278 43 -4 39.4183 -4 35V0H4V35V43ZM37 0V35C37 39.4183 33.4183 43 29 43V35V0H37Z"
                                    ></path>
                                    <path
                                      stroke-width="4"
                                      stroke="blue-400"
                                      d="M12 6L12 29"
                                      class="group-hover:stroke-red-500 stroke-blue-400" 
                                    ></path>
                                    <path
                                      stroke-width="4"
                                      stroke="blue-400"
                                      d="M21 6V29"
                                      class="group-hover:stroke-red-500 stroke-blue-400" 
                                    ></path>
                                  </svg>
                                </div>
                              </div>
                          </Col>
                        </Row>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>

          {/* Row to show after handleFinish */}
          {/* {isFinished && ( */}
          <>

            <Row gutter={24}>
              <Col span={11}>
                <Form.Item
                  // label="Select Lab Test" 
                  name='labTest'
                  className="mx-2">
                  <Select
                    showSearch
                    placeholder="Enter Lab Test Name"
                    className={`w-full h-10 labSearch ${!manualSearchVal ? "block" : "hidden"
                      }`}
                    onSearch={onSearchLap}
                    onChange={handleChange}
                    options={labTests?.map((test) => ({
                      label: test.name,
                      value: test.code,
                    }))}
                    filterOption={(input, option) =>
                      option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    notFoundContent={
                      isLoading ? (
                        <div className="text-center">
                          <Spin size="small" />
                        </div>
                      ) : null
                    }
                    allowClear
                  />
                  <Input
                    placeholder="Enter Lab Test"
                    type="text"
                    value={manualSearchVal}
                    className={`w-full h-10 ${manualSearchVal ? "block" : "hidden"
                      }`}
                    onChange={manualTestSearch}
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <button
                  disabled={!selectedLabTest && !manualSearchVal}
                  onClick={handleFinish}
                  title="Add New"
                  class="group mt-2 cursor-pointer bg-transparent outline-none hover:rotate-90 duration-300"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30px"
                    height="30px"
                    viewBox="0 0 24 24"
                    class="stroke-blue-400 fill-none group-hover:fill-blue-500 group-active:stroke-blue-200 group-active:fill-blue-600 group-active:duration-0 duration-300"
                  >
                    <path
                      d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                      stroke-width="1.5"
                    ></path>
                    <path
                      d="M8 12H16"
                      stroke-width="1.5"
                      className="group-hover:stroke-white"
                    ></path>
                    <path
                      d="M12 16V8"
                      stroke-width="1.5"
                      className="group-hover:stroke-white"
                    ></path>
                  </svg>
                </button>
              </Col>
            </Row>
          </>
          {/* // )} */}

          {/* Finish Button */}
          {/* <img
            src={add}
            disabled={!selectedLabTest && !manualSearchVal}
            onClick={handleFinish}
            alt="button"
            className="w-8 h-8 mx-4 mb-2 cursor-pointer"
            title="Add"
          /> */}
        </div>
        <Row gutter={24}>
          <Col span={11}>
            <Form.Item
              label="Recommendation"
              // name="recommendation"
              className="mx-2"
            >
              <Input className="h-10" placeholder="Enter recommendation" value={recommendation} onChange={handleRecommendation} />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Schedule follow up"
              name="followupVisitDate"
              className="mx-2"
            >
              <DatePicker
                className="h-10 w-full"
                disabledDate={disabledDate}
                onChange={handleFollowUpDate}
                defaultValue={consulationData ? dayjs(consulationData?.followupVisitDate) : null}
                format={"DD/MM/YYYY"}
                onScroll={false}
              />
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </div>
  );
}

export default ConsultationNotes;