import React from "react";
import DropdownArrowIcon from "../../../Assets/Images/Svg/dropdownArrow.svg";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  message,
} from "antd";
import { useForm } from "antd/es/form/Form";
import AppointmentService from "../../../Services/AppointmentService";
import dayjs from "dayjs";

function TodayAppointmentFilter({
  setFilterDate,
  setAppointmentList,
  todayCancel,
  todayFilter,
  setMaintainbg,
  setApiPayload,
  setTodayStatusValue,
  setTodayTypeValue,
}) {
  const storedLoginResult = localStorage.getItem("login_result");
  const loginResultObject = storedLoginResult
    ? JSON.parse(storedLoginResult)
    : null;

  const [form] = useForm();

  const handleFinish = () => {
    let payload = form.getFieldValue();
    if (
      !payload.patientId &&
      !payload.serviceCode &&
      !payload.toDate &&
      !payload.fromDate
    ) {
      message.error("Enter patient Id or Service Type to Filter");
      return;
    }

    const formattedDate = new Date().toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
    payload.userId = loginResultObject.user.userId;
    payload.fromDate = payload.fromDate
      ? dayjs(payload.fromDate, "MM/DD/YYYY").format("MM/DD/YYYY")
      : formattedDate;

    payload.toDate = payload.toDate
      ? dayjs(payload.toDate, "MM/DD/YYYY").format("MM/DD/YYYY")
      : null;

    if (
      payload.toDate &&
      dayjs(payload.toDate).isBefore(dayjs(payload.fromDate))
    ) {
      message.error("Select valid Date to Filter");
      return;
    }
    AppointmentService.AppointmentFilter(payload)
      .then((result) => {
        setTodayStatusValue("");
        setTodayTypeValue("1");
        setAppointmentList(result.data);
        if (payload.fromDate && payload.toDate) {
          setMaintainbg(false);
          setFilterDate(
            `${dayjs(payload.fromDate, "MM/DD/YYYY").format(
              "DD/MM/YYYY"
            )} - ${dayjs(payload.toDate, "MM/DD/YYYY").format("DD/MM/YYYY")}`
          );
        }
        if (payload.fromDate && !payload.toDate) {
          setMaintainbg(false);
          setFilterDate(
            `${dayjs(payload.fromDate, "MM/DD/YYYY").format("DD/MM/YYYY")}`
          );
        }
        if (!payload.fromDate && payload.toDate) {
          setMaintainbg(false);
          setFilterDate(
            `${dayjs(payload.toDate, "MM/DD/YYYY").format("DD/MM/YYYY")}`
          );
        }
        todayFilter();
        form.resetFields();
        setApiPayload(payload);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const disabledDate = (current) => {
    return current && current >= new Date().setHours(23, 59, 59, 999);
  };

  const disabledToDate = (current) => {
    const today = new Date();
    return current && current >= today.setHours(23, 59, 59, 999);
  };

  return (
    <div>
      <Form
        className="formDesign mb-10"
        name="trigger"
        onFinish={handleFinish}
        layout="vertical"
        form={form}
      >
        <Row gutter={20}>
        <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
          >
            <Form.Item label="Patient Id" name="patientId">
              <Input className="w-full" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Select From date" name="fromDate">
              <DatePicker
                className="w-full"
                format="DD/MM/YYYY"
                disabledDate={disabledDate}
                allowClear={false}
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
          >
            <Form.Item label="Select To date" name="toDate">
              <DatePicker
                className="w-full"
                span={20}
                format="DD/MM/YYYY"
                disabledDate={disabledToDate}
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
          >
            <Form.Item label="Type of Service" name="serviceCode">
              <Select
                className="w-full h-10"
                suffixIcon={<img src={DropdownArrowIcon} alt="img" />}
              >
                <Select.Option value="APPT_INPERSON">In-person</Select.Option>
                <Select.Option value="APPT_VIDEO">Video Call</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <div className="flex justify-end gap-3 pt-10">
        <Button className="h-10 Btnwhite md:px-8" onClick={todayCancel}>
            Cancel
          </Button>
          <Button className="h-10 md:px-10" type="primary" htmlType="submit">
            Filter Appointments
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default TodayAppointmentFilter;
