import http from "./http-common";

class PharmacyService {
  queryString(payload) {
    return Object.keys(payload)
      .map((key) => (key && payload[key] ? key + "=" + payload[key] : ""))
      .join("&");
  }

  GetPharmacyList(payload) {
    const queryString = this.queryString(payload);
    return http.get(`/userPharmacyMap/list/filter?${queryString}`);
  }

  GetShareDetails(payload) {
    const queryString = this.queryString(payload);
    return http.get(`/shareDetails/list/filter?${queryString}`);
  }

  GetDispensedDrugsDtl(payload) {
    const queryString = this.queryString(payload);
    return http.get(
      `/dispensedDrugsDtl/prescriptionforpharmacy?${queryString}`
    );
  }

  GetPrescriptionDetails(payload) {
    const queryString = this.queryString(payload);
    return http.get(`/prescriptionDetails/list/filter?${queryString}`);
  }

  PharmacySave(payload) {
    return http.post(`/dispensedDrugsHdr/save`, payload);
  }

  PharmacyUpdate(payload) {
    return http.put(`/dispensedDrugsHdr/update`, payload);
  }

  PharmacyGet(payload) {
    const queryString = this.queryString(payload);
    return http.get(`/dispensedDrugsHdr/list/filter?${queryString}`);
  }
}
export default new PharmacyService();
