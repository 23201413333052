import React, { useEffect, useState } from "react";
import plusIcon from "../../../../../Assets/Images/Svg/plusIcon.svg";
import { Modal } from "antd";
import AddPastMedicalHistory from "./AddPastMedicalHistory";
import MedicalHistoryService from "../../../../../Services/MedicalHistoryService";
import moment from "moment";

function PastMedicalHistory({ permissionData }) {
  const [pastHistoryData, setPastHistoryData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  let patienObject = localStorage.getItem("patient");
  let patientData = patienObject ? JSON.parse(patienObject) : null;
  console.log("inside medical history", patientData);

  useEffect(() => {
    PastHistory();
  }, [permissionData]);

  const PastHistory = () => {
    const userObj = localStorage.getItem('login_result')
    const userData = userObj ? JSON.parse(userObj) : null;
    console.log(permissionData,"permissionData")
    if(permissionData){
      let payload = {
        // account_Id: userData?.user?.account_Id,
        // clinic_Id: userData?.user?.clinic_Id,
        userId:(permissionData === null || permissionData?.medicalHistory === 'N')?userData?.user?.userId:'',
        patientId: patientData.patientId,
        status: 1,
      };
      MedicalHistoryService.PastMedicalHistory(payload).then((result) => {
        setPastHistoryData(result.data);
        console.log(result.data,"pastHistoryData")
      });
    }

  };

  const convertUTCDate = (date, format) => {
    if (!format) {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
        " " +
        d.getHours() +
        ":" +
        d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return moment(new Date(date)).utc().format(format);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleLatestUpdate = (data) => {
    setPastHistoryData(data);
  };

  return (
    <>
      <div>
        <div className="card h-[300px] m-5 border rounded-lg">
          <div className="header h-[20%] bg-brand-primaryColor flex items-center justify-between rounded-lg p-2">
            <h1 className="font-bold text-normal text-white mt-2">
              Past Medical History
            </h1>
            <img
              src={plusIcon}
              onClick={showModal}
              className="h-10 w-10 cursor-pointer"
              alt=""
            />
          </div>
          <div className="body h-[70%] overflow-y-auto  bg-brand-lightBg">
            {pastHistoryData.length > 0
              ? pastHistoryData.map((user, i) => (
                <div>
                  <div
                    key={i}
                    className="flex justify-between items-center p-2"
                  >
                    <div className="font-semibold text-sm">{user.pastInfo}</div>
                  </div>
                  <div className="flex justify-end font-medium text-sm">
                    check up date : {user.givenDate?.slice(0, 11)}
                  </div>
                  <hr className="my-2 border-gray-300" />
                </div>
              ))
              : null}
          </div>
          <div className="footer h-[10%]  bg-brand-primaryColor rounded-md"></div>
        </div>
      </div>
      <Modal
        title={<span className="font-bold text-base">Add Past Medical History Details</span>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <AddPastMedicalHistory
          onClose={handleCancel}
          handleLatestUpdate={handleLatestUpdate}
          permissionData={permissionData}
        />
      </Modal>
    </>
  );
}

export default PastMedicalHistory;
