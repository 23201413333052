import React, { useEffect, useState } from "react";
import VideoImage from "../../../../Assets/Images/VideoImage.png";
import axios from "axios";
import { Link, useLocation, useParams } from 'react-router-dom';
import medical from "../../../../Assets/Images/doctor2.avif";

function ShareCall(props) {

  const params = useParams()
  const [name, setName] = useState("");
  const [err, setErr] = useState("");
  const [openvideo, setOpenvideo] = useState(false);
  const location = useLocation();

  const save = (e) => {
    e.preventDefault();
    if (name.trim() === "") {
      setErr("Please enter the name")
    } else {
      setOpenvideo(true)
      const header = {
        secretKey: 'CcJYfbsgItHpTQPFr5lg'
      }
      const data = {
        "clientCode": "CCM@@202!",
        "userType": "PARTICIPANT",
        "meetingKey": params.id,
        "memberName": name,
        "memberEmail": "test@gmail.com",
        "memberMobile": "1234567890"
      }

      axios.post('https://cb.maggieplus.com/admin-api/client/meeting/authentication', data, { headers: header }).then(result => {
        if (params.type == "A") {
          window.location.href = result.data.response.url + '/1';
          setName("")
        } else {
          window.location.href = result.data.response.url;
          setName("")
        }
      }).catch(err => {
        console.log("Err", err)
      })
    }
  };
  const onChangeHandler = (fieldName, value) => {
    if (fieldName === "name") {
      setName(value);
      setErr("")
    }
  }

  return (
    <div className="h-screen bg-cover bg-center" style={{ backgroundImage: `url(${medical})`}}>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-80 my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-xl relative flex flex-col w-full outline-none focus:outline-none">
            {/*body*/}
            <div className="rounded-lg shadow-2xl border-blue-400 w-full h-112 p-5 antialiased justify-between border">
              <div className="flex pt-2 w-full ">
                <div className="w-full">
                  <form onSubmit={(e) => { save(e) }}>
                    <div className={"lg:flex justify-between lg:space-x-10 " + (err ? "py-1" : "py-4")}>
                      <input type="text"
                        placeholder="Enter the name"
                        value={name}
                        onChange={(e) => { onChangeHandler("name", e.target.value) }}
                        className="w-full peer bg-transparent h-10  border-b-2 border-gray-300 text-white focus:outline-none focus:borer-rose-600"
                      />
                    </div>
                    {err ? <span className="text-red-900">{err}</span> : null}
                    <div className="flex justify-end">
                      <input type="submit" value="Submit"
                        className="bg-brand-secondary font-rubik text-sm text-white font-normal rounded-md py-2 px-3 mr-2 cursor-pointer" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShareCall;
