import React, { useEffect, useState } from "react";
import Layouts from "../../Layouts";
import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Pagination,
  Row,
  Space,
  Table,
  message,
} from "antd";
import BillingService from "../../../Services/BillingService";
import { FaFilter } from "react-icons/fa6";
import moment from "moment";
import { useForm } from "antd/es/form/Form";
import PatientService from "../../../Services/PatientService";
import PatientQueue from "../VCPage/PatientQueue";
import patientqueue from "../../../Assets/Images/person-icon-blue.png";
import dayjs from "dayjs";
import { MdKeyboardDoubleArrowUp } from "react-icons/md";
import { IoIosCloseCircle } from "react-icons/io";
import Draggable from "react-draggable";
import convertUTCDateLocal from "../../../utils/convertToUtc";

function BillingReports() {
  useEffect(() => {
    getRecordList();
  }, []);

  const [tableData, setTableData] = useState([]);
  const [queueData, setQueueData] = useState();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [apiPayload, setApiPayload] = useState({});
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const storedLoginResult = localStorage.getItem("login_result");
  const loginResultObject = storedLoginResult
    ? JSON.parse(storedLoginResult)
    : null;

  const formatDate = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const getRecordList = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const firstDate = new Date(year, month, 1);
    const lastDate = new Date(year, month + 1, 0);

    setFromDate(formatDate(firstDate));
    setToDate(formatDate(lastDate));

    let payload = {
      userId: loginResultObject.user.userId,
      status: 1,
      fromDate: formatDate(firstDate),
      toDate: formatDate(lastDate),
      pageNumber: 0,
    };

    setApiPayload(payload);

    BillingService.BillingRecordsList(payload)
      .then((res) => {
        setTableData(res.data);
      })
      .catch((error) => {
        console.error("Error fetching billing records:", error);
      });

    BillingService.BillingCount(payload)
      .then((res) => {
        setPage(res.data.TOTAL_COUNT_STATUS);
      })
      .catch((error) => {
        console.error("Error fetching billing records:", error);
      });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    form.resetFields();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const [form, setForm] = useForm();

  const handleFinish = () => {
    let payload = form.getFieldValue();
    payload.status = 1;
    payload.fromDate = fromDate;
    payload.toDate = toDate;

    setApiPayload(payload);

    BillingService.BillingRecordsList(payload)
      .then((res) => {
        setTableData(res.data);
      })
      .catch((error) => {
        console.error("Error fetching billing records:", error);
      });

    BillingService.BillingCount(payload)
      .then((res) => {
        setPage(res.data.TOTAL_COUNT_STATUS);
      })
      .catch((error) => {
        console.error("Error fetching billing records:", error);
      });
    handleOk();
  };

  const handleMonthFilter = (value) => {
    const formattedDate = "01";
    const formattedMonth = value.format("MM");
    const formattedYear = value.format("YYYY");
    const fromDate = `${formattedMonth}/${formattedDate}/${formattedYear}`;
    const fromDateMoment = moment(fromDate, "MM/DD/YYYY");
    const lastDayOfMonth = fromDateMoment.endOf("month").format("MM/DD/YYYY");
    const toDate = lastDayOfMonth;

    setFromDate(fromDate);
    setToDate(toDate);

    let payload = {
      userId: loginResultObject.user.userId,
      fromDate: fromDate,
      toDate: toDate,
      status: 1,
      pageNumber: 0,
    };

    setApiPayload(payload);

    BillingService.BillingRecordsList(payload)
      .then((res) => {
        setTableData(res.data);
      })
      .catch((error) => {
        console.error("Error fetching billing records:", error);
      });

    BillingService.BillingCount(payload)
      .then((res) => {
        setPage(res.data.TOTAL_COUNT_STATUS);
      })
      .catch((error) => {
        console.error("Error fetching billing records:", error);
      });
  };

  const onClose = () => {
    setIsDrawerOpen(false);
  };
  const showDrawer = () => {
    const userObj = localStorage.getItem("login_result");
    const userData = userObj ? JSON.parse(userObj) : null;
    let payload = {
      userId: userData?.user?.userId,
      queueStatus: "Y",
    };
    PatientService.patientQueueList(payload).then((res) => {
      if (res.data.length) {
        let validate = res?.data?.filter(
          (x) => x.appointmentId != userData?.user?.appointmentId
        );
        if (validate && validate.length) {
          setQueueData(validate);
          setIsDrawerOpen(true);
        } else {
          message.warning("No patient available in queue");
        }
      } else {
        message.warning("No patient available in queue");
      }
    });
  };

  const handlePageChange = (pageNumber) => {
    apiPayload.pageNumber = pageNumber - 1;
    apiPayload.fromDate = fromDate;
    apiPayload.toDate = toDate;
    let payload = apiPayload;

    BillingService.BillingRecordsList(payload)
      .then((res) => {
        setTableData(res.data);
      })
      .catch((error) => {
        console.error("Error fetching billing records:", error);
      });

    if (apiPayload.pageNumber == 0) {
      BillingService.BillingCount(payload)
        .then((res) => {
          setPage(res.data.TOTAL_COUNT_STATUS);
        })
        .catch((error) => {
          console.error("Error fetching billing records:", error);
        });
    }
  };

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "patientName",
      render: (_, record) => (
        <Space size="middle" className="">
          <div>{record.patientName}</div>
        </Space>
      ),
    },
    {
      title: "Patient ID",
      dataIndex: "patientId",
    },
    {
      title: "Transaction ID",
      dataIndex: "transactionId",
    },
    {
      title: "Paid Date",
      dataIndex: "paymentDate",
      render: (_, record) => convertUTCDateLocal(record.paymentDate),
    },
    {
      title: "Type of Service",
      dataIndex: "type",
      render: (_, record) => (
        <Space size="middle" className="">
          <div className="text-black px-2 rounded py-1">Video</div>
        </Space>
      ),
    },
    {
      title: "Mode Of Payment",
      dataIndex: "paymode",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (_, record) => (
        <Space size="middle" className="">
          <div className="text-black px-2 rounded py-1">{record.amount}</div>
        </Space>
      ),
    },
    {
      title: "Discount Amount",
      dataIndex: "discountAmount",
      render: (_, record) => (
        <Space size="middle" className="">
          <div className="text-black px-2 rounded py-1">
            {record.discountAmount}
          </div>
        </Space>
      ),
    },
    {
      title: "Paid Amount",
      dataIndex: "paidAmount",
      render: (_, record) => (
        <Space size="middle" className="">
          <div className="text-black px-2 rounded py-1">
            {record.paidAmount}
          </div>
        </Space>
      ),
    },
  ];

  const [searchValue, setSearchValue] = useState("");

  const convertUTCDateLocal = (utcDate, format = "DD/MM/YYYY, hh:mm A") => {
    const localDate = dayjs.utc(utcDate).local();
    return localDate.format(format);
  };

  const handleButtonSearch = () => {
    let payload = {};
    payload.patientId = searchValue;
    payload.userId = loginResultObject.user.userId;
    payload.pageNumber = 0;
    payload.fromDate = fromDate;
    payload.toDate = toDate;
    payload.status = 1;

    setApiPayload(payload);

    BillingService.BillingRecordsList(payload)
      .then((res) => {
        setTableData(res.data);
        setSearchValue("");
      })
      .catch((error) => {
        console.error("Error fetching billing records:", error);
      });
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const [isVerticalOpen, setIsVerticalOpen] = useState(true);
  const handleClick = () => {
    setIsVerticalOpen(true);
  };
  const closeCircle = () => {
    setIsVerticalOpen(false);
  };

  return (
    <Layouts>
      <Modal
        title="Filter"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          className="formDesign"
          name="trigger"
          layout="vertical"
          form={form}
          onFinish={handleFinish}
        >
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item label="Patient Name" name="patientName">
                <Input className="w-full" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Patient ID" name="patientId">
                <Input className="w-full" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Transaction Id" name="transactionId">
                <Input className="w-full" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <div className="flex justify-end  border-brand-colorBorder gap-3 pt-2">
              <Button className="h-10 Btnwhite px-8" onClick={handleCancel}>
                Cancel
              </Button>
              <Button className="h-10 px-10" type="primary" htmlType="submit">
                Filter
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <div className="flex flex-col h-full ">
        <div className="flex justify-between">
          <div className="searchBoxtext-2xl font-bold my-2">
            Billing Reports
          </div>
          <div className="flex gap-2" style={{ marginTop: "20px" }}>
            {/* <Button className="font-bold text-white" onClick={showModal}>
              Filter
            </Button> */}
            <DatePicker
              onChange={handleMonthFilter}
              picker="month"
              format="MMM YYYY"
              defaultValue={dayjs()}
              dropdownClassName="custom-datepicker-popup"

            />
            <div className="flex ml-4 items-center justify-end">
              <button
                style={{ position: "relative", left: "0rem" }}
                className="text-white font-semibold p-2 rounded-md flex items-center "
                onClick={showModal}
              >
                <FaFilter className="mr-1" />
                Filter
              </button>
            </div>
          </div>
        </div>
        <div className="mt-10  rounded-lg h-full customHeight">
          <div className="border border-brand-textGray rounded-xl relative p-1 h-full customHeight">
            <Table
              className="p-0 customTable"
              columns={columns}
              dataSource={tableData}
              pagination={false}
              scroll={{ x: 1500 }}
            />
            <div className="flex justify-end items-end my-4">
              <Pagination
                onChange={handlePageChange}
                defaultCurrent={1}
                total={page}
              />
            </div>
          </div>
        </div>
      </div>
      {/* patient queue design */}
      {isVerticalOpen ? (
        <Draggable>
          <div className="draggable-container fixed top-1/2 right-2 w-14 transform -translate-y-1/2 z-50">
            <div
              className="hidden md:block relative mb-5 cursor-pointer"
              onClick={closeCircle}
            >
              <IoIosCloseCircle className="hidden md:block absolute mb-1 right-0 top-0" />
            </div>
            <div className="p-2 flex justify-center items-center rounded-lg bg-[#5584f4]">
              <img
                src={patientqueue}
                alt="patientqueue"
                className="w-10 h-10 cursor-pointer"
                title="Patient Queue"
                onClick={showDrawer}
              />
            </div>
          </div>
        </Draggable>
      ) : (
        <div
          className="vertical"
          style={{ marginTop: "16rem" }}
          onClick={handleClick}
        >
          <p className="vertical-p">
            <div className="vertical-icon-dashboard">
              <MdKeyboardDoubleArrowUp />
            </div>
            Queue
          </p>
        </div>
      )}

      {isDrawerOpen && (
        <div className="grid justify-end mt-5">
          {isDrawerOpen && (
            <PatientQueue
              queueData={queueData ? queueData : ""}
              onClose={onClose}
            />
          )}
        </div>
      )}
      {/* patient queue design end */}
    </Layouts>
  );
}

export default BillingReports;
