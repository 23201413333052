import React, { useEffect, useMemo, useState } from 'react'
import { useHistory,useLocation } from 'react-router-dom';
import Layouts from '../../Layouts';
import { Segmented, Tooltip, message } from 'antd';
import MedicalHistory from '../../../Components/Pages/ConsultationNotes/VitalTabs/MedicalHistory/index';
import MyPresctription from '../../Pages/ConsultationNotes/VitalTabs/ViewPrescription/index';
import MyVitals from '../../Pages/ConsultationNotes/VitalTabs/PatientVitals/PatientVitals';
import Reports from '../../Pages/ConsultationNotes/VitalTabs/Reports';
import { ArrowLeftOutlined } from '@ant-design/icons';
import medicalHistoryIcon from "../../../Assets/Images/mdhistory.png";
import medicalPrescriptionIcon from "../../../Assets/Images/mdpres.png";
import medicalVitalsIcon from "../../../Assets/Images/mdvitals.png";
import medicalReportsIcon from "../../../Assets/Images/mdreport.png";
import PatientService from '../../../Services/PatientService';
import { APP_ROUTES } from "../../../PageRouting/approutes";
import { DiVim } from 'react-icons/di';
import PatientQueue from './PatientQueue';
import patientqueue from "../../../Assets/Images/person-icon-blue.png"


function ViewMedicalRecords() {
  const [permissionData, setPermissionData] = useState(null);
  const [queueData, setQueueData] = useState();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const history = useHistory();
  const { state } = useLocation();
  const [selectedOption, setSelectedOption] = useState(null);
  const [arrow, setArrow] = useState('Show');
  
  const mergedArrow = useMemo(() => {
    if (arrow === 'Hide') {
      return false;
    }
    if (arrow === 'Show') {
      return true;
    }
  })

  useEffect(() => {
    const fetchPermittedData = async () => {
      await fetchPatientPermission();
    }
    fetchPermittedData();
  }, []);

  const fetchPatientPermission = async () => {
    const userObj = localStorage.getItem('login_result');
    const userData = userObj ? JSON.parse(userObj) : null;
    const patientObj = localStorage.getItem("patient");
    const patientData = patientObj ? JSON.parse(patientObj) : null;

    const currDate = new Date().toISOString();
    const date = currDate.split('T')[0];
    const time = currDate.split('T')[1].slice(0, 8);
    const constructedTime = date + " " + time;

    let permissionPayload = {
      patientId: patientData.patientId,
      userId: userData?.user?.userId,
      accessDate: constructedTime,
      status: 1
    }
    try {
      const response = await PatientService.isPermissionGranted(permissionPayload);
      console.log(response, "response")
      if (response?.data?.length !== 0) {
        if (response?.data[0]) {
          setPermissionData(response?.data[0]);
        }

      }

    }
    catch (e) {
      console.log(e);
    }
  }

  console.log(permissionData, "permissiondata");
  useEffect(() => {
    if (permissionData) {
      if (permissionData.medicalHistory === 'Y') {
        setSelectedOption("Medical history");
      } else if (permissionData.prescription === 'Y') {
        setSelectedOption("Prescription");
      } else if (permissionData.vitals === 'Y') {
        setSelectedOption("Vitals");
      } else if (permissionData.reports === 'Y') {
        setSelectedOption("Reports");
      }
    }
  }, [permissionData]);

  const handleOptionChange = (option) => {
    if (permissionData) {
      if (option === "Medical history" && permissionData.medicalHistory === 'Y') {
        setSelectedOption(option);
      } else if (option === "Prescription" && permissionData.prescription === 'Y') {
        setSelectedOption(option);
      } else if (option === "Vitals" && permissionData.vitals === 'Y') {
        setSelectedOption(option);
      } else if (option === "Reports" && permissionData.reports === 'Y') {
        setSelectedOption(option);
      }
    }
  };

  const selectedPatientObj = localStorage.getItem('patient');
  const selectedPatient = selectedPatientObj ? JSON.parse(selectedPatientObj) : null;
  console.log("Selected pat from med ", selectedPatient);
 
  const handleBackClick = ()=>{
    history.push({
      pathname: APP_ROUTES.VCPAGE,

      state: { vitalsArrObjData:state?.vitalsArrObjData ,selectedPatient:state?.selectedPatient,
       
      },
     
    });
  }
  const onClose = () => {
    setIsDrawerOpen(false);
  };
  const showDrawer = () => {
    const userObj = localStorage.getItem('login_result');
    const userData = userObj ? JSON.parse(userObj) : null;
    let payload = {
      userId: userData?.user?.userId,
      queueStatus: "Y"
    }
    PatientService.patientQueueList(payload).then((res) => {
      if (res.data.length) {
        let validate = res?.data?.filter((x) => x.appointmentId != userData?.appointmentId)
        if (validate && validate.length) {
          setQueueData(validate)
          setIsDrawerOpen(true);
        } else {
          message.warning(
            "No patient available in queue"
          );
        }
      } else {
        message.warning(
          "No patient available in queue"
        );
      }
    })
  };
  return (
    <Layouts>
      <div className=" h-screen px-5 pt-5">
        <div className="flex justify-between">
          <label className="font-bold text-lg">Medical Records</label>
          <button className="p-2 text-white rounded" onClick={handleBackClick}><ArrowLeftOutlined /> Back</button>
        </div>
        <div className="flex items-center mb-8 mt-5">
          <div className="w-7/12">

            <Segmented
              className="appSegment"
              onChange={handleOptionChange}
              value={selectedOption}
              block
              options={[
                  {
                    label: (  
                      <Tooltip placement="top" title={!permissionData || permissionData.medicalHistory === 'N' ? 'Patient should provide permission to access medical History records.' : 'Medical History'}>
                        <div className="bg-[inherit] w-[inherit] h-[inherit] flex gap-2 disabled:cursor-not-allowed" >
                          <img src={medicalHistoryIcon} alt="Medical history" className="w-5 h-5 mt-1 text-white" />
                          Medical history
                        </div>
                      </Tooltip>
                    ),
                    value: "Medical history",
                    disabled: !permissionData || permissionData.medicalHistory === 'N',
                  },
                {
                  label: (
                    <Tooltip placement="top" title={!permissionData || permissionData.prescription === 'N' ? 'Patient should provide permission to access medical records.' : 'Prescription'}>
                      <div className="bg-[inherit] w-[inherit] h-[inherit] flex gap-2 disabled:cursor-not-allowed" >
                        <img src={medicalPrescriptionIcon} alt="Prescription" className="w-5 h-5 mt-1 text-white" />
                        Prescription
                      </div>
                    </Tooltip>
                  ),
                  value: "Prescription",
                  disabled: !permissionData || permissionData.prescription === 'N',
                },
                {
                  label: (
                    <Tooltip placement="top" title={!permissionData || permissionData.vitals === 'N' ? 'Patient should provide permission to access medical records.' : 'Vitals'}>
                      <div className="bg-[inherit] w-[inherit] h-[inherit] flex gap-2 disabled:cursor-not-allowed" >
                        <img src={medicalVitalsIcon} alt="Vitals" className="w-5 h-5 mt-1 text-white" />
                        Vitals
                      </div>
                    </Tooltip>
                  ),
                  value: "Vitals",
                  disabled: !permissionData || permissionData.vitals === 'N',
                },
                {
                  label: (
                    <Tooltip placement="top" title={!permissionData || permissionData.reports === 'N' ? 'Patient should provide permission to access medical records.' : 'Reports'}>
                      <div className="bg-[inherit] w-[inherit] h-[inherit] flex gap-2 disabled:cursor-not-allowed" >
                        <img src={medicalReportsIcon} alt="Reports" className="w-5 h-5 mt-1 text-white" />
                        Reports
                      </div>
                    </Tooltip>
                  ),
                  value: "Reports",             
                  disabled: !permissionData || permissionData.reports === 'N',
                },
              ]}
            />
          </div>
        </div>
        <div className="relative p-1 h-full customHeight">
          {selectedOption === "Medical history" && <MedicalHistory permissionData={permissionData} selectedPatient={selectedPatient} />}

          {selectedOption === "Prescription" && <MyPresctription selectedPatient={selectedPatient} permissionData={permissionData} />}

          {selectedOption === "Vitals" && <MyVitals selectedPatient={selectedPatient} permissionData={permissionData} />}
          {selectedOption === "Reports" && <Reports selectedPatient={selectedPatient} permissionData={permissionData} />}
        </div>
      </div>
      
    </Layouts>
  )
}

export default ViewMedicalRecords;
