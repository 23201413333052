import {
  Button,
  Col,
  Radio,
  Collapse,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Modal,
  Space,
  Table,
  message,
  Spin,
} from "antd";
import { useForm } from "antd/es/form/Form";
import PhysicalExamination from "../PhysicalExamination";
import React, { useEffect, useRef, useState } from "react";
import ConsultationService from "../../../../../Services/ConsultationService";
import HPI from "./HPI";
import AddMedicine from "./AddMedicine";
import LabReports from "./LabReports";
import AddVitals from "../AddVitals";
import { useHistory, useLocation } from "react-router-dom";
import PrescriptionDetails from "../ViewPrescription/PrescriptionDetails";
import deleteicon from "../../../../../Assets/Images/Svg/deleteicon.svg";
import e from "cors";
import { APP_ROUTES } from "../../../../../PageRouting/approutes";
import VitalService from "../../../../../Services/VitalService";
import { render } from "@testing-library/react";
import dayjs from "dayjs";
const { Panel } = Collapse;
const months = {
  Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06', Jul: '07',
  Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12'
};


function ConsultationNotes({ handleAddPrescription }) {
  const formRef = useRef();
  const [selectedRadio, setSelectedRadio] = useState("primary");
  const [diagnosticList, setDiagnosticList] = useState([]);
  const [currPrimaryDiagList, setCurrPrimaryDiagList] = useState([]);
  const [currSecondaryDiagList, setCurrSecondaryDiagList] = useState([]);
  const [selectedPrimaryDiag, setSelectedPrimaryDiag] = useState({});
  const [selectedSecondaryDiag, setSelectedSecondaryDiag] = useState({});
  const [selectedPrimaryDiagVal, setSelectedPrimaryDiagVal] = useState('');
  const [selectedSecondaryDiagVal, setSelectedSecondaryDiagVal] = useState('');
  const [selectedDiagArr, setSelectedDiagArr] = useState([]);
  const [selectedOriginalDiagArr, setSelectedOriginalDiagArr] = useState([]);
  const [selectedCode, setSelectedCode] = useState(null);
  const [selectedSecondaryCode, setSelectedSecondaryCode] = useState(null);
  const [secondaryDiag, setSecondaryDiag] = useState([]);
  const [vitalsObj, setVitalsObj] = useState([]);
  const [medicinesObj, setMedicinesObj] = useState([]);
  const [labTestsObj, setLabTestsObj] = useState([]);
  const [followUpDate, setFollowUpDate] = useState('');
  const [bmiValue, setBmiValue] = useState();
  const [height, setHeight] = useState();
  const [weight, setWeight] = useState();
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [prescription, setPrescription] = useState(null);
  const [icdCode, setIcdCode] = useState();
  const [primdiag, setPrimdiag] = useState();
  const [tabledata, setTabledata] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [vitalMasterList, setVitalMasterList] = useState([]);
  const [bloodPressure, setBloodPressure] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sys, setSys] = useState("");
  const [dia, setDia] = useState("");
  const [key, setKey] = useState(0);
  const [secKey, setSecKey] = useState(0);
  const { state } = useLocation();
  const history = useHistory();
  const [consulationData, setConsulationData] = useState()
  const location = useLocation();
  const selectedPatient = location?.state?.selectedPatient;
  const [form] = useForm();



  useEffect(() => {
    if (selectedPatient?.consultationId && selectedPatient?.callMode === "EditCall") {
      getPrescriptionDetails()
    }
  }, []);

  useEffect(() => {
    if (consulationData?.labPrescription) {
      setLabTestsObj(consulationData?.labPrescription);
    }
    if (consulationData?.drugPrescription) {
      setMedicinesObj(consulationData?.drugPrescription);
    }
    if (consulationData?.patientVitals) {
      form.setFieldsValue({
        BPS: consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "BPS")?.value : '',
        BPD: consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "BPD")?.value : '',
        PO: consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "PO")?.value : '',
        HR: consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "HR")?.value : '',
        RR: consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "RR")?.value : '',
        TP: consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "TP")?.value : '',
        HI: consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "HI")?.value : '',
        WE: consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "WE")?.value : '',
        BMI: consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "BMI")?.value : '',
        symptoms: consulationData?.symptoms ? consulationData?.symptoms : '',
        recommendation: consulationData?.recommendation ? consulationData?.recommendation : ''
      });
    }
    setVitalsObj(consulationData?.patientVitals);
  }, [consulationData]);

  const getPrescriptionDetails = async () => {
    let load = {
      consultationId: selectedPatient.consultationId,
      detailsYN: "Y",
    }
    const consultationResponse = await ConsultationService.getPrescriptions(load);
    if (consultationResponse?.data?.length) {
      setConsulationData(consultationResponse?.data[0])
      console.log("koiiii", consultationResponse?.data[0])
      if (consultationResponse?.data[0].patientDiagnosisDetails.length) {
        console.log("lollsss", consultationResponse?.data[0].patientDiagnosisDetails)
        setSelectedDiagArr(consultationResponse?.data[0].patientDiagnosisDetails);
        setSelectedOriginalDiagArr(consultationResponse?.data[0].patientDiagnosisDetails);
        setShowTable(true);
      }
    }
  }
  useEffect(() => {
    fetchICDData();
    fetchVitals();
  }, []);

  const fetchVitals = async () => {
    let payload = {
      status: 1
    };
    const res = await VitalService.VitalMasterlist(payload);
    setVitalMasterList(res.data);
  }

  const fetchICDData = async () => {
    try {
      const response = await ConsultationService.ICDData();
      setDiagnosticList(response.data);
      setCurrPrimaryDiagList(response.data);
      setSecondaryDiag(response.data);
    }
    catch (e) {
      console.log(e);
    }
  }

  const handleSysChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    setSys(value);
  };

  // Function to handle changes in the input field for DIA
  const handleDiaChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    setDia(value);
  };

  const handleDelete = (record) => {
    const diagArr = selectedDiagArr;
    const diagOrgArr = selectedOriginalDiagArr;
    const updatedTableData = diagArr.filter((item) => item.id !== record.id);
    const itemToBeDeletedIdx = diagOrgArr.findIndex((item) => item.id === record.id);
    if (itemToBeDeletedIdx !== -1) {
      diagOrgArr[itemToBeDeletedIdx].status = 0;
    }
    setSelectedDiagArr(updatedTableData);
    setSelectedOriginalDiagArr(diagOrgArr);
  };

  const handleSecDelete = (record) => {
    const updatedTableData = selectedDiagArr.filter((item) => item.id !== record.id);
    setSelectedDiagArr(updatedTableData);
  }



  const Columns = [
    {
      title: "Type",
      dataIndex: "diagnosisType",
      key: "diagnosisType",

    },
    {
      title: "Description",
      dataIndex: "diagnosisDescription",
      key: "diagnosisDescription",
      render: (_, record) => (
        <Space className="text-left">
          {
            record?.diagnosisDescription?.length > 20 ?
              `${record?.diagnosisDescription?.slice(0, 20)}..` :
              record.diagnosisDescription
          }
        </Space>
      )
    },
    {
      title: "ICD Code",
      dataIndex: "diagnosisCode",
      key: "diagnosisCode",
    },
    {
      title: "",
      dataIndex: "delete",
      render: (_, record) => (
        <Space size="middle" className="">
          <div className="flex items-center gap-2 ">
            <div className="">
              <img
                src={deleteicon}
                alt="img"
                className="w-6 h-6"
                onClick={() => handleDelete(record)}
              />
            </div>
          </div>
        </Space>
      ),
    },
  ];


  const customHeaderContent = (
    <thead className="">
      <tr>
        <th className="bg-blue-100 text-left px-3 py-3">Type</th>
        <th className="bg-blue-100 py-3 px-3 text-left">ICD Description</th>
        <th className="bg-blue-100 py-3 px-3 text-left">ICD Code</th>
        <th className="bg-blue-100 py-3 px-3 text-left"></th>
      </tr>
    </thead>
  );



  const handlePrescription = async (e) => {
    e.preventDefault();
    const currDate = new Date().toISOString();
    const date = currDate.split('T')[0];
    const time = currDate.split('T')[1].slice(0, 8);
    const constructedTime = date + " " + time;
    const buttonClick = e.target.innerHTML;

    const patientData = state?.selectedPatient;
    console.log("patient Data ", patientData);
    const userObj = localStorage.getItem('login_result')
    const userData = userObj ? JSON.parse(userObj) : null;
    console.log("formRef.current.getFieldValue() ", formRef.current.getFieldValue(), medicinesObj, labTestsObj)
    const { primary, secondary, symptoms, recommendation } = formRef.current.getFieldValue();
    const { BPS, BPD, PO, HR, RR, TP, HI, WE, BMI } = formRef.current.getFieldValue();
    const FormNames = { BPS, BPD, PO, HR, RR, TP, HI, WE, BMI };
    const VitalKeys = Object.keys(FormNames);
    let diagnosisArr = [];
    let vitalsArr = [];
    let medicinesArr = [];
    let labtestArr = [];
    console.log("diag ", selectedOriginalDiagArr);
    // diagnosis payload objects
    for (let i = 0; i < selectedOriginalDiagArr?.length; i++) {
      let Diagpayload = {
        ...selectedOriginalDiagArr[i],
        account_Id: userData?.user?.account_Id,
        clinic_Id: userData?.user?.clinic_Id,
        createdBy: userData?.user.userId,
        createdDate: constructedTime,
        diagnosisCode: selectedOriginalDiagArr[i]?.diagnosisCode,
        diagnosisDescription: selectedOriginalDiagArr[i]?.diagnosisDescription,
        diagnosisType: selectedOriginalDiagArr[i]?.diagnosisType,
        modifiedBy: userData?.user.userId,
        modifiedDate: constructedTime,
        patientId: patientData?.patientId,
        status: selectedOriginalDiagArr[i]?.status === 0 ? 0 : selectedOriginalDiagArr[i]?.status === undefined ? 1 : selectedOriginalDiagArr[i]?.status,
        userId: userData?.user.userId
      }
      if (buttonClick === "Update Consulation") {
        Diagpayload.consultationId = selectedPatient?.consultationId;
      }
      diagnosisArr.push(Diagpayload);
    }

    // vitals payload objects
    const vitalDate = new Date().toUTCString();
    console.log(vitalDate);
    const dateArr = vitalDate.split(' ');
    const vitMonth = months[dateArr[2]];
    const vitDate = dateArr[1];
    const vitYear = dateArr[3];
    const constructedDate = vitYear + '-' + vitMonth + '-' + vitDate;
    const vitTime = dateArr[4];
    const constructedDateTime = constructedDate + " " + vitTime;

    // for (let i = 0; i < VitalKeys.length; i++) {
    //   console.log("formRef.current.getFieldValue(VitalKeys[i]) ",formRef.current.getFieldValue(VitalKeys[i]));
    //   let payload = {
    //     createdBy: userData?.user.userId,
    //     createdDate: constructedDateTime,
    //     modifiedBy: userData?.user.userId,
    //     modifiedDate: constructedDateTime,
    //     patientId: patientData?.patientId,
    //     recordedDateTime: constructedDateTime,
    //     status: 1,
    //     units: VitalKeys[i] === 'systolic' || VitalKeys[i] === 'diastolic' ? 'mmHg' :
    //       VitalKeys[i] === 'PO' ? '%' : VitalKeys[i] === 'HR' || VitalKeys[i] === 'RR' ? 'Beats/min' :
    //         VitalKeys[i] === 'TP' ? 'F' : VitalKeys[i] === 'HI' ? 'cm' : VitalKeys[i] === 'WE' ? 'kg' : 'kg/m2',
    //     value: VitalKeys[i] === 'bmi' ? bmiValue ? bmiValue : consulationData?.patientVitals?.find((item) => item.vitalCode === "BMI").value :
    //       VitalKeys[i] === 'systolic' ? sys ? sys : consulationData?.patientVitals?.find((item) => item.vitalCode === "BPS").value :
    //         VitalKeys[i] === 'diastolic' ? dia ? dia : consulationData?.patientVitals?.find((item) => item.vitalCode === "BPD").value :
    //           VitalKeys[i] === 'PO' ? formRef.current.getFieldValue(VitalKeys[i]) ? formRef.current.getFieldValue(VitalKeys[i]) : consulationData?.patientVitals?.find((item) => item.vitalCode === "PO").value :
    //             VitalKeys[i] === 'HR' ? formRef.current.getFieldValue(VitalKeys[i]) ? formRef.current.getFieldValue(VitalKeys[i]) : consulationData?.patientVitals?.find((item) => item.vitalCode === "HR").value :
    //               VitalKeys[i] === 'RR' ? formRef.current.getFieldValue(VitalKeys[i]) ? formRef.current.getFieldValue(VitalKeys[i]) : consulationData?.patientVitals?.find((item) => item.vitalCode === "RR").value :
    //                 VitalKeys[i] === 'TP' ? formRef.current.getFieldValue(VitalKeys[i]) ? formRef.current.getFieldValue(VitalKeys[i]) : consulationData?.patientVitals?.find((item) => item.vitalCode === "TP").value :
    //                   VitalKeys[i] === 'Hi' ? formRef.current.getFieldValue(VitalKeys[i]) ? formRef.current.getFieldValue(VitalKeys[i]) : consulationData?.patientVitals?.find((item) => item.vitalCode === "HI").value :
    //                     VitalKeys[i] === 'WE' ? formRef.current.getFieldValue(VitalKeys[i]) ? formRef.current.getFieldValue(VitalKeys[i]) : consulationData?.patientVitals?.find((item) => item.vitalCode === "WE").value : formRef.current.getFieldValue(VitalKeys[i]),
    //     vitalCode: VitalKeys[i] === 'systolic' ? 'BPS' : VitalKeys[i] === 'diastolic' ? 'BPD' :
    //       VitalKeys[i] === 'PO' ? 'PO' : VitalKeys[i] === 'HR' ? 'HR' : VitalKeys[i] === 'RR' ? 'RR' :
    //         VitalKeys[i] === 'TP' ? 'TP' : VitalKeys[i] === 'HI' ? 'HI' : VitalKeys[i] === 'WE' ? 'WE' : VitalKeys[i] === 'bmi' ? 'BMI' : '',
    //     vitalName: VitalKeys[i]
    //   }
    //   vitalsArr.push(payload);
    // }


    for (let i = 0; i < VitalKeys.length; i++) {

      let vitalPayload = {
        account_Id: userData?.user?.account_Id,
        clinic_Id: userData?.user?.clinic_Id,
        createdBy: userData?.user.userId,
        createdDate: constructedDateTime,
        modifiedBy: userData?.user.userId,
        modifiedDate: constructedDateTime,
        patientId: patientData?.patientId,
        recordedDateTime: constructedDateTime,
        status: 1,
        units: vitalMasterList.find((vital) => vital.vitalCode === VitalKeys[i])?.units,
        value: VitalKeys[i] === 'BMI' ? bmiValue ? bmiValue : consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "BMI")?.value : '' :
          VitalKeys[i] === 'BPS' ? sys ? sys : consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "BPS")?.value : '' :
            VitalKeys[i] === 'BPD' ? dia ? dia : consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "BPD")?.value : '' :
              VitalKeys[i] === 'PO' ? formRef.current.getFieldValue(VitalKeys[i]) ? Number(formRef.current.getFieldValue(VitalKeys[i])) : consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "PO")?.value : '' :
                VitalKeys[i] === 'HR' ? formRef.current.getFieldValue(VitalKeys[i]) ? Number(formRef.current.getFieldValue(VitalKeys[i])) : consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "HR")?.value : '' :
                  VitalKeys[i] === 'RR' ? formRef.current.getFieldValue(VitalKeys[i]) ? Number(formRef.current.getFieldValue(VitalKeys[i])) : consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "RR")?.value : '' :
                    VitalKeys[i] === 'TP' ? formRef.current.getFieldValue(VitalKeys[i]) ? Number(formRef.current.getFieldValue(VitalKeys[i])) : consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "TP")?.value : '' :
                      VitalKeys[i] === 'HI' ? formRef.current.getFieldValue(VitalKeys[i]) ? Number(formRef.current.getFieldValue(VitalKeys[i])) : consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "HI")?.value : '' :
                        VitalKeys[i] === 'WE' ? formRef.current.getFieldValue(VitalKeys[i]) ? Number(formRef.current.getFieldValue(VitalKeys[i])) : consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "WE")?.value : '' : '',
        vitalCode: vitalMasterList.find((vital) => vital.vitalCode === VitalKeys[i])?.vitalCode,
        vitalName: vitalMasterList.find((vital) => vital.vitalCode === VitalKeys[i])?.vitalName
      }
      // if(e.target.innerHTML === "Update Consulation"){
      //   const obj =  consulationData?.patientVitals?.find((item) => item.vitalCode === vitalPayload.vitalCode);
      //   console.log(obj);
      // }
      if (buttonClick === "Update Consulation") {
        vitalPayload.consultationId = selectedPatient?.consultationId;
        vitalPayload.id = consulationData?.patientVitals?.find((vital) => vital.vitalCode === vitalPayload.vitalCode)?.id;
      }
      vitalsArr.push(vitalPayload);
    }

    console.log("prepared vital arr ", vitalsArr);
    console.log("medicines data before final submit ", medicinesObj);
    // medicine payload objects
    for (let i = 0; i < medicinesObj?.length; i++) {
      const toDate = medicinesObj[i]?.duration * 24 * 60 * 60 * 1000 ? new Date(new Date().getTime() + medicinesObj[i]?.duration * 24 * 60 * 60 * 1000).toISOString() : new Date().toISOString();
      const toDays = toDate.split('T')[0];
      const constructedToDateTime = toDays + " " + time;
      let medicinePayload = {
        ...medicinesObj[i],
        account_Id: userData?.user?.account_Id,
        clinic_Id: userData?.user?.clinic_Id,
        afterBeforeFood: medicinesObj[i]?.timeTaken === "afterfood" ? "AF" : "BF",
        composition: medicinesObj[i]?.composition,
        createdBy: userData?.user.userId,
        createdDate: constructedTime,
        dosage: medicinesObj[i]?.dosage ? medicinesObj[i]?.dosage : null,
        drugCode: medicinesObj[i]?.code ? medicinesObj[i]?.code : medicinesObj[i]?.drugCode ? medicinesObj[i]?.drugCode : null,
        drugName: medicinesObj[i]?.drugName ? medicinesObj[i]?.drugName : null,
        duration: medicinesObj[i]?.duration ? medicinesObj[i]?.duration : null,
        form: null,
        frequency: medicinesObj[i]?.dosage ? medicinesObj[i]?.dosage : null,
        fromDateTime: constructedTime,
        givenDate: constructedTime,
        instruction: medicinesObj[i]?.instruction ? medicinesObj[i]?.instruction : null,
        medicineType: medicinesObj[i]?.productType ? medicinesObj[i]?.productType : null,
        modifiedBy: userData?.user.userId,
        modifiedDate: constructedTime,
        patientId: patientData?.patientId,
        quantity: 0,
        specialAuthority: null,
        status: medicinesObj[i]?.status === 0 ? 0 : medicinesObj[i]?.status === undefined ? 1 : medicinesObj[i]?.status,
        toDateTime: constructedToDateTime,
        userId: userData?.user.userId
      }
      if (buttonClick === "Update Consulation") {
        medicinePayload.consultationId = selectedPatient?.consultationId;
      }
      medicinesArr.push(medicinePayload);
    }

    console.log("labs data before final submit ", labTestsObj);
    //labtest payload objects
    for (let i = 0; i < labTestsObj?.length; i++) {
      let labPayload = {
        ...labTestsObj[i],
        account_Id: userData?.user?.account_Id,
        clinic_Id: userData?.user?.clinic_Id,
        createdBy: userData?.user.userId,
        createdDate: constructedTime,
        documentReceivedSource: null,
        labTestId: labTestsObj[i]?.labTestId ? labTestsObj[i]?.labTestId : null,
        labTestName: labTestsObj[i]?.labTestName ? labTestsObj[i]?.labTestName : null,
        modifiedBy: userData?.user.userId,
        modifiedDate: constructedTime,
        parentLabTestName: labTestsObj[i]?.parentDescription ? labTestsObj[i]?.parentDescription : null,
        patientId: patientData?.patientId,
        status: labTestsObj[i].status === 0 ? labTestsObj[i].status : 1,
        userId: userData?.user.userId
      }
      if (buttonClick === "Update Consulation") {
        labPayload.consultationId = selectedPatient?.consultationId;
      }
      labtestArr.push(labPayload);
    }

    console.log(selectedPrimaryDiag, selectedSecondaryDiag);

    console.log("followup date ", followUpDate);


    let payload = {
      account_Id: userData?.user?.account_Id,
      clinic_Id: userData?.user?.clinic_Id,
      consultationDate: constructedTime,
      consultationReason: patientData?.appointmentReason,
      serviceCode: patientData?.serviceCode,
      createdBy: userData?.user.userId,
      createdDate: constructedTime ? constructedTime : consulationData?.createdDate,
      drugPrescription: medicinesArr,
      followupVisitDate: followUpDate.length !== 0 ? followUpDate : consulationData?.followupVisitDate,
      image: "",
      imageName: null,
      imageType: null,
      labPrescription: labtestArr,
      modifiedBy: userData?.user.userId,
      modifiedDate: constructedTime,
      patientDocumentID: 0,
      patientId: patientData?.patientId,
      patientVitals: vitalsArr,
      patientDiagnosisDetails: diagnosisArr,
      // primaryDiagnosis: selectedPrimaryDiag.code,
      // primarySymptoms: selectedPrimaryDiag.description,
      recommendation: recommendation ? recommendation : consulationData?.recommendation,
      // secondaryDiagnosis: selectedSecondaryDiag.code,
      // secondarySymptoms: selectedSecondaryDiag.description,
      status: 1,
      symptoms: symptoms ? symptoms : consulationData?.symptoms,
      userId: userData?.user.userId,
      appointmentId: patientData?.appointmentId
    }
    if (buttonClick === "Update Consulation") {
      payload.id = consulationData?.id;
      payload.appointmentId = selectedPatient?.appointmentId;
      payload.consultationId = selectedPatient?.consultationId;
    }

    setPrescription(payload);
    console.log("pay from  ", payload);
    if (e.target.innerHTML === "Preview") {
      setIsPreviewModalOpen(true);
    }
    else if (buttonClick === "End Consulation") {
      const response = await ConsultationService.ConsultationNotes(payload);
      const { appointmentId, consultationId } = response.data;
      let endConsultationPayload = {
        appointmentId,
        consultationId
      }
      await ConsultationService.EndConsultation(endConsultationPayload);
      payload = {};
      message.success("Your consultation ended successfully, e-prescription will be shared with patient.");
      history.push(APP_ROUTES.DASHBOARD);
    }
    else if (buttonClick === "Update Consulation") {
      console.log("payload from update ", payload);
      await ConsultationService.updateConsultation(payload);
      payload = {};
      message.success("Your consultation updated successfully, e-prescription will be shared with patient");
      history.push(APP_ROUTES.DASHBOARD);
    }

  }



  const handleHeight = (e) => {
    const newHeight = Number(e.target.value);
    setHeight(newHeight);

    if (weight && newHeight) {
      const heightInMeters = newHeight / 100;
      const bmi = weight / (heightInMeters * heightInMeters);
      console.log(bmi.toFixed(1))
      setBmiValue(bmi.toFixed(1));
    }
  }

  const handleWeight = (e) => {
    const newWeight = Number(e.target.value);
    setWeight(newWeight);

    if (height && newWeight) {
      const heightInMeters = height / 100;
      const bmi = newWeight / (heightInMeters * heightInMeters);
      console.log(bmi)
      setBmiValue(bmi.toFixed(1));
    }
  }

  const handlePrimaryDiagVal = (value) => {
    setSelectedPrimaryDiagVal(value);
    const selectedDiag = currPrimaryDiagList?.find((diagnosis) => diagnosis.code === value);
    console.log("hiiii ", selectedDiag);
    setSelectedCode(selectedDiag);
  }

  const handleSecondaryDiagVal = (value) => {
    setSelectedSecondaryDiagVal(value);
    const selectedDiag = currPrimaryDiagList?.find((diagnosis) => diagnosis.code === value);
    console.log("From secondary ", selectedDiag);
    setSelectedCode(selectedDiag);
  }



  const handleDiag = (type) => {
    if (type === "Primary") {
      if (selectedPrimaryDiagVal && selectedPrimaryDiagVal.length !== 0) {
        console.log(selectedPrimaryDiagVal, diagnosticList);
        const selected = diagnosticList.filter((item) => item.code === selectedPrimaryDiagVal)[0];
        console.log("selected ", selected);
        setSelectedPrimaryDiag(null);
        selected.diagnosisType = type;
        selected.diagnosisCode = selected.code;
        selected.diagnosisDescription = selected.description;
        console.log("final   ", selected);
        setSelectedDiagArr(prevState => [...prevState, selected]);
        setSelectedOriginalDiagArr(prevState => [...prevState, selected]);
        setShowTable(true);
        setSelectedCode(null);
        setKey(key + 1);
      }
      else {
        setSelectedPrimaryDiag(null);
      }
    }
    else {
      if (selectedSecondaryDiagVal && selectedSecondaryDiagVal.length !== 0) {
        const selected = diagnosticList.filter((item) => item.code === selectedSecondaryDiagVal)[0];
        setSelectedSecondaryDiag(null);
        console.log(" selected ", selected);
        selected.diagnosisType = type;
        selected.diagnosisCode = selected.code;
        selected.diagnosisDescription = selected.description;
        setSelectedDiagArr(prevState => [...prevState, selected]);
        setSelectedOriginalDiagArr(prevState => [...prevState, selected]);
        setShowTable(true);
        setSelectedCode(null);
        setSecKey(secKey + 1);
      }
      else {
        setSelectedSecondaryDiag(null);
      }

    }

  };

  // const handleSecondaryDiag = (value) => {
  //   if (value && value.length !== 0) {
  //     const selected = diagnosticList.filter((item) => item.code === value)[0];
  //     setSelectedSecondaryDiag(selected);
  //     setTempSelectedSecondaryDiag(prevState => [...prevState, selected]);
  //   }
  //   else {
  //     setSelectedSecondaryDiag({});
  //   }

  // };

  const onSearch = async (value) => {
    try {
      setIsLoading(true);
      const filtered = diagnosticList.filter((item) => item.description.includes(value));
      // const filtered = await ConsultationService.ICDCode(value);
      setCurrPrimaryDiagList(filtered);
      if (filtered && filtered.length !== 0) {
        setIsLoading(false);
      }
    }
    catch (e) {
      console.log(e);
    }
  };

  const primaryIcdCodeSearch = async (value) => {
    try {
      setIsLoading(true);
      const filtered = diagnosticList.filter((item) => item.code.includes(value.toUpperCase()));
      // const filtered = await ConsultationService.ICDCode(value.toUpperCase());
      setCurrPrimaryDiagList(filtered);
      if (filtered && filtered.length !== 0) {
        setIsLoading(false);
      }

    }
    catch (e) {
      console.log(e);
    }
  }



  const handlePrimaryCode = (value) => {
    if (value && value.length !== 0) {
      const selected = diagnosticList.filter((item) => item.code === value)[0];
      console.log("filtered from 248 ", selected);
      setSelectedPrimaryDiag(selected);
      setSelectedDiagArr(prevState => [...prevState, selected])
    }
    else {
      setSelectedPrimaryDiag({});
    }
  }

  const onSearch2 = async (value) => {
    try {
      setIsLoading(true);
      const filtered = diagnosticList.filter((item) => item.description.includes(value));
      // const filtered = await ConsultationService.ICDCode(value);
      setCurrSecondaryDiagList(filtered);
      if (filtered && filtered.length !== 0) {
        setIsLoading(false);
      }
    }
    catch (e) {
      console.log(e);
    }
  };

  const secondaryIcdCodeSearch = async (value) => {
    try {
      setIsLoading(true);
      console.log("value from sec value.toUpperCase() ", value.toUpperCase());
      const filtered = diagnosticList.filter((item) => item.code.includes(value.toUpperCase()));
      // const filtered = await ConsultationService.ICDCode(value.toUpperCase());
      console.log("filtered ", filtered);
      setCurrSecondaryDiagList(filtered);
      if (filtered && filtered.length !== 0) {
        setIsLoading(false);
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  const handleSecondaryCode = (value) => {
    const selected = diagnosticList.filter((item) => item.code === value)[0];
    setSelectedSecondaryDiag(selected)
  }

  const handleRadioChange = (e) => {
    setSelectedRadio(e.target.value);
  };
  const [searchedValue, setSearchedValue] = useState("");

  const [activeKey, setActiveKey] = useState("");

  const onChange = (key) => {
    setActiveKey(key);
  };

  const handleAddMedicines = (data) => {
    setMedicinesObj(data);
  }

  const handleFollowUpDate = (date, dateString) => {
    if (dateString) {
      const constructedTime = dateString;
      console.log(dateString);
      setFollowUpDate(constructedTime);
    }
    else {
      setFollowUpDate('');
    }
  }

  const handleAddLabTests = (data) => {
    console.log("lollsss", data)
    setLabTestsObj(data);
  }

  const handleCancel = () => {
    setIsPreviewModalOpen(false);
  }

  const disabledDate = (current) => {
    return current && current < dayjs().startOf('day');
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, "");
    let formattedValue = inputValue;
    if (inputValue.length >= 3) {
      formattedValue = `${inputValue.slice(0, 3)}/${inputValue.slice(3)}`;
    }
    setBloodPressure(formattedValue);
  };

  const formatBloodPressure = (value) => {
    const numericValue = value.replace(/\D/g, "");
    if (numericValue.length > 3) {
      return `${numericValue.slice(0, 3)}/${numericValue.slice(3)}`;
    } else {
      return numericValue;
    }
  };


  return (
    <>
      <div className="border border-brand-textGray rounded-xl  mb-4 relative p-1">
        <div className="flex justify-between items-center ">
          <div className="font-bold my-2 ml-2 ">
            Consultation Notes
          </div>
        </div>
        <Form
          key={consulationData}
          layout="vertical"
          ref={formRef}
          form={form}
          initialValues={{
            recommendation: consulationData?.recommendation,
            // followupVisitDate:consulationData?.
            symptoms: consulationData?.symptoms,
            complaint: state?.selectedPatient.appointmentReason
          }}
        >
          <Row gutter={24} className="w-full mt-2 flex items-center">
            <Col span={4}>
              <Form.Item label="Chief Complaint" name="complaint" className="mx-2">
                <Input className="h-12" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Symptoms" name="symptoms" className="mx-2">
                <Input className="h-12"></Input>
              </Form.Item>
            </Col>

          </Row>
          <div className="flex justify-between items-center ">
            <div className="font-bold my-2 mx-4 ">
              Diagnosis
            </div>
          </div>
          <div className="m-4">
            <Radio.Group value={selectedRadio} onChange={handleRadioChange}>
              <Radio value="primary">Primary</Radio>
              <Radio value="secondary">Secondary</Radio>
            </Radio.Group>
          </div>
          <div className="mx-4">
            {selectedRadio === "primary" ? (
              <>
                <Row gutter={24} className="w-full mt-2 flex items-center">
                  <Col span={8}>
                    <Form.Item
                      label="Primary"
                      // name="primaryDiagnosis"
                      className="mx-2"
                    >
                      <Select
                        key={`primary-${key}`}
                        showSearch
                        placeholder="Select Primary Diagnosis"
                        className="h-10"
                        onSearch={onSearch}
                        onChange={handlePrimaryDiagVal}
                        value={selectedCode?.description}
                        options={currPrimaryDiagList?.map((des) => {
                          return {
                            label: des.description,
                            value: des.code,
                          };
                        })}
                        filterOption={(input, option) =>
                          option &&
                          option.label &&
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        allowClear
                        notFoundContent={isLoading ? <div className="text-center"><Spin size="small" /></div> : null}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="ICD-10 code" className="mx-2">
                      {/* <input
                    defaultValue={selectedPrimaryDiag?.code}
                    onChange={(e) => setSelectedPrimaryDiag(e.target.value)}
                    className="h-10 w-full rounded-md border-gray-200 px-4 outline-none border"
                  /> */}
                      <Select
                        showSearch
                        key={`primary-icd-${key}`}
                        placeholder="Select ICD Code"
                        className="h-10"
                        onSearch={primaryIcdCodeSearch}
                        onChange={handlePrimaryDiagVal}
                        value={selectedCode?.code}
                        options={currPrimaryDiagList?.map((des) => {
                          return {
                            label: des.code,
                            value: des.code,
                          };
                        })}
                        filterOption={(input, option) =>
                          option &&
                          option.label &&
                          option.label.toUpperCase().indexOf(input.toUpperCase()) >= 0
                        }
                        allowClear
                        notFoundContent={isLoading ? <div className="text-center"><Spin size="small" /></div> : null}
                      />
                      {/* <div className="flex flex-col">
                      {
                        tempSelectedPrimaryDiag && tempSelectedPrimaryDiag.map((diag) => (
                          <p>{diag.code}</p>
                        ))
                      }
                    </div> */}
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <button className="bg-blue-600 border-2 border-blue-600 rounded-md p-2 w-24 text-white"
                      onClick={() => handleDiag("Primary")}
                    >
                      Add
                    </button>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row gutter={24} className="my-0 flex items-center" >
                  <Col span={8}>
                    <Form.Item
                      label="Secondary"
                      // name="secondaryDiagnosis"
                      className="mx-2"
                    >
                      <Select
                        key={`secondary-${secKey}`}
                        showSearch
                        className="h-10"
                        allowClear
                        value={selectedCode?.description}
                        placeholder="Select Secondary"
                        onChange={handleSecondaryDiagVal}
                        // onChange={(value) => handleDiag(value, "Secondary")}
                        onSearch={onSearch2}
                        options={secondaryDiag?.map((des) => {
                          return {
                            label: des.description,
                            value: des.code,
                          };
                        })}
                        filterOption={(input, option) =>
                          option &&
                          option.label &&
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        notFoundContent={isLoading ? <div className="text-center"><Spin size="small" /></div> : null}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="ICD-10 code" className="mx-2">
                      <Select
                        key={`secondary-icd-${secKey}`}
                        showSearch
                        placeholder="Select ICD Code"
                        className="h-10"
                        onSearch={secondaryIcdCodeSearch}
                        onChange={handleSecondaryDiagVal}
                        // onChange={(value) => handleDiag(value, "Secondary")}
                        value={selectedCode?.code}
                        options={currSecondaryDiagList?.map((des) => {
                          return {
                            label: des.code,
                            value: des.code,
                          };
                        })}
                        filterOption={(input, option) =>
                          option &&
                          option.label &&
                          option.label.toUpperCase().indexOf(input.toUpperCase()) >= 0
                        }
                        allowClear
                        notFoundContent={isLoading ? <div className="text-center"><Spin size="small" /></div> : null}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <button className="bg-blue-600 border-2 border-blue-600 rounded-md p-2 w-24 text-white"
                      onClick={() => handleDiag("Secondary")}
                    >
                      Add
                    </button>
                  </Col>

                </Row>
              </>

            )}

            <Row gutter={24}>
              <Col span={12} className="mx-3">
                <div
                  className={`border border-brand-textGray ${(showTable && selectedDiagArr.length !== 0) ? "block" : "hidden"
                    } rounded-lg my-6 relative`}
                >
                  <Table
                    className=""
                    columns={Columns}
                    dataSource={selectedDiagArr}
                    pagination={false}

                    components={{
                      header: {
                        wrapper: (props) => customHeaderContent,
                      },
                    }}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <Col span={24}>
            <div className="flex justify-between ">
              <div className="font-bold my-4  ml-6 ">Add Vitals</div>
            </div>
            <Row gutter={24}>
              <Col span={7}>
                {/* <Form.Item
                  label="Blood Pressure"
                  name="bloodPressure"
                  className="mx-2 h-10"
                
                >
                  <Input
                    value={bloodPressure}
                    onChange={handleInputChange}
                    maxLength={7} // Set maximum length to 7 characters (3 digits + 1 slash + 3 digits)
                    placeholder="SYS/DIA"
                    formatter={formatBloodPressure} // Format the input value
                    className="outline-none border-none h-10"
                    suffix={<span style={{ color: "#BEC0C3" }}>mmHg</span>}
                  />
                </Form.Item> */}

                <Form.Item
                  label="Blood Pressure"
                  name="bloodPressure"
                  className="mx-2"

                >
                  <div className="w-full flex rounded-lg border border-brand-textGray h-10">
                    <Form.Item name="BPS" className="outline-none border-none border-r">
                      <Input
                        value={sys}
                        onChange={handleSysChange}
                        maxLength={3}
                        placeholder="SYS"
                        className="outline-none border-none placeholder:text-end text-end"
                      // style={{ width: "10%" }}
                      />
                    </Form.Item>

                    <span className="w-[10%] text-center">/</span>
                    <Form.Item name="BPD">
                      <Input
                        value={dia}
                        onChange={handleDiaChange}
                        maxLength={3}
                        placeholder="DIA"
                        className="outline-none border-none"
                        // style={{ width: "20%" }}
                        suffix={<span style={{ color: "#BEC0C3" }}>mmHg</span>}
                      />
                    </Form.Item>
                  </div>
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item name="PO" label="Pulse Ox" className="mx-2">
                  <Input
                    className="h-10"
                    suffix={<span style={{ color: "#BEC0C3" }}>%</span>}
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={7}>
                <Form.Item name="HR" label="Heart Rate" className="mx-2">
                  <Input
                    className="h-10"
                    suffix={<span style={{ color: "#BEC0C3" }}>Beats/min</span>}
                  ></Input>
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item name="RR" label="Respiration Rate" className="mx-2">
                  <Input
                    className="h-10"
                    suffix={<span style={{ color: "#BEC0C3" }}>Breaths/min</span>}
                  ></Input>
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item name="TP" label="Temperature" className="mx-2">
                  <Input
                    className="h-10"
                    suffix={<span style={{ color: "#BEC0C3" }}>°F</span>}
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={7}>
                <Form.Item name="HI" label="Height" className="mx-2">
                  <Input
                    className="h-10"
                    onChange={handleHeight}
                    suffix={<span style={{ color: "#BEC0C3" }}>Cm</span>}
                  ></Input>
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item name="WE" label="Weight" className="mx-2">
                  <Input
                    onChange={handleWeight}
                    className="h-10"
                    suffix={<span style={{ color: "#BEC0C3" }}>Kg</span>}
                  ></Input>
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item label="BMI" className="mx-2">
                  <Input
                    className="h-10"
                    disabled
                    value={bmiValue}
                    suffix={<span style={{ color: "#BEC0C3" }}>Kg/m<sup>2</sup></span>}
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <AddMedicine consulationData={consulationData} handleAddMedicines={handleAddMedicines} />
          </Col>
          <Col span={24} className="py-4 my-4">
            <LabReports consulationData={consulationData} handleAddLabTests={handleAddLabTests} />
          </Col>
          <Row gutter={24}>

            <Col span={8} className="mx-4">
              <Form.Item
                label="Recommendation"
                name="recommendation"
                className="mx-2"
              >
                <Input className="h-12"></Input>
              </Form.Item>
            </Col>
            {/* <Col span={8}></Col> */}

            <Col span={4}>
              <Form.Item
                label="Schedule follow up"
                name="followupVisitDate"
                className="mx-2 "
              >
                <DatePicker disabledDate={disabledDate} onChange={handleFollowUpDate} format={"MM/DD/YYYY"} className="h-12 w-full" />
              </Form.Item>
            </Col>
          </Row>

          <Col span={24} className="my-10 ">
            <div className="flex gap-2 justify-end">
              <button className="bg-[#26B144] disabled:opacity-50  rounded-md p-2 w-24 text-white" onClick={handlePrescription}>
                Preview
              </button>
              {selectedPatient?.consultationId && selectedPatient?.callMode == "EditCall" ?
                <button
                  onClick={handlePrescription}
                  className="bg-blue-600 border-2 border-blue-600 rounded-md p-2 w-32 text-white"
                >
                  Update Consulation
                </button>
                :
                <button
                  onClick={handlePrescription}
                  className="bg-blue-600 border-2 border-blue-600 rounded-md p-2 w-32 text-white"
                >
                  End Consulation
                </button>
              }
            </div>
          </Col>
        </Form >
      </div >
      <Modal
        width={"70%"}
        open={isPreviewModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        <PrescriptionDetails prescription={prescription} selectedPatient={state?.selectedPatient} />
      </Modal>
    </>
  );
}

export default ConsultationNotes;
