import React, { useEffect, useState } from "react";
import { Col, Form, Input, Row, message } from "antd";
import VitalService from "../../../../Services/VitalService";
import moment from "moment";

const months = {
  Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06', Jul: '07',
  Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12'
};

const AddVitals = (props) => {
  const activePatient = JSON.parse(localStorage.getItem("patient"));
  const patientCode = activePatient?.patientId;
  const [vitalMasterData, setVitalMasterData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sys, setSys] = useState("");
  const [dia, setDia] = useState("");

  const [formData, setFormData] = useState({
    bloodPressureSYS: 0,
    bloodPressureDIA: 0,
    pulseOx: 0,
    heartRate: 0,
    respirationRate: 0,
    Temperature: 0,
    Hight: 0,
    Weight: 0,
    BMI: "",
  });

  const VitalMaster = () => {
    let payload = {
      status: 1,
    };
    VitalService.VitalMasterlist(payload).then((result) => {
      setVitalMasterData(result.data);
    });
  };
  const convertUTCDate = (date, format) => {
    if (!format) {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
        " " +
        d.getHours() +
        ":" +
        d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return moment(new Date(date)).utc().format(format);
  };
  useEffect(() => {
    VitalMaster();
  }, []);

  const handleInputChange = (value, fieldName) => {
    console.log("fieldName & value ", fieldName, value)
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
    console.log("prev Data ", formData)
    if (fieldName === "Height") {
      const Weight = formData['Weight'];
      const Height = value;
      if (Number(Height !== 0) && Number(Weight) !== 0) {
        const bmi = calculateBMI(Height, Weight);
        console.log("hiiii", bmi);
        setFormData((prevFormData) => ({
          ...prevFormData,
          BMI: bmi,
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          BMI: "",
        }));
        // const calculateBMI = (height, weight) => {
        //   const heightInMeter = height / 100;
        //   return (weight / (heightInMeter * heightInMeter)).toFixed(2);
        // };
      }
    }
    else if (fieldName === "Weight") {
      const Height = formData['Height'];
      const Weight = value;
      if (Number(Height !== 0) && Number(Weight) !== 0) {
        const bmi = calculateBMI(Height, Weight);
        console.log("hiiii", bmi);
        setFormData((prevFormData) => ({
          ...prevFormData,
          BMI: bmi,
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          BMI: "",
        }));
      }
    }
  };

  const calculateBMI = (height, weight) => {
    const heightInMeters = Number(height) / 100;
    console.log("eiigg", heightInMeters, weight);
    const bmi = Number(weight) / (heightInMeters * heightInMeters);
    return bmi.toFixed(1)
    // const heightInMeter = Number(height) / 100;
    // return (Number(weight) / (heightInMeter * heightInMeter)).toFixed(1);
  };

  const handleVitalSubmit = () => {
    setIsLoading(true);
    const payload = [];
    const currDate = new Date().toUTCString();
    console.log(currDate);
    const dateArr = currDate.split(' ');
    const month = months[dateArr[2]];
    const date = dateArr[1];
    const year = dateArr[3];
    const constructedDate = year + '-' + month + '-' + date;
    const time = dateArr[4];
    const constructedDateTime = constructedDate + " " + time;
    console.log(constructedDateTime);
    const userObj = localStorage.getItem('login_result')
    const userData = userObj ? JSON.parse(userObj) : null;

    if (!formData.bloodPressureSYS && !formData.bloodPressureDIA && !formData.pulseOx && !formData.heartRate && !formData.respirationRate &&
      !formData.Temperature && !formData.Hight && !formData.Weight && !formData.BMI
    ) {
      message.warning("Please fill in at least one field before submitting the form.!!");
      setIsLoading(false);
      return;
    }
    Object.entries(formData).forEach(([fieldName, value]) => {
      const vitalRange = vitalMasterData.find(
        (vital) => vital.vitalName.toLowerCase() === fieldName.toLowerCase()
      );

      if (vitalRange) {
        payload.push({
          account_Id: userData?.user?.account_Id,
          clinic_Id: userData?.user?.clinic_Id,
          consultationId: null,
          createdBy: userData?.user?.userId,
          createdDate: constructedDateTime,
          modifiedBy: userData?.user?.userId,
          modifiedDate: constructedDateTime,
          patientId: patientCode,
          recordedDateTime: constructedDateTime,
          status: 1,
          units: vitalRange.units,
          value: value,
          vitalCode: vitalRange.vitalCode,
          vitalName: vitalRange.vitalName,
        });
      }
    });
    console.log("payyyy   ", payload);
    VitalService.AddVitals(payload)
      .then((response) => {
        props.updatedVitalsList(response.data);
        setIsLoading(false);
        message.success("Vitals added successfully.")
        props.onClose();
      })
      .catch((error) => {
        setIsLoading(false);
        message.error("Error occurred. Please try after sometime.!!");
        console.error("Error sending data:", error);
      });
  };

  const handleSysChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    setSys(value);
  };

  const handleDiaChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    setDia(value);
  };

  return (
    <div className="mt-6 border rounded-xl  p-1.5">
      <Form layout="vertical">
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label={<span className="font-medium">Blood Pressure</span>}
              name="bloodPressure"
              className="mx-2"

            >
              <div className="w-full flex items-center rounded-lg border border-brand-textGray h-10">
                <Input
                  // value={sys}
                  onChange={(e) => handleInputChange(e.target.value, "Blood Pressure(SYS)")}
                  maxLength={3}
                  placeholder="SYS"
                  className="outline-none border-none placeholder:text-end"
                // style={{ width: "10%" }}
                />
                <span style={{ width: "10%", textAlign: "center" }}>/</span>
                <Input
                  // value={dia}
                  onChange={(e) => handleInputChange(e.target.value, "Blood Pressure(DIA)")}
                  maxLength={3}
                  placeholder="DIA"
                  className="outline-none border-none"
                  // style={{ width: "20%" }}
                  suffix={<span style={{ color: "#BEC0C3" }}>mmHg</span>}
                />
              </div>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={<span className="font-medium">Pulse Ox</span>} className="mx-2">
              <Input
                className="h-10"
                suffix={<span style={{ color: "#BEC0C3" }}>%</span>}
                onChange={(e) => handleInputChange(e.target.value, "Pulse Ox")}
              ></Input>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={<span className="font-medium">Heart Rate</span>} className="mx-2">
              <Input
                className="h-10"
                suffix={<span style={{ color: "#BEC0C3" }}>Beats/min</span>}
                onChange={(e) =>
                  handleInputChange(e.target.value, "Heart Rate")
                }
              ></Input>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item label={<span className="font-medium">Respiration Rate</span>} className="mx-2">
              <Input
                className="h-10"
                suffix={<span style={{ color: "#BEC0C3" }}>Breaths/min</span>}
                onChange={(e) =>
                  handleInputChange(e.target.value, "Respiration Rate")
                }
              ></Input>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={<span className="font-medium">Temperature</span>} className="mx-2">
              <Input
                className="h-10"
                suffix={<span style={{ color: "#BEC0C3" }}>°F</span>}
                onChange={(e) =>
                  handleInputChange(e.target.value, "Temperature")
                }
              ></Input>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <hr className="my-4 border-gray-400" />

      <Form layout="vertical">
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item label={<span className="font-medium">Height</span>} className="mx-2">
              <Input
                className="h-10"
                suffix={<span style={{ color: "#BEC0C3" }}>Cm</span>}
                onChange={(e) => handleInputChange(e.target.value, "Height")}
              ></Input>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={<span className="font-medium">Weight</span>} className="mx-2">
              <Input
                className="h-10"
                suffix={<span style={{ color: "#BEC0C3" }}>Kg</span>}
                onChange={(e) => handleInputChange(e.target.value, "Weight")}
              ></Input>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={<span className="font-medium">BMI</span>} className="mx-2">
              <Input
                className="h-10"
                disabled
                suffix={
                  <span style={{ color: "#BEC0C3" }}>
                    Kg/m<sup>2</sup>
                  </span>
                }
                onChange={(e) => handleInputChange(e.target.value, "BMI")}
                value={formData.BMI}
              ></Input>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24}>
            <div className="flex items-center w-full justify-end my-5">
              <button
                className="bg-white rounded border border-brand-primaryColor text-brand-primaryColor text-sm px-6 py-1.5"
                onClick={props.handleClosemodal}
              >
                Cancel
              </button>
              <button
                disabled={isLoading}
                className="bg-brand-primaryColor rounded px-5 py-1.5 text-white text-sm ml-3 disabled:cursor-not-allowed disabled:opacity-50"
                onClick={handleVitalSubmit}
              >
                Submit
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddVitals;
