import http from "./http-common";

class VitalService {
  queryString(payload) {
    return Object.keys(payload)
      .map((key) => (key && payload[key])? key + "=" + payload[key]:'')
      .join("&");
  }

  VitalMasterlist(payload) {
    var queryString = this.queryString(payload);
    return http.get(`vitalMaster/list/filter?${queryString}`);
  }
  async AddVitals(data) {
    try {
      const response = await http.post(`patientVitals/BatchSave/`, data);
      if(response.data){
        return http.get(`/patientVitals/list/recentRecords/${data[0].patientId}`);
      }
    }
    catch(e){
      console.log(e);
    }
  }

  updateVital(data){
    return http.put('/patientVitals/BatchUpdate',data);
  }

  getVitalsData(payload) {
    var queryString = this.queryString(payload);
    return http.get(`/patientVitals/list/recordedVitalDateTime?${queryString}`);
  }

  getAllVitalsData(payload){
    var queryString = this.queryString(payload);
    return http.get(`patientVitals/list/filter?${queryString}`);
  }

  getSpecificVitalData(payload){
    var queryString = this.queryString(payload);
    return http.get(`/patientVitals/list/filter?${queryString}`);
  }

  // getPatientRecentVitals(payload) {
  //   var queryString = this.queryString(payload);
  //   return http.get(`/patientVitals/list/filter/${queryString}`);
  // }
}

export default new VitalService();
