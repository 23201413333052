import React, { useEffect, useState } from "react";
import Layouts from "../../Layouts";
import { message } from "antd";
import InviteCard from "./InviteCard";
import UpcomingAppointments from "../UpcomingAppointments";
import AppointmentService from "../../../Services/AppointmentService";
import Card from "./Card";
import DashboardService from "../../../Services/DashboardService";
import PatientQueue from "../VCPage/PatientQueue";
import patientqueue from "../../../Assets/Images/person-icon-blue.png";
import PatientService from "../../../Services/PatientService";
import { MdKeyboardDoubleArrowUp } from "react-icons/md";
import { IoIosCloseCircle } from "react-icons/io";
import Draggable from "react-draggable";

function Dashboard() {
  const token = localStorage.getItem("access_token");
  const [queueData, setQueueData] = useState();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [patientsInviteList, setPatientsInviteList] = useState([]);
  const [counts, setCounts] = useState({});

  const storedLoginResult = localStorage.getItem("login_result");
  const loginResultObject = storedLoginResult
    ? JSON.parse(storedLoginResult)
    : null;

  const appointmentsList = () => {
    const fromDate = new Date();
    const formattedDate = fromDate.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });

    let payload = {
      userId: loginResultObject.user.userId,
      fromDate: formattedDate,
    };
    AppointmentService.AppointmentFilter(payload)
      .then((result) => {
        setPatientsInviteList(result.data);
        getCount();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    appointmentsList();
    getCount();
  }, []);

  const appointmentData = [
    { name: "Mon", uv: 600 },
    { name: "Tue", uv: 500 },
    { name: "Wed", uv: 700 },
    { name: "Thu", uv: 978 },
    { name: "Fri", uv: 789 },
    { name: "Sat", uv: 439 },
    { name: "Sun", uv: 749 },
  ];

  const patientsData = [
    { name: "Mon", uv: 400 },
    { name: "Tue", uv: 180 },
    { name: "Wed", uv: 220 },
    { name: "Thu", uv: 178 },
    { name: "Fri", uv: 189 },
    { name: "Sat", uv: 209 },
    { name: "Sun", uv: 249 },
  ];

  const earningData = [
    { name: "Mon", uv: 600 },
    { name: "Tue", uv: 450 },
    { name: "Wed", uv: 470 },
    { name: "Thu", uv: 520 },
    { name: "Fri", uv: 780 },
    { name: "Sat", uv: 530 },
    { name: "Sun", uv: 800 },
  ];

  const getCount = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const firstDate = new Date(year, month, 1);
    const lastDate = new Date(year, month + 1, 0);

    const formatDate = (date) => {
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    };

    let payload = {
      account_Id: loginResultObject.user.account_Id,
      clinic_Id: loginResultObject.user.clinic_Id,
      userId: loginResultObject.user.userId,
      billingStatus: 1,
      fromDate: formatDate(firstDate),
      toDate: formatDate(lastDate),
    };

    DashboardService.DashboardCounts(payload).then((res) => {
      setCounts(res.data);
    });
  };

  const onClose = () => {
    setIsDrawerOpen(false);
  };
  const showDrawer = () => {
    const userObj = localStorage.getItem("login_result");
    const userData = userObj ? JSON.parse(userObj) : null;
    let payload = {
      userId: userData?.user?.userId,
      queueStatus: "Y",
    };
    PatientService.patientQueueList(payload).then((res) => {
      if (res.data.length) {
        let validate = res?.data?.filter(
          (x) => x.appointmentId != userData?.user?.appointmentId
        );
        if (validate && validate.length) {
          setQueueData(validate);
          setIsDrawerOpen(true);
        } else {
          message.warning("No patient available in queue");
        }
      } else {
        message.warning("No patient available in queue");
      }
    });
  };

  const [isVerticalOpen, setIsVerticalOpen] = useState(true);
  const handleClick = () => {
    setIsVerticalOpen(true);
  };
  const closeCircle = () => {
    setIsVerticalOpen(false);
  };
  return (
    <Layouts>
      <div className="mainContent  w-[95%] mx-auto">
        <div className="mb-10">
          <div className="gutter-row h-full w-full">
            <div className="md:text-2xl text-xl tracking-wider font-medium mb-8">
              Dashboard
            </div>
            <div className="md:flex flex-col  items-center justify-center w-full bg-[#1557b3] rounded-xl bgset">
              <InviteCard appointmentsList={appointmentsList} />
            </div>

            <div className="grid grid-cols-1 w-full mx-auto md:grid-cols-3 gap-6 mt-10">
              <Card
                title={
                  <span className="font-bold text-blue-600">
                    Total Appointments
                  </span>
                }
                value={counts.Total_Appointment_Count}
                percentage="5.9"
                increase={true}
                color="#FB7185"
                data={appointmentData}
              />
              <Card
                title={
                  <span className="font-bold text-blue-600">
                    Total Patients
                  </span>
                }
                value={counts.Total_Patient_Count}
                percentage="4.7"
                increase={false}
                color="#1557B3"
                data={patientsData}
              />
              <Card
                title={
                  <span className="font-bold text-blue-600">Total Earning</span>
                }
                value={counts.Total_Earnings_Count}
                percentage="8.2"
                increase={true}
                color="#399918"
                data={earningData}
              />
            </div>
          </div>
        </div>

        <div>
          <div className=" w-full h-full">
            <UpcomingAppointments
              appointmentsList={appointmentsList}
              patientsInviteList={patientsInviteList}
              setPatientsInviteList={setPatientsInviteList}
            />
          </div>
        </div>
      </div>
      {/* patient queue design */}
      {isVerticalOpen ? (
        <Draggable>
          <div className="draggable-container fixed top-1/2 right-2 w-14 transform -translate-y-1/2 z-50">
            <div
              className="hidden md:block relative mb-5 cursor-pointer"
              onClick={closeCircle}
            >
              <IoIosCloseCircle className="hidden md:block absolute mb-1 right-0 top-0" />
            </div>
            <div className="p-2 flex justify-center items-center rounded-lg bg-[#5584f4]">
              <img
                src={patientqueue}
                alt="patientqueue"
                className="w-10 h-10 cursor-pointer"
                title="Patient Queue"
                onClick={showDrawer}
              />
            </div>
          </div>
        </Draggable>
      ) : (
        <div
          className="vertical"
          style={{ marginTop: "16rem" }}
          onClick={handleClick}
        >
          <p className="vertical-p">
            <div className="vertical-icon-dashboard">
              <MdKeyboardDoubleArrowUp />
            </div>
            Queue
          </p>
        </div>
      )}

      {isDrawerOpen && (
        <div className="grid justify-end mt-5">
          {isDrawerOpen && (
            <PatientQueue
              queueData={queueData ? queueData : ""}
              onClose={onClose}
            />
          )}
        </div>
      )}
    </Layouts>
  );
}

export default Dashboard;
