import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import AuthLayout from ".";
import Arrowicon from "../../Assets/Images/Svg/Arrow.svg";
import { useHistory } from "react-router-dom";
import { Base64 } from "js-base64";
import AuthService from "../../Services/AuthService";
import { APP_ROUTES } from "../../PageRouting/approutes";
import { UserSwitchOutlined } from "@ant-design/icons";
import { generateAesKey } from "../../utils/passwordEncrypt";

function LoginForm() {
  const [form] = Form.useForm();
  const history = useHistory();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [disable, setDisable] = useState(false);

  const handleLogin = async () => {
    // try {
    //   let payload = {
    //     password: Base64.encode(password),
    //     userId: userName,
    //   };

    //   if (!payload.userId || !payload.password) {
    //     message.error("please enter valid credentials to login");
    //     setDisable(false);
    //     return;
    //   } else {
    //     try {
    //       const tokenResult = await AuthService.TokenAuthentication(payload);
    //       if (!tokenResult.data.access_token) {
    //         message.error("Invalid Credentials! Please verify and try again");
    //         setDisable(false);
    //         return;
    //       }
    //       localStorage.setItem("access_token", tokenResult.data.access_token);
    //     } catch (error) {
    //       message.error(
    //         "An error occurred during authentication. Please try again later."
    //       );
    //       setDisable(false);
    //     }
    //   }

    //   const encryptedPass = generateAesKey(password);
    //   const loginPayload = {
    //     currentVal: encryptedPass,
    //     userCode: userName,
    //   };

    //   const loginResult = await AuthService.Login(loginPayload);
    //   if (!loginResult.data.message) {
    //     history.push(APP_ROUTES.DASHBOARD);
    //     message.success(
    //       `Welcome ${loginResult.data.user.firstName} ${loginResult.data.user.lastName}`
    //     );
    //     localStorage.setItem("login_result", JSON.stringify(loginResult.data));
    //   } else {
    //     message.error(loginResult.data.message);
    //     setDisable(false);
    //   }
    // } catch (error) {
    //   setDisable(false);
    // }

    setDisable(true);
    let payload = {
      password: Base64.encode(password),
      userId: userName,
    };

    if (!payload.userId || !payload.password) {
      message.error("please enter valid credentials to login");
      setDisable(false);
      return;
    }

    try {
      const res = await AuthService.TokenAuthentication(payload);
      localStorage.setItem("access_token", res.data.access_token);
      if (res.data.error === "1") {
        message.error(res.data.error_description);
        setDisable(false);
        return;
      }
      const encryptedPass = generateAesKey(password);
      let data1 = {
        currentVal: encryptedPass,
        userCode: userName,
      };
      const loginResult = await AuthService.Login(data1);

      if (!loginResult.data.message) {
        if (
          loginResult.data.user.rolecode !== "SUPERADMIN" &&
          loginResult.data.user.userType == "P"
        ) {
          history.push(APP_ROUTES.DASHBOARD);
          message.success(
            `Welcome ${loginResult.data.user.firstName} ${loginResult.data.user.lastName}`
          );
          localStorage.setItem(
            "login_result",
            JSON.stringify(loginResult.data)
          );
        } else {
          message.error("Invalid Credentials, verify and try again");
          setDisable(false);
        }
      } else {
        message.error(loginResult.data.message);
        setDisable(false);
      }
    } catch (error) {
      message.error("An Error occured. Please Try Again");
      setDisable(false);
    }
  };

  const handleForgotPassword = () => {
    history.push(APP_ROUTES.FORGETPASSWORD, { userName: userName });
  };

  return (
    <AuthLayout>
      <div className="w-[80%]">
        <div className="w-full justify-start flex mt-6">
          <div className="w-full">
            <Form
              name="basic"
              layout="vertical"
              autoComplete="off"
              form={form}
              onFinish={handleLogin}
            >
              <Form.Item name="userId">
                <Input
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  placeholder="User ID"
                  className="h-10 w-full px-2  border-[1px] border-gray-400 appearance-none bg-none dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  style={{ background: "none" }}
                  suffix={
                    <UserSwitchOutlined className="h-5 w-5 text-gray-500" />
                  }
                />
              </Form.Item>

              <Form.Item name="password">
                <Input.Password
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="h-10 w-full px-2 bg-transparent  border-[1px] border-gray-400 appearance-none dark:focus:border-blue-500 focus:outline-none focus:border-blue-600 peer"
                  style={{ background: "none" }}
                  placeholder="Password"
                />
              </Form.Item>
              <div
                className="flex justify-end items-center"
                onClick={handleForgotPassword}
              >
                <p className=" text-brand-primaryColor text-sm cursor-pointer mb-0">
                  Forgot Password?
                </p>
              </div>
              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="w-full  inline-block my-5 h-10 bg-[#009FAB]"
                  disabled={disable}
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}

export default LoginForm;
