const CryptoJS = require("crypto-js");

export const generateAesKey = (PasswordVal) => {
    try {
        const initVector = process.env.REACT_APP_VECTORKEY;
        const key =  process.env.REACT_APP_SECRETKEY;
        console.log("process.env.REACT_APP_VECTORKEY ",process.env.REACT_APP_VECTORKEY,"process.env.REACT_APP_SECRETKEY ",process.env.REACT_APP_SECRETKEY);
        const iv = CryptoJS.enc.Utf8.parse(initVector);
        const skeySpec = CryptoJS.enc.Utf8.parse(key);

        const encrypted = CryptoJS.AES.encrypt(
            PasswordVal,
            skeySpec,
            { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
        );
        console.log("from encrypted.toString() ",encrypted.toString());
        return encrypted.toString();

    } catch (ex) {
        console.error(ex);
        return null;
    }
};


