import http from "./http-common";

class DashboardService {
  DashboardCounts(payload) {
    const queryString = Object.entries(payload)
      .filter(
        ([_, value]) => value !== undefined && value !== null && value !== ""
      )
      .map(([key, value]) => `${key}=${value}`)
      .join("&");

    return http.get(`/user-dashboard/getDetails?${queryString}`);
  }
}
export default new DashboardService();
