import React, { useEffect, useRef, useState } from "react";
import PrescriptionHeader from "../ConsultationNotes/VitalTabs/ViewPrescription/PrescriptionHeader";
import PrescriptionFooter from "../ConsultationNotes/VitalTabs/ViewPrescription/PrescriptionFooter";
import ConsultationService from "../../../Services/ConsultationService";
import DoctorService from "../../../Services/DoctorService";
import PatientService from "../../../Services/PatientService";
import { Modal, Spin } from "antd";
import { DatePicker, Form, Input, Select } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import Preview from "../../../Assets/Images/Svg/PreviewIcon.svg";
import deleteHoverIcon from "../../../Assets/Images/Svg/deleteicon.svg";
import PrescriptionDetails from "../ConsultationNotes/VitalTabs/ViewPrescription/PrescriptionDetails";
import moment from "moment";

function LabPharma() {
  const [doctorData, setDoctorData] = useState();
  const [hospitalAccountData, setHospitalAccountData] = useState(null);
  const [clinicDetails, setClinicDetails] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedPrescription, setSelectedPrescription] = useState(null);
  const [preview, setPreview] = useState(false);
  const [loading, setLoading] = useState(true);

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [viewReportModal, setViewReportModal] = useState(false);
  const [imageData, setImageData] = useState("");
  const [imageType, setImageType] = useState("");
  const [reportPreview, setReportPreview] = useState("");
  const formRef = useRef();

  const storedLoginResult = localStorage.getItem("login_result");
  const loginResultObject = storedLoginResult
    ? JSON.parse(storedLoginResult)
    : null;

  console.log("loginres", loginResultObject);

  useEffect(() => {
    fetchClinicData(loginResultObject.user.clinic_Id);
    getDoctorName(loginResultObject.user.userId);
    fetchHospitalData(loginResultObject.user.account_Id);
  }, []);

  const fetchClinicData = async (AccId) => {
    const response = await ConsultationService.getClinicData(AccId);
    console.log("response", response);
    setClinicDetails(response.data[0]);
  };

  const getDoctorName = async (id) => {
    try {
      const response = await DoctorService.getDoctorData(id);
      setDoctorData(response?.data[0]);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchHospitalData = async (id) => {
    const response = await DoctorService.getHospitalAccountData(id);
    setHospitalAccountData(response.data[0]);
    setLoading(false);
  };

  const showPreview = async () => {
    let record = {
      appointmentId: "DZAPP1181049",
      patientId: "DZP6301000",
      serviceCode: "APPT_VIDEO",
      serviceName: "Video Consultation",
      userId: "DZU5891001",
      startTime: "15:00",
      endTime: "15:15",
      totalAmount: 500,
      paymentType: "Cash",
      paymentConsultation: "BC",
      account_Id: "DZA8981001",
      clinic_Id: "DZC8571001",
      appointmentReason: "fever",
      status: 1,
      appointmentDate: "09/23/2024",
      createdDate: "2024-09-23 05:00:40",
      modifiedDate: "2024-09-23 05:00:40",
      createdBy: "DZU5891001",
      modifiedBy: "DZU5891001",
      patientName: "Mr.Doxzon patient",
      userName: "Dr.Doxzon Doctor",
      account_Name: "DoxzonAccount",
      clinic_Name: "Doxzonclinic",
      queueStatus: "N",
      transactionId: "C20240923050039686",
      specialityCode: "S33",
      patientMobile: "8838595844",
      patientEmail: "doxzonpatient@gmail.com",
    };
    let patientPayload = {
      patientId: record?.patientId,
    };
    let consultationPayload = {
      consultationId: record?.consultationId,
      detailsYN: "Y",
    };
    try {
      const patientResponse = await PatientService.PatientListFilter(
        patientPayload
      );
      if (patientResponse) {
        setSelectedPatient(patientResponse.data[0]);
      }
      const consultationResponse = await ConsultationService.getPrescriptions(
        consultationPayload
      );
      if (consultationResponse.data) {
        setSelectedPrescription(consultationResponse.data[0]);
        setPreview(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const cancel = () => {
    setPreview(false);
  };

  const [value, setValue] = useState("");

  const handleChange = (e) => {
    let inputValue = e.target.value;

    // If input is a valid number
    if (!isNaN(inputValue) && inputValue !== "") {
      // Check if it's a whole number
      if (inputValue.indexOf(".") === -1) {
        // If whole number, format it with two decimal places
        inputValue = parseFloat(inputValue).toFixed(2);
      }
    }

    setValue(inputValue);
  };

  const handleRemoveFile = () => {
    setImageData("");
  };

  const disabledFutureDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const showModal = () => {
    setShowUploadModal(true);
  };
  const handleOk = () => {
    setShowUploadModal(false);
  };
  const handleCancel = () => {
    setShowUploadModal(false);
  };
  const showImageModal = () => {
    setViewReportModal(true);
  };
  const handlePreviewOk = () => {
    setViewReportModal(false);
  };
  const handlePreviewClose = () => {
    setViewReportModal(false);
  };

  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      let imageArray = [];
      imageArray = reader.result.split(",");
      const imageDataUrl = imageArray[1];
      const imageType = file.type;
      const fileExtension = imageType.split("/")[1];
      setImageData(imageDataUrl);
      setImageType(fileExtension);
      setReportPreview(reader.result);
    };
  };

  const props1 = {
    customRequest: ({ file, onSuccess }) => {
      if (file && file.size > 1048576) {
        message.error(
          "Image/file size should be less than or equal to 1 mb !!"
        );
        return;
      } else {
        handleFileUpload(file);
        message.success(`${file.name} uploaded successfully.`);
        onSuccess("ok");
      }
    },
  };

  return (
    <>
      <Modal
        open={preview}
        onCancel={cancel}
        style={{
          top: 40,
        }}
        width={"70%"}
        footer={null}
      >
        <PrescriptionDetails
          prescription={selectedPrescription}
          selectedPatient={selectedPatient}
        />
      </Modal>
      {loading ? (
        <div className="w-screeen h-screen flex justify-center items-center">
          <div>
            <Spin />
          </div>
        </div>
      ) : (
        <div className="m-4">
          <PrescriptionHeader
            clinicDetails={clinicDetails}
            doctorData={doctorData}
            hospitalAccountData={hospitalAccountData}
          />
          <div className="my-4">
            <div className="flex justify-between items-center">
              <div className="md:flex gap-5  my-4">
                <div className="text-sm font-bold flex gap-1">
                  Name:
                  <span className="text-sm text-gray-500 font-semibold">
                    Mr. Doxzon Patient
                  </span>
                </div>
                <div className="text-sm font-bold md:flex gap-1">
                  Age/Gender:
                  <span className="text-sm text-gray-500 font-semibold">
                    25/ Male
                  </span>
                </div>
                <div className="text-sm font-bold md:flex gap-1">
                  Phone Number:
                  <span className="text-sm text-gray-500 font-semibold">
                    9876543210
                  </span>
                </div>

                <div className="text-sm font-bold flex gap-1">
                  Visit Type:
                  <span className=" text-sm text-gray-500 font-semibold">
                    Video Consultation
                  </span>
                </div>
              </div>
              <button
                className="p-2 rounded-md h-12 text-white"
                onClick={showPreview}
              >
                ViewPrescription
              </button>
            </div>

            <div class="w-full mx-auto bg-white shadow-md rounded-lg p-6">
              <table class="min-w-full border-collapse">
                <thead>
                  <tr class="bg-gray-200">
                    <th class="border px-4 py-2 text-left">Tests</th>
                    <th class="border px-4 py-2 text-left">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border">
                      <input
                        value={"X-ray"}
                        className="w-full h-full p-1 outline-none  px-3"
                      />
                    </td>
                    <td class="border ">
                      <input
                        className="w-full h-full p-1 outline-none px-3"
                        // value={value}
                        // onChange={handleChange}
                        placeholder="Enter amount"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="border">
                      <input className="w-full h-full p-1 outline-none  px-3" />
                    </td>
                    <td class="border ">
                      <input className="w-full h-full p-1 outline-none  px-3" />
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td class="border px-4 py-2 text-right font-semibold">
                      Total
                    </td>
                    <td class="border px-4 py-2">100</td>
                  </tr>
                </tfoot>
              </table>
              <div className="px-4 py-2">
                <label class="inline-flex items-center">
                  <input
                    type="checkbox"
                    class="form-checkbox h-5 w-5 text-blue-600"
                  />
                  <span class="ml-2">Dispensed</span>
                </label>
              </div>
              <div className="px-4 py-2 flex justify-between w-full">
                <div>
                  <button
                    class="bg-blue-500 text-white px-4 py-2 rounded-md mr-4 border"
                    onClick={showModal}
                  >
                    Upload Reports
                  </button>
                </div>
                <div>
                  <button class="bg-white text-black px-4 py-2 rounded-md mr-4 border border-blue-500 ">
                    Cancel
                  </button>
                  <button class="bg-blue-500 text-white px-4 py-2 rounded-md mr-4 border">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <PrescriptionFooter clinicDetails={clinicDetails} />
          <Modal
            title={
              <div className="text-center text-2xl font-bold uppercase">
                Reports
              </div>
            }
            open={showUploadModal}
            onOk={handleOk}
            footer={[]}
            closeIcon={false}
            onCancel={handleCancel}
          >
            <Modal
              title="Preview"
              open={viewReportModal}
              onOk={handlePreviewOk}
              onCancel={handlePreviewClose}
              footer={null}
            >
              {imageType === "pdf" ? (
                <iframe
                  src={`data:application/pdf;base64,${imageData}`}
                  title="PDF Preview"
                  style={{ width: "100%", height: "500px" }}
                />
              ) : (
                <img
                  src={reportPreview}
                  alt="Preview"
                  style={{ width: "100%" }}
                />
              )}
            </Modal>
            <Form
              className="CommonForm grid grid-cols-2 gap-5"
              layout="vertical"
              autoComplete="off"
              ref={formRef}
            >
              <Form.Item
                className="mb-0"
                label={<span className="font-medium">Order ID</span>}
                name="orderID"
              >
                <Input placeholder="Enter OrderID" />
              </Form.Item>
              <Form.Item
                className="mb-0"
                label={<span className="font-medium">Date</span>}
                name="date"
              >
                <DatePicker
                  placeholder="Date"
                  className="w-full h-8"
                  format="YYYY-MM-DD"
                  // onChange={handleDate}
                  disabledDate={disabledFutureDate}
                />
              </Form.Item>
              <Form.Item
                className="mb-0"
                label={<span className="font-medium">Test</span>}
                name="test"
              >
                <Input placeholder="Enter Test" />
              </Form.Item>
              <Form.Item
                name="document"
                label={
                  <span className="font-medium">
                    Document<span style={{ color: "#FF000F" }}>*</span>
                  </span>
                }
              >
                {imageData ? (
                  <div className="flex gap-1 items-center">
                    <span>
                      {imageType === "application/pdf" ? "PDF" : "document"}
                    </span>
                    <img
                      className="ml-3 cursor-pointer"
                      onClick={handleRemoveFile}
                      src={deleteHoverIcon}
                      alt="image"
                    />
                    <img
                      className="ml-3 cursor-pointer"
                      src={Preview}
                      alt="image"
                      onClick={showImageModal}
                    />
                  </div>
                ) : (
                  <Upload {...props1} showUploadList={true}>
                    <Button
                      icon={<CloudUploadOutlined />}
                      className="text-white"
                    >
                      CHOOSE FILE
                    </Button>
                  </Upload>
                )}
              </Form.Item>
            </Form>
            <div className=" flex justify-end gap-3 mt-10">
              <button className="bg-white text-brand-primaryColor border-brand-primaryColor border rounded-lg w-[100px] h-[40px] text-base">
                Cancel
              </button>
              <button className="bg-brand-primaryColor w-[100px] h-[40px] text-base rounded-lg text-white disabled:cursor-not-allowed disabled:opacity-50">
                Save
              </button>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
}

export default LabPharma;
