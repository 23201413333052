import http from "./http-common";

class MedicalHistoryService {
  queryString(payload) {
    return Object.keys(payload)
      .map((key) => (key && payload[key])? key + "=" + payload[key]:'')
      .join("&");
  }
  loadICDCodesothers(payload) {
    var queryString = Object.keys(payload)
      .map((key) => key + "=" + payload[key])
      .join("&");
    return http.get(`common-icd-codes/list?${queryString}`);
  }

  AddPatientReport(data) {
    return http.post(`patientDocument/`, data);
  }

  async AddPatientAllery(data) {
    const response = await http.post(`PatientAllergies/save/`, data);
    return response;
  }
  async AddSocialHistory(data) {
    const response = await http.post(`PatientSocialHistory/save/`, data);
    // if (response.data === 1) {
    //   let payload = {
    //     account_Id:data.account_Id,
    //     // clinic_Id:data.clinic_Id,
    //     patientId: data.patientId,
    //     status: 1,
    //   };
    //   var queryString = this.queryString(payload);
    //   return http.get(`PatientSocialHistory/list/filter?${queryString}`);
    // }
    return response;
  }

  async AddFamilyHistory(data) {
    const response = await http.post(`/PatientFamilyHistory/save`, data);
    // if (response.data === 1) {
    //   let payload = {
    //     account_Id:data.account_Id,
    //     // clinic_Id:data.clinic_Id,
    //     patientId: data.patientId,
    //     status: 1,
    //   };
    //   var queryString = this.queryString(payload);
    //   return http.get(`PatientFamilyHistory/list/filter?${queryString}`);
    // }
    return response;
  }

  async AddPastMedicalHistory(data) {
    const response = await http.post(`PatientPastMedicalHistory/save/`, data);
    // if (response.data === 1) {
    //   let payload = {
    //     account_Id:data.account_Id,
    //     // clinic_Id:data.clinic_Id,
    //     patientId: data.patientId,
    //     status: 1,
    //   };
    //   var queryString = this.queryString(payload);
    //   return http.get(`PatientPastMedicalHistory/list/filter?${queryString}`);
    // }
    return response;
  }
  async AddSurgicalHistory(data) {
    const response = await http.post(`PatientSurgicalHistory/save/`, data);
    if (response.data === 1) {
      let payload = {
        account_Id:data.account_Id,
        // clinic_Id:data.clinic_Id,
        patientId: data.patientId,
        status: 1,
      };
      var queryString = this.queryString(payload);
      return http.get(`PatientSurgicalHistory/list/filter?${queryString}`);
    }
  }
  async AddMedication(data) {
    const response = await http.post(`PatientMedication/save/`, data);
    if (response.data === 1) {
      let payload = {
        account_Id:data[0].account_Id,
        // clinic_Id:data[0].clinic_Id,
        patientCode: data[0].patientCode,
        status: 1,
      };
      var queryString = this.queryString(payload);
      return http.get(`PatientMedication/list/filter?${queryString}`);
    }
  }
  PatientAllergiesList(payload) {
    var queryString = this.queryString(payload);
    return http.get(
      `PatientAllergies/list/filter?${queryString}`
    );
  }
  PastMedicalHistory(payload) {
    var queryString = this.queryString(payload);
    return http.get(`PatientPastMedicalHistory/list/filter?${queryString}`);
  }
  PatientSocialHistory(payload) {
    var queryString = this.queryString(payload);
    return http.get(`PatientSocialHistory/list/filter?${queryString}`);
  }
  PatientSurgicalHistory(payload) {
    var queryString = this.queryString(payload);
    return http.get(`PatientSurgicalHistory/list/filter?${queryString}`);
  }
  PatientFamilyHistory(payload) {
    var queryString = this.queryString(payload);
    return http.get(`PatientFamilyHistory/list/filter?${queryString}`);
  }
  PatientMedication(payload) {
    var queryString = this.queryString(payload);
    return http.get(`PatientMedication/list/filter?${queryString}`);
  }
  AddChronicConditions(data) {
    return http.post(`patientChronicConditions/save/`, data);
  }
  chronicConditions(payload) {
    var queryString = this.queryString(payload);
    return http.get(`chronicConditions/list/filter?${queryString}`);
  }
  PatientchronicConditions(payload) {
    var queryString = this.queryString(payload);
    return http.get(`patientChronicConditions/list/filter?${queryString}`);
  }
}

export default new MedicalHistoryService();
