import React, { useRef, useState } from "react";
import { DatePicker, Form, Input, Modal, Select } from "antd";
import { Button, message, Upload } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import moment from "moment";
import deleteHoverIcon from "../../../../Assets/Images/hoverdelete.svg";
import Preview from "../../../../Assets/Images/Svg/PreviewIcon.svg";
import MedicalService from "../../../../Services/MedicalHistoryService";

function AddReport(props) {
  const activePatient = JSON.parse(localStorage.getItem("patient"));
  const patientCode = activePatient?.patientId;
  const [imageData, setImageData] = useState("");
  const [imageType, setImageType] = useState("");
  const [givenDate, setGivenDate] = useState("");
  const [documentDate, setDocumentDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { TextArea } = Input;
  const formRef = useRef();
  const [reportPreview, setReportPreview] = useState("");

  const handleSave = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const currDate = new Date().toISOString();
    const date = currDate.split("T")[0];
    const time = currDate.split("T")[1].slice(0, 8);
    const constructedTime = date + " " + time;

    const userObj = localStorage.getItem("login_result");
    const userData = userObj ? JSON.parse(userObj) : null;
    let values = formRef.current.getFieldValue();

    let payload = {
      account_Id: userData?.user?.account_Id,
      clinic_Id: userData?.user?.clinic_Id,
      createdDate: constructedTime,
      givenDate: givenDate,
      createdBy: userData?.user.userId,
      modifiedBy: userData?.user.userId,
      status: 1,
      patientId: patientCode,
      doctorName: userData.user.firstName,
      document: imageData,
      documentDate: documentDate,
      documentName: null,
      documentType: values.documentType,
      documentsType: imageType,
      title: values.title,
      note: values.action,
      consultationId: "",
      modifiedDate: constructedTime,
      fromDate: givenDate,
      toDate: givenDate,
      userId: userData?.user?.userId,
    };
    console.log(payload);
    MedicalService.AddPatientReport(payload)
      .then((result) => {
        formRef.current.resetFields();
        setImageData("");
        setIsLoading(false);
        props.onClose();
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleCancel = () => {
    setImageData("");
    props.onClose();
  };

  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      let imageArray = [];
      imageArray = reader.result.split(",");
      const imageDataUrl = imageArray[1];
      const imageType = file.type;
      const fileExtension = imageType.split("/")[1];
      setImageData(imageDataUrl);
      setImageType(fileExtension);
      setReportPreview(reader.result);
    };
  };

  const props1 = {
    customRequest: ({ file, onSuccess }) => {
      if (file && file.size > 1048576) {
        message.error(
          "Image/file size should be less than or equal to 1 mb !!"
        );
        return;
      } else {
        handleFileUpload(file);
        message.success(`${file.name} uploaded successfully.`);
        onSuccess("ok");
      }
    },
  };

  const handleRemoveFile = () => {
    setImageData("");
  };

  const disabledFutureDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const handleDate = (date, dateString) => {
    const constructedTime = dateString + " " + "00:00:00";
    console.log(dateString);
    setGivenDate(constructedTime);
    const dateArr = dateString.split("-");
    const docDate = `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`;
    setDocumentDate(docDate);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handlePreviewOk = () => {
    setIsModalOpen(false);
  };
  const handlePreviewClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Modal
        title="Preview"
        open={isModalOpen}
        onOk={handlePreviewOk}
        onCancel={handlePreviewClose}
        footer={null}
      >
        {imageType === "pdf" ? (
          <iframe
            src={`data:application/pdf;base64,${imageData}`}
            title="PDF Preview"
            style={{ width: "100%", height: "500px" }}
          />
        ) : (
          <img src={reportPreview} alt="Preview" style={{ width: "100%" }} />
        )}
      </Modal>
      <Form
        className="CommonForm grid grid-cols-2 gap-5"
        layout="vertical"
        autoComplete="off"
        ref={formRef}
      >
        <Form.Item
          className="mb-0"
          label={<span className="font-medium">Date</span>}
          name="date"
        >
          <DatePicker
            placeholder="Date"
            className="w-full h-8"
            format="YYYY-MM-DD"
            onChange={handleDate}
            disabledDate={disabledFutureDate}
          />
        </Form.Item>
        <Form.Item
          className="mb-0"
          label={<span className="font-medium">Title</span>}
          name="title"
        >
          <Input placeholder="Enter Title" />
        </Form.Item>
        <Form.Item
          className="mb-0"
          label={<span className="font-medium">Document Type</span>}
          name="documentType"
        >
          <Select
            name="reportType"
            className="w-full h-14  p-2  rounded-md"
            placeholder="--Select--"
          >
            <Select.Option value="Prescription">Prescription</Select.Option>
            <Select.Option value="Lab Report">Lab Report</Select.Option>
            <Select.Option value="Scan Report">Scan Report</Select.Option>
            <Select.Option value="MRI Scan">MRI Scan</Select.Option>
            <Select.Option value="Ultrasound Report">
              Ultrasound Report
            </Select.Option>
            <Select.Option value="Clinical Records">
              Clinical Records
            </Select.Option>
            <Select.Option value="Health Records">Health Records</Select.Option>
            <Select.Option value="Others">Others</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="document"
          label={
            <span className="font-medium">
              Document<span style={{ color: "#FF000F" }}>*</span>
            </span>
          }
        >
          {imageData ? (
            <div className="flex gap-1 items-center">
              <span>
                {imageType === "application/pdf" ? "PDF" : "document"}
              </span>
              <img
                className="ml-3 cursor-pointer"
                onClick={handleRemoveFile}
                src={deleteHoverIcon}
                alt="image"
              />
              <img
                className="ml-3 cursor-pointer"
                src={Preview}
                alt="image"
                onClick={showModal}
              />
            </div>
          ) : (
            <Upload {...props1} showUploadList={false}>
              <Button icon={<CloudUploadOutlined />} className="text-white">
                CHOOSE FILE
              </Button>
            </Upload>
          )}
        </Form.Item>
      </Form>
      <div className=" flex justify-end gap-3 mt-3 ">
        <button
          onClick={handleCancel}
          className="bg-white text-brand-primaryColor border-brand-primaryColor border rounded-lg w-[100px] h-[40px] text-base"
        >
          Cancel
        </button>
        <button
          onClick={handleSave}
          disabled={isLoading}
          className="bg-brand-primaryColor w-[100px] h-[40px] text-base rounded-lg text-white disabled:cursor-not-allowed disabled:opacity-50"
        >
          Save
        </button>
      </div>
    </div>
  );
}

export default AddReport;
