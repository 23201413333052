import {
  Button,
  Form,
  Input,
  Modal,
  Space,
  Table,
  Tooltip,
  message,
} from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "../../../PageRouting/approutes";
import PatientService from "../../../Services/PatientService";
import whatsappIcon from "../../../Assets/Images/whatsapp.png";
import Gmail from "../../../Assets/Images/Gmail.png";
import Message from "../../../Assets/Images/Message.png";
import moment from "moment";
import AppointmentService from "../../../Services/AppointmentService";
import Edit from "../../../Assets/Images/Svg/EditIcon.svg";
import Preview from "../../../Assets/Images/Svg/PreviewIcon.svg";
import shareIcon from "../../../Assets/Images/Svg/shareIcon.svg";
import PrescriptionDetails from "../ConsultationNotes/VitalTabs/ViewPrescription/PrescriptionDetails";
import ConsultationService from "../../../Services/ConsultationService";
import { useForm } from "antd/es/form/Form";
import enrollmentServices from "../../../Services/enrollmentServices";
import { RWebShare } from "react-web-share";
import videoicon from "../../../Assets/Images/videoicon.png";
import close from "../../../Assets/Images/close.png";
import share from "../../../Assets/Images/share.png";
import CommonFunctions from "../../Shared/CommonFunctions";
import { IoPersonSharp } from "react-icons/io5";
import { Base64 } from "js-base64";
import SharePrescription from "./SharePrescription";
import ShareOrders from "./ShareOrders";
import MedicineDisabled from "../../../Assets/Images/Svg/MedicineDisabled.svg";
import LabDisabled from "../../../Assets/Images/Svg/LabDisabled.svg";
import PharmacyMed from "../PharmaMed";

function AppointmentTable(props) {
  const [selectedIcon, setSelectedIcon] = useState("whatsapp");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cancelAppointmentID, setCancelAppointmentID] = useState({});
  const [shareModal, setShareModal] = useState(false);
  const [preview, setPreview] = useState(false);
  const [selectedPrescription, setSelectedPrescription] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [form, setForm] = useForm();

  const showModal = (record) => {
    setIsModalOpen(true);
    setCancelAppointmentID(record);
  };

  const showShareModal = (record) => {
    setShareModal(true);
    setSelectedPatient(record);
  };

  const handleShareOk = (payload) => {
    if (!payload.email && !payload.mobile && !payload.whatsApp) {
      message.error("Enter any contact information to send link");
      return;
    }
    payload.patientId = selectedPatient.patientId;
    payload.userId = selectedPatient.userId;
    payload.serviceCode = selectedPatient.serviceCode;
    payload.appointmentId = selectedPatient.appointmentId;

    enrollmentServices
      .InvitationShare(payload)
      .then((res) => {
        message.success("Invitation sent successfully");
      })
      .catch((e) => {
        message.error("An error occurred, Try again");
      });
    setShareModal(false);
    form.resetFields();
  };

  const handleOk = () => {
    AppointmentService.AppointmentCancel(
      cancelAppointmentID.appointmentId
    ).then((res) => {
      setIsModalOpen(false);
      props.appointmentsList();
    });
    setCancelAppointmentID({});
  };

  const cancelAppointment = () => {
    setIsModalOpen(false);
  };

  const { patientsInviteList } = props;
  const history = useHistory();

  const handleClick = (record, tab) => {
    if (tab === "1") {
      let payload = {
        appointmentId: record.appointmentId,
        modifiedBy: record.userId,
        modifiedDate: CommonFunctions.convertUTCDate(
          new Date(),
          "YYYY-MM-DD HH:mm:ss"
        ),
        patientId: record.patientId,
        startDateTime: CommonFunctions.convertUTCDate(
          new Date(),
          "YYYY-MM-DD HH:mm:ss"
        ),
        userId: record.userId,
      };

      ConsultationService.updateStartTime(payload).then((res) => {
        console.log("");
      });
    }
    record.callMode = tab == "1" ? "JoinCall" : "EditCall";
    let payload = {
      patientId: record.patientId,
    };

    PatientService.PatientListFilter(payload).then((res) => {
      localStorage.setItem("patient", JSON.stringify(res.data[0]));
    });
    history.push(APP_ROUTES.VIDEOCALL, { selectedPatient: record });
    window.scrollTo(0, 0);
  };

  const encodeBase64 = (str) => {
    let id = Base64.encode(str);
    return id;
  };

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "firstName",
      render: (_, record) => (
        <Space size="middle" className="">
          <div className="flex items-center gap-4">
            <div>
              <div className="text-base font-medium">{record.patientName}</div>
              <p className="text-brand-textDarkgray text-sm mb-0">
                <span>{record.patientId}</span>
              </p>
            </div>
          </div>
        </Space>
      ),
      sorter: {
        compare: (a, b) => a.patientName.localeCompare(b.patientName),
      },
      width: 200,
    },
    {
      title: "Appt.ID",
      dataIndex: "appointmentId",
      width: 150,
    },
    {
      title: "Chief Complaints",
      dataIndex: "appointmentReason",
      width: 150,
    },

    {
      title: "Date & Time",
      width: 200,
      dataIndex: "startTime",
      render: (_, record) => {
        const [hours, minutes] = record.startTime.split(":");
        const date = new Date();
        date.setHours(parseInt(hours, 10));
        date.setMinutes(parseInt(minutes, 10));
        const formattedTime = date.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });

        const formattedDate = moment
          .utc(record.appointmentDate)
          .local()
          .format("DD/MM/YYYY");
        return (
          <span>
            {formattedDate}-{formattedTime}
          </span>
        );
      },
      sorter: {
        compare: (a, b) => a.startTime.localeCompare(b.startTime),
      },
    },

    {
      title: "Payment Status",
      dataIndex: "transactionId",
      width: 150,
      render: (_, record) => {
        return record.transactionId ? (
          <span className="text-green-500 font-semibold">Completed</span>
        ) : (
          <span className="text-blue-500 font-semibold">Pending</span>
        );
      },
    },
    {
      title: "Call & Type",
      width: 200,
      dataIndex: "actions",
      render: (_, record) => (
        <>
          {record.status === 2 || record.status === 0 ? (
            <button
              className="flex items-center justify-center gap-2 w-full px-1 py-2 border bg-gray-400 border-black rounded-lg  text-black cursor-not-allowed"
              disabled
            >
              {record.serviceCode === "APPT_VIDEO" ? (
                <>
                  <img src={videoicon} alt="videoicon" className="w-5 h-5" />
                  Join call
                </>
              ) : (
                <>
                  <IoPersonSharp className="text-green-500 " /> Consult
                </>
              )}
            </button>
          ) : !record.transactionId && record.paymentConsultation === "BC" ? (
            <Tooltip
              placement="top"
              title={"payment is not completed on the patient side"}
            >
              <button
                className="flex items-center justify-center gap-2 w-full px-1 py-2 border border-black rounded-lg bg-gray-400 text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                disabled
              >
                {record.serviceCode === "APPT_VIDEO" ? (
                  <>
                    <img src={videoicon} alt="videoicon" className="w-5 h-5" />
                    Join call
                  </>
                ) : (
                  <>
                    <IoPersonSharp className="text-green-500" /> Consult
                  </>
                )}
              </button>
            </Tooltip>
          ) : (
            <button
              onClick={() => handleClick(record, "1")}
              className="flex items-center justify-center gap-2 w-full px-1 py-2 border border-black rounded-lg bg-white text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {record.serviceCode === "APPT_VIDEO" ? (
                <>
                  <img src={videoicon} alt="videoicon" className="w-5 h-5" />
                  Join call
                </>
              ) : (
                <>
                  <IoPersonSharp className="text-green-500" /> Consult
                </>
              )}
            </button>
          )}
        </>
      ),
    },
    {
      title: "Rx",
      width: 150,
      dataIndex: "actions",
      render: (_, record) => {
        return (
          <Space size="middle" className="">
            <div className="flex items-center gap-2">
              <div size="middle" className="">
                {record.status === 2 ? (
                  <div className="flex gap-1 items-center cursor-pointer">
                    <img
                      onClick={() => handleClick(record, "2")}
                      src={Edit}
                      alt="Edit"
                      className="w-6 h-6"
                    />
                    <img
                      src={Preview}
                      alt="Preview"
                      className="w-6 h-6"
                      role="button"
                      onClick={() => showPreview(record)}
                    />

                    <SharePrescription
                      record={record}
                      className={"w-6 h-6"}
                      paypendingClass={
                        "w-6 h-6 bg-gray-400 rounded-md cursor-not-allowed"
                      }
                    />
                  </div>
                ) : (
                  <div className="flex gap-1 items-center cursor-pointer">
                    <img
                      src={Edit}
                      alt="Edit"
                      className="w-6 h-6 bg-gray-400 rounded-md cursor-not-allowed"
                    />
                    <img
                      src={Preview}
                      alt="Preview"
                      className="w-6 h-6 bg-gray-400 rounded-md cursor-not-allowed"
                      role="button"
                    />
                    <img
                      src={shareIcon}
                      alt="Share"
                      className="w-6 h-6 bg-gray-400 rounded-md cursor-not-allowed"
                      role="button"
                    />
                  </div>
                )}
              </div>
            </div>
          </Space>
        );
      },
    },
    {
      title: "Order Medicine/Lab Test",
      width: 150,
      dataIndex: "actions",
      render: (_, record) => {
        return (
          <Space size="middle" className="">
            <div className="flex items-center gap-2">
              <div size="middle" className="">
                {record.status === 2 ? (
                  <div className="flex gap-1 items-center cursor-pointer">
                    <ShareOrders record={record} />
                  </div>
                ) : (
                  <div className="flex gap-1 items-center cursor-pointer">
                    <img
                      src={MedicineDisabled}
                      alt="Preview"
                      className="w-8 h-8 cursor-not-allowed"
                      role="button"
                    />

                    <img
                      src={LabDisabled}
                      alt="Share"
                      className="w-8 h-8 cursor-not-allowed"
                      role="button"
                    />
                  </div>
                )}
              </div>
            </div>
          </Space>
        );
      },
    },

    {
      title: "Status",
      width: 120,
      dataIndex: "status",
      render: (_, record) => (
        <div size="middle" className="">
          {record.status === 0 && (
            <span className=" text-red-500 " disabled>
              Cancelled
            </span>
          )}
          {record.status === 1 && (
            <span className=" text-blue-500 ">Pending</span>
          )}
          {record.status === 2 && (
            <span className=" text-green-500 ">Completed</span>
          )}
        </div>
      ),
    },
    {
      title: "Action",
      width: 100,
      dataIndex: "action",
      render: (_, record) => (
        <Space size="middle" className="">
          {record.status === 1 && (
            <div className="flex justify-center items-center gap-2">
              <Tooltip placement="left" title={"Share"}>
                <div className="border  border-gray-500 p-1 cursor-pointer rounded-lg">
                  <div className="flex items-center gap-2">
                    <RWebShare
                      data={{
                        text: "Appointment",
                        url:
                          "https://patient.doxzon.com/vc/" +
                          record?.appointmentId +
                          "/V",
                        title: "Share Link",
                      }}
                      onClick={() => console.log("shared successfully!")}
                    >
                      <img className="w-4" src={share} alt="shareImage" />
                    </RWebShare>
                  </div>
                </div>
              </Tooltip>
              <Tooltip placement="right" title={"Cancel"}>
                <div
                  onClick={() => showModal(record)}
                  className="border  border-gray-500 p-1 cursor-pointer rounded-lg"
                >
                  <img className="w-4" src={close} alt="closeImage" />
                </div>
              </Tooltip>
            </div>
          )}
          {record.status === 2 && (
            <div className="flex justify-center items-center gap-2">
              <div className="border  border-gray-500 bg-gray-500 cursor-not-allowed p-1  rounded-lg">
                <div className="flex items-center gap-2">
                  <img className="w-4" src={share} alt="shareImage" />
                </div>
              </div>
              <div className="border  border-gray-500 bg-gray-500 p-1 cursor-not-allowed rounded-lg">
                <img className="w-4" src={close} alt="closeImage" />
              </div>
            </div>
          )}
          {record.status === 0 && (
            <div className="flex justify-center items-center gap-2">
              <div className="border  border-gray-500 bg-gray-500 cursor-not-allowed p-1  rounded-lg">
                <div className="flex items-center gap-2">
                  <img className="w-4" src={share} alt="shareImage" />
                </div>
              </div>
              <div className="border  border-gray-500 bg-gray-500 p-1 cursor-not-allowed rounded-lg">
                <img className="w-4" src={close} alt="closeImage" />
              </div>
            </div>
          )}
        </Space>
      ),
    },
  ];

  const showPreview = async (record) => {
    let patientPayload = {
      patientId: record?.patientId,
    };
    let consultationPayload = {
      consultationId: record?.consultationId,
      detailsYN: "Y",
    };
    try {
      const patientResponse = await PatientService.PatientListFilter(
        patientPayload
      );
      if (patientResponse) {
        setSelectedPatient(patientResponse.data[0]);
      }
      const consultationResponse = await ConsultationService.getPrescriptions(
        consultationPayload
      );
      if (consultationResponse.data) {
        setSelectedPrescription(consultationResponse.data[0]);
        setPreview(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const download = () => {
    setPreview(false);
  };
  const cancel = () => {
    setPreview(false);
  };

  const [selectShare, setSelectShare] = useState("mail");

  const handleShareoption = (option) => {
    form.resetFields();
    setSelectShare(option);
  };

  return (
    <>
      <Modal
        title={
          <div className="text-2xl flex gap-2  items-center">
            <span>Share</span>
            <span className="flex gap-2 cursor-pointer">
              <img
                src={whatsappIcon}
                alt="WhatsApp"
                className="w-8 h-8"
                onClick={() => handleShareoption("wp")}
              />
              <img
                src={Gmail}
                alt="Gmail"
                className="w-8 h-8"
                onClick={() => handleShareoption("mail")}
              />
              <img
                src={Message}
                alt="Message"
                className="w-8 h-8"
                onClick={() => handleShareoption("mobile")}
              />
            </span>
          </div>
        }
        open={shareModal}
        onCancel={() => {
          setShareModal(false);
          form.resetFields();
        }}
        footer={[]}
        height="15%"
      >
        <Form form={form} onFinish={handleShareOk} layout="vertical">
          {selectShare === "wp" && (
            <Form.Item name="whatsApp" label="WhatsApp">
              <Input
                placeholder="Enter WhatsApp no."
                className="h-10"
                disabled
              />
            </Form.Item>
          )}

          {selectShare === "mobile" && (
            <Form.Item name="mobile" label="Moblie">
              <Input placeholder="Enter Mobile no." className="h-10" />
            </Form.Item>
          )}

          {selectShare === "mail" && (
            <Form.Item name="email" label="Email">
              <Input placeholder="Enter email" className="h-10" />
            </Form.Item>
          )}
          <Form.Item>
            <div className="flex justify-end">
              <Button
                htmlType="submit"
                className="h-10 mt-2 text-white font-semibold p-2 rounded-md"
              >
                Send
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={preview}
        onOk={download}
        onCancel={cancel}
        style={{
          top: 40,
        }}
        width={"70%"}
        footer={null}
      >
        <PrescriptionDetails
          prescription={selectedPrescription}
          selectedPatient={selectedPatient}
        />
      </Modal>
      <Modal
        title="Cancel Appointment"
        open={isModalOpen}
        // onOk={handleOk}
        onCancel={cancelAppointment}
        footer={[]}
      >
        Do you want to cancel this appointment
        <div className="flex justify-end border-brand-colorBorder gap-3 mt-10">
          <Button className="h-10 Btnwhite px-8" onClick={cancelAppointment}>
            Cancel
          </Button>
          <Button onClick={handleOk} className="h-10 px-10" type="primary">
            Ok
          </Button>
        </div>
      </Modal>

      <div className="w-full">
        <Table
          columns={columns}
          dataSource={patientsInviteList}
          pagination={{ pageSize: 4 }}
          scroll={{ x: "max-content", y: false }}
        />
      </div>
    </>
  );
}

export default AppointmentTable;
