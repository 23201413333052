import React, { useEffect, useState } from "react";
import PatientService from "../../../Services/PatientService";
import ConsultationService from "../../../Services/ConsultationService";
import DoctorService from "../../../Services/DoctorService";
import PrescriptionHeader from "../ConsultationNotes/VitalTabs/ViewPrescription/PrescriptionHeader";
import PrescriptionFooter from "../ConsultationNotes/VitalTabs/ViewPrescription/PrescriptionFooter";
import PharmacyService from "../../../Services/PharmacyService";

const MedOrderPreview = ({ medRecord }) => {
  const [doctorData, setDoctorData] = useState();
  const [hospitalAccountData, setHospitalAccountData] = useState(null);
  const [selectedPatientDetails, setSelectedPatientDetails] = useState([]);
  const [clinicDetails, setClinicDetails] = useState(null);
  const [medicines, setMedicines] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const storedLoginResult = localStorage.getItem("login_result");
  const loginResultObject = storedLoginResult
    ? JSON.parse(storedLoginResult)
    : null;
  useEffect(() => {
    let payload = {
      patientId: medRecord?.patientId,
    };
    PatientService.PatientListFilter(payload)
      .then((result) => {
        console.log("sel from pres", result.data[0]);
        setSelectedPatientDetails(result.data[0]);
      })
      .catch((error) => {
        console.error("Error fetching patient list:", error);
      });

    const fetchDrugList = async () => {
      let medlistpayload = {
        consultationId: medRecord.consultationId,
        status: 1,
      };

      try {
        let druglist = await PharmacyService.GetDispensedDrugsDtl(
          medlistpayload
        );
        setMedicines(druglist.data);
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchDrugList();

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await getDoctorName(loginResultObject.user.userId);
        console.log(response);
        setDoctorData(response?.data[0]);
        fetchHospitalData(response?.data[0]?.account_Id);
        fetchClinicData(response?.data[0]?.clinic_Id);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const getDoctorName = async (id) => {
    try {
      const response = await DoctorService.getDoctorData(id);
      return response;
    } catch (e) {
      console.log(e);
    }
  };

  const fetchHospitalData = async (id) => {
    const response = await DoctorService.getHospitalAccountData(id);
    setHospitalAccountData(response.data[0]);
  };

  const fetchClinicData = async (AccId) => {
    console.log(AccId);
    const response = await ConsultationService.getClinicData(AccId);
    console.log(response);
    setClinicDetails(response.data[0]);
  };

  return (
    <section>
      <div
        id="prescription"
        className="w-full mb-4 border rounded overflow-x-auto cursor-pointer"
      >
        {isLoading ? (
          <div className="w-full flex h-screen justify-center items-center">
            <div className="spinner"></div>
          </div>
        ) : (
          <>
            <div className="md:p-3 p-4">
              <div className="md:flex justify-between"></div>
              <PrescriptionHeader
                clinicDetails={clinicDetails}
                doctorData={doctorData}
                hospitalAccountData={hospitalAccountData}
              />
              <div className="md:flex gap-5  my-4">
                <div className="text-sm font-bold flex gap-1">
                  Name:
                  <span className="text-sm text-gray-500 font-semibold">
                    {selectedPatientDetails?.salutation
                      ? selectedPatientDetails?.salutation + " "
                      : ""}
                    {selectedPatientDetails?.firstName
                      ? selectedPatientDetails?.firstName + " "
                      : ""}
                    {selectedPatientDetails?.lastName
                      ? selectedPatientDetails?.lastName
                      : ""}
                  </span>
                </div>
                <div className="text-sm font-bold md:flex gap-1">
                  Age/Gender:
                  <span className="text-sm text-gray-500 font-semibold">
                    {selectedPatientDetails?.dob ? "" : ""}/
                    {selectedPatientDetails?.gender
                      ? selectedPatientDetails?.gender === "M"
                        ? "Male"
                        : "Female"
                      : ""}
                  </span>
                </div>
                <div className="text-sm font-bold md:flex gap-1">
                  Phone Number:
                  <span className="text-sm text-gray-500 font-semibold">
                    {selectedPatientDetails?.mobile
                      ? selectedPatientDetails?.mobile
                      : ""}
                  </span>
                </div>

                <div className="text-sm font-bold flex gap-1">
                  Visit Type:
                  <span className=" text-sm text-gray-500 font-semibold">
                    {medRecord?.serviceName}
                  </span>
                </div>
              </div>

              <table className="min-w-full border-collapse">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="border px-4 py-2 text-left">Medicine1</th>
                    <th className="border px-4 py-2 text-left">Qty</th>
                    <th className="border px-4 py-2 text-left">Dosage</th>
                    <th className="border px-4 py-2 text-left">Duration</th>
                    <th className="border px-4 py-2 text-left">
                      Alternative Med
                    </th>
                    <th className="border px-4 py-2 text-left">
                      Dispensed Qty
                    </th>
                    <th className="border px-4 py-2 text-left">Price</th>
                  </tr>
                </thead>
                <tbody>
                  {medicines.map((medicine, index) => (
                    <tr key={index}>
                      <td className="border px-2 py-2">{medicine.drugName}</td>
                      <td className="border px-2 py-2">{medicine.quantity}</td>
                      <td className="border px-2 py-2">{medicine.dosage}</td>
                      <td className="border px-2 py-2">{medicine.duration}</td>
                      <td className="border px-2 py-2">
                        {medicine.alternativeDrugName
                          ? medicine.alternativeDrugName
                          : medicine.drugName}
                      </td>
                      <td className="border px-2 py-2">
                        {medicine.dispensedQty}
                      </td>
                      <td className="border px-2 py-2">{medicine.amount}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td
                      colSpan="6"
                      className="border px-4 py-2 text-right font-semibold"
                    >
                      Total
                    </td>
                    <td className="border px-4 py-2">{medRecord.drugAmount}</td>
                  </tr>
                </tfoot>
              </table>

              <div className=" ">
                <PrescriptionFooter clinicDetails={clinicDetails} />
              </div>
              <div className="grid grid-cols-1 ">
                <div className="px-1 py-1">
                  <p className="text-gray-700 text-sm "></p>
                </div>
              </div>
            </div>
            l
          </>
        )}
      </div>
    </section>
  );
};

export default MedOrderPreview;
