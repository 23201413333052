import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Table,
  message,
} from "antd";
import labReports from "../../../../../Assets/Images/Svg/labReports.svg";
import ConsultationService from "../../../../../Services/ConsultationService";
import deleteicon from "../../../../../Assets/Images/Svg/deleteicon.svg";
import { useForm } from "antd/es/form/Form";

const { Panel } = Collapse;

function LabReports({ handleAddLabTests, consulationData }) {
  const [activeKey, setActiveKey] = useState(false);
  const [labTests, setLabTests] = useState([]);
  const [selectedLabTest, setSelectedLabTest] = useState({});
  const [selectedLabtestList, setSelectedLabTestList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [tabledata, setTabledata] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [manualTest, setManualTest] = useState(null);
  const [manualSearchVal, setManualSearchVal] = useState('')
  const formRef = useRef();
  useEffect(() => {
    if (consulationData) {
      setShowTable(true)
      setTabledata(consulationData?.labPrescription)
      setSelectedLabTestList(consulationData?.labPrescription);
    }
  }, [consulationData])
  useEffect(() => {
    console.log(tabledata, " table Data");
  }, [tabledata])

  const onChange = (key) => {
    setActiveKey(key);
  };

  const columns = [
    {
      title: "Code",
      dataIndex: "labTestId",
      key: "labTestId",
    },
    {
      title: "Name",
      dataIndex: "labTestName",
      key: "labTestName"
    },
    {
      title: "",
      dataIndex: "delete",
      render: (_, record) => (
        <Space size="middle" className="">
          <div className="flex items-center gap-">
            <div className="">
              <img
                src={deleteicon}
                alt="img"
                className="w-6 h-6 cursor-pointer"
                onClick={() => handleDelete(record)}
              />
            </div>
          </div>
        </Space>
      ),
    },
  ];

  const customHeaderContent = (
    <thead className="">
      <tr>
        <th className="bg-blue-100 py-3 text-B">Code</th>
        <th className="bg-blue-100 py-3 text-B">Name</th>
        <th className="bg-blue-100 py-3 text-B"></th>
      </tr>
    </thead>
  );

  const onSearch = (value) => {
    if (value) {
      setIsLoading(true);
      ConsultationService.LabTest(value).then((res) => {
        if (res.data.length > 0) {
          setLabTests(res.data);
        }
        else {
          // let manualTestVal = { name: value, code: value };
          // setManualTest(manualTestVal);
          setManualSearchVal(value);
          let manualTestVal = { labTestId: value, labTestName: value };
          setSelectedLabTest(manualTestVal);
        }
        // else {
        //   let payload = []
        //   let x = { name: value, code: value }

        //   payload.push(x)
        //   console.log(payload)
        //   setLabTests(payload)
        //   setSelectedLabTest(x)
        // }
        setIsLoading(false);
      }).catch((err) => {
        console.log(err);
      })
    }
  };

  const handleChange = (value) => {
    console.log(value);
    if (value) {
      const isValIdx = labTests?.findIndex((lab) => lab.code === value[0]);
      if (isValIdx === -1) {
        let payload = []
        let x = { name: value, code: value }
        payload.push(x)
        setSelectedLabTest(x)
        setIsSelected(true);
        console.log(value)
      }
      else {
        console.log("hi  ")
        setIsSelected(true);
      }
    }

  }

  const handleFinish = (e) => {
    e.preventDefault();
    let value = selectedLabTest.name;
    try {
      ConsultationService.LabTestCode(value).then((res) => {
        console.log("from handle search", res.data[0]);
        if (res?.data[0]) {
          setSelectedLabTest(res.data[0]);
        }
        let data = res?.data[0];
        console.log(data);
        let isMedicineExist;
        if (data) {
          isMedicineExist = tabledata?.findIndex((lab) => lab.labTestId === data?.code);
        }
        if (!manualSearchVal && isMedicineExist !== -1) {
          message.error("Lab Test already exist in the prescription!");
          return;
        }
        if (data) {
          data.labTestId = data.code
          data.labTestName = data.name
          const labArr = selectedLabtestList;
          console.log(selectedLabtestList)
          labArr.push(data);
          setSelectedLabTestList(labArr);
          formRef.current.resetFields();
          console.log(labArr, " ");
          const filteredLabTests = labArr.filter((lab) => lab.status !== 0);
          setTabledata([...filteredLabTests]);
          handleAddLabTests(labArr);
          setShowTable(true);
          setIsSelected(false);
        }
        else {
          console.log(selectedLabTest);
          const labArr = selectedLabtestList;
          labArr.push({ labTestId: selectedLabTest?.labTestId, labTestName: selectedLabTest?.labTestName, status: 1 });
          setManualSearchVal('');
          setSelectedLabTestList(labArr);
          const filteredLabTests = labArr.filter((lab) => lab.status !== 0);
          formRef.current.resetFields();
          console.log(labArr, " ");
          setTabledata([...filteredLabTests]);
          handleAddLabTests(labArr);
          setShowTable(true);
          setIsSelected(false);
        }
      });
    }
    catch (e) {
      console.log(e);
    }

  };

  const handleDelete = (value) => {
    console.log(value, " .......", selectedLabtestList);
    if (value?.consultationId) {
      const updatedList = selectedLabtestList.filter((item) => item.status !== 0 && item.labTestId !== value.labTestId);
      const itemToBeDeletedIdx = selectedLabtestList.findIndex((item) => item.labTestId === value.labTestId);
      const LabTestListOrg = selectedLabtestList;
      if (itemToBeDeletedIdx !== -1) {
        LabTestListOrg[itemToBeDeletedIdx].status = 0;
      }
      console.log(LabTestListOrg, "     after deletion ");
      setSelectedLabTestList(LabTestListOrg);
      setTabledata(updatedList);
      handleAddLabTests(LabTestListOrg);
    }
    else {
      const updatedList = selectedLabtestList.filter((item) => item.labTestId !== value.labTestId);
      const updatedListForTable = selectedLabtestList.filter((item) => item.status !== 0 && item.labTestId !== value.labTestId);
      console.log(updatedList, "     after deletion ");
      setTabledata(updatedListForTable);
      setSelectedLabTestList(updatedList);
      handleAddLabTests(updatedList);

    }

  };

  const manualTestSearch = (e) => {
    console.log(e.target.value)
    if (e.target.value) {
      setManualSearchVal(e.target.value);
      let manualTestVal = { labTestId: e.target.value, labTestName: e.target.value };
      console.log(manualTestVal)
      setSelectedLabTest(manualTestVal);
    }
    else {
      setManualSearchVal('');
    }

  }

  // const handleDelete = (record) => {
  //   console.log(tabledata, "     before deleting ");
  //   const updatedTableData = tabledata.filter((item) => item.id !== record.id);
  //   const itemToBeDeletedIdx = tabledata.findIndex((item) => item.id === record.id);
  //   if (itemToBeDeletedIdx !== -1) {
  //     tabledata[itemToBeDeletedIdx].status = 0;
  //   }
  //   console.log(tabledata, "     qwweuer ");
  //   setTabledata(updatedTableData);
  //   handleAddMedicines(tabledata);
  // };



  return (
    <Collapse activeKey={activeKey} onChange={onChange} bordered={false}>
      <Panel
        header={
          <div className="flex justify-between items-center">
            <div className="flex gap-2 items-center">
              <div className="bg-[#D6E6FF] w-9 h-9 flex items-center justify-center rounded-2xl">
                <img src={labReports} alt="labReportsIMG" />
              </div>
              <div className="font-bold ">Lab Tests</div>
            </div>
            <div>
              {/* <Button
                onClick={() => setActiveKey(true)}
                className=" text-white "
                style={{ backgroundColor: "#1D64C8" }}
              >
                + Add
              </Button> */}
            </div>
          </div>
        }
        key="2"
        showArrow={false}
        collapsible={false}
      >
        <Form layout="vertical" ref={formRef}>
          <Row gutter={24} className="mt-2 mx-4 flex items-center">
            <Col span={11}>
              <Form.Item label="Select a Lab Test" name="testName" className="mx-4">
                <Select
                  // mode="tags"
                  showSearch
                  placeholder="Enter Lab Test Name"
                  className={`w-full h-10 labSearch ${!manualSearchVal ? "block" : "hidden"}`}
                  onSearch={onSearch}
                  onChange={handleChange}
                  options={labTests?.map((test) => {
                    return {
                      label: test.name,
                      value: test.code,
                    };
                  })}
                  filterOption={(input, option) =>
                    option &&
                    option.label &&
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  notFoundContent={isLoading ? <div className="text-center"><Spin size="small" /></div> : null}
                  allowClear
                />
                <Input type="text" value={manualSearchVal} className={`${manualSearchVal ? 'block' : 'hidden'}`} onChange={manualTestSearch} />
              </Form.Item>
            </Col>
            <Col
            // span={2}
            // className="flex justify-center items-center font-bold"
            >
              <div className="flex gap-2 justify-end">
                {/* <button className="bg-white border-2 border-blue-600 rounded-md p-2 w-24 text-black">
                    Cancel
                  </button> */}
                <button
                  disabled={!selectedLabTest}
                  onClick={handleFinish}
                  className="bg-blue-600 border-2 border-blue-600 rounded-md p-2 w-24 text-white"
                >
                  Add
                </button>
              </div>
            </Col>
          </Row>
        </Form>

        <Row gutter={16}>
          <Col span={12}>
            <div
              className={`border border-brand-textGray ${showTable ? "block" : "hidden"
                } rounded-lg mx-4 my-6 relative`}
            >
              <Table
                className="addMedicine"
                columns={columns}
                dataSource={tabledata}
                pagination={false}
                components={{
                  header: {
                    wrapper: (props) => customHeaderContent,
                  },
                }}
              />
            </div>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
}

export default LabReports;