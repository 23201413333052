const APP_ROUTES = {
  LOGINFORM: "/",
  SIGNUPFORM: "/signup",
  SUCCESSMSG: "/successmsg",
  FORGETPASSWORD: "/forgot-password",
  CHANGEPASSWORD: "/change-password",
  DASHBOARD: "/dashboard",
  DASHBOARD1: "/dashboard1",
  APPOINTMENTS: "/appointments",
  PAYMENTS: "/payments",
  PATIENTS: "/patients",
  BILLINGREPORTS: "/billing-repors",
  VIDEOCALL: "/videocall",
  SETTINGS: "/settings",
  CONSULTATIONNOTES: "/consultaion_notes",
  EPRESCRIPTION: "/eprescription",
  SHARECALL: "/vc/:id/:type",
  SHAREPAYMENTS: "/s/v1/:id",
  VCPAGE: "/vcpage",
  CHAT: "/chat",
  MEDICALRECORDS: "/medicalrecords",
  VIEWMEDICALRECORDS: "/viewmedicalrecords",
  SHAREPRESCRIPTION: "/prescription/:id",
  PHONEPEPAYMENT: "/phonepepayment/:transactionId",
  MEDPHARMA: "/sp/v1/p/:id",
  LABPHARMA: "/labPharma",
};

export { APP_ROUTES };
