import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  message,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import "dayjs/plugin/utc";
import { PlusOutlined } from "@ant-design/icons";
import profile from "../../../Assets/Images/Profile Avatar.png";
import { useForm } from "antd/es/form/Form";
import CommonFunctions from "../../Shared/CommonFunctions";
import PatientService from "../../../Services/PatientService";
import dayjs from "dayjs";
import StateMasterService from "../../../Services/StateMasterService";

function NewPatientRegister({ handleKeyPress, handleCancel, getPatientList }) {
  const [form] = useForm();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const fileInputRef = useRef(null);
  const [photoValue, setPhotoValue] = useState(null);
  const [profileType, setProfileType] = useState(null);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);

  const getStateList = () => {
    let payload = {
      status: 1,
    };
    StateMasterService.StateMaster(payload).then((res) => {
      setStates(res.data);
    });
    StateMasterService.CountryMaster(payload).then((res) => {
      setCountries(res.data);
    });
  };

  useEffect(() => {
    getStateList();
  }, []);

  const storedLoginResult = localStorage.getItem("login_result");
  const loginResultObject = storedLoginResult
    ? JSON.parse(storedLoginResult)
    : null;

  const patientCancel = () => {
    form.resetFields();
    handleCancel();
    setPhotoValue(null);
    setProfileType(null);
    setProfileImage(null);
  };

  const handleSubmit = (payload) => {
    setIsButtonDisabled(true);
    payload.account_Id = loginResultObject.user.account_Id;
    payload.clinic_Id = loginResultObject.user.clinic_Id;
    payload.createdBy = loginResultObject.user.userId;
    payload.modifiedBy = loginResultObject.user.userId;
    payload.photo = photoValue;
    payload.photoType = profileType;
    payload.status = 1;
    payload.dob = dayjs(payload.dob, "MM/DD/YYYY").format("MM/DD/YYYY");
    payload.createdDate = CommonFunctions.convertUTCDate(
      new Date(),
      "YYYY-MM-DD HH:mm:ss"
    );
    payload.modifiedDate = CommonFunctions.convertUTCDate(
      new Date(),
      "YYYY-MM-DD HH:mm:ss"
    );
    PatientService.PatientRegistration(payload)
      .then((result) => {
        if (result.data) {
          message.success("New Patient Registration successfull");
          form.resetFields();
          handleCancel();
          // getPatientList();
          setPhotoValue(null);
          setProfileType(null);
          setProfileImage(null);
        } else {
          message.error("Registration failed");
        }
      })
      .catch((error) => {
        console.error("An error occurred during registration:", error);
        message.error("This Email  or Mobile number is already registered");
      })
      .finally(() => {
        setIsButtonDisabled(false);
      });
  };

  const genders = [
    {
      value: "M",
      label: "Male",
    },
    {
      value: "F",
      label: "Female",
    },
  ];
  const bloodgroup = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];
  const maritalStatusOptions = [
    { label: "Married", value: "M" },
    { label: "Unmarried", value: "U" },
  ];
  const salutationOptions = [
    {
      value: "Mr.",
      label: "Mr.",
    },
    {
      value: "Mrs.",
      label: "Mrs.",
    },
    {
      value: "Miss.",
      label: "Miss.",
    },
    {
      value: "Ms.",
      label: "Ms.",
    },
  ];

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        let imageArray = reader.result.split(",");
        setProfileImage(reader.result);
        const imageDataUrl = imageArray[1];
        const imageType = file.type;
        const fileExtension = imageType.split("/")[1];
        setPhotoValue(imageDataUrl);
        setProfileType(fileExtension);
      };
      reader.readAsDataURL(file);
    }
  };

  const disabledDate = (current) => {
    return current && current > new Date().setHours(23, 59, 59, 999);
  };

  return (
    <>
      <Form
        className="formDesign patientForm mt-7 mb-10"
        name="basic"
        layout="vertical"
        autoComplete="off"
        form={form}
        onFinish={handleSubmit}
        closeIcon={false}
      >
        <Row gutter={24}>
          <div className="flex flex-col m-auto mb-2" onClick={handleClick}>
            <div className="Upload h-24  w-24  border-2 border-dashed p-4 rounded-full cursor-pointer relative">
              <input
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
                ref={fileInputRef}
              />
              <div
                className="absolute bottom-0 right-0 rounded-full text-center p-1 bg-slate-300 cursor-pointer"
                style={{ width: "24px", height: "24px", zIndex: 1 }}
              >
                <PlusOutlined style={{}} />
              </div>
              {profileImage && (
                <img
                  src={profileImage}
                  alt="Profile"
                  className="absolute top-0 left-0 w-full h-full object-cover rounded-full"
                />
              )}
            </div>
            <label htmlFor="" className="mt-2">
              Upload Profile
            </label>
          </div>
        </Row>
        <Row gutter={20}>
        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
            <Form.Item
              label={
                <span>
                  Salutation
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="salutation"
              rules={[
                {
                  required: true,
                  message: "Please input salutation!",
                },
              ]}
            >
              <Select options={salutationOptions} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Form.Item
              label={
                <span>
                  First Name
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "Please Enter First Name!",
                },
              ]}
            >
              <Input className="w-full" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Form.Item
              label={
                <span>
                  Last Name
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "Please Enter Last Name!",
                },
              ]}
            >
              <Input className="w-full" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Form.Item
              label={
                <span>
                  D.O.B
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="dob"
              rules={[
                {
                  required: true,
                  message: "Please Enter D.O.B!",
                },
              ]}
            >
              <DatePicker
                className="w-full"
                format={"DD/MM/YYYY"}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={4}>
            <Form.Item
              label={
                <span>
                  Gender
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="gender"
              rules={[
                {
                  required: true,
                  message: "Please Enter Gender!",
                },
              ]}
            >
              <Select options={genders} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Form.Item
              label={
                <span>
                  Blood Group
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="bloodGroup"
              rules={[
                {
                  required: true,
                  message: "Please Enter Blood Group!",
                },
              ]}
            >
              <Select className="w-full">
                {bloodgroup.map((option) => (
                  <Select.Option key={option} value={option}>
                    {option}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Form.Item label={<span>Marital Status</span>} name="maritalStatus">
              <Select className="w-full">
                {maritalStatusOptions.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Form.Item
              label={
                <span>
                  Mobile Number
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="mobile"
              rules={[
                {
                  required: true,
                  message: "Please Enter Mobile Number!",
                },
              ]}
            >
              <Input
                className="w-full"
                maxLength={15}
                onKeyPress={handleKeyPress}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Form.Item
              label={
                <span>
                  Email ID
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please Enter Email ID!",
                },
              ]}
            >
              <Input className="w-full" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={
                <span>
                  Address 1<span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="address1"
              rules={[
                {
                  required: true,
                  message: "Please Enter contact address!",
                },
              ]}
            >
              <TextArea
                autoSize={{
                  minRows: 2,
                  maxRows: 2,
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label="Address 2" name="address2">
              <TextArea
                autoSize={{
                  minRows: 2,
                  maxRows: 2,
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Form.Item
              label={
                <span>
                  City
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="city"
              rules={[
                {
                  required: true,
                  message: "Please Enter City",
                },
              ]}
            >
              <Input className="w-full" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Form.Item
              label={<span>Pincode</span>}
              name="pinCode"
              rules={[
                {
                  required: true,
                  message: "Please Enter Pincode",
                },
              ]}
            >
              <Input
                className="w-full"
                maxLength={6}
                onKeyPress={handleKeyPress}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Form.Item
              label={
                <span>
                  State
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="state"
              rules={[
                {
                  required: true,
                  message: "Please Enter State",
                },
              ]}
            >
              <Select
                placeholder="Select State"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {states?.map((state) => (
                  <Select.Option key={state.stateId} value={state.stateId}>
                    {state.stateName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Form.Item label="Country" name="country">
              <Select
                placeholder="Select Country"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {countries?.map((country) => (
                  <Select.Option
                    key={country.country_Id}
                    value={country.country_Id}
                  >
                    {country.country_Name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <div className="flex justify-end  border-brand-colorBorder gap-3 pt-2">
            <Button className="md:h-10 Btnwhite md:px-8" onClick={patientCancel}>
              Cancel
            </Button>
            <Button
              className="md:h-10 md:px-10"
              type="primary"
              htmlType="submit"
              disabled={isButtonDisabled}
            >
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
}

export default NewPatientRegister;
