import { Col, Collapse, Form, Input, Row } from 'antd'
import React, { useState } from 'react'
import HealthSummary from "../../../Assets/Images/HealthSummary.png";


const { Panel } = Collapse;

function Vitals() {

    const [activeKey, setActiveKey] = useState(false);

    const onChange = (key) => {
        setActiveKey(key);
      };

  return (
    <>
    <Collapse activeKey={activeKey} onChange={onChange} bordered={false} className="my-2  bg-slate-200">
      <Panel
        header={
          <div className="flex justify-between items-center " >
            <div className="flex gap-2 items-center" onClick={() => setActiveKey(true)}>
              <div>
                <img src={HealthSummary} alt="medicineImg" className="h-6 w-6" />
              </div>
              <div className="font-bold text-base py-2">Add Vitals</div>
            </div>
          </div>
          
        }
        key="1"
        showArrow={false}
        className='mb-4'
      >
    <div className="mt-6 border rounded-xl  p-1.5 bg-white">
      <Form layout="vertical">
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label="Blood Pressure"
              name="bloodPressure"
              className="mx-2"

            >
              <div className="w-full flex items-center rounded-lg border border-brand-textGray h-10">
                <Input
                  // value={sys}
                //   onChange={(e) => handleInputChange(e.target.value, "Blood Pressure(SYS)")}
                  maxLength={3}
                  placeholder="SYS"
                  className="outline-none border-none placeholder:text-end"
                // style={{ width: "10%" }}
                />
                <span style={{ width: "10%", textAlign: "center" }}>/</span>
                <Input
                  // value={dia}
                //   onChange={(e) => handleInputChange(e.target.value, "Blood Pressure(DIA)")}
                  maxLength={3}
                  placeholder="DIA"
                  className="outline-none border-none"
                  // style={{ width: "20%" }}
                  suffix={<span style={{ color: "#BEC0C3" }}>mmHg</span>}
                />
              </div>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Pulse Ox" className="mx-2">
              <Input
                className="h-10"
                suffix={<span style={{ color: "#BEC0C3" }}>%</span>}
                // onChange={(e) => handleInputChange(e.target.value, "Pulse Ox")}
              ></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Heart Rate" className="mx-2">
              <Input
                className="h-10"
                suffix={<span style={{ color: "#BEC0C3" }}>Beats/min</span>}
                // onChange={(e) =>
                //   handleInputChange(e.target.value, "Heart Rate")
                // }
              ></Input>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Respiration Rate" className="mx-2">
              <Input
                className="h-10"
                suffix={<span style={{ color: "#BEC0C3" }}>Breaths/min</span>}
                // onChange={(e) =>
                //   handleInputChange(e.target.value, "Respiration Rate")
                // }
              ></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Temperature" className="mx-2">
              <Input
                className="h-10"
                suffix={<span style={{ color: "#BEC0C3" }}>°F</span>}
                // onChange={(e) =>
                //   handleInputChange(e.target.value, "Temperature")
                // }
              ></Input>
            </Form.Item>
          </Col>
          <Col span={6}>
          </Col>

        </Row>
      </Form>
      <hr className="my-4 border-gray-400" />

      <Form layout="vertical">
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item label="Height" className="mx-2">
              <Input
                className="h-10"
                suffix={<span style={{ color: "#BEC0C3" }}>Cm</span>}
                // onChange={(e) => handleInputChange(e.target.value, "Height")}
              ></Input>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Weight" className="mx-2">
              <Input
                className="h-10"
                suffix={<span style={{ color: "#BEC0C3" }}>Kg</span>}
                // onChange={(e) => handleInputChange(e.target.value, "Weight")}
              ></Input>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="BMI" className="mx-2">
              <Input
                className="h-10"
                disabled
                suffix={
                  <span style={{ color: "#BEC0C3" }}>
                    Kg/m<sup>2</sup>
                  </span>
                }
                // onChange={(e) => handleInputChange(e.target.value, "BMI")}
                // value={formData.BMI}
              ></Input>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={24}>
            <div className="flex items-center w-full justify-end my-5">
              <button
                className="bg-white rounded border border-brand-primaryColor text-brand-primaryColor text-sm px-6 py-1.5"
            //   onClick={props.handleClosemodal}
              >
                Cancel
              </button>
              <button
                className="bg-brand-primaryColor rounded px-5 py-1.5 text-white text-sm ml-3"
                // onClick={handleVitalSubmit}
              >
                Submit
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
    </Panel>
      </Collapse>

    </>
  )
}

export default Vitals
