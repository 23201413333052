import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  DatePicker,
  Select,
  Modal,
  message,
  Spin,
} from "antd";
import moment from "moment";
import MedicalService from "../../../../../Services/MedicalHistoryService";
import DrugServices from "../../../../../Services/DrugServices";
import { Option } from "antd/es/mentions";
import MedicalHistoryService from "../../../../../Services/MedicalHistoryService";

function AddMedication(props) {
  const formRef = useRef();
  const [form] = Form.useForm();
  const [medicines, setMedicines] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const activePatient = JSON.parse(localStorage.getItem("patient"));
  const [selectedMedicine, setSelectedMedicine] = useState(null);
  const [searchedManualMed, setSearchedManualMed] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState();
  const patientCode = activePatient?.patientId;
  const [composition, setComposition] = useState("");
  const InputRef = useRef();

  useEffect(() => {
    if (searchedManualMed && InputRef.current) InputRef.current.focus();
  }, [searchedManualMed]);

  const handleCancel = () => {
    props.onClose();
  };

  const handleKeyPress = (e) => {
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ];
    if (!allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const onSearch = async (value) => {
    setSearchedValue(value);
    if (value.length !== 0) {
      try {
        setIsLoading(true);
        const res = await DrugServices.fetchDrugList(value);
        if (res?.data?.length > 0) {
          setMedicines(res?.data);
        }
        else {
          setSearchedManualMed(value);
        }
        setIsLoading(false);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleChange = (label, value) => {
    if (value && value.length !== 0) {
      DrugServices.fetchDrugList(value.label).then((res) => {
        setSelectedMedicine(res.data[0]);
        const composition = res.data[0]?.composition;
        form.setFieldsValue({ composition });
        setComposition(composition || "");
      });
    } else {
      setSelectedMedicine(null);
      setComposition('');
    }
  };

  const options = [
    "0-0-1",
    "0-1-1",
    "0-1-0",
    "1-1-1",
    "1-0-0",
    "1-0-1",
    "1-1-0",
    "1-1-1-1",
    "1-0-0-1",
    "1-0-0-0",
    "1-1-0-0",
    "1-1-1-0",
    "0-0-0-1",
    "0-0-1-1",
    "0-0-1-0",
    "0-1-0-0",
    "0-1-1-1",
    "s.o.s",
    "q.wk.",
    "b.i.d.",
    "t.i.d.",
    "q.i.d.",
    "q.h.s.",
    "5X a day",
    "q.4h",
    "q.6h",
    "q.0.d.",
    "prn.",
    "q.d.",
    "I.M.",
    "Subq.",
    "Rectally",
    "I.V.",
    "O.D.",
    "O.S.",
    "O.U.",
    "2 ml",
    "2.5 ml",
    "5 ml",
    "10 ml",
  ];

  const handleFromDate = (date, dateString) => {
    const constructedTime = dateString + " " + "00:00:00";
    console.log(dateString);
    setFromDate(constructedTime);
  };

  const handleToDate = (date, dateString) => {
    const constructedTime = dateString + " " + "00:00:00";
    console.log(dateString);
    setToDate(constructedTime);
  };

  const disabledFutureDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const onFinish = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    const currDate = new Date().toISOString();
    const date = currDate.split("T")[0];
    const time = currDate.split("T")[1].slice(0, 8);
    const constructedTime = date + " " + time;
    const loginObj = localStorage.getItem("login_result");
    const userData = loginObj ? JSON.parse(loginObj) : null;
    console.log(selectedMedicine    );
    const {
      medicineName,
      dosage,
      adverseEffects,
      adviceRelatedMedicine,
      compliance,
      dose,
      frequency,
      indication,
      sideEffects,
      duration,
      durationtype,
    } = formRef.current.getFieldValue();
    if (
      // !medicineName ||
      // !dosage ||
      // !adverseEffects ||
      // !adviceRelatedMedicine ||
      // !compliance ||
      // !frequency ||
      // !indication ||
      // !sideEffects ||
      !duration ||
      !durationtype ||
      !fromDate
    ) {
      message.error("Please fill in the required field");
      setIsSubmitted(false);
      return;
    }
    let payload = [
      {
        account_Id: userData?.user?.account_Id,
        clinic_Id: userData?.user?.clinic_Id,
        userId: userData?.user?.userId,
        createdDate: constructedTime,
        givenDate: fromDate,
        createdBy: userData?.user?.userId,
        modifiedBy: userData?.user?.userId,
        status: 1,
        patientCode: patientCode,
        enteredBy: userData.user.userId,
        modifiedDate: constructedTime,
        startCreatedTime: constructedTime,
        endCreatedTime: constructedTime,
        adverseEffects: adverseEffects,
        adviceRelatedMedicine: adviceRelatedMedicine,
        compliance: compliance,
        composition: selectedMedicine ?selectedMedicine.composition:composition,
        dosage: dosage,
        dose: dose,
        drugName: selectedMedicine? selectedMedicine.medicineName:searchedManualMed,
        frequency: frequency,
        indication: indication,
        sideEffects: sideEffects,
        duration,
        durationType: durationtype,
      },
    ];
    // try{
    //   const saveResponse = await  MedicalService.AddMedication(payload);
    //   if(saveResponse.data === 1){
    //     let payload = {
    //       account_Id: userData?.user?.account_Id,
    //       // clinic_Id: userData?.user?.clinic_Id,
    //       userId: (props.permissionData === null || props.permissionData === "N")?userData?.user?.userId:'',
    //       patientCode: patientCode,
    //       status: 1,
    //     };
    //     const getResponse = await MedicalHistoryService.PatientMedication(payload);
    //     formRef.current.resetFields();
    //     props.handleLatestUpdate(getResponse.data);
    //     setIsSubmitted(false);
    //     props.onClose();
    //   }
    // }
    // catch(e){
    //   console.log(e);
    //   setIsSubmitted(false);
    // }

    MedicalService.AddMedication(payload)
      .then((result) => {
        formRef.current.resetFields();
        props.handleLatestUpdate(result.data);
        setIsSubmitted(false);
        props.onClose();
      })
      .catch((error) => {
        console.log(error);
        setIsSubmitted(false);
      });
  };

  const handleDate = (date, dateString) => {
    if (dateString) {
      const constructedTime = dateString + " " + "00:00:00";
      console.log(dateString);
      setFromDate(constructedTime);
    }
    else {
      setFromDate('');
    }
  }

  const handleManualMedChange = (e) => {
    setSearchedManualMed(e.target.value);
  }

  return (
    <div>
      <Form
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        form={form}
        ref={formRef}
      >
        <Row gutter={20}>
          <Col className="gutter-row" span={12}>
            <Form.Item label={<span>Drug Name </span>} name="medicineName">
              {/* <Input rows={2} placeholder="Enter " /> */}
              <Select
                onSearch={onSearch}
                onChange={handleChange}
                showSearch
                placeholder="Enter Medicine Name"
                className={`${!searchedManualMed ? 'block' : 'hidden'} w-full h-8`}
                options={medicines.map((medicine) => {
                  return {
                    label: medicine.medicineName,
                    value: medicine.medicineName,
                  };
                })}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                notFoundContent={
                  isLoading ? (
                    <div className="text-center">
                      <Spin size="small" />
                    </div>
                  ) : null
                }
                allowClear
              />
              <Input type="text"
                className={`${searchedManualMed ? 'block' : 'hidden'} w-full h-8`}
                value={searchedManualMed}
                onChange={handleManualMedChange}
                ref={InputRef}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label={<span>Composition </span>}>
              <Input
                value={!searchedManualMed ? selectedMedicine?.composition : composition}
                disabled={!searchedManualMed}
                className="h-8 w-full rounded-md border-gray-200 px-4 outline-none border"
                onChange={(e) => setComposition(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<span>Dosage </span>} className="gutter-row" name="dosage">
              <Input rows={2} placeholder="Enter Dosage" />
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={12}>
            <Form.Item label={<span>Frequency </span>} name="frequency">
              <Select placeholder="Select Frequency" style={{ width: 200 }}>
                {options.map((option, index) => (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <div className="w-2/4 my-4">
            <label><span>Duration <span style={{ color: "#FF000F" }}>*</span></span></label>
            <div className="w-full h-[2rem] relative flex items-center border-2 border-brand-textGray p-4 rounded-lg">
              <Form.Item name="duration" className="w-2/4 h-[inherit] absolute top-0">
                <Input
                  onKeyPress={handleKeyPress}
                  // rows={2}
                  className="border-none outline-none"
                  placeholder="Enter Duration"
                />
              </Form.Item>
              <Form.Item name="durationtype" className="w-2/4 h-[inherit] absolute right-0 top-[0px]">
                <Select placeholder="Select Duration Type" className="border-none">
                  <Select.Option value="Days">Days</Select.Option>
                  <Select.Option value="Weeks">Weeks</Select.Option>
                  <Select.Option value="Months">Months</Select.Option>
                </Select>
              </Form.Item>
            </div>
          </div>
          {/* <Col className="gutter-row" span={12}>
            <Form.Item label={<span>Duration Type <span style={{ color: "#FF000F" }}>*</span></span>} name="durationtype">
              <Select style={{ width: 200 }} placeholder="Select Duration Type">
                <Select.Option value="Days">Days</Select.Option>
                <Select.Option value="Weeks">Weeks</Select.Option>
                <Select.Option value="Months">Months</Select.Option>
              </Select>
            </Form.Item>
          </Col> */}
          <Col className="gutter-row" span={12}>
            <Form.Item label={<span>Indication </span>} name="indication">
              <Input rows={2} placeholder="Enter Indication" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label={<span>Side effect </span>} name="sideEffects">
              <Input rows={2} placeholder="Enter Side Effect" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item label={<span>Compliance </span>} name="compliance">
              <Input rows={2} placeholder="Enter Compliance" />
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={12}>
            <Form.Item
              label={<span>Adverse effect </span>}
              name="adverseEffects"
            >
              <Input rows={2} placeholder="Enter Adverse Effect" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={<span>Advice related to medicines by the doctor </span>}
              name="adviceRelatedMedicine"
            >
              <Input rows={2} placeholder="Enter " />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={<span>From / Checkup Date <span style={{ color: "#FF000F" }}>*</span></span>}
              name="fromdate"
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Select"
                format="YYYY-MM-DD"
                onChange={handleDate}
                disabledDate={disabledFutureDate}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Col className="gutter-row" span={24}>
        <div className="flex items-center w-full justify-end my-5">
          <button
            onClick={handleCancel}
            className="border-brand-primaryColor bg-white border rounded text-brand-primaryColor text-sm px-6 py-1.5"
          >
            Cancel
          </button>
          <button
            disabled={isSubmitted}
            onClick={onFinish}
            className="bg-brand-primaryColor border rounded px-5 py-1.5 text-white text-sm ml-3 disabled:cursor-not-allowed disabled:opacity-50"
          >
            Submit
          </button>
        </div>
      </Col>
    </div>
  );
}

export default AddMedication;
