import http from "./http-common";

class PatientService {

  queryString(payload) {
    return Object.keys(payload).map((key) => (key && payload[key]) ? key + "=" + payload[key] : '').join('&');
  }


  /* to register a new patient */
  PatientRegistration(payload) {
    return http.post("/patient/", payload);
  }

  /* this api is used to get the list of all the patient*/
  PatientList(payload) {
    return http.get(`/patient/list/filter?${payload}`);
  }

  /* this api is used to filters the patient*/
  PatientListFilter(payload) {
    const queryString = Object.entries(payload)
      .filter(
        ([_, value]) => value !== undefined && value !== null && value !== ""
      )
      .map(([key, value]) => `${key}=${value}`)
      .join("&");

    return http.get(`/patient/list/filter?${queryString}`);
  }

  EditPatient(data) {
    return http.put(`/patient/${data.id}`, data);
  }

  isPermissionGranted(data) {
    const queryString = this.queryString(data);
    return http.get(`/patientHistoryAccess/list/filter?${queryString}`);
  }
  patientQueueList(data) {
    const queryString = this.queryString(data);
    return http.get(`/patientAppointment/list/filter?${queryString}`);
  }
  patientPaymentDetails(data) {
    return http.get(`/shareLinkTransaction/list/filter?transactionId=${data}`);
  }
}

export default new PatientService();
