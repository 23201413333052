import React, { useState } from "react";
import AuthLayout from ".";
import Arrowicon from "../../Assets/Images/Svg/Arrow.svg";
import { useHistory } from "react-router-dom";
import { Progress, Button, Row, Col, Input, Select, DatePicker } from "antd";
import Form from "antd/es/form/Form";
import { Option } from "antd/es/mentions";
import RegistrationTable from "./Registrationtable";

function SignupForm() {
  const dateFormat = "DD/MM/YYYY";
  const history = useHistory();

  const handleRouteClick = (route) => {
    history.push(route);
  };

  const [percent, setPercent] = useState(25);
  const steps = ["Steps 1", "Steps 2", "Steps 3", "Steps 4"];
  const currentStep = Math.min(Math.ceil((percent + 1) / 30), steps.length);

  const increase = () => {
    setPercent((prevPercent) => {
      const newPercent = prevPercent + 30;
      if (newPercent > 100) {
        return 100;
      }
      return newPercent;
    });

    if (currentStep === 3) {
      // If it's the third step, navigate to another page
      history.push("/successmsg");
    }
  };

  const decline = () => {
    setPercent((prevPercent) => {
      const newPercent = prevPercent - 30;
      if (newPercent < 0) {
        return 0;
      }
      return newPercent;
    });
  };

  const selectBefore = (
    <Select defaultValue="Mr.">
      <Option value="Mr.">Mr.</Option>
      <Option value="Ms.">Ms.</Option>
      <Option value="Mrs.">Mrs.</Option>
    </Select>
  );

  return (
    <AuthLayout>
      <div className="w-full">
        <div
          className="cursor-pointer flex items-center mb-5"
          onClick={() => handleRouteClick("/")}
        >
          <img src={Arrowicon} alt="icon" />
          <p className="text-brand-primaryColor text-base hover:underline ml-2 mb-0">
            Back
          </p>
        </div>
        <div className="text-4xl text-brand-secondaryColor font-medium mb-3 inline-block">
          Doctor Registration
        </div>
        <div>
          <div className="text-right">
            <span className="text-brand-primaryColor countIncrease">
              {steps[currentStep - 1]}
            </span>{" "}
            to 4
          </div>
          <Progress percent={percent} showInfo={false} />

          <div className={`a ${currentStep === 1 ? "block" : "hidden"}`}>
            <Form name="basic" layout="vertical" autoComplete="off">
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                  <Form.Item label="First name" name="username">
                    <Input classname="h-12 w-full"
                      addonBefore={selectBefore}
                      placeholder="Enter Name"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Last name"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Input classname="h-12" placeholder="Enter Name" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Email"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Input classname="h-12" placeholder="Enter Email" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Mobile Number"
                    name="Number"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Input classname="h-12"  placeholder="(431)-947225" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Gender"
                    name="Gender"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Gender!",
                      },
                    ]}
                  >
                    <Select placeholder="Select Gender" className="h-12">
                      <Select.Option value="consA">Male</Select.Option>
                      <Select.Option value="consB">Female</Select.Option>
                      <Select.Option value="consC">Others</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item label="Date of Birth" name="dob">
                    <DatePicker
                      format={dateFormat}
                      className="w-full h-12"
                      placeholder="dd/mm/yy"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password classname="h-12" placeholder="Enter password" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Confirm Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password classname="h-12" placeholder="Enter password" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
          <div className={`b ${currentStep === 2 ? "block" : "hidden"}`}>
          <Form name="basic" layout="vertical" autoComplete="off">
              <Form.Item label="Address" name="field_a">
                <Input classname="h-12" placeholder="Enter Address"/>
              </Form.Item>
              <Row gutter={30}>
                <Col span={12}>
                  <Form.Item
                    label="Country"
                    name="country"
                    // className={`a ${selectedOption === "Phone" ? "block" : "hidden"}`}
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                     <Select  placeholder="Select country"  className="h-12">
                      <Select.Option value="couA">India</Select.Option>
                      <Select.Option value="couB">USA</Select.Option>
                      <Select.Option value="couC">Australia</Select.Option>
                      <Select.Option value="couD">Britain</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="State"
                    name="state"
                    // className={`a ${selectedOption === "Phone" ? "block" : "hidden"}`}
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Select  placeholder="Select State"  className="h-12">
                      <Select.Option value="couA">Tamilnadu</Select.Option>
                      <Select.Option value="couB">Delhi</Select.Option>
                      <Select.Option value="couC">Kerala</Select.Option>
                      <Select.Option value="couD">Pondicherry</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={30}>
                <Col span={12}>
                  <Form.Item
                    label="Pincode"
                    name="pincode"
                    // className={`a ${selectedOption === "Phone" ? "block" : "hidden"}`}
                    rules={[
                      {
                        required: true,
                        message: "Please input your pincode!",
                      },
                    ]}
                  >
                    <Input classname="h-12" placeholder="Enter Pincode" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Language Spoken"
                    name="language"
                    // className={`a ${selectedOption === "Phone" ? "block" : "hidden"}`}
                    rules={[
                      {
                        required: true,
                        message: "Please input your Language!",
                      },
                    ]}
                  >
                    <Select  placeholder="Choose Language" className="h-12">
                      <Select.Option value="couA">Tamil</Select.Option>
                      <Select.Option value="couB">English</Select.Option>
                      <Select.Option value="couC">Hindi</Select.Option>
                      <Select.Option value="couD">French</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
          <div className={`c ${currentStep === 3 ? "block" : "hidden"}`}>
          <Form name="basic" layout="vertical" autoComplete="off">
           <Row gutter={30}>
              <Col span={12}>
                  <Form.Item
                    label="Primary Speciality"
                    name="specialist"
                    // className={`a ${selectedOption === "Phone" ? "block" : "hidden"}`}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please input your Language!",
                    //   },
                    // ]}
                  >
                    <Select  placeholder="Select Speciality" className="h-12">
                      <Select.Option value="couA">Pediatrician</Select.Option>
                      <Select.Option value="couB">Cardiologist</Select.Option>
                      <Select.Option value="couC">Psychiatrist</Select.Option>
                      <Select.Option value="couD">Neurologist</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Medical Degree"
                    name="degree"
                    // className={`a ${selectedOption === "Phone" ? "block" : "hidden"}`}
                    rules={[
                      {
                        required: true,
                        message: "Please Enter your degree!",
                      },
                    ]}
                  >
                    <Input classname="h-12" placeholder="Enter degree" />
                  </Form.Item>
                </Col>
              </Row>
              <Col span={24} className="">
                  <RegistrationTable />
                </Col>
                <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    label=" License Number"
                    name=" license no"
                    // className={`a ${selectedOption === "Phone" ? "block" : "hidden"}`}
                    rules={[
                      {
                        required: true,
                        message: "Please input your number!",
                      },
                    ]}
                  >
                    <Input classname="h-12" placeholder="Enter License No." />
                  </Form.Item>
                </Col>
                </Row>
           </Form>
          </div>

          <div className="flex gap-5">
            {currentStep === 1 ? (
              <Button
                type="primary"
                onClick={increase}
                className="w-full inline-block my-5 h-12"
              >
                Continue
              </Button>
            ) : currentStep === 4 ? null : ( // Hide all buttons on the final step
              <>
                <Button
                  onClick={decline}
                  className="w-full h-12 Btnwhite inline-block my-5"
                >
                  Back
                </Button>
                <Button
                  type="primary"
                  onClick={increase}
                  className="w-full h-12 inline-block my-5"
                >
                  Continue
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}

export default SignupForm;
