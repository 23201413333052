import React, { useEffect, useState } from "react";
import { Radio, Button, Drawer } from "antd";
import AddNewPatient from "./AddNewPatient";
import AddExistingPatient from "./AddExistingPatient";
import { FaUser } from "react-icons/fa";
import { useForm } from "antd/es/form/Form";

function AddAppointments({ getAppointmentList }) {
  const [open, setOpen] = useState(false);

  const [paymentType, setPaymentType] = useState("Cash"); // changed
  const [paymentReceived, setPaymentReceived] = useState(true); //changed
  const [form, setForm] = useForm();
  const [callType, setCallType] = useState("APPT_VIDEO");

  const [quickInvite, setQuickInvite] = useState(false);
  const [disableRequired, setDisableRequired] = useState(true);

  const [mobileNumber, setMobileNumber] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [radioValue, setRadioValue] = useState("newPatient");

  const storedLoginResult = localStorage.getItem("login_result");
  const loginResultObject = storedLoginResult
    ? JSON.parse(storedLoginResult)
    : null;

  const onChange = (e) => {
    setQuickInvite(false);
    setRadioValue(e.target.value);
    form.resetFields();
    setIsVisible(false);
    setMobileNumber("");
    setPaymentType("Cash"); //changed
    setCallType("APPT_VIDEO");
  };

  const handleKeyPress = (e) => {
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ];
    if (!allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const showaddAppointment = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    form.resetFields();
    setMobileNumber("");
    setPaymentType("Cash"); //changed
    setQuickInvite(false);
    setPaymentReceived(true); //changed
    setDisableRequired(true);
    setRadioValue("newPatient");
    setCallType("APPT_VIDEO");
  };

  return (
    <>
      <Button
        className="bg-brand-primaryColor  text-white !h-10"
        onClick={showaddAppointment}
      >
        + Add Appointment
      </Button>
      <Drawer
        width={750}
        className="customDrawer"
        closeIcon={false}
        title={false}
        placement="right"
        onClose={onClose}
        open={open}
      >
        <div className="relative">
          <div className="bg-brand-primaryColor relative drawerBg">
            <div className="md:p-8 p-4 md:z-10 z-5 relative">
              <div className="flex md:justify-between items-center gap-2 md:gap-0 md:mb-8">
                <div className="md:text-xl text-lg tracking-wider font-medium text-white ">
                  Add Appointments
                </div>
                <div
                  className="text-white font-bold border rounded md:p-1 cursor-pointer"
                  onClick={onClose}
                >
                  X
                </div>
              </div>
              <div className="flex items-center gap-4">
                {loginResultObject.user.imageName ? (
                  <div className="rounded-full w-14 h-14 ml-6 flex justify-center items-center">
                    <img
                      src={
                        process.env.REACT_APP_IMAGE_URL +
                        loginResultObject.user.imageName
                      }
                      alt="DocImg"
                      className="rounded-full md:w-12 w-6 md:h-12 h-6"
                    />
                  </div>
                ) : (
                  <div className="rounded-full border border-brand-secondaryColor md:w-14 w-8 md:h-14 h-8 md:ml-6 ml-4 flex justify-center items-center">
                    {" "}
                    <FaUser className="md:w-8 w-6 md:h-8 h-6" />
                  </div>
                )}

                <div>
                  <div className="text-base text-white font-medium mb-1">
                    {loginResultObject.user.salutation}
                    {loginResultObject.user.firstName}
                    {loginResultObject.user.lastName}
                  </div>
                  <p className="text-white text-xs font-light mb-0">
                    {loginResultObject.user.qualification}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="relative p-8">
            <div className="">
              <Radio.Group onChange={onChange} value={radioValue}>
                <Radio value={"newPatient"}>Quick Patient</Radio>
                <Radio value={"existingPatient"}>Existing Patient</Radio>
                <Radio value={"domainPatients"}>Domain Patient</Radio>
              </Radio.Group>

              {radioValue === "newPatient" ? (
                <AddNewPatient
                  form={form}
                  onClose={onClose}
                  handleKeyPress={handleKeyPress}
                  getAppointmentList={getAppointmentList}
                  paymentType={paymentType}
                  setPaymentType={setPaymentType}
                  paymentReceived={paymentReceived}
                  setPaymentReceived={setPaymentReceived}
                  callType={callType}
                  setCallType={setCallType}
                  //
                  quickInvite={quickInvite}
                  setQuickInvite={setQuickInvite}
                  disableRequired={disableRequired}
                  setDisableRequired={setDisableRequired}
                />
              ) : (
                <AddExistingPatient
                  form={form}
                  onClose={onClose}
                  handleKeyPress={handleKeyPress}
                  getAppointmentList={getAppointmentList}
                  paymentType={paymentType}
                  setPaymentType={setPaymentType}
                  paymentReceived={paymentReceived}
                  setPaymentReceived={setPaymentReceived}
                  callType={callType}
                  setCallType={setCallType}
                  //
                  mobileNumber={mobileNumber}
                  setMobileNumber={setMobileNumber}
                  radioValue={radioValue}
                  isVisible={isVisible}
                  setIsVisible={setIsVisible}
                />
              )}
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default AddAppointments;
