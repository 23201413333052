import React, { useEffect } from "react";
import {  Segmented } from "antd";
import { useState } from "react";
import MedicaHistory from "../MedicalHistory";
import MyPresctription from "../ViewPrescription";
import MyVitals from "../PatientVitals/PatientVitals";
import axios from 'axios';
import Reports from "../Reports";
import { ArrowLeftOutlined, FileDoneOutlined, FileTextOutlined, HeartOutlined, HistoryOutlined } from "@ant-design/icons";
import medicalHistoryIcon from "../../../../../Assets/Images/mdhistory.png";
import medicalPrescriptionIcon from "../../../../../Assets/Images/mdpres.png";
import medicalVitalsIcon from "../../../../../Assets/Images/mdvitals.png";
import medicalReportsIcon from "../../../../../Assets/Images/mdreport.png";
import { useHistory } from 'react-router-dom';
import PatientService from "../../../../../Services/PatientService";


const MedicalRecords = ({selectedPatient}) => {
  const [permissionData, setPermissionData] = useState(null);
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState("Medical history");

// useEffect(() =>{

// },[])

  useEffect(() => {
    const fetchPermittedData = async () => {
      await fetchPatientPermission();
    }
    fetchPermittedData();
  }, []);

  const fetchPatientPermission = async () => {
    
    const userObj = localStorage.getItem('login_result');
    const userData = userObj ? JSON.parse(userObj) : null;
    const patientObj = localStorage.getItem("patient");
    const patientData = patientObj ? JSON.parse(patientObj) : null;

    const currDate = new Date().toISOString();
    const date = currDate.split('T')[0];
    const time = currDate.split('T')[1].slice(0, 8);
    const constructedTime = date + " " + time;

    let permissionPayload = {
      patientId: patientData.patientId,
      userId: userData?.user?.userId,
      accessDate: constructedTime,
      status: 1
    }
    try {
      const response = await PatientService.isPermissionGranted(permissionPayload);
      if (response?.data?.length !== 0) {
        if (response?.data[0]) {
          setPermissionData(response?.data[0]);
        }
        
      }

    }
    catch (e) {
      console.log(e);
    }
  }
 
console.log(permissionData,"permissionData")
  const handleOptionChange = (option) => {
    setSelectedOption(option);
    console.log(option,"tabclick")
  };
  


  return (
       <div className=" h-screen px-5 pt-5">
        {/* <div className="flex justify-between">
          <label className="font-bold text-lg">Medical Records</label>
          <button className="p-2 text-white rounded" onClick={handleBackClick}><ArrowLeftOutlined /> Back</button>
        </div> */}
        <div className="flex items-center mb-8 mt-5">
          <div className="w-7/12">
            <Segmented
              className="appSegment"
              onChange={handleOptionChange}
              block
              options={[
                {
                  label: (
                    <div className="flex gap-2">
                    <img src={medicalHistoryIcon} alt="Medical history" className="w-5 h-5 mt-1 text-white"/>
                    Medical history
                  </div>
                  ),
                  value: "Medical history",
                },
                {
                  label: (
                    <div className="flex gap-2">
                    <img src={medicalPrescriptionIcon} alt="Medical history" className="w-5 h-5 mt-1 text-white"/>
                    Prescription
                  </div>
                  ),
                  value: "Prescription",
                },
                {
                  label: (
                    <div className="flex gap-2">
                    <img src={medicalVitalsIcon} alt="Medical history" className="w-5 h-5 mt-1 text-white"/>
                    Vitals
                  </div>
                  ),
                  value: "Vitals",
                },
                {
                  label: (
                    <div className="flex gap-2">
                    <img src={medicalReportsIcon} alt="Medical history" className="w-5 h-5 mt-1 text-white"/>
                    Reports
                  </div>
                  ),
                  value: "Reports",
                },
              ]}
            />
          </div>
        </div>
        <div className="relative p-1 h-full customHeight">
          {selectedOption === "Medical history" && <MedicaHistory/>}

          {selectedOption === "Prescription" && <MyPresctription selectedPatient={selectedPatient}/>}

          {selectedOption === "Vitals" && <MyVitals selectedPatient={selectedPatient}/>}
          {selectedOption === "Reports" && <Reports selectedPatient={selectedPatient}/>}
        </div>
      </div>
  );
};

export default MedicalRecords;
