import { Space, Table } from "antd";
import React, { useEffect } from "react";
import AppointmentService from "../../../Services/AppointmentService";
import { useState } from "react";
import moment from "moment";

function MonthlyAppointments(props) {
  const columns = [
    {
      title: "Appt. ID",
      dataIndex: "appointmentId",
    },
    {
      title: "Patient Name",
      dataIndex: "patientName",
    },
    {
      title: "Patient ID",
      dataIndex: "patientId",
    },
    {
      title: "Date/Time",
      dataIndex: "appointmentDate",
      key: "appointmentDate",
      render: (_, record) => {
        let date = moment(record.appointmentDate).format("DD-MM-YYYY");
        const [hours, minutes] = record.startTime.split(":");
        const date1 = new Date();
        date1.setHours(parseInt(hours, 10));
        date1.setMinutes(parseInt(minutes, 10));
        const formattedTime = date1.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
        let dateTime = `${date}/${formattedTime}`;
        return <span>{dateTime}</span>;
      },
    },
    {
      title: "Type",
      dataIndex: "serviceCode",
      render: (_, record) =>
        record.serviceCode === "APPT_VIDEO" ? "Video" : "In-person",
    },
    {
      title: "Chief Complaint",
      dataIndex: "appointmentReason",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, record) => (
        <Space size="middle" className="">
          {record.status === 0 && (
            <span className=" text-red-500 " disabled>
              Cancelled
            </span>
          )}
          {record.status === 1 && (
            <span className=" text-blue-500 ">Pending</span>
          )}
          {record.status === 2 && (
            <span className=" text-green-500 ">Completed</span>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className="relative w-full">
      <Table
        className="p-0 customTable"
        columns={columns}
        dataSource={props.upcomingList}
        pagination={true}
        scroll={{ x: 1400 }}
      />
    </div>
  );
}

export default MonthlyAppointments;
