import React, { useEffect, useState } from "react";
import VideoImage from "../../../../Assets/Images/VideoImage.png";
import axios from "axios";
function VideoConsultation(props) {

  let loginData = localStorage.getItem("login_result");
  let loginDataObject = loginData ? JSON.parse(loginData) : null;

  const { selectedPatient } = props;
  const [url, setUrl] = useState("");
  const [permissionsGranted, setPermissionsGranted] = useState(false);
  useEffect(() => {
    getLink();
  }, []);
  // useEffect(() => {
  //   // Function to check and request permissions
  //   const requestPermissions = async () => {
  //     try {
  //       const devices = await navigator.mediaDevices.enumerateDevices();
  //       const hasCamera = devices.some(device => device.kind === 'videoinput');
  //       const hasMicrophone = devices.some(device => device.kind === 'audioinput');

  //       if (hasCamera && hasMicrophone) {
  //         const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
  //         if (stream) {
  //           setPermissionsGranted(true);
  //           stream.getTracks().forEach(track => track.stop()); // Stop the stream after checking
  //         }
  //       } else {
  //         console.error('No camera or microphone found.');
  //       }
  //     } catch (error) {
  //       console.error('Error accessing media devices.', error);
  //     }
  //   };

  //   requestPermissions();
  // }, []);
  const getLink = () => {
    const header = {
      secretKey: "CcJYfbsgItHpTQPFr5lg",
    };
    const data = {
      clientCode: "CCM@@202!",
      userType: "HOST",
      meetingKey: selectedPatient.appointmentId,
      memberName: "Dr. " + `${loginDataObject.user.firstName} ` + ``,
      memberEmail: `${loginDataObject.email}`,
      memberMobile: `${loginDataObject.mobile}`,
    };
    axios
      .post(
        "https://cb.maggieplus.com/admin-api/client/meeting/authentication",
        data,
        { headers: header }
      )
      .then((result) => {
        // if (state?.consultationsType == "A") {
        //   // state.callUrl = result.data.response.url + "/1";
        //   setUrl(result.data.response.url + "/1");
        // } else {
        // state.callUrl = result.data.response.url;
        setUrl(result.data.response.url);
        props.fetchUrl(result.data.response.url);
        // }
      })
      .catch((err) => {
        console.log("Err", err);
      });
  };
  if (!url) {
    return (
      <div>
        {/* <img src={VideoImage} className="w-full" /> */}
      </div>
    );
  }


  return (
    <div>
      <div className="relative">
        <div
          id="videoload"
          style={{
            width: "100px",
            height: "100px",
            margin: "auto",
            textAlign: "center",
            display: "none",
          }}
        >
          Loading...
        </div>
        {/* {permissionsGranted && ( */}
          <div id="videoframe">
            <div
              dangerouslySetInnerHTML={{
                __html: `<iframe id="ccc" height="500" style="width: 100%;"  src=${url} allow="camera;microphone"></iframe>`,
              }}
            ></div>
          </div>
        {/* )} */}
      </div>
    </div>
  );
}

export default VideoConsultation;
