import React, { useEffect, useRef, useState } from "react";
import Layouts from "../../Layouts";
import VcImage from "../../../Assets/Images/VideoImage.png";
import MedicalHistory from "../../Pages/ConsultationNotes/VitalTabs/MedicalHistory";
import MyPresctription from "../../Pages/ConsultationNotes/VitalTabs/ViewPrescription";
import MyVitals from "../../Pages/ConsultationNotes/VitalTabs/PatientVitals/PatientVitals";
import Reports from "../../Pages/ConsultationNotes/VitalTabs/Reports";
import medicalHistoryIcon from "../../../Assets/Images/mdhistory.png";
import medicalPrescriptionIcon from "../../../Assets/Images/mdpres.png"
import medicalVitalsIcon from "../../../Assets/Images/mdvitals.png";
import medicalReportsIcon from "../../../Assets/Images/mdreport.png"
import NoDataFound from "../../../Assets/Images/Svg/No data-found.svg";
import AddMedicines from "./AddMedicines";
import ConsultationNote from "./ConsultationNote";
import { SlArrowUp } from "react-icons/sl";
import { IoIosCloseCircle } from "react-icons/io";
import Draggable from 'react-draggable';
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Modal,
  Space,
  Segmented,
  Tooltip,
  message,
  Flex,
  Spin,
} from "antd";
import LabTest from "./LabTest";
import { FaRegShareFromSquare } from "react-icons/fa6";
import { HiMiniArrowTopRightOnSquare } from "react-icons/hi2";
import Vitals from "./Vitals";
import { useHistory, useLocation } from "react-router-dom";
import AddVitals from "./Addvitals";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import ConsultationService from "../../../Services/ConsultationService";
import VitalService from "../../../Services/VitalService";
import { APP_ROUTES } from "../../../PageRouting/approutes";
import deleteicon from "../../../Assets/Images/Svg/deleteicon.svg";
import PrescriptionDetails from "../ConsultationNotes/VitalTabs/ViewPrescription/PrescriptionDetails";
import PatientService from "../../../Services/PatientService";
import { RiNurseFill } from "react-icons/ri";
import medicineImage from "../../../Assets/Images/Svg/addMedicine.svg";
import labReports from "../../../Assets/Images/Svg/addMedicine@.svg";
import consultationNotes from "../../../Assets/Images/Svg/consultationNotesIcon.svg";
import enrollmentServices from "../../../Services/enrollmentServices";
import VideoConsultation from "../ConsultationNotes/VideoConsultation";
import PatientQueue from "../VCPage/PatientQueue";
import { FaArrowCircleLeft } from "react-icons/fa";
import patientqueue from "../../../Assets/Images/person-icon-blue.png";
import { FaPrescription } from "react-icons/fa";
import { FaVideoSlash } from "react-icons/fa6";
import ViewMedicalRecords from "./ViewMedicalRecords";

const { Panel } = Collapse;

const months = {
  Jan: "01",
  Feb: "02",
  Mar: "03",
  Apr: "04",
  May: "05",
  Jun: "06",
  Jul: "07",
  Aug: "08",
  Sep: "09",
  Oct: "10",
  Nov: "11",
  Dec: "12",
};

const VCPage = () => {
  const formRef = useRef();
  const [selectedRadio, setSelectedRadio] = useState("primary");
  const [diagnosticList, setDiagnosticList] = useState([]);
  const [currPrimaryDiagList, setCurrPrimaryDiagList] = useState([]);
  const [currSecondaryDiagList, setCurrSecondaryDiagList] = useState([]);
  const [selectedPrimaryDiag, setSelectedPrimaryDiag] = useState({});
  const [selectedSecondaryDiag, setSelectedSecondaryDiag] = useState({});
  const [selectedPrimaryDiagVal, setSelectedPrimaryDiagVal] = useState("");
  const [selectedSecondaryDiagVal, setSelectedSecondaryDiagVal] = useState("");
  const [selectedDiagArr, setSelectedDiagArr] = useState([]);
  const [selectedOriginalDiagArr, setSelectedOriginalDiagArr] = useState([]);
  const [selectedCode, setSelectedCode] = useState(null);
  const [selectedSecondaryCode, setSelectedSecondaryCode] = useState(null);
  const [secondaryDiag, setSecondaryDiag] = useState([]);
  const [isEditScreenEnabled, setIsEditScreenEnabled] = useState(false);
  const [vitalsObj, setVitalsObj] = useState([]);
  const [medicinesObj, setMedicinesObj] = useState([]);
  const [labTestsObj, setLabTestsObj] = useState([]);
  const [followUpDate, setFollowUpDate] = useState("");
  const [bmiValue, setBmiValue] = useState();
  const [height, setHeight] = useState();
  const [weight, setWeight] = useState();
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [prescription, setPrescription] = useState(null);
  const [icdCode, setIcdCode] = useState();
  const [primdiag, setPrimdiag] = useState();
  const [tabledata, setTabledata] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [vitalMasterList, setVitalMasterList] = useState([]);
  const [bloodPressure, setBloodPressure] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [vitalsArrObj, setVitalsArrObj] = useState([]);
  const [sys, setSys] = useState("");
  const [dia, setDia] = useState("");
  const [key, setKey] = useState(0);
  const [secKey, setSecKey] = useState(0);
  const [chiefComplaint, setChiefComplaint] = useState("");
  const [symptoms, setSymptoms] = useState("");
  const [shareModal, setShareModal] = useState(false);
  const [selectedPat, setSelectedPat] = useState(null);
  const { state } = useLocation();
  const history = useHistory();
  const [consulationData, setConsulationData] = useState();
  const [recommendation, setRecommendation] = useState("");
  const [videoUrl, setUrl] = useState("");
  const [selectedPatientDetails, setSelectedPatientDetails] =
    useState(RiNurseFill);
  const [age, setAge] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isImageVisible, setIsImageVisible] = useState(false);
  const [states, setStates] = useState([]);
  const [queueData, setQueueData] = useState();
  const [form] = useForm();
  const location = useLocation(null);
  let selectedPatient = location?.state?.selectedPatient;
  console.log("selectedPatient", selectedPatient);

  useEffect(() => {
    if (location?.state?.vitalsArrObjData) {
      setVitalsArrObj(prevState => ({
        ...prevState,
        ...location.state.vitalsArrObjData
      }));
    }


    handleEditScreen();
  }, []);

  useEffect(() => {
    if (consulationData?.labPrescription) {
      setLabTestsObj(consulationData?.labPrescription);
    }
    if (consulationData?.drugPrescription) {
      setMedicinesObj(consulationData?.drugPrescription);
    }
    if (consulationData?.patientVitals) {
      form.setFieldsValue({
        BPS: consulationData?.patientVitals
          ? consulationData?.patientVitals?.find(
            (item) => item.vitalCode === "BPS"
          )?.value
          : "",
        BPD: consulationData?.patientVitals
          ? consulationData?.patientVitals?.find(
            (item) => item.vitalCode === "BPD"
          )?.value
          : "",
        PO: consulationData?.patientVitals
          ? consulationData?.patientVitals?.find(
            (item) => item.vitalCode === "PO"
          )?.value
          : "",
        HR: consulationData?.patientVitals
          ? consulationData?.patientVitals?.find(
            (item) => item.vitalCode === "HR"
          )?.value
          : "",
        RR: consulationData?.patientVitals
          ? consulationData?.patientVitals?.find(
            (item) => item.vitalCode === "RR"
          )?.value
          : "",
        TP: consulationData?.patientVitals
          ? consulationData?.patientVitals?.find(
            (item) => item.vitalCode === "TP"
          )?.value
          : "",
        HI: consulationData?.patientVitals
          ? consulationData?.patientVitals?.find(
            (item) => item.vitalCode === "HI"
          )?.value
          : "",
        WE: consulationData?.patientVitals
          ? consulationData?.patientVitals?.find(
            (item) => item.vitalCode === "WE"
          )?.value
          : "",
        BMI: consulationData?.patientVitals
          ? consulationData?.patientVitals?.find(
            (item) => item.vitalCode === "BMI"
          )?.value
          : "",
        symptoms: consulationData?.symptoms ? consulationData?.symptoms : "",
        recommendation: consulationData?.recommendation
          ? consulationData?.recommendation
          : "",
      });
    }
    setVitalsObj(consulationData?.patientVitals);
  }, [consulationData]);

  useEffect(() => {
    fetchVitals();
  }, []);

  useEffect(() => {
    let payload = {
      patientId: selectedPatient.patientId,
    };
    PatientService.PatientListFilter(payload)
      .then((result) => {
        setSelectedPatientDetails(result.data[0]);
        const dobData = result.data[0].dob;
        const dobYear = dobData?.split("/")[2];
        const currYear = new Date().getFullYear();
        console.log("age ", Number(dobYear));
        if (dobYear) {
          const patientAge = currYear - Number(dobYear);
          console.log("age ", patientAge);
          setAge(patientAge);
        }
      })
      .catch((error) => {
        console.error("Error fetching patient list:", error);
      });
  }, [selectedPatient.patientId]);

  useEffect(() => {
    if (
      selectedPatient?.consultationId &&
      selectedPatient?.callMode === "EditCall"
    ) {
      getPrescriptionDetails();
    }
  }, []);

  const showDrawer = () => {
    let payload = {
      userId: selectedPatient.userId,
      queueStatus: "Y"
    }
    PatientService.patientQueueList(payload).then((res) => {
      if (res.data.length) {
        let validate = res?.data?.filter((x) => x.appointmentId != selectedPatient?.appointmentId)
        if (validate && validate.length) {
          setQueueData(validate)
          setIsDrawerOpen(true);
        } else {
          message.warning(
            "No patient available in queue"
          );
        }
      } else {
        message.warning(
          "No patient available in queue"
        );
      }
    })
  };

  const onClose = () => {
    setIsDrawerOpen(false);
  };

  const toggleImageVisibility = () => {
    setIsImageVisible(!isImageVisible);
  };

  const handleEditScreen = async () => {
    if (selectedPatient.consultationId) {
      setIsEditScreenEnabled(true);
    }
  };

  const handleFullScreen = () => {
    window.open(videoUrl, "_blank").focus();
  };

  const showShareModal = (record) => {
    setShareModal(true);
    setSelectedPat(record);
  };

  const handleShareLink = (value) => {
    console.log(value);
    const { enteredVal } = value;
    var validation =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;
    var number = /^\d+$/;
    let payload = {};
    setIsLoading(true);
    if (validation.test(enteredVal)) {
      if (number.test(enteredVal)) {
        payload.mobile = enteredVal;
      } else {
        payload.email = enteredVal;
      }
      payload.patientId = selectedPatient.patientId;
      payload.userId = selectedPatient.userId;
      payload.consultationType = selectedPatient.consultationType;
      payload.appointmentId = selectedPatient.appointmentId;

      enrollmentServices
        .InvitationShare(payload)
        .then((res) => {
          console.log("res", res.data);
          message.success("Invitation sent successfully");
          setShareModal(false);
          setIsLoading(false);
          form.resetFields();
        })
        .catch((e) => {
          message.error("An error occurred, Try again");
          setIsLoading(false);
        });
    } else {
      message.error("Please enter the valid email or phone");
      setIsLoading(false);
    }
  };

  const getPrescriptionDetails = async () => {
    let load = {
      consultationId: selectedPatient.consultationId,
      detailsYN: "Y",
    };
    const consultationResponse = await ConsultationService.getPrescriptions(
      load
    );
    if (consultationResponse?.data?.length) {
      setConsulationData(consultationResponse?.data[0]);
      console.log("koiiii", consultationResponse?.data[0]);
      if (consultationResponse?.data[0].patientDiagnosisDetails.length) {
        console.log(
          "lollsss",
          consultationResponse?.data[0].patientDiagnosisDetails
        );
        setSelectedDiagArr(
          consultationResponse?.data[0].patientDiagnosisDetails
        );
        setSelectedOriginalDiagArr(
          consultationResponse?.data[0].patientDiagnosisDetails
        );
        setShowTable(true);
      }
    }
  };

  const fetchVitals = async () => {
    let payload = {
      status: 1,
    };
    const res = await VitalService.VitalMasterlist(payload);
    setVitalMasterList(res.data);
  };

  const handleChiefComplaint = (e) => {
    setChiefComplaint(e.target.value);
  };

  const handleSymptoms = (e) => {
    setSymptoms(e.target.value);
  };

  const handleCancel = () => {
    setIsPreviewModalOpen(false);
  };

  const handlePrescription = async (e) => {
    e.preventDefault();
    const currDate = new Date().toISOString();
    const date = currDate.split("T")[0];
    const time = currDate.split("T")[1].slice(0, 8);
    const constructedTime = date + " " + time;
    const buttonClick = e.target.innerHTML;
    console.log(buttonClick);

    const patientData = state?.selectedPatient;
    console.log("patient Data ", patientData);
    const userData = JSON.parse(localStorage.getItem("login_result"));

    const VitalKeys = Object.keys(vitalsArrObj);
    let diagnosisArr = [];
    let vitalsArr = [];
    let medicinesArr = [];
    let labtestArr = [];
    console.log("diag ", selectedOriginalDiagArr);
    // diagnosis payload objects
    for (let i = 0; i < selectedOriginalDiagArr?.length; i++) {
      let Diagpayload = {
        ...selectedOriginalDiagArr[i],
        account_Id: userData?.user?.account_Id,
        clinic_Id: userData?.user?.clinic_Id,
        createdBy: userData?.user.userId,
        createdDate: constructedTime,
        diagnosisCode: selectedOriginalDiagArr[i]?.diagnosisCode,
        diagnosisDescription: selectedOriginalDiagArr[i]?.diagnosisDescription,
        diagnosisType: selectedOriginalDiagArr[i]?.diagnosisType,
        modifiedBy: userData?.user.userId,
        modifiedDate: constructedTime,
        patientId: patientData?.patientId,
        status:
          selectedOriginalDiagArr[i]?.status === 0
            ? 0
            : selectedOriginalDiagArr[i]?.status === undefined
              ? 1
              : selectedOriginalDiagArr[i]?.status,
        userId: userData?.user.userId,
      };
      if (buttonClick === "Update") {
        Diagpayload.consultationId = selectedPatient?.consultationId;
      }
      diagnosisArr.push(Diagpayload);
    }

    // vitals payload objects
    const vitalDate = new Date().toUTCString();
    console.log(vitalDate);
    const dateArr = vitalDate.split(" ");
    const vitMonth = months[dateArr[2]];
    const vitDate = dateArr[1];
    const vitYear = dateArr[3];
    const constructedDate = vitYear + "-" + vitMonth + "-" + vitDate;
    const vitTime = dateArr[4];
    const constructedDateTime = constructedDate + " " + vitTime;

    // for (let i = 0; i < VitalKeys.length; i++) {
    //   console.log("formRef.current.getFieldValue(VitalKeys[i]) ",formRef.current.getFieldValue(VitalKeys[i]));
    //   let payload = {
    //     createdBy: userData?.user.userId,
    //     createdDate: constructedDateTime,
    //     modifiedBy: userData?.user.userId,
    //     modifiedDate: constructedDateTime,
    //     patientId: patientData?.patientId,
    //     recordedDateTime: constructedDateTime,
    //     status: 1,
    //     units: VitalKeys[i] === 'systolic' || VitalKeys[i] === 'diastolic' ? 'mmHg' :
    //       VitalKeys[i] === 'PO' ? '%' : VitalKeys[i] === 'HR' || VitalKeys[i] === 'RR' ? 'Beats/min' :
    //         VitalKeys[i] === 'TP' ? 'F' : VitalKeys[i] === 'HI' ? 'cm' : VitalKeys[i] === 'WE' ? 'kg' : 'kg/m2',
    //     value: VitalKeys[i] === 'bmi' ? bmiValue ? bmiValue : consulationData?.patientVitals?.find((item) => item.vitalCode === "BMI").value :
    //       VitalKeys[i] === 'systolic' ? sys ? sys : consulationData?.patientVitals?.find((item) => item.vitalCode === "BPS").value :
    //         VitalKeys[i] === 'diastolic' ? dia ? dia : consulationData?.patientVitals?.find((item) => item.vitalCode === "BPD").value :
    //           VitalKeys[i] === 'PO' ? formRef.current.getFieldValue(VitalKeys[i]) ? formRef.current.getFieldValue(VitalKeys[i]) : consulationData?.patientVitals?.find((item) => item.vitalCode === "PO").value :
    //             VitalKeys[i] === 'HR' ? formRef.current.getFieldValue(VitalKeys[i]) ? formRef.current.getFieldValue(VitalKeys[i]) : consulationData?.patientVitals?.find((item) => item.vitalCode === "HR").value :
    //               VitalKeys[i] === 'RR' ? formRef.current.getFieldValue(VitalKeys[i]) ? formRef.current.getFieldValue(VitalKeys[i]) : consulationData?.patientVitals?.find((item) => item.vitalCode === "RR").value :
    //                 VitalKeys[i] === 'TP' ? formRef.current.getFieldValue(VitalKeys[i]) ? formRef.current.getFieldValue(VitalKeys[i]) : consulationData?.patientVitals?.find((item) => item.vitalCode === "TP").value :
    //                   VitalKeys[i] === 'Hi' ? formRef.current.getFieldValue(VitalKeys[i]) ? formRef.current.getFieldValue(VitalKeys[i]) : consulationData?.patientVitals?.find((item) => item.vitalCode === "HI").value :
    //                     VitalKeys[i] === 'WE' ? formRef.current.getFieldValue(VitalKeys[i]) ? formRef.current.getFieldValue(VitalKeys[i]) : consulationData?.patientVitals?.find((item) => item.vitalCode === "WE").value : formRef.current.getFieldValue(VitalKeys[i]),
    //     vitalCode: VitalKeys[i] === 'systolic' ? 'BPS' : VitalKeys[i] === 'diastolic' ? 'BPD' :
    //       VitalKeys[i] === 'PO' ? 'PO' : VitalKeys[i] === 'HR' ? 'HR' : VitalKeys[i] === 'RR' ? 'RR' :
    //         VitalKeys[i] === 'TP' ? 'TP' : VitalKeys[i] === 'HI' ? 'HI' : VitalKeys[i] === 'WE' ? 'WE' : VitalKeys[i] === 'bmi' ? 'BMI' : '',
    //     vitalName: VitalKeys[i]
    //   }
    //   vitalsArr.push(payload);
    // }
    console.log(VitalKeys, vitalsArrObj);
    for (let i = 0; i < VitalKeys.length; i++) {
      let vitalPayload = {
        account_Id: userData?.user?.account_Id,
        clinic_Id: userData?.user?.clinic_Id,
        userId: userData?.user?.userId,
        createdBy: userData?.user.userId,
        createdDate: constructedDateTime,
        modifiedBy: userData?.user.userId,
        modifiedDate: constructedDateTime,
        patientId: patientData?.patientId,
        recordedDateTime: constructedDateTime,
        status: 1,
        units: vitalMasterList.find((vital) => vital.vitalCode === VitalKeys[i])
          ?.units,
        value:
          VitalKeys[i] === "BMI"
            ? vitalsArrObj[VitalKeys[i]]
              ? vitalsArrObj[VitalKeys[i]]
              : consulationData?.patientVitals
                ? consulationData?.patientVitals?.find(
                  (item) => item.vitalCode === "BMI"
                )?.value
                : ""
            : VitalKeys[i] === "BPS"
              ? vitalsArrObj[VitalKeys[i]]
                ? vitalsArrObj[VitalKeys[i]]
                : consulationData?.patientVitals
                  ? consulationData?.patientVitals?.find(
                    (item) => item.vitalCode === "BPS"
                  )?.value
                  : ""
              : VitalKeys[i] === "BPD"
                ? vitalsArrObj[VitalKeys[i]]
                  ? vitalsArrObj[VitalKeys[i]]
                  : consulationData?.patientVitals
                    ? consulationData?.patientVitals?.find(
                      (item) => item.vitalCode === "BPD"
                    )?.value
                    : ""
                : VitalKeys[i] === "PO"
                  ? vitalsArrObj[VitalKeys[i]]
                    ? Number(vitalsArrObj[VitalKeys[i]])
                    : consulationData?.patientVitals
                      ? consulationData?.patientVitals?.find(
                        (item) => item.vitalCode === "PO"
                      )?.value
                      : ""
                  : VitalKeys[i] === "HR"
                    ? vitalsArrObj[VitalKeys[i]]
                      ? Number(vitalsArrObj[VitalKeys[i]])
                      : consulationData?.patientVitals
                        ? consulationData?.patientVitals?.find(
                          (item) => item.vitalCode === "HR"
                        )?.value
                        : ""
                    : VitalKeys[i] === "RR"
                      ? vitalsArrObj[VitalKeys[i]]
                        ? Number(vitalsArrObj[VitalKeys[i]])
                        : consulationData?.patientVitals
                          ? consulationData?.patientVitals?.find(
                            (item) => item.vitalCode === "RR"
                          )?.value
                          : ""
                      : VitalKeys[i] === "TP"
                        ? vitalsArrObj[VitalKeys[i]]
                          ? Number(vitalsArrObj[VitalKeys[i]])
                          : consulationData?.patientVitals
                            ? consulationData?.patientVitals?.find(
                              (item) => item.vitalCode === "TP"
                            )?.value
                            : ""
                        : VitalKeys[i] === "HI"
                          ? vitalsArrObj[VitalKeys[i]]
                            ? Number(vitalsArrObj[VitalKeys[i]])
                            : consulationData?.patientVitals
                              ? consulationData?.patientVitals?.find(
                                (item) => item.vitalCode === "HI"
                              )?.value
                              : ""
                          : VitalKeys[i] === "WE"
                            ? vitalsArrObj[VitalKeys[i]]
                              ? Number(vitalsArrObj[VitalKeys[i]])
                              : consulationData?.patientVitals
                                ? consulationData?.patientVitals?.find(
                                  (item) => item.vitalCode === "WE"
                                )?.value
                                : ""
                            : "",
        vitalCode: vitalMasterList.find(
          (vital) => vital.vitalCode === VitalKeys[i]
        )?.vitalCode,
        vitalName: vitalMasterList.find(
          (vital) => vital.vitalCode === VitalKeys[i]
        )?.vitalName,
      };
      // if(e.target.innerHTML === "Update Consulation"){
      //   const obj =  consulationData?.patientVitals?.find((item) => item.vitalCode === vitalPayload.vitalCode);
      //   console.log(obj);
      // }
      if (buttonClick === "Update") {
        vitalPayload.consultationId = selectedPatient?.consultationId;
        vitalPayload.id = consulationData?.patientVitals?.find(
          (vital) => vital.vitalCode === vitalPayload.vitalCode
        )?.id;
      }
      vitalsArr.push(vitalPayload);
    }

    console.log("prepared vital arr ", vitalsArr);
    console.log("medicines data before final submit ", medicinesObj);
    // medicine payload objects
    for (let i = 0; i < medicinesObj?.length; i++) {
      const toDate =
        medicinesObj[i]?.duration * 24 * 60 * 60 * 1000
          ? new Date(
            new Date().getTime() +
            medicinesObj[i]?.duration * 24 * 60 * 60 * 1000
          ).toISOString()
          : new Date().toISOString();
      const toDays = toDate.split("T")[0];
      const constructedToDateTime = toDays + " " + time;
      let medicinePayload = {
        // ...medicinesObj[i],
        account_Id: userData?.user?.account_Id,
        clinic_Id: userData?.user?.clinic_Id,
        afterBeforeFood:
          medicinesObj[i]?.timeTaken === "afterfood" ? "AF" : "BF",
        composition: medicinesObj[i]?.composition,
        createdBy: userData?.user.userId,
        createdDate: constructedTime,
        dosage: medicinesObj[i]?.dosage ? medicinesObj[i]?.dosage : null,
        drugCode: medicinesObj[i]?.code
          ? medicinesObj[i]?.code
          : medicinesObj[i]?.drugCode
            ? medicinesObj[i]?.drugCode
            : null,
        drugName: medicinesObj[i]?.drugName ? medicinesObj[i]?.drugName : null,
        duration: medicinesObj[i]?.duration ? medicinesObj[i]?.duration : null,
        form: null,
        frequency: medicinesObj[i]?.dosage ? medicinesObj[i]?.dosage : null,
        fromDateTime: constructedTime,
        givenDate: constructedTime,
        instruction: medicinesObj[i]?.instruction
          ? medicinesObj[i]?.instruction
          : null,
        medicineType: medicinesObj[i]?.productType
          ? medicinesObj[i]?.productType
          : null,
        modifiedBy: userData?.user.userId,
        modifiedDate: constructedTime,
        patientId: patientData?.patientId,
        quantity: 0,
        specialAuthority: null,
        status:
          medicinesObj[i]?.status === 0
            ? 0
            : medicinesObj[i]?.status === undefined
              ? 1
              : medicinesObj[i]?.status,
        toDateTime: constructedToDateTime,
        userId: userData?.user.userId,
      };
      if (buttonClick === "Update") {
        medicinePayload.consultationId = selectedPatient?.consultationId;
      }
      medicinesArr.push(medicinePayload);
    }

    console.log("labs data before final submit ", labTestsObj);
    //labtest payload objects
    for (let i = 0; i < labTestsObj?.length; i++) {
      let labPayload = {
        ...labTestsObj[i],
        account_Id: userData?.user?.account_Id,
        clinic_Id: userData?.user?.clinic_Id,
        createdBy: userData?.user.userId,
        createdDate: constructedTime,
        documentReceivedSource: null,
        labTestId: labTestsObj[i]?.labTestId ? labTestsObj[i]?.labTestId : null,
        labTestName: labTestsObj[i]?.labTestName
          ? labTestsObj[i]?.labTestName
          : null,
        modifiedBy: userData?.user.userId,
        modifiedDate: constructedTime,
        parentLabTestName: labTestsObj[i]?.parentDescription
          ? labTestsObj[i]?.parentDescription
          : null,
        patientId: patientData?.patientId,
        status:
          labTestsObj[i]?.status === 0
            ? labTestsObj[i]?.status
            : labTestsObj[i]?.status === undefined
              ? 1
              : labTestsObj[i]?.status,
        userId: userData?.user.userId,
      };
      if (buttonClick === "Update") {
        labPayload.consultationId = selectedPatient?.consultationId;
      }
      labtestArr.push(labPayload);
    }

    console.log(selectedPrimaryDiag, selectedSecondaryDiag);

    console.log("followup date ", followUpDate);

    let payload = {
      account_Id: userData?.user?.account_Id,
      clinic_Id: userData?.user?.clinic_Id,
      consultationDate: constructedTime,
      consultationReason: patientData?.appointmentReason,
      consultationType: patientData?.consultationType,
      createdBy: userData?.user.userId,
      createdDate: constructedTime
        ? constructedTime
        : consulationData?.createdDate,
      drugPrescription: medicinesArr,
      followupVisitDate:
        followUpDate.length !== 0
          ? followUpDate
          : consulationData?.followupVisitDate,
      image: "",
      imageName: null,
      imageType: null,
      labPrescription: labtestArr,
      modifiedBy: userData?.user.userId,
      modifiedDate: constructedTime,
      patientDocumentID: 0,
      patientId: patientData?.patientId,
      patientVitals: vitalsArr,
      patientDiagnosisDetails: diagnosisArr,
      // primaryDiagnosis: selectedPrimaryDiag.code,
      // primarySymptoms: selectedPrimaryDiag.description,
      recommendation: recommendation
        ? recommendation
        : consulationData?.recommendation,
      // secondaryDiagnosis: selectedSecondaryDiag.code,
      // secondarySymptoms: selectedSecondaryDiag.description,
      status: 1,
      symptoms: symptoms ? symptoms : consulationData?.symptoms,
      userId: userData?.user.userId,
      appointmentId: patientData?.appointmentId,
      specialityCode: userData?.user?.specialityCode
    };
    if (buttonClick === "Update") {
      payload.id = consulationData?.id;
      payload.appointmentId = selectedPatient?.appointmentId;
      payload.consultationId = selectedPatient?.consultationId;
    }

    setPrescription(payload);
    console.log("pay from  ", payload);
    if (buttonClick === "Preview") {
      setIsPreviewModalOpen(true);
    } else if (buttonClick === "End") {
      const response = await ConsultationService.ConsultationNotes(payload);
      const { appointmentId, consultationId } = response.data;
      let endConsultationPayload = {
        appointmentId,
        consultationId,
      };
      await ConsultationService.EndConsultation(endConsultationPayload);
      payload = {};
      message.success(
        "Your consultation ended successfully, e-prescription will be shared with patient."
      );
      history.push(APP_ROUTES.DASHBOARD);
    } else if (buttonClick === "Update") {
      console.log("payload from update ", payload);
      await ConsultationService.updateConsultation(payload);
      payload = {};
      message.success(
        "Your consultation updated successfully, e-prescription will be shared with patient"
      );
      history.push(APP_ROUTES.DASHBOARD);
    }
  };

  const handleRecommendation = (e) => {
    if (e.target.value) {
      setRecommendation(e.target.value);
    } else {
      setRecommendation("");
    }
  };

  const disabledDate = (current) => {
    return current && current < dayjs().startOf("day");
  };

  const handleFollowUpDate = (date, dateString) => {
    if (dateString) {
      const constructedTime = dateString;
      console.log(dateString);
      setFollowUpDate(constructedTime);
    } else {
      setFollowUpDate("");
    }
  };

  const handleAddVitals = (data) => {
    console.log("newly added data ", data, " prev state ", vitalsArrObj);
    const VitalsObj = {
      ...vitalsArrObj,
      ...data,
    };
    console.log(VitalsObj);
    setVitalsArrObj(VitalsObj);
  };

  const handleAddMedicines = (data) => {
    setMedicinesObj(data);
  };

  const handleAddLabTests = (data) => {
    setLabTestsObj(data);
  };

  const handleDiagonsisArr = (data) => {
    setSelectedOriginalDiagArr(data);
  };

  const fetchUrl = (value) => {
    setUrl(value);
  };

  const [medicalOpen, setMedicalOpen] = useState(false)

  const handleClick = () => {
    setMedicalOpen(true)
    const fetchPermittedData = async () => {
      await fetchPatientPermission();
    }
    fetchPermittedData()
    //history.push(APP_ROUTES.VIEWMEDICALRECORDS,);
    // history.push({
    //   pathname: APP_ROUTES.VIEWMEDICALRECORDS,
    //   state: { vitalsArrObjData:vitalsArrObj ,selectedPatient:selectedPatient,},
    // });

  };
  const handleMedicalCancel = () => {
    setMedicalOpen(false)
  }

  const items = [
    {
      key: "1",
      label: (
        <div className="flex justify-between items-center">
          <div className="flex gap-2 items-center">
            <div>
              <img src={consultationNotes} alt="medicineImg" />
            </div>
            <div className="font-bold">Consultation Notes</div>
          </div>
        </div>
      ),
      children: (
        <ConsultationNote
          consulationData={consulationData}
          handleDiagonsisArr={handleDiagonsisArr}
          handleChiefComplaint={handleChiefComplaint}
          handleSymptoms={handleSymptoms}
        />
      ),
    },
    {
      key: "2",
      label: (
        <div className="flex justify-between items-center">
          <div className="flex gap-2 items-center">
            <div>
              <img src={medicineImage} alt="medicineImg" />
            </div>
            <div className="font-bold">Medicines</div>
          </div>
        </div>
      ),
      children: (
        <AddMedicines
          consulationData={consulationData}
          handleAddMedicines={handleAddMedicines}
        />
      ),
    },
    {
      key: "3",
      label: (
        <div className="flex justify-between items-center">
          <div className="flex gap-2 items-center">
            <div>
              <img src={labReports} alt="medicineImg" />
            </div>
            <div className="font-bold">Lab Tests</div>
          </div>
        </div>
      ),
      children: (
        <LabTest
          consulationData={consulationData}
          handleAddLabTests={handleAddLabTests}
        />
      ),
    },
  ];

  const [permissionData, setPermissionData] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  // useEffect(() => {
  //   const fetchPermittedData = async () => {
  //     await fetchPatientPermission();
  //   }
  //   fetchPermittedData();
  // }, []);

  const fetchPatientPermission = async () => {
    const userObj = localStorage.getItem('login_result');
    const userData = userObj ? JSON.parse(userObj) : null;
    const patientObj = localStorage.getItem("patient");
    const patientData = patientObj ? JSON.parse(patientObj) : null;

    const currDate = new Date().toISOString();
    const date = currDate.split('T')[0];
    const time = currDate.split('T')[1].slice(0, 8);
    const constructedTime = date + " " + time;

    let permissionPayload = {
      patientId: patientData.patientId,
      userId: userData?.user?.userId,
      accessDate: constructedTime,
      status: 1
    }
    try {
      const response = await PatientService.isPermissionGranted(permissionPayload);
      console.log(response, "response")
      if (response?.data?.length !== 0) {
        if (response?.data[0]) {
          setPermissionData(response?.data[0]);
        }

      }

    }
    catch (e) {
      console.log(e);
    }
  }

  console.log(permissionData, "permissiondata");
  useEffect(() => {
    if (permissionData) {
      if (permissionData.medicalHistory === 'Y') {
        setSelectedOption("Medical history");
      } else if (permissionData.prescription === 'Y') {
        setSelectedOption("Prescription");
      } else if (permissionData.vitals === 'Y') {
        setSelectedOption("Vitals");
      } else if (permissionData.reports === 'Y') {
        setSelectedOption("Reports");
      }
    }
  }, [permissionData]);

  const handleOptionChange = (option) => {
    if (permissionData) {
      if (option === "Medical history" && permissionData.medicalHistory === 'Y') {
        setSelectedOption(option);
      } else if (option === "Prescription" && permissionData.prescription === 'Y') {
        setSelectedOption(option);
      } else if (option === "Vitals" && permissionData.vitals === 'Y') {
        setSelectedOption(option);
      } else if (option === "Reports" && permissionData.reports === 'Y') {
        setSelectedOption(option);
      }
    }
  };

  const [isVerticalOpen, setIsVerticalOpen] = useState(true);
  const QueuehandleClick = () => {
    setIsVerticalOpen(false);
  }
  const closeCircle = () => {
    setIsVerticalOpen(true);
  }
  return (
    <Layouts>
      <div className="flex max-w-full gap-3">
        <div className="w-[50%] h-screen">
          {/* <div className="flex justify-between items-center mb-1">
            <HiMiniArrowTopRightOnSquare className="text-brand-primaryColor text-xl font-medium cursor-pointer" />
            <button className="p-2 rounded text-base text-white flex gap-2">
              <FaRegShareFromSquare className="mt-1 text-xl font-medium" />
              Share Link
            </button>
          </div>
          <div className="h-[50%] sticky mb-2">
            <img src={VcImage} className="h-full" alt="Video Consultation" />
          </div> */}
          {!isEditScreenEnabled && (
            <div className="flex items-center justify-between mx-2">
              <HiMiniArrowTopRightOnSquare
                className="text-brand-primaryColor text-xl font-medium cursor-pointer"
                onClick={handleFullScreen}
              />
              <button
                className="border-brand-primaryColor border bg-brand-primaryColor rounded-md p-2 text-white flex items-center"
                onClick={showShareModal}
              >
                <FaRegShareFromSquare className="mx-3" />
                <span>Share Link</span>
              </button>
            </div>
          )}
          {!isEditScreenEnabled ? (
            <VideoConsultation
              selectedPatient={selectedPatient}
              fetchUrl={fetchUrl}
            />
          ) : (
            <div className="h-3/5 sticky mb-2 flex justify-center items-center">
              {/* <img src={VcImage} className="h-full" alt="Video Consultation" /> */}
              <FaVideoSlash className="w-36 h-36" />
            </div>
          )}
          {/* <div className="h-[50%] overflow-y-scroll">
            <div className="bg-slate-100 px-2 py-1 rounded mt-2">
              <div className="flex justify-between mb-2">
                <p className="text-sm mb-0 text-brand-primaryColor">
                  Patient Name:{" "}
                  <span className="font-light text-green-400">Gopal Krish</span>
                </p>
                <p className="text-sm mb-0 text-brand-primaryColor">
                  Mobile:{" "}
                  <span className="font-light text-green-400">9464738334</span>
                </p>
                <p className="text-sm mb-0 text-brand-primaryColor">
                  Age/Gender:{" "}
                  <span className="font-light text-green-400">45/M</span>
                </p>
              </div>
              <div className="flex justify-between">
                <p className="text-sm mb-0 text-brand-primaryColor">
                  E-mail:{" "}
                  <span className="font-light text-green-400">
                    krish457@gmail.com
                  </span>
                </p>
              </div>
            </div>
            <AddVitals consulationData={consulationData} handleAddVitals={handleAddVitals} />
          </div> */}
          <div className="bg-slate-100 px-2 py-1 rounded mt-2">
            <div className="flex justify-between mb-2">
              <p className="text-sm mb-0 text-brand-primaryColor">
                Patient Name:{" "}
                <span className="font-light text-green-400">
                  {(selectedPatientDetails?.firstName || selectedPatientDetails?.lastName) ? selectedPatientDetails?.firstName + " " + selectedPatientDetails?.lastName : ''}
                </span>
              </p>
              <p className="text-sm mb-0 text-brand-primaryColor">
                Mobile:{" "}
                <span className="font-light text-green-400">
                  {selectedPatientDetails?.mobile ? selectedPatientDetails?.mobile : ''}
                </span>
              </p>
              <p className="text-sm mb-0 text-brand-primaryColor">
                Age/Gender:{" "}
                <span className="font-light text-green-400">
                  {selectedPatientDetails?.age
                    ? selectedPatientDetails?.age
                    : selectedPatientDetails?.dob
                      ? age
                      : ""}{" "}
                  /
                  {selectedPatientDetails?.gender
                    ? selectedPatientDetails?.gender === "M"
                      ? "Male"
                      : "Female"
                    : ""}
                </span>
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-sm mb-0 text-brand-primaryColor">
                E-mail:{" "}
                <span className="font-light text-green-400">
                  {selectedPatientDetails?.email == null
                    ? ""
                    : selectedPatientDetails?.email}
                </span>
              </p>
            </div>
          </div>
          <AddVitals
            consulationData={consulationData}
            handleAddVitals={handleAddVitals}
          //vitalsArrObj={vitalsArrObj}
          />
        </div>
        <div className="w-[50%]">
          <div className="flex justify-between items-center">
            {/* <label className="font-bold text-lg"></label> */}
            <FaPrescription className="font-bold text-2xl" />
            <button className="p-2 rounded text-white" onClick={handleClick}>
              Medical Records
            </button>
          </div>
          <div className="p-2 border-2 m-1 rounded">
            <Collapse accordion>
              {items.map((item) => (
                <Panel
                  key={item.key}
                  header={item.label}
                  showArrow={false}
                  className="text-base font-bold bg-slate-100"
                >
                  {item.children}
                </Panel>
              ))}
            </Collapse>
            <Col span={24} className="mt-5">
              {/* <Form
                ref={formRef}
                initialValues={{
                  recommendation: consulationData?.recommendation
                }}
              > */}
              <div className="flex gap-5 w-full">
                <div className="w-3/5">
                  <label className="text-sm font-semibold">
                    Recommendation
                  </label>
                  <Form.Item name="recommendation">
                    <Input
                      className="h-12 mt-1"
                      onChange={handleRecommendation}
                    />
                  </Form.Item>
                </div>
              </div>
              {/* </Form> */}
            </Col>
            <div className="w-full flex justify-between items-center">
              <div className="w-2/5">
                <label className="text-sm font-semibold">
                  Schedule follow up
                </label>
                <DatePicker
                  className="h-12 w-full mt-1"
                  disabledDate={disabledDate}
                  onChange={handleFollowUpDate}
                  format={"MM/DD/YYYY"}
                />
              </div>
              <div className="w-2/4 flex gap-2 justify-end mt-5">
                <button
                  className="bg-[#26B144] disabled:opacity-50  rounded-md p-2 w-24 text-white"
                  onClick={handlePrescription}
                >
                  Preview
                </button>
                {selectedPatient?.consultationId &&
                  selectedPatient?.callMode === "EditCall" ? (
                  <button
                    onClick={handlePrescription}
                    className="bg-blue-600 border-2 border-blue-600 rounded-md p-2 w-32 text-white"
                  >
                    Update
                  </button>
                ) : (
                  <button
                    onClick={handlePrescription}
                    className="bg-blue-600 border-2 border-blue-600 rounded-md p-2 w-1/3 text-white"
                  >
                    End
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {/* patient queue design */}
        {isVerticalOpen ? (
          <div className="vertical" onClick={QueuehandleClick}>
            <p className="vertical-p">
              <div className="verical-icon">
                <SlArrowUp />
              </div>
              Patient Queue
            </p>
          </div>
        ) : (
          <Draggable>
            <div className="draggable-container">
              <div className="close-icon" onClick={closeCircle} style={{ top: "-25rem" }}><IoIosCloseCircle /></div>
              <div className="vertical-patient" style={{ top: "-22rem" }}>
                <span className="h-26 w-14 transition-all duration-100 ease-in-out patient">
                  <div className="relative mt-1">
                    <>
                      <img
                        src={patientqueue}
                        alt="patientqueue"
                        className="w-8 h-8 cursor-pointer bg-[#5584f4]"
                        title="Patient Queue"
                        onClick={showDrawer}
                      />
                    </>
                  </div>
                </span>
              </div>
            </div>
          </Draggable>
        )}

        {isDrawerOpen && (
          <div className="grid justify-end mt-5">
            {isDrawerOpen && (
              <PatientQueue
                queueData={queueData ? queueData : ""}
                onClose={onClose}
              />
            )}
          </div>
        )}
        {/* patient queue design end */}
      </div>
      <Modal
        width={"70%"}
        open={isPreviewModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        {
          (prescription && state?.selectedPatient) ?
            (
              <PrescriptionDetails
                prescription={prescription}
                selectedPatient={state?.selectedPatient}
              />
            )
            :
            (
              <div className="flex items-center justify-center mt-[10%]">
                <Flex align="center" gap="middle">
                  <Spin size="large" tip="Loading" />
                </Flex>
              </div>
            )
        }

      </Modal>
      <Modal
        title={
          <div className="text-2xl flex gap-2  items-center">
            <span>Share</span>
            {/* <span className="flex gap-2 cursor-pointer">
              <img
                src={whatsappIcon}
                alt="WhatsApp"
                className="w-8 h-8"
                onClick={() => handleShareoption("wp")}
              />
              <img
                src={Gmail}
                alt="Gmail"
                className="w-8 h-8"
                onClick={() => handleShareoption("mail")}
              />
              <img
                src={Message}
                alt="Message"
                className="w-8 h-8"
                onClick={() => handleShareoption("mobile")}
              />
            </span> */}
          </div>
        }
        open={shareModal}
        onCancel={() => {
          setShareModal(false);
          form.resetFields();
        }}
        footer={[]}
        height="15%"
      >
        <Form form={form} onFinish={handleShareLink} layout="vertical">
          {/* {selectShare === "wp" && (
            <Form.Item name="whatsApp" label="WhatsApp">
              <Input
                placeholder="Enter WhatsApp no."
                className="h-10"
                disabled
              />
            </Form.Item>
          )} */}

          {/* {selectShare === "mobile" && (
            <Form.Item name="mobile" label="Moblie">
              <Input placeholder="Enter Mobile no." className="h-10" />
            </Form.Item>
          )} */}

          {/* {selectShare === "mail" && (
            <Form.Item name="email" label="Email">
              <Input placeholder="Enter email" className="h-10" />
            </Form.Item>
          )} */}
          <Form.Item name="enteredVal">
            <Input placeholder="Enter" className="h-10" />
          </Form.Item>
          <Form.Item>
            <div className="flex justify-end">
              <Button
                disabled={isLoading}
                htmlType="submit"
                className="h-10 mt-2 text-white font-semibold p-2 rounded-md disabled:cursor-not-allowed"
              >
                Send
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        width={"90%"}
        title={
          <div className="text-2xl flex gap-2  items-center">
            <span>Medical Records</span>
          </div>
        }
        open={medicalOpen}
        onCancel={handleMedicalCancel}
        // bodyStyle={{ maxHeight: '90vh', overflowY: 'auto' }}
        footer={null}
      >
        <div className=" h-screen px-5 pt-5">
          {/* <div className="flex justify-between">
          <label className="font-bold text-lg">Medical Records</label>
          <button className="p-2 text-white rounded" onClick={handleBackClick}><ArrowLeftOutlined /> Back</button>
        </div> */}
          <div className="flex justify-center items-center mb-8 mt-5">
            <div className="w-7/12">

              <Segmented
                className="appSegment"
                onChange={handleOptionChange}
                value={selectedOption}
                block
                options={[
                  {
                    label: (
                      <Tooltip placement="top" title={!permissionData || permissionData.medicalHistory === 'N' ? 'Patient should provide permission to access medical history records.' : 'Medical History'}>
                        <div className="bg-[inherit] w-[inherit] h-[inherit] flex gap-2 disabled:cursor-not-allowed" >
                          <img src={medicalHistoryIcon} alt="Medical history" className="w-5 h-5 mt-1 text-white" />
                          Medical history
                        </div>
                      </Tooltip>
                    ),
                    value: "Medical history",
                    disabled: !permissionData || permissionData.medicalHistory === 'N',
                  },
                  {
                    label: (
                      <Tooltip placement="top" title={!permissionData || permissionData.prescription === 'N' ? 'Patient should provide permission to access prescription records.' : 'Prescription'}>
                        <div className="bg-[inherit] w-[inherit] h-[inherit] flex gap-2 disabled:cursor-not-allowed" >
                          <img src={medicalPrescriptionIcon} alt="Prescription" className="w-5 h-5 mt-1 text-white" />
                          Prescription
                        </div>
                      </Tooltip>
                    ),
                    value: "Prescription",
                    disabled: !permissionData || permissionData.prescription === 'N',
                  },
                  {
                    label: (
                      <Tooltip placement="top" title={!permissionData || permissionData.vitals === 'N' ? 'Patient should provide permission to access vitals records.' : 'Vitals'}>
                        <div className="bg-[inherit] w-[inherit] h-[inherit] flex gap-2 disabled:cursor-not-allowed" >
                          <img src={medicalVitalsIcon} alt="Vitals" className="w-5 h-5 mt-1 text-white" />
                          Vitals
                        </div>
                      </Tooltip>
                    ),
                    value: "Vitals",
                    disabled: !permissionData || permissionData.vitals === 'N',
                  },
                  {
                    label: (
                      <Tooltip placement="top" title={!permissionData || permissionData.reports === 'N' ? 'Patient should provide permission to access reports records.' : 'Reports'}>
                        <div className="bg-[inherit] w-[inherit] h-[inherit] flex gap-2 disabled:cursor-not-allowed" >
                          <img src={medicalReportsIcon} alt="Reports" className="w-5 h-5 mt-1 text-white" />
                          Reports
                        </div>
                      </Tooltip>
                    ),
                    value: "Reports",
                    disabled: !permissionData || permissionData.reports === 'N',
                  },
                ]}
              />
            </div>
          </div>
          {!permissionData || permissionData.vitals === 'N' && permissionData.reports === 'N'
            ? (
              <div className="w-full flex h-screen justify-center items-center">
                <div>
                  <img src={NoDataFound} alt="No Data" className="w-51 h-31" />
                  <p className="text-center ">Patient should provide permission to access medical records</p>
                </div>
              </div>
            ) : (
              <div className="relative p-1 h-full customHeight" style={{ maxHeight: "70vh", overflowY: 'auto' }}>
                {selectedOption === "Medical history" && <MedicalHistory permissionData={permissionData} selectedPatient={selectedPatient} />}

                {selectedOption === "Prescription" && <MyPresctription selectedPatient={selectedPatient} permissionData={permissionData} />}

                {selectedOption === "Vitals" && <MyVitals selectedPatient={selectedPatient} permissionData={permissionData} />}
                {selectedOption === "Reports" && <Reports selectedPatient={selectedPatient} permissionData={permissionData} />}
              </div>
            )}


        </div>
      </Modal>
    </Layouts>
  );
};

export default VCPage;
