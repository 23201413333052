import React, { useEffect, useState } from "react";
import { Button, Col, Input, Row, Space, Modal, Radio } from "antd";
import DashboardShape from "../../../Assets/Images/doctor.png";
import AddIcon from "../../../Assets/Images/Svg/add.svg";
import InviteIcon from "../../../Assets/Images/Svg/inviteicon.svg";
import NewPatientInvite from "./NewPatientInvite";
import ExistingPatient from "./ExistingPatient";
import { useForm } from "antd/es/form/Form";

function InviteCard({ appointmentsList }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [paymentType, setPaymentType] = useState("Cash"); //changed
  const [paymentReceived, setPaymentReceived] = useState(true); //changed
  const [form, setForm] = useForm();
  const [callType, setCallType] = useState("APPT_VIDEO");

  const [quickInvite, setQuickInvite] = useState(false);
  const [disableRequired, setDisableRequired] = useState(true);

  const [mobileNumber, setMobileNumber] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [radioValue, setRadioValue] = useState("newPatient");

  const storedLoginResult = localStorage.getItem("login_result");
  const loginResultObject = storedLoginResult
    ? JSON.parse(storedLoginResult)
    : null;

  const onChange = (e) => {
    setQuickInvite(false);
    setRadioValue(e.target.value);
    form.resetFields();
    setIsVisible(false);
    setMobileNumber("");
    setPaymentType("Cash"); // temporarily put it in commented because they want by default select will be Cash
    setCallType("APPT_VIDEO");
  };

  const handleKeyPress = (e) => {
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ];
    if (!allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    appointmentsList();
  }, []);

  const showModal = () => setIsModalOpen(true);

  const handleOk = () => {
    form.resetFields();
    setIsModalOpen(false);
    setMobileNumber("");
    appointmentsList();
    setPaymentType("Cash"); // temporarily changed because they want by default select will be Cash
    setQuickInvite(false);
    setPaymentReceived(true); // temporarily changed because they want by default Checked
    setDisableRequired(true);
    setRadioValue("newPatient");
    setCallType("APPT_VIDEO");
  };

  const handleCancel = () => {
    form.resetFields();
    setPaymentType("Cash"); // temporarily changed because they want by default select will be Cash
    setMobileNumber("");
    setIsModalOpen(false);
    setIsVisible(false);
    setRadioValue("newPatient");
    setQuickInvite(false);
    setPaymentReceived(true); // temporarily changed because they want by default Checked
    setDisableRequired(true);
    setCallType("APPT_VIDEO");
  };

  return (
    <div className="p-5 w-full">
      <Row gutter={30}>
        <Col xs={24} sm={24} md={18} lg={18}>
          <div className="text-white text-xl font-medium mb-4">
            {` Welcome, ${loginResultObject.user.salutation} ${loginResultObject.user.firstName} ${loginResultObject.user.lastName} ${loginResultObject.user.qualification}`}
          </div>
          <div className="md:flex text-white mb-7 gap-2 font-semibold">
            <div>Speciality : </div>
            <div className=" ">{loginResultObject.user.speciality}</div>
          </div>

          <div className="flex items-center gap-5 relative">
            <Space.Compact className="w-full">
              <Input value={"Send Invite"} readOnly />
              <Button
                onClick={showModal}
                className="flex items-center Btnwhite w-28 h-10"
              >
                <img src={AddIcon} alt="img" className="mr-2" />
                Invite
              </Button>
            </Space.Compact>
          </div>
        </Col>
        <Col span={6}>
          <div>
            <img
              className="md:block hidden w-[100%]"
              src={DashboardShape}
              alt="doctor"
            />
          </div>
        </Col>
      </Row>
      <Modal
        title={
          <div className="flex items-center border-b border-brand-colorBorder pb-4">
            <img src={InviteIcon} alt="img" />
            <h3 className="mb-0 text-lg font-semibold tracking-wider ml-3">
              Invite Link
            </h3>
          </div>
        }
        footer={[]}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width="50%"
        style={{
          top: 20,
        }}
      >
        <div>
          <Radio.Group onChange={onChange} value={radioValue}>
            <Radio value={"newPatient"}>Quick Patient</Radio>
            <Radio value={"existingPatient"}>Existing Patient</Radio>
            <Radio value={"domainPatient"}>Domain Patient</Radio>
          </Radio.Group>

          {radioValue === "newPatient" ? (
            <NewPatientInvite
              form={form}
              handleOk={handleOk}
              handleKeyPress={handleKeyPress}
              appointmentsList={appointmentsList}
              paymentType={paymentType}
              setPaymentType={setPaymentType}
              paymentReceived={paymentReceived}
              setPaymentReceived={setPaymentReceived}
              callType={callType}
              setCallType={setCallType}
              //
              quickInvite={quickInvite}
              setQuickInvite={setQuickInvite}
              disableRequired={disableRequired}
              setDisableRequired={setDisableRequired}
            />
          ) : (
            <ExistingPatient
              form={form}
              handleOk={handleOk}
              handleKeyPress={handleKeyPress}
              appointmentsList={appointmentsList}
              paymentType={paymentType}
              setPaymentType={setPaymentType}
              paymentReceived={paymentReceived}
              setPaymentReceived={setPaymentReceived}
              callType={callType}
              setCallType={setCallType}
              //
              setMobileNumber={setMobileNumber}
              mobileNumber={mobileNumber}
              radioValue={radioValue}
              isVisible={isVisible}
              setIsVisible={setIsVisible}
            />
          )}
        </div>
      </Modal>
    </div>
  );
}

export default InviteCard;
