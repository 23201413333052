import React, { useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import VitalService from "../../../../../Services/VitalService";

const CustomTooltip = ({ active, payload, label, type }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "white",
          padding: "10px",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
      >
        <p style={{ margin: 0, color: "black" }}>{`${label}`}</p>
        <p style={{ margin: 0, color: "blue" }}>{`${type}: ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};


const CustomLegendFormatter = (value, entry, type) => {
  return <span style={{ color: "blue" }}>{type}</span>;
};

function VitalChart(props) {
  const colors = ["rgb(54, 162, 235)", "rgb(75, 192, 192)", "rgb(255, 99, 132)"];
  const [vitalsData, setVitalsData] = useState([]);
  const activePatient = JSON.parse(localStorage.getItem("patient"));
  const patientCode = activePatient?.patientId;

const MyVitalList = () => {
  let payload = {
    patientId: patientCode,
    vitalCodes: props.vitalCode,
  };
  VitalService.getAllVitalsData(payload)
    .then((result) => {
      const last7Records = result.data.slice(0, 7);
      setVitalsData(last7Records);
    })
    .catch((error) => {
      console.error(error);
    });
};
useEffect(() => {
  MyVitalList();
}, []);

let dataset = [];
if (props.type) {
  // Aggregate data by date
  const aggregatedData = vitalsData.reduce((acc, d) => {
    const date = moment(d.recordedDateTime).format("MM/DD");
    if (!acc[date]) {
      acc[date] = { date, value: 0, count: 0 };
    }
    acc[date].value += d.value;
    acc[date].count += 1;
    return acc;
  }, {});

  // Convert aggregated data to array and compute average value
  const dataArray = Object.values(aggregatedData).map(item => ({
    date: item.date,
    value: item.value / item.count,  // Compute average
  })).reverse();

  dataset.push({
    name: props.type,
    data: dataArray,
    color: colors[0],
  });
} else if (props.types) {
  dataset = props.types?.map((type, i) => {
    const aggregatedData = vitalsData.reduce((acc, d) => {
      const date = moment(d.recordedDateTime).format("MM/DD");
      if (!acc[date]) {
        acc[date] = { date, [type]: 0, count: 0 };
      }
      acc[date][type] = (acc[date][type] || 0) + (d[type] ?? 0);
      acc[date].count += 1;
      return acc;
    }, {});

    const dataArray = Object.values(aggregatedData).map(item => ({
      date: item.date,
      [type]: item[type] / item.count,  // Compute average
    })).reverse();

    return {
      name: type,
      data: dataArray,
      color: colors[i % colors.length],
    };
  });
}
  

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-11/12 md:w-1/3 my-6 mx-auto max-w-3xl">
          <div className="rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="rounded-lg shadow-lg bg-brand-white w-full h-112 p-5 antialiased justify-between border border-gray-200">
              <div className="flex w-auto justify-between mb-4">
                <p className="text-medium font-medium text-2xl">
                  {props.title}
                </p>
                <div className="w-8 h-8 cursor-pointer">
                  <CloseOutlined onClick={props.closePopup} />
                </div>
              </div>
              <div>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart
                    data={dataset[0]?.data}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip content={<CustomTooltip type={props.type} />} />
                    <Legend
                      formatter={(value) =>
                        CustomLegendFormatter(value, null, props.type)
                      }
                    />
                    <Line type="monotone" dataKey="value" stroke={dataset[0]?.color} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </>
  );
}

export default VitalChart;
