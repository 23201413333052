import http from "./http-common";

class enrollmentServices {
  Invite(data) {
    return http.post(`/invitationDetails/save`, data);
  }

  InviteLinkMaster(id) {
    return http.get(`/inviteLinkMaster/list/filter?userId=${id}`);
  }

  InvitationShare(data) {
    return http.post(`/invitationDetails/invitationLink`, data);
  }

  PatientList(payload) {
    const queryString = Object.entries(payload)
      .filter(
        ([_, value]) => value !== undefined && value !== null && value !== ""
      )
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
    return http.get(`/patient/list/filter?${queryString}`);
  }

  StateMaster() {
    return http.get(`/stateMaster/list/filter?status=1`);
  }
}

export default new enrollmentServices();
