import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Table,
  message,
} from "antd";
import deleteicon from "../../../../../Assets/Images/Svg/deleteicon.svg";
import medicineImage from "../../../../../Assets/Images/Svg/addMedicine.svg";
import DrugServices from "../../../../../Services/DrugServices";
import { useForm } from "antd/es/form/Form";
import { render } from "@testing-library/react";
import { useLocation } from "react-router-dom";
// import { uniqueId } from "lodash";
// import ShortUniqueId from "short-unique-id";
import {generateUniqueId} from "../../../../../utils/generateUID";
const { Option } = Select;
const { Panel } = Collapse;

function AddMedicine({ handleAddMedicines, consulationData }) {
  const [activeKey, setActiveKey] = useState(["1"]);
  const [medicines, setMedicines] = useState([]);
  const [selectedMedicine, setSelectedMedicine] = useState(null);
  const [compositionVal, setCompositionVal] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [tabledata, setTabledata] = useState([]);
  const [currTableData, setCurrTableData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [searchedValue, setSearchedValue] = useState("");
  const [manualMedicine, setManualMedicine] = useState('');
  const [searchedManualMed, setSearchedManualMed] = useState('');
  // const uid = new ShortUniqueId({ length: 6 });

  const formRef = useRef();
  useEffect(() => {
    console.log(manualMedicine)
  }, [manualMedicine])
  useEffect(() => {
    if (consulationData) {
      setShowTable(true)
      setTabledata(consulationData?.drugPrescription)
      setCurrTableData(consulationData?.drugPrescription);
    }
  }, []);

  const onChange = (key) => {
    setActiveKey(key);
  };

  const handleChange = (label, value) => {
    if (value) {
      console.log("med from hand chan ", value.label, "label is ", label);
      const isValueThere = Object.keys(value).length === 0 ? true : false;
      if (!isValueThere) {
        DrugServices.fetchDrugList(value.label).then((res) => {
          console.log("resss ", res.data);
          if (res.data?.length !== 0) {
            setSelectedMedicine(res.data[0]);
            setCompositionVal(res.data[0]?.composition);
          }
          else {
            value.medicineName = value.label;
            value.composition = value.label;
            console.log("hiiii ", value);
            setSelectedMedicine(value);
            setCompositionVal(value.label);
          }

        }).catch((e) => {
          console.log(e);
        })
      }
      else {
        console.log(value, label);
        setManualMedicine(label[0]);
      }
    }
    else {
      setSelectedMedicine(null);
      setCompositionVal('');
    }
  };

  const onSearch = async (value) => {
    console.log(value);
    setSearchedValue(value);
    if (value.length !== 0) {
      try {
        setIsLoading(true);
        const res = await DrugServices.fetchDrugList(value);
        if (res) {
          if (res.data.length > 0) {
            setMedicines(res?.data);
          }
          else {
            console.log("from else valuw", value);
            setSearchedManualMed(value);
          }

          // else {
          //   let payload = []
          //   let x = { code: value, medicineName: value }

          //   payload.push(x)
          //   console.log(payload)
          //   setMedicines(payload)
          //   setSelectedMedicine(x)
          // }
        }
        setIsLoading(false);
      }
      catch (e) {
        console.error(e);
      }
    }
    else {
      setSearchedManualMed('');
    }

  };

  const manualSearch = async (e) => {
    console.log(e.target.value);
    if (e.target.value) {
      setSearchedManualMed(e.target.value);
    }
    else {
      setSearchedManualMed('');
    }
  }

  const handleDelete = (record) => {
    console.log(record,"............",tabledata);
    if (record?.consultationId) {
      console.log(tabledata, "     before deleting ", record);
      const updatedTableData = tabledata.filter((item) => item.status !== 0 && item.id !== record.id);
      const itemToBeDeletedIdx = tabledata.findIndex((item) => item.id === record.id);
      if (itemToBeDeletedIdx !== -1) {
        tabledata[itemToBeDeletedIdx].status = 0;
      }
      console.log(tabledata, "     after deletion  ");
      setCurrTableData([...updatedTableData])
      handleAddMedicines(tabledata);
    }
    else{
      const updatedTableData = tabledata.filter((item) => item.status !== 0 && item.id !== record.id);
      const medicinesDataOrg = tabledata.filter((item) =>  item.id !== record.id);
      setTabledata([...medicinesDataOrg]);
      setCurrTableData([...updatedTableData]);
      console.log(updatedTableData,"after deletion in newly added and final med",medicinesDataOrg);
      handleAddMedicines(medicinesDataOrg);
    }
  };

  const columns = [
    {
      title: "Medicine Name",
      dataIndex: "drugName",
      key: "drugName",
    },
    {
      title: "Composition",
      dataIndex: "composition",
      key: "composition",
      render: (_, record) => {
        return record?.composition?.length > 20 ?
          `${record?.composition?.slice(0, 20)}...` :
          record.composition
      }
    },
    {
      title: "Dosage",
      dataIndex: "dosage",
      key: "dosage",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Taken Time",
      dataIndex: "timeTaken",
      key: "timeTaken",
      render: (text, record) => (
        <span>
          {record.timeTaken === "afterfood" ? "After Food" : "Before Food"}
        </span>
      ),
    },
    {
      title: "Instruction",
      dataIndex: "instruction",
      key: "instruction",
    },
    {
      title: "",
      dataIndex: "delete",
      render: (_, record) => (
        <Space size="middle" className="">
          <div className="flex items-center gap-2 ">
            <div className="">
              <img
                src={deleteicon}
                alt="img"
                className="w-6 h-6"
                onClick={() => handleDelete(record)}
              />
            </div>
          </div>
        </Space>
      ),
    },
  ];

  const customHeaderContent = (
    <thead className="">
      <tr>
        <th className="bg-blue-100 py-3 text-B">Medicine Name</th>
        <th className="bg-blue-100 py-3 text-B">Composition</th>
        <th className="bg-blue-100 py-3 text-B">Dosage</th>
        <th className="bg-blue-100 py-3 text-B">Duration</th>
        <th className="bg-blue-100 py-3 text-B">Taken Time</th>
        <th className="bg-blue-100 py-3 text-B">Notes</th>
        <th className="bg-blue-100 py-3 text-B"></th>
      </tr>
    </thead>
  );

  const handleManualSearch = (e) => {
    console.log(e.target.value);
    setManualMedicine(e.target.value);
  }


  const handleFormSubmit = (e) => {
    e.preventDefault();
    let { drugName, composition, dosage, duration, timeTaken, notes } = formRef.current.getFieldValue();
    console.log(selectedMedicine);
    console.log(manualMedicine, compositionVal);
    let isMedicineExist;
    if (!searchedManualMed) {
      isMedicineExist = tabledata?.findIndex((med) => med.drugCode === selectedMedicine.code);
    }
    if (!searchedManualMed && isMedicineExist !== -1) {
      message.error("Medicine already exist in the prescription!");
      return;
    }
    let medicineData = {
      code: selectedMedicine ? selectedMedicine?.code : searchedManualMed,
      drugName: selectedMedicine ? selectedMedicine?.medicineName : searchedManualMed,
      composition: selectedMedicine?.composition ? selectedMedicine?.composition : compositionVal,
      dosage,
      duration,
      timeTaken,
      instruction: notes,
      status:1
    };

    medicineData.id = generateUniqueId();
    console.log("med  ", medicineData, tabledata);
    setTabledata((prevTableData) => [...prevTableData, medicineData]);
    setCurrTableData((prevTableData) => [...prevTableData, medicineData]);
    handleAddMedicines([...tabledata, medicineData]);
    formRef.current.resetFields();
    setManualMedicine('');
    setSearchedManualMed('');
    setCompositionVal('');
    setShowTable(true);
    setSelectedMedicine(null);

  };

  const options = [
    "0-0-1",
    "0-1-1",
    "0-1-0",
    "1-1-1",
    "1-0-0",
    "1-0-1",
    "1-1-0",
    "1-1-1-1",
    "1-0-0-1",
    "1-0-0-0",
    "1-1-0-0",
    "1-1-1-0",
    "0-0-0-1",
    "0-0-1-1",
    "0-0-1-0",
    "0-1-0-0",
    "0-1-1-1",
    "s.o.s",
    "q.wk.",
    "b.i.d.",
    "t.i.d.",
    "q.i.d.",
    "q.h.s.",
    "5X a day",
    "q.4h",
    "q.6h",
    "q.0.d.",
    "prn.",
    "q.d.",
    "I.M.",
    "Subq.",
    "Rectally",
    "I.V.",
    "O.D.",
    "O.S.",
    "O.U.",
    "2 ml",
    "2.5 ml",
    "5 ml",
    "10 ml",
  ];

  return (
    <Collapse activeKey={activeKey} onChange={onChange} bordered={false}>
      <Panel
        header={
          <div className="flex justify-between items-center">
            <div className="flex gap-2 items-center">
              <div>
                <img src={medicineImage} alt="medicineImg" />
              </div>
              <div className="font-bold">Medicines</div>
            </div>
            {/* <div>
              <Button
                onClick={() => setActiveKey(["1"])}
                className=" text-white "
                style={{ backgroundColor: "#1D64C8" }}
              >
                + Add
              </Button>
            </div> */}
          </div>
        }
        key="1"
        showArrow={false}
        collapsible={false}
      >
        <Form layout="vertical" ref={formRef}>
          <Row gutter={24} className="mt-2 mx-4 flex items-center">
            <Col span={8}>
              <Form.Item
                label="Medicine Name"
                name="drugName"
                className="mx-4"
              >

                <Select
                  showSearch
                  key={manualMedicine}
                  placeholder="Enter Medicine Name"
                  className={`w-full h-10 medicineSearch ${!searchedManualMed ? 'block' : 'hidden'}`}
                  onSearch={onSearch}
                  onChange={handleChange}
                  // value={selectedMedicine?.code || ''}
                  // defaultValue={searchedValue}
                  options={
                    medicines && medicines.map((medicine) => {
                      return {
                        label: medicine.medicineName,
                        value: medicine.code,
                      };
                    })
                  }
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }

                  notFoundContent={isLoading ? <div className="text-center"><Spin size="small" /></div> : null}
                  allowClear
                />
                <Input type="text" value={searchedManualMed} className={`${searchedManualMed ? 'block' : 'hidden'}`} onChange={manualSearch} />


              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Dosage" name="dosage" className="mx-4">
                <Select style={{ width: 200 }}>
                  {options.map((option, index) => (
                    <Option key={index} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={3} className="flex items-center">
              <Form.Item rules={[
                {
                  type: 'number',
                  min: 1,
                  message: 'Duration must be at least 1'
                }
              ]} label="Duration" name="duration" className="mx-1">
                <InputNumber />
              </Form.Item>
              <span style={{ color: "#BEC0C3" }}>Days</span>
            </Col>
            <Col span={6}>
              <Form.Item label="To be Taken" name="timeTaken" className="mx-4">
                <Radio.Group
                  buttonStyle="solid"
                  options={[
                    { label: "After Food", value: "afterfood" },
                    { label: "BeforeFood", value: "beforefood" },
                  ]}
                  optionType="button"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} className="flex items-center">
            <Col span={8}>
              <Form.Item label="Composition" className="mx-4">
                <input
                  key={selectedMedicine}
                  className={`h-10 w-full rounded-md border-gray-200 px-4 outline-none border ${searchedManualMed ? 'hidden' : 'block'}`}
                  value={selectedMedicine?.composition}
                />
                <Input
                  className={`h-10 w-full rounded-md border-gray-200 px-4 outline-none border ${searchedManualMed ? 'block' : 'hidden'}`}
                  onChange={(e) => setCompositionVal(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Notes" name="notes" className="mx-5">
                <Input maxLength={25} className=" h-12" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <div>
                {/* <button className="bg-white border-2 border-blue-600 rounded-md p-2 w-24 text-black">
                    Cancel
                  </button> */}
                <button
                  disabled={(!selectedMedicine && !searchedManualMed)}
                  onClick={handleFormSubmit}
                  className="bg-blue-600 border-2 border-blue-600 rounded-md p-2.5 w-32 text-white"
                >
                  Add Medicine
                </button>
              </div>
            </Col>
          </Row>
          <Row gutter={24}>
            {/* <Col span={4}>
              <Form.Item label="Dosage" name="dosage" className="mx-4">
                <Select style={{ width: 200 }}>
                  {options.map((option, index) => (
                    <Option key={index} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Duration" name="duration" className="mx-4">
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="To be Taken" name="timeTaken" className="mx-4">
                <Radio.Group
                  buttonStyle="solid"
                  options={[
                    { label: "After Food", value: "afterfood" },
                    { label: "BeforeFood", value: "beforefood" },
                  ]}
                  optionType="button"
                />
              </Form.Item>
            </Col> */}
          </Row>

        </Form>

        <Row gutter={24}>
          <Col span={24}>
            <div
              className={`border border-brand-textGray ${showTable ? "block" : "hidden"
                } rounded-lg mx-4 my-6 relative`}
            >
              <Table
                className="addMedicine"
                columns={columns}
                dataSource={currTableData}
                pagination={false}
                components={{
                  header: {
                    wrapper: (props) => customHeaderContent,
                  },
                }}
              />
            </div>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
}

export default AddMedicine;