import React from "react";
import DropdownArrowIcon from "../../../Assets/Images/Svg/dropdownArrow.svg";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  message,
} from "antd";
import { useForm } from "antd/es/form/Form";
import AppointmentService from "../../../Services/AppointmentService";
import moment from "moment";
import dayjs from "dayjs";

function UpcomingAppointmentFilter({
  handleOk,
  handleCancel,
  setUpcomingList,
  setFilterDate,
  setMaintainbg,
  setApiPayload,

  setUpcomingStatusValue,
  setUpcomingTypeValue,
}) {
  const storedLoginResult = localStorage.getItem("login_result");
  const loginResultObject = storedLoginResult
    ? JSON.parse(storedLoginResult)
    : null;

  const [form] = useForm();

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowDate = tomorrow.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const months = new Date();
  months.setDate(months.getDate() + 30);
  const futureDate = months.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });

  const handleFinish = (payload) => {
    if (
      !payload.patientId &&
      !payload.serviceCode &&
      !payload.toDate &&
      !payload.fromDate
    ) {
      message.error("Enter Anything to Filter Appointments");
      return;
    }

    payload.userId = loginResultObject.user.userId;
    payload.fromDate = payload.fromDate
      ? dayjs(payload.fromDate, "MM/DD/YYYY").format("MM/DD/YYYY")
      : tomorrowDate;

    payload.toDate = payload.toDate
      ? dayjs(payload.toDate, "MM/DD/YYYY").format("MM/DD/YYYY")
      : null;

    if (
      payload.toDate &&
      dayjs(payload.toDate).isBefore(dayjs(payload.fromDate))
    ) {
      message.error("To date cannot be before the from date.");
      return;
    }

    if (
      payload.fromDate &&
      payload.toDate &&
      dayjs(payload.fromDate).isSame(dayjs(payload.toDate))
    ) {
      message.error("From date and to date cannot be the same.");
      return;
    }
    setApiPayload(payload);

    AppointmentService.AppointmentFilter(payload)
      .then((result) => {
        setUpcomingStatusValue("");
        setUpcomingTypeValue("1");
        setUpcomingList(result.data);
        if (payload.fromDate && payload.toDate) {
          setMaintainbg(false);
          setFilterDate(
            `${dayjs(payload.fromDate, "MM/DD/YYYY").format(
              "DD/MM/YYYY"
            )} - ${dayjs(payload.toDate, "MM/DD/YYYY").format("DD/MM/YYYY")}`
          );
        }
        if (payload.fromDate && !payload.toDate) {
          setMaintainbg(false);
          setFilterDate(
            `${dayjs(payload.fromDate, "MM/DD/YYYY").format("DD/MM/YYYY")}`
          );
        }

        if (!payload.fromDate && payload.toDate) {
          setMaintainbg(false);
          setFilterDate(
            `${dayjs(payload.toDate, "MM/DD/YYYY").format("DD/MM/YYYY")}`
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
    form.resetFields();
    handleOk();
  };

  const disabledDate = (current) => {
    return current && current < dayjs().endOf("day");
  };

  const disabledToDate = (current) => {
    const tomorrow = new Date(today);
    return current && current >= tomorrow.setHours(0, 0, 0, 0);
  };

  return (
    <div>
      <Form
        className="formDesign mb-10"
        name="trigger"
        onFinish={handleFinish}
        layout="vertical"
        form={form}
      >
        <Row gutter={20}>
        <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
          >
            <Form.Item label="Patient Id" name="patientId">
              <Input className="w-full" />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
          >
            <Form.Item label="Select From date" name="fromDate">
              <DatePicker
                disabledDate={disabledDate}
                className="w-full"
                format="DD/MM/YYYY"
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
          >
            <Form.Item label="Select To Date" name="toDate">
              <DatePicker
                disabledDate={disabledDate}
                className="w-full"
                span={20}
                format="DD/MM/YYYY"
              />
            </Form.Item>
          </Col>

          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
          >
            <Form.Item label="Type of Service" name="serviceCode">
              <Select
                className="w-full h-10"
                suffixIcon={<img src={DropdownArrowIcon} alt="img" />}
              >
                <Select.Option value="APPT_INPERSON">In-person</Select.Option>
                <Select.Option value="APPT_VIDEO">Video Call</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <div className="flex justify-end gap-3 pt-10">
        <Button className="h-10 Btnwhite md:px-8" onClick={handleCancel}>
            Cancel
          </Button>
          <Button className="h-10 px-10" type="primary" htmlType="submit">
            Filter Appointments
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default UpcomingAppointmentFilter;
