import React, { useState } from "react";
import { Button, Input, message, Modal, Spin, Tooltip } from "antd";
import ConsultationService from "../../../Services/ConsultationService";
import medicineImage from "../../../Assets/Images/Svg/addMedicine.svg";
import labReports from "../../../Assets/Images/Svg/addMedicine@.svg";
import MedicineDisabled from "../../../Assets/Images/Svg/MedicineDisabled.svg";
import LabDisabled from "../../../Assets/Images/Svg/LabDisabled.svg";
import PharmacyService from "../../../Services/PharmacyService";

function ShareOrders({ record }) {
  const [loadingType, setLoadingType] = useState(null); // null means no button is loading

  const storedLoginResult = localStorage.getItem("login_result");
  const loginResultObject = storedLoginResult
    ? JSON.parse(storedLoginResult)
    : null;

  const handleOrderShare = async (type) => {
    setLoadingType(type);
    let pharmaListPayload = {
      userId: loginResultObject.user.userId,
      status: 1,
    };

    let LinkPayload = {
      consultationId: record?.consultationId,
      patientId: record.patientId,
      userId: loginResultObject.user.userId,
      appointmentId: record.appointmentId,
      prescriptionShareType: type,
    };

    try {
      let pharmaList1 = await PharmacyService.GetPharmacyList(
        pharmaListPayload
      );

      let pharmaList = pharmaList1.data[0];
      LinkPayload.pharmacyId = pharmaList.pharmacyId;
      LinkPayload.pharmacyName = pharmaList.pharmacyName;
      LinkPayload.pharmacyMailId = pharmaList.pharmacyMailId;
      LinkPayload.pharmacyMobile = pharmaList.pharmacyMobile;

      console.log("link payloa", LinkPayload);

      setLoadingType(type);
      try {
        const response = await ConsultationService.SharePharmaLabAPI(
          LinkPayload
        );
        if (LinkPayload.prescriptionShareType === "p") {
          message.success(
            "Medical Prescription shared successfully to Pharmacy"
          );
        } else {
          message.success("Lab Diagnostics shared successfully to Pharmacy");
        }
        setLoadingType(null);
      } catch (error) {
        console.error("Error sharing prescription:", error);
        message.error("Failed to share prescription. Please try again.");
        setLoadingType(null);
      }
    } catch (error) {
      console.log("error", error);
    }

    return;
  };

  return (
    <>
      {record.transactionId ? (
        <>
          <Tooltip placement="top" title={"Share Medicine Order to Medplus"}>
            {loadingType === "p" ? (
              <Spin />
            ) : (
              <img
                src={medicineImage}
                alt="Preview"
                className="w-8 h-8"
                role="button"
                onClick={() => handleOrderShare("p")}
              />
            )}
          </Tooltip>
          <Tooltip placement="top" title={"Share Lab Diagnostics"}>
            {loadingType === "l" ? (
              <Spin />
            ) : (
              <img
                src={labReports}
                alt="Share"
                className="w-8 h-8"
                role="button"
                onClick={() => handleOrderShare("l")}
              />
            )}
          </Tooltip>
        </>
      ) : (
        <>
          <Tooltip
            placement="top"
            title={"Payment is not completed on Patient Side"}
          >
            <img
              src={MedicineDisabled}
              alt="Preview"
              className="w-8 h-8 cursor-not-allowed"
              role="button"
            />
          </Tooltip>

          <Tooltip
            placement="top"
            title={"Payment is not completed on Patient Side"}
          >
            <img
              src={LabDisabled}
              alt="Share"
              className="w-8 h-8 cursor-not-allowed"
              role="button"
            />
          </Tooltip>
        </>
      )}
    </>
  );
}

export default ShareOrders;
