import React, { useEffect, useState } from "react";
import patientImage from "../../../../Assets/Images/alex-suprun-ZHvM3XIOHoE-unsplash 1.jpg";
import { Divider } from "antd";
import PatientService from "../../../../Services/PatientService";
import { FaUser } from "react-icons/fa";
import { RiNurseFill } from "react-icons/ri";
import StateMasterService from "../../../../Services/StateMasterService";

function PatientInfo({ selectedPatient }) {
  const [selectedPatientDetails, setSelectedPatientDetails] = useState(RiNurseFill);
  const [age,setAge] = useState(null);
  const [states,setStates] = useState([]);


  useEffect(() => {
    getStateList();
    let payload = {
      patientId: selectedPatient.patientId,
    };
    PatientService.PatientListFilter(payload)
      .then((result) => {
        setSelectedPatientDetails(result.data[0]);
        const dobData = result.data[0].dob;
        const dobYear = dobData?.split('/')[2];
        const currYear = new Date().getFullYear();
        console.log("age ",Number(dobYear));
        if(dobYear){
          const patientAge = currYear - Number(dobYear);
          console.log("age ",patientAge);
          setAge(patientAge);
        }
        
      })
      .catch((error) => {
        console.error('Error fetching patient list:', error);
      });
  }, [selectedPatient.patientId]);

  const getStateList = () => {
    let payload = {
      status: 1,
    };

    StateMasterService.StateMaster(payload).then((res) => {
      setStates(res.data);
    });
  };

  return (
    <div>
      <div key={selectedPatientDetails.id} className="flex justify-around mt-4 text-lg">
        <div>
          {/* <img src={patientImage} alt="patient Img" className="rounded-full" /> */}
          <FaUser className="md:w-8 w-6 md:h-8 h-6" />
        </div>
        <div className="flex flex-col">
          <span className="font-extrabold text-lg">{selectedPatientDetails.firstName}</span>
          <span>{selectedPatientDetails.patientId}</span>
        </div>
        <div className="flex flex-col ">
          <span>Gender : {selectedPatientDetails.gender ? selectedPatientDetails.gender === "M"?"Male":"Female" : "N/A"}</span>
          <span>Age : {selectedPatientDetails.age ? selectedPatientDetails.age :selectedPatientDetails.dob? age:"N/A"}</span>
          <span>DOB : {selectedPatientDetails.dob ? selectedPatientDetails.dob.slice(0,11) : "N/A"}</span>
        </div>
        <div>
          <Divider type="vertical" className="h-20 rounded-sm w-1 bg-slate-700" />
        </div>
        <div className="flex flex-col ">
          <span>Location :{selectedPatientDetails.state == null ? "N/A" : states?.find((state)=> state?.stateId === selectedPatientDetails?.state)?.stateName?states?.find((state)=> state?.stateId === selectedPatientDetails?.state)?.stateName:selectedPatientDetails?.state}</span>

          <span>Email ID :{selectedPatientDetails.email == null ? "N/A" : selectedPatientDetails.email}</span>

        </div>
      </div>
    </div>
  );
}

export default PatientInfo;
