import { Button, Modal, Space, Table, Form, DatePicker, Input } from "antd";
import React, { useEffect, useState } from "react";
import PrescriptionDetails from "./PrescriptionDetails";
import ConsultationService from "../../../../../Services/ConsultationService";
import DoctorService from "../../../../../Services/DoctorService";
import PatientService from "../../../../../Services/PatientService";
import NoDataFound from "../../../../../Assets/Images/Svg/No data-found.svg"
import dayjs from "dayjs";
import { useForm } from "antd/es/form/Form";
import moment from "moment";
import { FaFilter } from "react-icons/fa6";

function ViewPrescription({ selectedPatient }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [prescriptionsList, setPrescriptionsList] = useState([]);
  const [selectedPrescription, setSelectedPrescription] = useState({});
  const [loading, setLoading] = useState(true);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [form, setForm] = useForm();;
  const [toDate, setToDate] = useState("");

  const storedLoginResult = localStorage.getItem("login_result");
  const loginResultObject = storedLoginResult
    ? JSON.parse(storedLoginResult)
    : null;

  useEffect(() => {
    const fetchPermittedData = async () => {
      await fetchPatientPermission();
    }
    fetchPermittedData();
  }, []);

  const showModal1 = () => {
    setIsModalOpen1(true);
    form.resetFields();
  };
  const handleCancel1 = () => {
    setIsModalOpen1(false);
    form.resetFields();
  };
  useEffect(() => {
    getRecordList();
  }, []);

  const activePatient = JSON.parse(localStorage.getItem("patient"));
  console.log(activePatient, "activePatientpatient")
  //const patientCode = activePatient?.patientId;

  const patientName = `${activePatient?.salutation || ""} ${activePatient?.firstName || ""
    } ${activePatient?.lastName || ""}`.trim();

  const updatedPrescriptionsList = prescriptionsList.map((prescription) => ({
    ...prescription,
    patientName: patientName,
  }));

  const fetchPatientPermission = async () => {
    const userObj = localStorage.getItem('login_result');
    const userData = userObj ? JSON.parse(userObj) : null;
    const patientObj = localStorage.getItem("patient");
    const patientData = patientObj ? JSON.parse(patientObj) : null;

    const currDate = new Date().toISOString();
    const date = currDate.split('T')[0];
    const time = currDate.split('T')[1].slice(0, 8);
    const constructedTime = date + " " + time;

    let permissionPayload = {
      patientId: patientData.patientId,
      userId: userData?.user?.userId,
      accessDate: constructedTime,
      status: 1
    }
    try {
      const response = await PatientService.isPermissionGranted(permissionPayload);
      if (response?.data?.length !== 0 && response?.data[0]?.prescription === "Y") {
        fetchAllPrescriptions(response?.data[0]?.prescription);
      }
      else if (response?.data?.length !== 0 && response?.data[0]?.prescription === "N") {
        fetchAllPrescriptions(response?.data[0]?.prescription);
      }
      else {
        fetchAllPrescriptions(null);
      }

    }
    catch (e) {
      console.log(e);
    }
  }


  console.log("prescriptionsList ", prescriptionsList);


  // const fetchAllPrescriptions = async (permission) => {
  //   console.log(permission,"permmmm");
  //   const userData = JSON.parse(localStorage.getItem('login_result'));
  //   let payload = {
  //     // account_Id: userData?.user?.account_Id,
  //     // clinic_Id: userData?.user?.clinic_Id,
  //     patientId: selectedPatient?.patientId,
  //     userId: (permission === null || permission === "N")  ? userData?.user?.userId : '',
  //     detailsYN: "Y",
  //   }
  //   const response = await ConsultationService.getPrescriptions(payload);
  //   console.log("response.data ", response);
  //   setPrescriptionsList(response.data);
  // }

  const fetchAllPrescriptions = async (permission) => {
    const userData = JSON.parse(localStorage.getItem('login_result'));
    let payload = {
      userId: (permission === null || permission === "N") ? userData?.user?.userId : '',
      patientId: selectedPatient?.patientId,
      detailsYN: "Y",
      status:2
    };
    setLoading(true);
    await ConsultationService.getPrescriptions(payload)
      .then((result) => {
        setPrescriptionsList(result.data);
        console.log(result.data, "Aaa")
      })
      .catch((error) => {
        console.error("Failed to fetch reports:", error);
        setPrescriptionsList([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClick = (presc) => {
    setIsModalOpen(true);
    console.log("hiiiiii ", presc);
    setSelectedPrescription(presc);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    console.log("Updated prescriptionsList:", prescriptionsList);
  }, [prescriptionsList]);
  const handleFinish = async() => {
    let payload = form.getFieldValue();
    payload.userId = loginResultObject.user.userId;
    payload.fromDate = fromDate;
    payload.toDate = toDate;
    console.log("payloadss", payload);

   await ConsultationService.getPrescriptions(payload)
    .then((result) => {
      setPrescriptionsList(result.data);
    })
    .catch((error) => {
      console.error("Failed to fetch reports:", error);
      setPrescriptionsList([]);
    })
    handleCancel1()
  }
  const columns = [
    {
      title: "Patient Name",
      dataIndex: "patientName",
    },

    {
      title: "Appointment Id",
      dataIndex: "appointmentId",
    },
    {
      title: "Consultation Reason",
      dataIndex: "consultationReason",
    },
    {
      title: "Doctor Name",
      dataIndex: "userId",
      render: (_, record) => (
        <Space size="middle" className="">
          {
            record.userName
          }
        </Space>
      )

    },
    {
      title: "Clinic Name",
      dataIndex: "clinic_Name",
    },
    {
      title: "Date",
      dataIndex: "consultationDate",
      render: (_, record) => (
        <Space size="middle" className="">
          {
            record.consultationDate.slice(0, 10)
          }
        </Space>
      )
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <Space size="middle" className="">
          <button
            className="p-2 rounded-md text-white flex items-center justify-center"
            onClick={() => handleClick(record)}
          >
            View prescription
          </button>
        </Space>
      ),
    },
  ];
  const formatDate = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };
  const getRecordList = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const firstDate = new Date(year, month, 1);
    const lastDate = new Date(year, month + 1, 0);

    setFromDate(formatDate(firstDate));
    setToDate(formatDate(lastDate));

    let payload = {
      userId: loginResultObject.user.userId,
      fromDate: formatDate(firstDate),
      toDate: formatDate(lastDate),
      pageNumber: 0,
    };

    // setApiPayload(payload);

    ConsultationService.getPrescriptions(payload)
      .then((res) => {
        console.log("record data", res.data);
        setPrescriptionsList(res.data);
      })
      .catch((error) => {
        console.error("Error fetching billing records:", error);
      });


  };
  const handleMonthFilter = (value) => {
    const formattedDate = "01";
    const formattedMonth = value.format("MM");
    const formattedYear = value.format("YYYY");
    const fromDate = `${formattedMonth}/${formattedDate}/${formattedYear}`;
    const fromDateMoment = moment(fromDate, "MM/DD/YYYY");
    const lastDayOfMonth = fromDateMoment.endOf("month").format("MM/DD/YYYY");
    const toDate = lastDayOfMonth;

    setFromDate(fromDate);
    setToDate(toDate);

    let payload = {
      userId: loginResultObject.user.userId,
      fromDate: fromDate,
      toDate: toDate,
      patientId: selectedPatient?.patientId,
      detailsYN: "Y",

    };

    //setApiPayload(payload);
    ConsultationService.getPrescriptions(payload)
      .then((result) => {
        setPrescriptionsList(result.data);
        console.log(result.data, "kaa")
      })
      .catch((error) => {
        console.error("Error fetching billing records:", error);
      });

  };

  return (
    <div>
      <div className="w-full flex justify-end mb-4">
        <DatePicker
          onChange={handleMonthFilter}
          picker="month"
          format="MMM YYYY"
          defaultValue={dayjs()}
          allowClear={false}
        />
        <div className="flex ml-4 items-center justify-end">
          <button
            style={{ position: "relative", left: "0rem" }}
            className="text-white font-semibold p-2 rounded-md flex items-center "
            onClick={showModal1}
          >
            <FaFilter className="mr-1" />
            Filter
          </button>
        </div>
      </div>
      {loading ? (
        <div className="w-full flex h-screen justify-center items-center">
          <div className="spinner"></div>
        </div>
      ) : !prescriptionsList || prescriptionsList.length === 0 ? (
        <div className="w-full flex flex-col h-screen justify-center items-center">
          <img src={NoDataFound} alt="No Data" style={{ height: "12rem", width: "19rem" }} />
          <p className="mt-4">No prescriptions found for the selected month.</p>
        </div>
      ) : (
        <Table dataSource={updatedPrescriptionsList} columns={columns} />
      )}

      {/* <div className={`container w-[600px]  rounded-md shadow-gray-500 shadow-md mx-auto item-center justify-center p-4 ${isMessageModalOpen ? 'flex' : 'hidden'}`}>
        <div className="h-full">
          <span className="bg-yellow-300 text-black font-bold p-1 rounded-lg w-6 h-6">
            !
          </span>{" "}
          Patient needs to provide permission to view prescriptions.
        </div>
      </div> */}
      <Modal
        title={<span className="font-bold">Filter</span>}
        className="commonModal"
        footer={false}
        open={isModalOpen1}
        onOk={showModal1}
        onCancel={handleCancel1}
      >
        <Form
          layout="vertical"
          autoComplete="off"
          className=" grid grid-cols-2 gap-5"
          form={form}
          onFinish={handleFinish}
        >
          <Form.Item className="mb-0" label="Doctor Name" name="userName">
            <Input className="w-full"
            />
          </Form.Item>
          <Form.Item className="mb-0" label="Clinic Name" name="clinic_Name">
            <Input className="w-full"
            />
          </Form.Item>
          <Form.Item>
            <div className="flex justify-end  border-brand-colorBorder gap-3 pt-2" style={{position:"relative",left:"16rem"}}>
              <Button className="h-10 Btnwhite px-8" onClick={handleCancel1}>
                Cancel
              </Button>
              <Button className="h-10 px-10" type="primary" htmlType="submit">
              Submit
              </Button>
            </div>
          </Form.Item>
        </Form>
       
        {/* <div className=" flex justify-end gap-3 mt-3 ">
          <Button
            onClick={handleCancel1}
            className="bg-White border-primaryColor border rounded-md text-base text-white p-2"
          >
            Cancel
          </Button>
          <Button
           type="primary" htmlType="submit"
            className="bg-brand-primaryColor p-2 text-base rounded-md text-white"
          >
            Submit
          </Button>
        </div> */}
      </Modal>
      <Modal
        // title="Prescription Details"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ top: 40 }}
        width={"90%"}
        footer={[]}
      >
        <PrescriptionDetails prescription={selectedPrescription} selectedPatient={selectedPatient} />
      </Modal>
    </div>
  );
}

export default ViewPrescription;
