import React, { useState } from "react";
import shareIcon from "../../../Assets/Images/Svg/shareIcon.svg";
import { Button, Input, message, Modal, Tooltip } from "antd";
import ConsultationService from "../../../Services/ConsultationService";

function SharePrescription({ record, className, paypendingClass }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [enteredValue, setEnteredValue] = useState("");
  const [loading, setLoading] = useState(false);

  const storedLoginResult = localStorage.getItem("login_result");
  const loginResultObject = storedLoginResult
    ? JSON.parse(storedLoginResult)
    : null;

  const showModal = () => {
    setIsModalOpen(true);
    setEnteredValue("");
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setEnteredValue("");
  };

  const handleShare = async () => {
    setLoading(true);
    if (enteredValue === "") {
      message.error("Enter patient Email Id or Mobile Number");
      setLoading(false);
      return;
    }

    var validation =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;
    var number = /^\d+$/;
    let payload = {};
    setLoading(true);
    if (validation.test(enteredValue)) {
      if (number.test(enteredValue)) {
        payload.patientMobile = enteredValue;
      } else {
        payload.patientEmail = enteredValue;
      }
      payload.consultationId = record.consultationId;
      payload.patientId = record.patientId;
      payload.userId = loginResultObject.user.account_Id;
      payload.appointmentId = record.appointmentId;

      try {
        const response = await ConsultationService.SharePrescriptionAPI(
          payload
        );
        message.success("Prescription shared successfully!");
        handleCancel();
        setLoading(false);
      } catch (error) {
        console.error("Error sharing prescription:", error);
        message.error("Failed to share prescription. Please try again.");
        setLoading(false);
      }
    } else {
      message.error("Enter Valid Email Id or Mobile Number");
      setLoading(false);
    }
  };

  const handleValueChange = (e) => {
    setEnteredValue(e.target.value);
  };

  return (
    <>
      {record.transactionId ? (
        <img
          src={shareIcon}
          alt="Share"
          className={className}
          role="button"
          onClick={showModal}
        />
      ) : (
        <Tooltip placement="top" title={"Payment is pending"}>
          <img
            src={shareIcon}
            alt="Share"
            className={paypendingClass}
            role="button"
          />
        </Tooltip>
      )}

      <Modal
        title="Share Prescription"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
      >
        <div className="flex gap-2">
          <Input
            placeholder="Enter Email Id or Mobile Number"
            onChange={handleValueChange}
            value={enteredValue}
          />
          <Button
            className="text-white"
            onClick={handleShare}
            disabled={loading}
          >
            Share
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default SharePrescription;
