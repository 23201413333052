import {
  Col,
  Input,
  Row,
  Form,
  DatePicker,
  Select,
  Checkbox,
  TimePicker,
  Button,
  message,
  Table,
  Radio,
} from "antd";
import React, { useState } from "react";
import PatientService from "../../../Services/PatientService";
import CommonFunctions from "../../Shared/CommonFunctions";
import enrollmentServices from "../../../Services/enrollmentServices";
import dayjs from "dayjs";

function ExistingPatient({
  handleOk,
  form,
  setMobileNumber,
  mobileNumber,
  handleKeyPress,
  appointmentsList,
  radioValue,
  isVisible,
  setIsVisible,
  paymentType,
  setPaymentType,
  paymentReceived,
  setPaymentReceived,
  callType,
  setCallType,
}) {
  const [filteredPatient, setFilteredPatient] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [save, setSave] = useState(false);
  const [endTime, setEndTime] = useState(null);
  const [submitType, setSubmitType] = useState(null);

  const onPaymentTypeChange = (value) => {
    setPaymentType(value);

    if (value === "Cash") {
      //changed
      setPaymentReceived(true);
      form.setFieldsValue({ paymentConsultation: "BC" });
    } else if (value === "Digital") {
      form.setFieldsValue({ paymentConsultation: "BC" });
      setPaymentReceived(false);
    } else {
      setPaymentReceived(false);
      form.setFieldsValue({ paymentConsultation: null });
    }
  };

  const handleCallTypeChange = (value) => {
    setCallType(value);
  };

  const storedLoginResult = localStorage.getItem("login_result");
  const loginResultObject = storedLoginResult
    ? JSON.parse(storedLoginResult)
    : null;

  const hideFilter = () => {
    if (selectedPatient) {
      form.setFieldsValue({
        ...selectedPatient,
      });
    }
    setIsVisible(false);
  };

  const cancelFilter = () => {
    setIsVisible(false);
    setMobileNumber("");
    setSelectedPatient(null);
  };

  const handleRadioChange = (record) => {
    if (!record) {
      setSelectedPatient(null);
    } else {
      setSelectedPatient(record);
    }
  };

  const handleSearch = () => {
    if (mobileNumber === "") {
      if (radioValue === "domainPatient") {
        message.error("Enter patient mobile number to filter");
      } else {
        message.error("Enter patient mobile number  or name to filter");
      }
      return;
    }

    if (radioValue === "domainPatient") {
      let payload = {
        mobile: isNaN(mobileNumber) ? null : mobileNumber,
      };

      PatientService.PatientListFilter(payload).then((result) => {
        setFilteredPatient(result.data);
        setIsVisible(true);
      });
    } else {
      let payload = {
        mobile: isNaN(mobileNumber) ? null : mobileNumber,
        firstName: isNaN(mobileNumber) ? mobileNumber : null,
        account_Id: loginResultObject.user.account_Id,
      };

      PatientService.PatientListFilter(payload).then((result) => {
        setFilteredPatient(result.data);
        setIsVisible(true);
      });
    }
  };

  const handleCancelClick = () => {
    setMobileNumber("");
    handleOk();
  };

  const disabledDate = (current) => {
    return current && current < new Date().setHours(0, 0, 0, 0);
  };

  const handleExistingInvite = async (payload) => {
    setSave(true);
    let data = {
      consultationReason: payload.consultationReason,
      createdBy: loginResultObject.user.userId,
      createdDate: CommonFunctions.convertUTCDate(
        new Date(),
        "YYYY-MM-DD HH:mm:ss"
      ),
      email: payload.email,
      account_Id: loginResultObject.user.account_Id,
      clinic_Id: loginResultObject.user.clinic_Id,
      firstName: payload.firstName,
      lastName: payload.lastName,
      specialityCode: loginResultObject.user?.specialityCode,
      mobile: payload.mobile,
      modifiedBy: loginResultObject.user.userId,
      modifiedDate: CommonFunctions.convertUTCDate(
        new Date(),
        "YYYY-MM-DD HH:mm:ss"
      ),
      patientId: selectedPatient.patientId,
      paymentConsultation: payload.paymentConsultation,
      paymentType: payload.paymentType,
      appointmentDate: CommonFunctions.convertDate(payload.appointmentDate),
      startTime: CommonFunctions.ConvertTime(payload.startTime),
      endTime: CommonFunctions.ConvertTime(payload.endTime),
      serviceName:
        payload.serviceCode === "APPT_VIDEO"
          ? "Video Consultation"
          : payload.serviceCode === "APPT_INPERSON"
          ? "Inperson Consultation"
          : "",
      serviceCode: payload.serviceCode,
      totalAmount: payload.totalAmount,
      userId: loginResultObject.user.userId,
      patientType: "E",
    };

    if (data.startTime > data.endTime) {
      message.error("Select Valid Start time and End time");
      setSave(false);
      return;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailRegex.test(payload.email)) {
      console.log("");
    } else {
      message.error("enter valid email in the send invite");
      return;
    }

    const currentTime = CommonFunctions.ConvertTime(dayjs());
    const startTime = CommonFunctions.ConvertTime(payload.startTime);

    if (
      startTime < currentTime &&
      dayjs().format("MM/DD/YYYY") ==
        CommonFunctions.convertDate(payload.appointmentDate)
    ) {
      message.error("Scheduled time has already ended");
      setSave(false);
      return;
    }

    if (submitType === "1") {
      setSave(true);
      data.status = 0;
      data.paymentReceived = "N";
      CommonFunctions.ExisTingPatientPayment(data)
        .catch((error) => {
          console.error("An error occurred:", error);
          message.error("An Error occurred, try again");
        })
        .finally(() => {
          setSave(false);
        });
    } else if (submitType === "2") {
      if (payload.paymentType == "Cash" && !paymentReceived) {
        message.error("Confirm Payment received by clicking the check box");
        setSave(false);
        return;
      }

      setSave(true);
      try {
        data.paymentReceived = paymentReceived ? "Y" : "N";
        data.status = 1;
        const result = await enrollmentServices.Invite(data);
        if (result.data.code === "FAILED") {
          message.error("Sending Invitation Failed, Retry");
          setSave(false);
          return;
        }
        if (result.data.code === "SUCCESS") {
          message.success("Invitation sent Successfully");
          appointmentsList();
          handleOk();
          setMobileNumber("");
          setSelectedPatient(null);
        }
      } catch (error) {
        console.error("An error occurred:", error);
        message.error("An Error occured please try again");
      } finally {
        setSave(false);
      }
    } else if (submitType == "3") {
      // console.log("share payment link");
      setSave(true);
      try {
        // console.log("data from share payment link", data);
        if (data.paymentType == "Digital" && data.paymentConsultation == "AC") {
          message.error(
            "Share payment link is not available for After Consultation"
          );
          return;
        }
        data.status = 0;
        data.paymentType = "Share_Link";
        const result = await enrollmentServices.Invite(data);
        if (result.data.code === "FAILED") {
          message.error("Sending Invitation Failed, Retry");
          setSave(false);
          return;
        }
        if (result.data.code === "SUCCESS") {
          message.success("Payment Link and invitation sent Successfully");
          appointmentsList();
          handleOk();
          setMobileNumber("");
          setSelectedPatient(null);
        }
      } catch (error) {
        console.error("An error occurred:", error);
        message.error("An Error occured please try again");
      } finally {
        setSave(false);
      }
    }
  };

  const handleMobileNumber = (event) => {
    setMobileNumber(event.target.value);
  };

  const handleStartTimeChange = (time) => {
    if (time) {
      const endTime = dayjs(time).add(15, "minutes");
      setEndTime(endTime);
      form.setFieldsValue({ endTime });
    } else {
      setEndTime(null);
    }
  };

  const columns = [
    {
      title: "",
      dataIndex: "patientId",
      render: (patientId, record) => (
        <Checkbox
          className=""
          checked={selectedPatient && selectedPatient.patientId === patientId}
          onChange={() => handleRadioChange(record)}
        />
      ),
    },
    {
      title: "Patient ID",
      dataIndex: "patientId",
      key: "patientId",
    },
    {
      title: "Name",
      dataIndex: "firstName",
      key: "name",
    },
    {
      title: "Email ID",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
  ];

  const onPaymentStatusChange = () => {
    setPaymentReceived(!paymentReceived);
  };

  const handleSubmitType = (type) => {
    setSubmitType(type);
  };

  return (
    <div>
      <div>
        <div className="flex gap-2 mt-4 ">
          {radioValue === "domainPatient" ? (
            <Input
              placeholder="Enter patient mobile number "
              name="mobile"
              value={mobileNumber}
              onChange={handleMobileNumber}
              onKeyPress={handleKeyPress}
            />
          ) : (
            <Input
              placeholder="Enter patient mobile number  or name to filter patients"
              name="mobile"
              value={mobileNumber}
              onChange={handleMobileNumber}
            />
          )}
          <Button className="h-10 px-10" type="primary" onClick={handleSearch}>
            Search
          </Button>
        </div>

        <div className={isVisible ? "block" : "hidden"}>
          <Table
            columns={columns}
            dataSource={filteredPatient}
            pagination={{ pageSize: 3 }}
            onRow={(record) => {
              return {
                onClick: () => {
                  handleRadioChange(record);
                },
              };
            }}
            className="cursor-pointer"
          ></Table>
          <div className="w-full flex justify-end gap-2">
            <button
              className="p-2 rounded-md border border-blue-700 w-24 bg-white text-black justify-end "
              onClick={cancelFilter}
            >
              cancel
            </button>

            <button
              className="p-2 rounded-md text-white w-24"
              onClick={hideFilter}
            >
              Ok
            </button>
          </div>
        </div>
      </div>

      <Form
        className="formDesign mt-7 mb-10"
        name="trigger"
        layout="vertical"
        form={form}
        onFinish={handleExistingInvite}
        initialValues={{
          serviceCode: "APPT_VIDEO",
          paymentType: "Cash", //changed
          paymentConsultation: "BC", //changed
          totalAmount: "500",
          appointmentDate: dayjs(),
        }}
      >
        <Form.Item
          label={
            <span>
              Send Invite <span className="text-red-500">*</span>
            </span>
          }
          name="email"
          rules={[
            {
              required: true,
              message: "Please Enter Mail or Mobile Number to send Invite!",
            },
          ]}
        >
          <Input type="email" placeholder="Enter email" />
        </Form.Item>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              label={
                <span>
                  First Name
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "Enter your First Name!",
                },
              ]}
            >
              <Input
                className="w-full"
                placeholder="Enter First Name"
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <span>
                  Last Name
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "Enter Last Name!",
                },
              ]}
            >
              <Input
                className="w-full"
                placeholder="Enter Last Name"
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <span>
                  Mobile Number
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="mobile"
              rules={[
                {
                  required: true,
                  message: "Enter Mobile Number!",
                },
              ]}
            >
              <Input
                disabled
                className="w-full"
                onKeyPress={handleKeyPress}
                placeholder="Enter Mobile No."
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <span>
                  Email ID
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="email"
              rules={[
                {
                  required: true,
                  message: "Enter email!",
                },
              ]}
            >
              <Input className="w-full" placeholder="Enter Email" disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <span>
                  Appointment Date
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="appointmentDate"
              rules={[
                {
                  required: true,
                  message: "Enter appointmentDate!",
                },
              ]}
            >
              <DatePicker
                format={"DD/MM/YYYY"}
                className="w-full"
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <span>
                  Start Time <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="startTime"
              rules={[
                {
                  required: true,
                  message: "Enter Start Time!",
                },
              ]}
            >
              <TimePicker
                use12Hours
                format="h:mm a"
                className="w-full"
                onChange={handleStartTimeChange}
                minuteStep={10}
                needConfirm={false}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <span>
                  End Time <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="endTime"
              rules={[
                {
                  required: true,
                  message: "Enter End Time!",
                },
              ]}
            >
              <TimePicker
                use12Hours
                format="h:mm a"
                className="w-full"
                value={endTime}
                minuteStep={10}
                needConfirm={false}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <span>
                  Service Type
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="serviceCode"
              rules={[
                {
                  required: true,
                  message: "Enter Consultation Type!",
                },
              ]}
            >
              <Select
                showSearch
                className="w-full"
                placeholder="Select consultation"
                onChange={handleCallTypeChange}
              >
                <Select.Option value="APPT_INPERSON">In-person</Select.Option>
                <Select.Option value="APPT_VIDEO">Video</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label={
                <span>
                  Payment Type <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="paymentType"
              rules={[
                {
                  required: true,
                  message: "Please Enter Payment Type!",
                },
              ]}
            >
              <Select
                showSearch
                className="w-full"
                placeholder="Select Payment mode"
                onChange={onPaymentTypeChange}
              >
                <Select.Option value="Cash">Cash</Select.Option>
                <Select.Option value="Digital">Digital Payment</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <span>
                  Reason for Consultation
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="consultationReason"
              rules={[
                {
                  required: true,
                  message: "Please Enter Consultation Type!",
                },
              ]}
            >
              <Input placeholder="Enter Consultation Reason" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <span>
                  Enter Fees <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="totalAmount"
              rules={[
                {
                  required: true,
                  message: "Please Enter Total Amount!",
                },
              ]}
            >
              <Input placeholder="Enter Amount" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label={
                <span>
                  Payment <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="paymentConsultation"
              rules={[
                {
                  required: true,
                  message: "Please Enter Payment Consultation!",
                },
              ]}
            >
              <Radio.Group
                disabled={
                  paymentType === "Cash" || paymentType === "Digital"
                    ? true
                    : false
                }
              >
                <Radio value="BC" className="text-black">
                  Before Consultation
                </Radio>
                <Radio value="AC" className="text-black">
                  After Consultation
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            {paymentType === "Cash" && (
              <Form.Item label={<span></span>} name="">
                <div>
                  <Checkbox
                    checked={paymentReceived}
                    className="mr-2"
                    onChange={onPaymentStatusChange}
                  />
                  Payment Received
                </div>
              </Form.Item>
            )}
          </Col>
        </Row>
        <Form.Item>
          <div className="flex gap-2 justify-end">
            <Button className="h-10 Btnwhite px-8" onClick={handleCancelClick}>
              Cancel
            </Button>
            {paymentType == "Digital" ? (
              <>
                <Button
                  className="h-10 bg-blue-600 text-white px-4"
                  type="primary"
                  disabled={save}
                  htmlType="submit"
                  onClick={() => handleSubmitType("3")}
                >
                  Share Payment Link
                </Button>
                <Button
                  className="h-10 bg-blue-600 text-white px-4"
                  type="primary"
                  disabled={save}
                  htmlType="submit"
                  onClick={() => handleSubmitType("1")}
                >
                  {callType === "APPT_VIDEO"
                    ? "Pay & Send Invite"
                    : "Pay & Schedule"}
                </Button>
                <Button
                  className="h-10 px-10"
                  type="primary"
                  htmlType="submit"
                  disabled={save}
                  onClick={() => handleSubmitType("2")}
                >
                  {callType === "APPT_VIDEO" ? "Send Invite" : "Schedule"}
                </Button>
              </>
            ) : (
              <>
                <Button
                  className="h-10 px-10"
                  type="primary"
                  htmlType="submit"
                  disabled={save}
                  onClick={() => handleSubmitType("2")}
                >
                  {callType === "APPT_VIDEO" ? "Send Invite" : "Schedule"}
                </Button>
              </>
            )}
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default ExistingPatient;
