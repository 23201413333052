import { Button, Col, Form, Input, Row, message } from "antd";
import { useForm } from "antd/es/form/Form";
import React from "react";
import PatientService from "../../../Services/PatientService";

function FilterPatient({
  setPatientData,
  handleFilterCancel,
  setSearchPerformed,
}) {
  const [form, setForm] = useForm();

  const storedLoginResult = localStorage.getItem("login_result");
  const loginResultObject = storedLoginResult
    ? JSON.parse(storedLoginResult)
    : null;

  const handleCancel = () => {
    handleFilterCancel();
    form.resetFields();
  };

  const handleFinish = () => {
    let payload = form.getFieldValue();
    if (
      !payload.patientId &&
      !payload.email &&
      !payload.mobile &&
      !payload.firstName
    ) {
      message.error("Enter Any Details to filter");
      return;
    }
    payload.account_Id = loginResultObject.user.account_Id;
    // payload.clinic_Id = loginResultObject.user.clinic_Id;

    PatientService.PatientListFilter(payload).then((res) => {
      setPatientData(res.data);
      setSearchPerformed(true);
    });
    handleFilterCancel();
    form.resetFields();
  };

  return (
    <Form
      className="formDesign"
      name="trigger"
      layout="vertical"
      form={form}
      onFinish={handleFinish}
    >
      <Row gutter={20}>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Patient ID" name="patientId">
            <Input className="w-full" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Patient Name" name="firstName">
            <Input className="w-full" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Email ID" name="email">
            <Input className="w-full" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Mobile Number" name="mobile">
            <Input className="w-full" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <div className="flex justify-end  border-brand-colorBorder gap-3 pt-2">
          <Button className="md:h-10 Btnwhite md:px-8" onClick={handleCancel}>
            Cancel
          </Button>
          <Button className="md:h-10 md:px-10" type="primary" htmlType="submit">
            Filter
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
}

export default FilterPatient;
