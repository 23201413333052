import React from "react";
import AuthLayout from ".";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import Ticicon from "../../Assets/Images/Vector 127.svg";

function SuccessMsg() {
  const history = useHistory();

  const handleRouteClick = (route) => {
    history.push(route);
  };
  return (
    <AuthLayout>
      <div className="flex flex-col  justify-center items-center mt-32 gap-5 h-full">
        <div className="w-14 h-14 rounded-full bg-blue-500 flex justify-center items-center">
          <img src={Ticicon} alt="icon" />
        </div>
        <div className="text-3xl font-bold">Thank you choosing Doxzon</div>
        <div className="flex flex-col justify-center items-center gap-5 text-xl font-normal text-gray-400">
          <div>Sucessfully registered</div>
          <div>Please allow us to review your profile</div>
          <div>
            You will receive an automated email once your profile is approved.
          </div>
        </div>
        <div>
          <Button
            className="h-12 w-96 text-white font-bold"
            type="primary"
            onClick={() => handleRouteClick("/")}
          >
            Go to Login
          </Button>
        </div>
      </div>
    </AuthLayout>
  );
}

export default SuccessMsg;
