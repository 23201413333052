import React, { useState, useEffect, useRef } from "react";
import { Modal, Space, Table, message } from "antd";
import EditIcon from "../../../Assets/Images/Svg/edit.svg";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import CommonFunctions from "../../Shared/CommonFunctions";
import PatientService from "../../../Services/PatientService";
import { MdChatBubble } from "react-icons/md";
import PatientChat from "./PatientChat";
import PatientInvite from "./PatientInvite";
import { FaVideo } from "react-icons/fa";
import StateMasterService from "../../../Services/StateMasterService";
import { PlusOutlined } from "@ant-design/icons";

function PatientList({ patientData, getPatientList }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [componentDisabled, setComponentDisabled] = useState(true);
  const [selectedChat, setSelectedChat] = useState({});
  const [open, setOpen] = useState(false);
  const [form] = useForm();
  const [inviteDetails, setInviteDetails] = useState({});
  const [invitePatient, setInvitePatient] = useState(false);
  const [paymentConsultationType, setPaymentConsultationType] = useState("");
  const [paymentReceived, setPaymentReceived] = useState(false);
  const [showPaymentCheckBox, setShowPaymentCheckBox] = useState(false);
  const [paymentType, setPaymentType] = useState("Digital");
  const [callType, setCallType] = useState("APPT_VIDEO");
  const [selectedEditPatient, setSelectedEditPatient] = useState(null);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [profileImageName, setProfileImageName] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [photoValue, setPhotoValue] = useState(null);
  const [profileType, setProfileType] = useState(null);
  const fileInputRef = useRef(null);

  const showInviteModal = (record) => {
    setInviteDetails(record);
    setInvitePatient(true);
  };
  const handleInviteOk = () => {
    setPaymentConsultationType("");
    setPaymentReceived(false);
    setShowPaymentCheckBox(false);
    setPaymentType("Digital");
    setInvitePatient(false);
    setCallType("APPT_VIDEO");
  };
  const handleInviteCancel = () => {
    setPaymentConsultationType("");
    setPaymentReceived(false);
    setShowPaymentCheckBox(false);
    setPaymentType("Digital");
    setInvitePatient(false);
    setCallType("APPT_VIDEO");
  };

  const editPatient = (record) => {
    setSelectedEditPatient(record);
    if (record.dob) {
      const formattedDob = dayjs(record.dob, "MM/DD/YYYY");
      if (formattedDob.isValid()) {
        record.dob = formattedDob;
      } else {
        console.error("Invalid date format:", record.dob);
      }
    }
    form.setFieldsValue(record);
    setIsModalOpen(true);
  };

  const handleSubmit = () => {
    let data = form.getFieldValue();
    data.dob = dayjs(data.dob, "MM/DD/YYYY").format("MM/DD/YYYY");
    data.modifiedDate = CommonFunctions.convertUTCDate(
      new Date(),
      "YYYY-MM-DD HH:mm:ss"
    );
    if (photoValue) {
      data.photo = photoValue;
      data.photoType = profileType;
      data.photoName = profileImageName;
    }

    PatientService.EditPatient(data)
      .then((res) => {
        if (res.data === 1) {
          message.success("Patient Details updated successfully");
          setProfileType(null);
          setPhotoValue(null);
          setProfileImageName('');
          // getPatientList();
        } else {
          message.error("Failed to update patient details");
        }
      })
      .catch((error) => {
        message.error(
          "Updating patient details failed!. Please try again later."
        );
      })
      .finally(() => {
        form.resetFields();
        setIsModalOpen(false);
        setComponentDisabled(true);
      });
  };

  const handleCancel = () => {
    setComponentDisabled(true);
    form.resetFields();
    setProfileImage(null);
    setProfileImageName('');
    setPhotoValue(null);
    setProfileType(null);
    setIsModalOpen(false);
  };

  const handleDisabled = () => {
    setComponentDisabled(!componentDisabled);
  };

  const showDrawer = (record) => {
    setSelectedChat(record);
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      title: "Patient ID",
      dataIndex: "patientId",
      sorter: {
        compare: (a, b) => a.patientId.localeCompare(b.patientId),
      },
    },
    {
      title: "Name",
      dataIndex: "firstName",
      render: (_, record) => (
        <span>
          {record.firstName} {record.lastName}
        </span>
      ),
      sorter: {
        compare: (a, b) => a.firstName.localeCompare(b.firstName),
      },
    },

    {
      title: "Email ID",
      dataIndex: "email",
      sorter: {
        compare: (a, b) => a.email.localeCompare(b.email),
      },
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <div className="flex gap-2">
          <FaVideo
            className="text-blue-600 text-xl"
            onClick={() => showInviteModal(record)}
            role="button"
          />
          <img
            role="button"
            className="cursor-pointer w-5 h-5"
            src={EditIcon}
            width={20}
            alt="Edit"
            onClick={() => editPatient(record)}
          />
        </div>
      ),
    },
    {
      title: "Chat",
      dataIndex: "action",
      render: (_, record) => (
        <MdChatBubble
          className="text-xl text-blue-500"
          role="button"
          onClick={() => showDrawer(record)}
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, record) => (
        <span
          className={`${record.status === 1
            ? " p-1 rounded font-semibold text-blue-600"
            : " p-1 text-red-600 rounded-md"
            }`}
        >
          {record.status === 1 ? "Active" : "Inactive"}
        </span>
      ),
    },
  ];

  const genders = ["Male", "Female"];
  const salutationOptions = [
    {
      value: "Mr.",
      label: "Mr.",
    },
    {
      value: "Mrs.",
      label: "Mrs.",
    },
    {
      value: "Miss.",
      label: "Miss.",
    },
    {
      value: "Ms.",
      label: "Ms.",
    },
  ];
  const bloodgroup = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];
  const maritalStatusOptions = [
    { label: "Married", value: "M" },
    { label: "Unmarried", value: "U" },
  ];
  const handleKeyPress = (e) => {
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ];
    if (!allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };


  const getStateList = () => {
    let payload = {
      status: 1,
    };
    StateMasterService.StateMaster(payload).then((res) => {
      setStates(res.data);
    });
    StateMasterService.CountryMaster(payload).then((res) => {
      setCountries(res.data);
    });
  };

  useEffect(() => {
    getStateList();
  }, []);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImageName(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        let imageArray = reader.result.split(",");
        setProfileImage(reader.result);
        const imageDataUrl = imageArray[1];
        const imageType = file.type;
        const fileExtension = imageType.split("/")[1];
        setPhotoValue(imageDataUrl);
        setProfileType(fileExtension);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Modal
        title="Invite Patient"
        open={invitePatient}
        onOk={handleInviteOk}
        onCancel={handleInviteCancel}
        footer={null}
        width={1000}
        style={{
          top: 20,
        }}
        maskClosable={false}
      >
        <PatientInvite
          inviteDetails={inviteDetails}
          handleInviteOk={handleInviteOk}
          paymentConsultationType={paymentConsultationType}
          setPaymentConsultationType={setPaymentConsultationType}
          paymentReceived={paymentReceived}
          setPaymentReceived={setPaymentReceived}
          showPaymentCheckBox={showPaymentCheckBox}
          setShowPaymentCheckBox={setShowPaymentCheckBox}
          paymentType={paymentType}
          setPaymentType={setPaymentType}
          callType={callType}
          setCallType={setCallType}
        />
      </Modal>
      <PatientChat open={open} onClose={onClose} selectedChat={selectedChat} />
      <div className="mt-4">
        <Table
          columns={columns}
          pagination={{ pageSize: 10 }}
          dataSource={patientData}
        />
      </div>
      <Modal
        title={
          <div className="flex justify-between ">
            <h2 className="font-bold text-xl">Edit Patient</h2>

            {componentDisabled ? (
              <button
                className="px-2 text-white font-normal rounded"
                onClick={handleDisabled}
              >
                Edit
              </button>
            ) : (
              <button
                className="px-2 text-white font-normal rounded"
                onClick={handleCancel}
              >
                X
              </button>
            )}
          </div>
        }
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[]}
        width="60%"
        style={{
          top: 20,
        }}
        closeIcon={false}
      >
        <Form
          className="formDesign patientForm mt-7 mb-10"
          name="basic"
          layout="vertical"
          autoComplete="off"
          form={form}
          onFinish={handleSubmit}
          disabled={componentDisabled}
        >
          <Row gutter={20}>
            <Col span={6}>
              <div className="flex flex-col m-auto mb-2" onClick={handleClick}>
                <div className="Upload h-24 w-24 border-2 border-dashed p-4 rounded-full cursor-pointer relative">
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    ref={fileInputRef}
                  />
                  <div
                    className="absolute bottom-0 right-0 rounded-full text-center p-1 bg-slate-300 cursor-pointer"
                    style={{ width: "24px", height: "24px", zIndex: 1 }}
                  >
                    <PlusOutlined style={{}} />
                  </div>
                  {selectedEditPatient?.photoName && profileImage === null ?
                    (
                      <img
                        src={process.env.REACT_APP_IMAGE_URL + selectedEditPatient?.photoName}
                        alt="Profile"
                        className="absolute top-0 left-0 w-full h-full object-cover rounded-full"
                      />
                    )
                    : (
                      <img
                        src={profileImage}
                        alt="Profile"
                        className="absolute top-0 left-0 w-full h-full object-cover rounded-full"
                      />
                    )}
                </div>
              </div>
            </Col>
            <Col span={4}>
              <Form.Item
                label={
                  <span>
                    Salutation
                    <span style={{ color: "#FF000F" }}> *</span>
                  </span>
                }
                name="salutation"
                rules={[
                  {
                    required: true,
                    message: "Please input salutation!",
                  },
                ]}
              >
                <Select options={salutationOptions} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={
                  <span>
                    First Name
                    <span style={{ color: "#FF000F" }}> *</span>
                  </span>
                }
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please Enter your First Name!",
                  },
                ]}
              >
                <Input className="w-full" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={
                  <span>
                    Last Name
                    <span style={{ color: "#FF000F" }}> *</span>
                  </span>
                }
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Last Name!",
                  },
                ]}
              >
                <Input className="w-full" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={
                  <span>
                    D.O.B
                    <span style={{ color: "#FF000F" }}> *</span>
                  </span>
                }
                name="dob"
                rules={[
                  {
                    required: true,
                    message: "Please Enter your D.O.B!",
                  },
                ]}
              >
                <DatePicker className="w-full" format={"DD/MM/YYYY"} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label={
                  <span>
                    Gender
                    <span style={{ color: "#FF000F" }}> *</span>
                  </span>
                }
                name="gender"
                rules={[
                  {
                    required: true,
                    message: "Please Enter your Gender!",
                  },
                ]}
              >
                <Select className="w-full">
                  {genders.map((option) => (
                    <Select.Option key={option} value={option}>
                      {option}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={
                  <span>
                    Blood Group
                    <span style={{ color: "#FF000F" }}> *</span>
                  </span>
                }
                name="bloodGroup"
                rules={[
                  {
                    required: true,
                    message: "Please Enter your Blood Group!",
                  },
                ]}
              >
                <Select className="w-full">
                  {bloodgroup.map((option) => (
                    <Select.Option key={option} value={option}>
                      {option}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={<span>Marital Status</span>}
                name="maritalStatus"
              >
                <Select className="w-full">
                  {maritalStatusOptions.map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={
                  <span>
                    Mobile Number
                    <span style={{ color: "#FF000F" }}> *</span>
                  </span>
                }
                name="mobile"
                rules={[
                  {
                    required: true,
                    message: "Please Enter your Mobile Number!",
                  },
                ]}
              >
                <Input
                  className="w-full"
                  maxLength={15}
                  onKeyPress={handleKeyPress}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={
                  <span>
                    Email ID
                    <span style={{ color: "#FF000F" }}> *</span>
                  </span>
                }
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please Enter your Email ID!",
                  },
                ]}
              >
                <Input className="w-full" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                label={
                  <span>
                    Address 1<span style={{ color: "#FF000F" }}> *</span>
                  </span>
                }
                name="address1"
                rules={[
                  {
                    required: true,
                    message: "Please Enter your contact address!",
                  },
                ]}
              >
                <TextArea
                  autoSize={{
                    minRows: 2,
                    maxRows: 2,
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Address 2" name="address2">
                <TextArea
                  autoSize={{
                    minRows: 2,
                    maxRows: 2,
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={6}>
              <Form.Item
                label={
                  <span>
                    City
                    <span style={{ color: "#FF000F" }}> *</span>
                  </span>
                }
                name="city"
                rules={[
                  {
                    required: true,
                    message: "Please Enter your City",
                  },
                ]}
              >
                <Input className="w-full" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={<span>Pincode</span>}
                name="pinCode"
                rules={[
                  {
                    required: true,
                    message: "Please Enter your Pincode",
                  },
                ]}
              >
                <Input
                  className="w-full"
                  maxLength={6}
                  onKeyPress={handleKeyPress}
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={
                  <span>
                    State
                    <span style={{ color: "#FF000F" }}> *</span>
                  </span>
                }
                name="state"
                rules={[
                  {
                    required: true,
                    message: "Please Enter your State",
                  },
                ]}
              >
                <Select
                  placeholder="Select State"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {states?.map((state) => (
                    <Select.Option key={state.stateId} value={state.stateId}>
                      {state.stateName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Country" name="country">
                <Select
                  placeholder="Select Country"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {countries?.map((country) => (
                    <Select.Option
                      key={country.country_Id}
                      value={country.country_Id}
                    >
                      {country.country_Name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <div className="flex justify-end  border-brand-colorBorder gap-3 pt-2">
              <Button className="h-10 Btnwhite px-8" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                className="h-10 px-10"
                type="primary"
                htmlType="submit"
                disabled={componentDisabled}
              >
                Save
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default PatientList;
