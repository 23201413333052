import React, { useEffect, useState } from "react";
import AllergyDetails from "./AllergyDetails";
import PastMedicalHistory from "./PastMedicalHistory";
import SurgicalHistory from "./SurgicalHistory";
import FamilyHistory from "./FamilyHistory";
import SocialHistory from "./SocialHistory";
import ChronicCondition from "./ChronicCondition";
import Medication from "./Medication";
import NoDataFound from "../../../../../Assets/Images/Svg/No data-found.svg"
import PatientService from "../../../../../Services/PatientService";
import axios from "axios";
// import PatientService from "../../../../../Services/PatientService";
// import MedicalHistoryService from "../../../../../Services/MedicalHistoryService";

function MedicalHistory({permissionData}) {
  // const [permissionData, setPermissionData] = useState(null);
  // const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);

  // useEffect(() => {
  //   const fetchPermittedData = async () => {
  //     await fetchPatientPermission();
  //   }
  //   fetchPermittedData();
  // }, []);

  // const fetchPatientPermission = async () => {
  //   const userObj = localStorage.getItem('login_result');
  //   const userData = userObj ? JSON.parse(userObj) : null;
  //   const patientObj = localStorage.getItem("patient");
  //   const patientData = patientObj ? JSON.parse(patientObj) : null;

  //   const currDate = new Date().toISOString();
  //   const date = currDate.split('T')[0];
  //   const time = currDate.split('T')[1].slice(0, 8);
  //   const constructedTime = date + " " + time;

  //   let permissionPayload = {
  //     patientId: patientData.patientId,
  //     userId: userData?.user?.userId,
  //     accessDate: constructedTime,
  //     status: 1
  //   }
  //   try {
  //     const response = await PatientService.isPermissionGranted(permissionPayload);
  //     if (response?.data?.length !== 0) {
  //       console.log(response?.data[0]);
  //       if (response?.data[0].medicalHistory) {
  //         setPermissionData(response?.data[0]);
  //       }
        
  //     }

  //   }
  //   catch (e) {
  //     console.log(e);
  //   }
  // }
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <section>
      <div className="container ">
      {isLoading ? (
            <div className="w-full flex h-screen justify-center items-center">
              <div className="spinner"></div> 
            </div>
          ) : !permissionData || permissionData.medicalHistory  === 'Y'? (
            <div className={`border grid  grid-cols-3 gap-2`}>
          <AllergyDetails permissionData={permissionData} />
          <PastMedicalHistory permissionData={permissionData} />
          <SurgicalHistory permissionData={permissionData} />
          <FamilyHistory permissionData={permissionData} />
          <SocialHistory permissionData={permissionData} />
          <Medication permissionData={permissionData} />
          <ChronicCondition permissionData={permissionData} />
        </div> 
          ) : (
            <div className="w-full flex h-screen justify-center items-center">
              <div>
                <img src={NoDataFound} alt="No Data" style={{height:"12rem", width:"19rem"}} />
                <p className="text-center">No Reports Found</p>
              </div>
              </div>
          )}
        {/* <div className={`border grid  grid-cols-3 gap-2`}>
          <AllergyDetails permissionData={permissionData} />
          <PastMedicalHistory permissionData={permissionData} />
          <SurgicalHistory permissionData={permissionData} />
          <FamilyHistory permissionData={permissionData} />
          <SocialHistory permissionData={permissionData} />
          <Medication permissionData={permissionData} />
          <ChronicCondition permissionData={permissionData} />
        </div> */}
        {/* <div className={`container w-[600px]  rounded-md shadow-gray-500 shadow-md mx-auto item-center justify-center p-4 ${isMessageModalOpen?'flex':'hidden'}`}>
          <div className="h-full">
            <span className="bg-yellow-300 text-black font-bold p-1 rounded-lg w-6 h-6">
              !
            </span>{" "}
            Patient needs to provide permission to view medical histories.
          </div>
        </div> */}
      </div>
    </section>
  );
}

export default MedicalHistory;
