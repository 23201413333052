import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Form, Input, DatePicker, Select, Modal, message } from "antd";
import moment from "moment";
import MedicalService from "../../../../../Services/MedicalHistoryService";
import ChronicConditionService from "../../../../../Services/ChronicConditionService"
import MedicalHistoryService from "../../../../../Services/MedicalHistoryService";

function AddChronicCondition(props) {
  const [form] = Form.useForm();
  const activePatient = JSON.parse(localStorage.getItem("patient"));
  const patientCode = activePatient?.patientId;
  const [chronicValue, setChronicValue] = useState();
  const [chronicCode, setChronicCode] = useState([]);
  const [chronicDesc, setChronicDesc] = useState();
  const [isLoading,setIsLoading] = useState(false);
  const [givenDate,setGivenDate] = useState();
  const formRef = useRef();

  const handleCancel = () => {
    props.onClose();
  };

  const handleDate = (date, dateString) => {
    if(dateString){
    const constructedTime = dateString + " " + "00:00:00";
    console.log(dateString);
    setGivenDate(constructedTime);
    }
    else{
      setGivenDate('')
    }
  }

  const disabledFutureDate = (current) => {
    return current && current > moment().endOf("day");
  };
  
  const handlehealthChange = (value, desc) => {
    setChronicValue(value);
    setChronicDesc(desc.description);
  };
  console.log(chronicDesc, 'ddd');
  const healthConditions = [
    { name: "COPD", value: "COPD" },
    { name: "Asthma", value: "Asthma" },
    { name: "Diabetes Mellitus", value: "Diabetes Mellitus" },
    { name: "Hypertension", value: "Hypertension" },
    { name: "Congestive Heart Failure", value: "Congestive Heart Failure" },
    { name: "Cardiac Disease", value: "Cardiac Disease" },
    { name: "Renal Disease", value: "Renal Disease" },
    { name: "Chronic Renal Failure", value: "Chronic Renal Failure" },
    { name: "Rheumatoid Arthritis", value: "Rheumatoid Arthritis" },
    { name: "Organ Transplant", value: "Organ Transplant" },
    { name: "High Cholesterol", value: "High Cholesterol" },
    { name: "Psychiatric Disorder", value: "Psychiatric Disorder" },
    { name: "Neurological Disorder", value: "Neurological Disorder" },
    { name: "Oncological Disorders", value: "Oncological Disorders" }
  ];

  const ChronicCode = () => {
    let payload = {
      status: 1,
    };
    ChronicConditionService.chronicConditions(payload).then((result) => {
      setChronicCode(result.data);
    });
  };

  useEffect(() => {
    ChronicCode();
  }, []);

  const convertUTCDate = (date, format) => {
    if (!format) {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
        " " +
        d.getHours() +
        ":" +
        d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return moment(new Date(date)).utc().format(format);
  };

  const onFinish = async(e) => {
    e.preventDefault();
    setIsLoading(true);
    
    const userObj = localStorage.getItem('login_result')
    const userData = userObj? JSON.parse(userObj):null;

    const currDate = new Date().toISOString();
    const date = currDate.split('T')[0];
    const time = currDate.split('T')[1].slice(0, 8);
    const constructedTime = date + " " + time;

    const {pastInfo} = formRef.current.getFieldValue();
    if(!pastInfo || !givenDate){
      message.error("Please fill all fields");
      setIsLoading(false);
      return;
    }
    let payload = {
      account_Id:userData?.user?.account_Id,
      clinic_Id:userData?.user?.clinic_Id,
      userId:userData?.user?.userId,
      createdDate: constructedTime,
      givenDate: givenDate,
      createdBy: userData?.user?.userId,
      modifiedBy: userData?.user?.userId,
      status: 1,
      patientId: patientCode,
      chronicCode: chronicValue,
      chronicDescription: chronicDesc,
      patientName: activePatient?.salutation + ' ' + activePatient?.firstName + ' ' + activePatient?.lastName,
      modifiedDate: constructedTime,
    };
    // try{
    //   const saveResponse = await ChronicConditionService.AddChronicConditions(payload);
    //   if(saveResponse.data === 1){
    //     let payload = {
    //       patientId: patientCode,
    //       account_Id: userData?.user?.account_Id,
    //       // clinic_Id: userData?.user?.clinic_Id,
    //       userId:(props.permissionData === null || props.permissionData === "N")?userData?.user?.userId:'',
    //       status:1
    //     };
    //     const getResponse = await MedicalHistoryService.PatientchronicConditions(payload);
    //     formRef.current.resetFields();
    //     props.handleLatestUpdate(getResponse.data);
    //     setIsLoading(false);
    //     props.onClose();
    //   }
    // }
    // catch(e){
    //   console.log(e);
    //   setIsLoading(false);
    // }
    ChronicConditionService.AddChronicConditions(payload)
      .then((result) => {
        formRef.current.resetFields();
        props.handleLatestUpdate(result.data);
        setIsLoading(false);
        props.onClose();
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <div>
      <Form
        ref={formRef}
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
      >
        <Row gutter={20}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span>
                  Chronic Condition{" "}
                  <span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="pastInfo"
            >
              <Select
                style={{ width: "100%" }}
                onChange={handlehealthChange}
                placeholder="Select"
                value={chronicValue}
                options={chronicCode.map((ele) => ({
                  value: ele.code,
                  description: ele.description,
                  label: ele.description,
                  key: ele.value,
                }))}
              />
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={12}>
            <Form.Item label={<span>Date<span style={{ color: "#FF000F" }}>*</span></span>} name="givenDate">
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Select"
                format="YYYY-MM-DD"
                onChange={handleDate}
                disabledDate={disabledFutureDate}
              />
            </Form.Item>
          </Col>

        </Row>
      </Form>
      <Col className="gutter-row" span={24}>
        <div className="flex items-center w-full justify-end my-5">
          <button
            onClick={handleCancel}
            className="border-brand-primaryColor bg-white border rounded text-brand-primaryColor text-sm px-6 py-1.5"
          >
            Cancel
          </button>
          <button disabled={isLoading} onClick={onFinish} className="bg-brand-primaryColor rounded px-5 py-1.5 text-white text-sm ml-3 disabled:cursor-not-allowed disabled:opacity-50">
            Submit
          </button>
        </div>
      </Col>
    </div>
  );
}

export default AddChronicCondition;
