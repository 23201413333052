import { Button, Form, Input } from "antd";
import React from "react";
import Arrowicon from "../../Assets/Images/Svg/Arrow.svg";
import AuthLayout from ".";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "../../PageRouting/approutes";

function ChangePassword() {
  const history = useHistory();

  const handleClick = () => {
    history.push(APP_ROUTES.LOGINFORM);
  };
  return (
    <AuthLayout>
      <div className="w-[80%]">
        <div className="flex cursor-pointer  items-center mb-5">
          <img src={Arrowicon} alt="icon" />
          <p className="text-brand-primaryColor text-base hover:underline ml-2 mt-5">
            Back
          </p>
        </div>
        <div className="text-3xl text-brand-secondaryColor font-medium mb-3 inline-block">
          Set Password
        </div>
        <div className="w-full justify-start flex mt-4">
          <div className="w-full">
            <Form name="basic" layout="vertical" autoComplete="off">
              <Form.Item
                label={<span className="font-bold">New Password</span>}
                name="newpassword"
                autoFocus
                rules={[
                  {
                    required: true,
                    // message: "Please input your username!",
                  },
                ]}
              >
                <Input placeholder="" className="h-12 w-full px-2 border-0 border-b-2 border-gray-500 appearance-none bg-none dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" style={{ background: 'none' }}/>
              </Form.Item>
              <Form.Item
                label={<span className="font-bold">Confirm New Password</span>}
                name="confrmpassword"
                autoFocus
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input placeholder="" className="h-12 w-full px-2 border-0 border-b-2 border-gray-500 appearance-none bg-none dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" style={{ background: 'none' }}/>
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="w-full inline-block my-5 h-12 bg-[#009FAB]"
                  onClick={handleClick}
                >
                  Confirm
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}

export default ChangePassword;
