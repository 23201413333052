import React, { useState, useEffect } from "react";
import docqueimg from "../../../Assets/Images/doctordp1.jpeg";
import kuberan from "../../../Assets/Images/doctordp2.jpeg";
import { IoMdVideocam } from "react-icons/io";
import { IoIosCloseCircle } from "react-icons/io";
import moment from "moment";
import ConsultationService from "../../../Services/ConsultationService";
import PatientService from "../../../Services/PatientService";
import { APP_ROUTES } from "../../../PageRouting/approutes";
import { useHistory, useLocation } from "react-router-dom";
import CommonFunctions from "../../Shared/CommonFunctions";
import { message } from "antd";

function PatientQueue({ onClose, queueData }) {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleClick = (record) => {
    const currentPath = location.pathname;

    if (currentPath === "/videocall") {
      message.error("You cannot change the patient while in a video call.");
    } else {
      let payload = {
        appointmentId: record.appointmentId,
        modifiedBy: record.userId,
        modifiedDate: CommonFunctions.convertUTCDate(
          new Date(),
          "YYYY-MM-DD HH:mm:ss"
        ),
        patientId: record.patientId,
        startDateTime: CommonFunctions.convertUTCDate(
          new Date(),
          "YYYY-MM-DD HH:mm:ss"
        ),
        userId: record.userId,
      };

      ConsultationService.updateStartTime(payload).then((res) => {
        console.log("res", res);
      });

      let patientPayload = {
        patientId: record.patientId,
      };

      PatientService.PatientListFilter(patientPayload).then((res) => {
        localStorage.setItem("patient", JSON.stringify(res.data[0]));
      });

      history.push(APP_ROUTES.VIDEOCALL, { selectedPatient: record });
    }
  };

  return (
    <div
      className={`fixed inset-0 z-50 flex justify-end ${
        isVisible ? "opacity-100" : "opacity-0"
      } transition-opacity duration-700`}
    >
      <div className="fixed inset-0 bg-black opacity-10"></div>
      <div className="relative bg-[#0F1821] w-60 h-3/2 mt-28 mb-24 shadow-xl p-4 rounded-l-3xl transition-transform duration-500 transform-gpu translate-x-0">
        <div className="flex justify-end">
          <div className="mb-4 text-white cursor-pointer" onClick={onClose}>
            <IoIosCloseCircle size={27} />
          </div>
        </div>
        <div className="overflow-y-auto h-full scrollBarWidth">
          <label className="text-white uppercase" style={{ fontSize: "small" }}>
            Patient Queue
          </label>
          <div className="flex-grow overflow-y-auto mt-4">
            {queueData &&
              queueData.map((option, index) => (
                <>
                  <div
                    key={index}
                    className="flex items-center mb-4 justify-between"
                  >
                    <div className="flex">
                      <img
                        src={docqueimg}
                        alt="contact"
                        className="w-10 h-10 rounded-full mr-4"
                      />
                      <div className="grid">
                        <h4 className="font-sans text-white">
                          {option.patientName}
                        </h4>
                        <label className="text-xs text-green-500">
                          {moment(option.startTime, "HH:mm").format("hh:mm A")}
                        </label>
                        <label className="text-xs text-green-500">
                          Waiting {option.waitingTime + "m"}
                        </label>
                      </div>
                    </div>
                    <div className="flex justify-end">
                      <IoMdVideocam
                        className="w-6 h-6 text-green-500 cursor-pointer"
                        onClick={() => handleClick(option, "1")}
                      />
                    </div>
                  </div>
                </>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatientQueue;
