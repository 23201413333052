import http from "./http-common";

class AuthService {
  TokenAuthentication(payload) {
    var queryString = Object.keys(payload)
      .map((key) => key + "=" + payload[key])
      .join("&");
    return http.get(`/oauth/token?${queryString}`);
  }

  Login(data) {
    return http.post("/Login/", data);
  }

  Invite(data) {
    return http.post("/invitationDetails/save", data);
  }

  queryString(payload) {
    return;
  }

  getMyPrescription(payload) {
    var queryString = Object.keys(payload)
      .map((key) => (key && payload[key])? key + "=" + payload[key]:'')
      .join("&");
    return http.get(`patientDocument / list / filter ? ${queryString}`);
  }
  getMyReport(payload) {
    var queryString = Object.keys(payload)
      .map((key) => (key && payload[key])? key + "=" + payload[key]:'')
      .join("&");
    return http.get(`patientDocument/list/filter?${queryString}`);
  }

  changePassword(data) {
    return http.post("/password-config/change-password/", data);
  }

  forgetPassword(payload){
    return http.post('/password-config/otpforgetpassword',payload);
  }

  verifyPassword(payload){
    return http.post('/password-config/verifyforgetOTP/',payload);
  }

  updatePassword(payload){
    return http.post('/password-config/updatePassword/',payload);
  }
}

export default new AuthService();
