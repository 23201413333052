import { Col, Row } from "antd";
import React from "react";
import Authbg from "../../Assets/Images/auth-img.png";
import cytologo from "../../Assets/Images/cytozon_logo.png";

const AuthLayout = ({ children }) => {
  return (
    <div
      className="h-screen"
      style={{ backgroundSize: "cover", backgroundPosition: "full" }}
    >
      <div className="min-h-screen flex flex-col justify-center items-center w-screen h-screen">
        <div
          className="md:w-[35%] w-[80%] flex flex-col justify-center items-center shadow-2xl border rounded-md"
          style={{ margin: "0 auto" }}
        >
          <div className="mt-2">
            <img src={cytologo} alt="logo" className="w-10 h-10" />
          </div>
          <div className="text-center mt-4 text-2xl font-bold text-blue-600">
            Doxzon Doctor
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
