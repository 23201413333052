import React, { useEffect, useRef, useState } from "react";
import { Card, Form, Input, Row, Col } from "antd";
import { useForm } from "antd/es/form/Form";
import { IoIosCloseCircle } from "react-icons/io";

function Vitals({ consulationData, handleAddVitals }) {
  const [sys, setSys] = useState('');
  const [dia, setDia] = useState("");
  const [bmiValue, setBmiValue] = useState('');
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [pulse, setPulse] = useState('');
  const [heartRate, setHeartRate] = useState('');
  const [respirationRate, setRespirationRate] = useState('');
  const [temp, setTemp] = useState('');
  const [activeKey, setActiveKey] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const formRef = useRef();
  const [form] = useForm()

  useEffect(() => {
    form.setFieldsValue({
      BPS: consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "BPS")?.value : '',
      BPD: consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "BPD")?.value : '',
      PO: consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "PO")?.value : '',
      HR: consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "HR")?.value : '',
      RR: consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "RR")?.value : '',
      TP: consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "TP")?.value : '',
      HI: consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "HI")?.value : '',
      WE: consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "WE")?.value : '',
      BMI: consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "BMI")?.value : '',
      symptoms: consulationData?.symptoms ? consulationData?.symptoms : '',
      recommendation: consulationData?.recommendation ? consulationData?.recommendation : ''
    });
    setBmiValue(consulationData?.patientVitals?.find((item) => item.vitalCode === "BMI")?.value);

  }, [consulationData])


  const handleFormSubmit = () => {
    handleAddVitals({
      BPS: sys,
      BPD: dia,
      PO: pulse,
      HR: heartRate,
      RR: respirationRate,
      TP: temp,
      HI: height,
      WE: weight,
      BMI: bmiValue
    });
    setShowTable(true);
    // formRef.current.resetFields();
    // setBmiValue('');
    // setHeight('');
    // setWeight('');
    // setSys('');
    // setDia('');
    // setTemp('');
    // setRespirationRate('');
    // setHeartRate('');
    // setPulse('');


  }


  const onChange = (key) => {
    setActiveKey(key);
  };

  const handleSysChange = (e) => {
    console.log(typeof e.target.value);
    if (e.target.value) {
      // setSys(e.target.value);
      handleAddVitals({ BPS: e.target.value });
    }
    else {
      setSys('');
    }
  };

  const handleDiaChange = (e) => {
    console.log(e.target.value);
    if (e.target.value) {
      // setDia(value);
      handleAddVitals({ BPD: e.target.value });
    }
    else {
      setDia('');
    }
  };

  const handleHeight = (e) => {
    const newHeight = Number(e.target.value);
    setHeight(newHeight);
    handleAddVitals({ HI: newHeight });

    if (weight && newHeight) {
      const heightInMeters = newHeight / 100;
      const bmi = weight / (heightInMeters * heightInMeters);
      console.log(bmi.toFixed(1));
      setBmiValue(bmi.toFixed(1));
      handleAddVitals({ HI: newHeight, BMI: bmi.toFixed(1) });
    }
  }

  const handleWeight = (e) => {
    const newWeight = Number(e.target.value);
    setWeight(newWeight);
    handleAddVitals({ WE: newWeight });

    if (height && newWeight) {
      const heightInMeters = height / 100;
      const bmi = newWeight / (heightInMeters * heightInMeters);
      console.log(bmi)
      setBmiValue(bmi.toFixed(1));
      handleAddVitals({ WE: newWeight, BMI: bmi.toFixed(1) });
    }
  }

  const handlePulse = (e) => {
    if (e.target.value) {
      // setPulse(e.target.value);
      handleAddVitals({ PO: e.target.value });
    }
    else {
      setPulse('');
    }
  }

  const handleHeartRate = (e) => {
    if (e.target.value) {
      // setHeartRate(e.target.value);
      handleAddVitals({ HR: e.target.value });
    }
    else {
      setHeartRate('');
    }
  }

  const handleRespirationRate = (e) => {
    if (e.target.value) {
      // setRespirationRate(e.target.value);
      handleAddVitals({ RR: e.target.value });
    }
    else {
      setRespirationRate('');
    }
  }

  const handleTemp = (e) => {
    if (e.target.value) {
      // setTemp(e.target.value);
      handleAddVitals({ TP: e.target.value });
    }
    else {
      setTemp('');
    }
  }

  return (
    <div className="w-full">
      <Card className="shadow-lg overflow-y-scroll h-[495px] w-[160px] scrollWidth items-center rounded-none ">
        <Form layout="vertical" className="" form={form} ref={formRef}>
          <Row gutter={24}>
            <Col span={24}>
              <div className="grid">
                <Form.Item className="text-sm" label="Blood Pressure">
                  <div className="border bg-white rounded-lg flex items-center justify-center w-[100px] h-9" >
                    <Form.Item name="BPS" className="outline-none ml-10" >
                      <Input
                        value={sys}
                        onChange={handleSysChange}
                        autoComplete="off"
                        // suffix={<span >SYS</span>}
                        placeholder="SYS"
                        className="border-none mt-2 w-10 text-xs"
                      />
                    </Form.Item>
                    <span className="">/</span>
                    <Form.Item name="BPD">
                      <Input
                        value={dia}
                        autoComplete="off"
                        onChange={handleDiaChange}
                        maxLength={3}
                        // suffix={<span >DIA</span>}
                        placeholder="DIA"
                        className="border-none mt-2 w-10 text-xs"
                      />
                    </Form.Item>
                    <div style={{ color: "#BEC0C3", fontSize: "10px", marginLeft: "17px" }}>mmHg</div>
                    {/* <span style={{ color: "#BEC0C3", fontSize: "10px",position:"relative",left:"17px"}}>mmHg</span> */}
                  </div>

                </Form.Item>
              </div>
              {/* <div className="flex border  items-center h-10 w-[80%]">
                <Form.Item name="BPS" className="outline-none">
                  <Input 
                   value={sys}
                   onChange={handleSysChange}
                   maxLength={3}
                   className="border-none mt-2 w-10"
                  /> 
                  </Form.Item>
                  <span >/</span>
                  <Form.Item name="BPD">
                  <Input
                    value={dia}
                    onChange={handleDiaChange}
                    maxLength={3}
                     suffix={<span >mmHg</span>}
                     className="border-none mt-2 w-10"
                  />  
                  </Form.Item>
                
               </div> */}
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <div className="flex items-center justify-between">
                <Form.Item label="Pulse Ox" name="PO">

                  <Input
                    style={{ width: '100px' }}
                    maxLength={3}
                    autoComplete="off"
                    // className="h-8"
                    onChange={handlePulse}
                  //suffix={<span style={{ color: "#BEC0C3", fontSize: "10px" }}>%</span>}
                  />

                  {/* <span style={{ color: "#BEC0C3", fontSize: "10px",marginLeft:"4px"}}>%</span>  */}
                </Form.Item>
                <span className="mt-4" style={{ color: "#BEC0C3", fontSize: "10px", marginRight: "4px" }}>%</span>
              </div>

            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <div className="flex items-center justify-between">
                <Form.Item label="Heart Rate" name="HR">

                  <Input
                    autoComplete="off"
                    onChange={handleHeartRate}
                    maxLength={3}
                    style={{ width: '100px' }}
                  // suffix={<span style={{ color: "#BEC0C3", fontSize: "10px" }}>Bpm</span>}
                  />
                  {/* <div className="my-auto" style={{ color: "#BEC0C3", fontSize: "10px",marginLeft:"4px" }}>Bpm</div> */}


                  {/* <span style={{ color: "#BEC0C3", fontSize: "10px",marginLeft:"4px" }}>Bpm</span> */}
                </Form.Item>
                <span className="mt-4" style={{ color: "#BEC0C3", fontSize: "10px", marginRight: "4px" }}>Bpm</span>
              </div>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <div className="flex items-center justify-between">
                <Form.Item label="Respiration" name="RR">
                  {/* <div className="flex"> */}
                  <Input
                    autoComplete="off"
                    onChange={handleRespirationRate}
                    style={{ width: '100px' }}
                    maxLength={3}
                  //suffix={<span style={{ color: "#BEC0C3", fontSize: "10px" }}>Rpm</span>}
                  />
                  {/* <div className="my-auto" style={{ color: "#BEC0C3", fontSize: "10px",marginLeft:"4px"}}>Rpm</div>
                </div> */}

                  {/* <span style={{ color: "#BEC0C3", fontSize: "10px",marginLeft:"4px"}}>Rpm</span> */}
                </Form.Item>
                <span className="mt-4" style={{ color: "#BEC0C3", fontSize: "10px", marginRight: "4px" }}>Rpm</span>
              </div>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <div className="flex items-center justify-between">
                <Form.Item label="Temperature" name="TP">
                  {/* <div className="flex"> */}
                  <Input
                    onChange={handleTemp}
                    maxLength={3}
                    autoComplete="off"
                    style={{ width: '100px' }}
                  // suffix={<span style={{ color: "#BEC0C3", fontSize: "10px" }}>°F</span>}
                  />
                  {/* <div className="my-auto" style={{ color: "#BEC0C3", fontSize: "10px",marginLeft:"4px" }}>°F</div>
                </div> */}

                  {/* <span style={{ color: "#BEC0C3", fontSize: "10px",marginLeft:"4px" }}>°F</span> */}
                </Form.Item>
                <span className="mt-4" style={{ color: "#BEC0C3", fontSize: "10px", marginRight: "4px" }}>°F</span>

              </div>
            </Col>
          </Row>

          <h3 className="text-base font-bold mt-4">Anthropometry</h3>

          <Row gutter={24}>
            <Col span={24}>
              <div className="flex items-center justify-between">
                <Form.Item label="Height" name="HI">
                  {/* <div className="flex"> */}
                  <Input
                    onChange={handleHeight}
                    style={{ width: '100px' }}
                    maxLength={3}
                    autoComplete="off"
                  //suffix={<span style={{ color: "#BEC0C3", fontSize: "10px" }}>Cm</span>}
                  />
                  {/* <div className="my-auto" style={{ color: "#BEC0C3", fontSize: "10px",marginLeft:"4px"}}>Cm</div>
                </div> */}

                  {/* <span style={{ color: "#BEC0C3", fontSize: "10px",marginLeft:"4px"}}>Cm</span> */}
                </Form.Item>
                <span className="mt-4" style={{ color: "#BEC0C3", fontSize: "10px", marginRight: "4px" }}>Cm</span>

              </div>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <div className="flex items-center justify-between">
                <Form.Item label="Weight" name="WE">
                  {/* <div className="flex">  */}
                  <Input
                    onChange={handleWeight}
                    style={{ width: '100px' }}
                    maxLength={3}
                    autoComplete="off"
                  //suffix={<span style={{ color: "#BEC0C3", fontSize: "10px" }}>Kg</span>}
                  />
                  {/* <div className="my-auto" style={{ color: "#BEC0C3", fontSize: "10px",marginLeft:"4px"}}>Kg</div>
                </div> */}

                  {/* <span style={{ color: "#BEC0C3", fontSize: "10px",marginLeft:"4px"}}>Kg</span> */}
                </Form.Item>
                <span className="mt-4" style={{ color: "#BEC0C3", fontSize: "10px", marginRight: "4px" }}>Kg</span>

              </div>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <div className="flex items-center justify-between">
                <Form.Item label="BMI">
                  {/* <div className="flex"> */}
                  <Input
                    style={{ width: '100px' }}
                    maxLength={3}
                    autoComplete="off"
                    value={bmiValue}
                  //suffix={<span style={{ color: "#BEC0C3", fontSize: "10px" }}>Kg/m<sup>2</sup></span>}
                  />
                  {/* <div className="my-auto" style={{ color: "#BEC0C3",position:"relative",fontSize: "10px",left:"4px" }}>Kg/m<sup>2</sup></div>
                </div> */}

                  {/* <span style={{ color: "#BEC0C3",position:"relative",fontSize: "10px",left:"4px",bottom:"3px" }}>Kg/m<sup>2</sup></span> */}
                </Form.Item>
                <span className="mt-4" style={{ color: "#BEC0C3", fontSize: "10px", marginRight: "4px" }}>Kg/m<sup>2</sup></span>
              </div>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
}

export default Vitals;