import React, { useEffect, useState } from "react";
import plusIcon from "../../../../../Assets/Images/Svg/plusIcon.svg";
import AddSocialHistory from "./AddSocialHistory";
import { Modal } from "antd";
import MedicalHistoryService from "../../../../../Services/MedicalHistoryService";

function SocialHistory({permissionData}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [socialHistoryData, setSocialHistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let patientData = JSON.parse(localStorage.getItem("patient"));
  console.log("inside medical history", patientData);


  useEffect(() => {
    if(!isLoading){
      socialHistory();
    }
    
  }, [permissionData,isLoading]);

  const handleLatestUpdate = (data) => {
    setSocialHistoryData(data);
  }


  const socialHistory = () => {
    const userObj = localStorage.getItem('login_result')
    const userData = userObj ? JSON.parse(userObj) : null;
    if(permissionData){
      let payload = {
        // account_Id: userData?.user?.account_Id,
        // clinic_Id: userData?.user?.clinic_Id,
        userId:(permissionData === null || permissionData?.medicalHistory === 'N')?userData?.user?.userId:'',
        patientId: patientData.patientId,
        status: 1,
      };
      MedicalHistoryService.PatientSocialHistory(payload).then((result) => {
        setSocialHistoryData(result.data);
      });
    }
    
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      {" "}
      <div className="card h-[300px] m-5 border rounded-lg">
        <div className="header h-[20%] bg-brand-primaryColor flex items-center justify-between  rounded-lg p-2">
          <h1 className="font-bold text-normal text-white mt-3">
            Social History
          </h1>
          <img
            src={plusIcon}
            onClick={showModal}
            className="h-10 w-10 cursor-pointer"
            alt=""
          />
        </div>
        <div className="body h-[70%] items-center bg-brand-lightBg overflow-y-auto">
          {socialHistoryData.length > 0
            ? socialHistoryData.map((user, i) => (
              <div>
                <div key={i} className="items-center mt-3 p-2 text-sm">
                  <p className="font-semibold">
                    Social History:{" "}
                    <span className="font-medium text-gray-500">
                      {user.description}
                    </span>
                  </p>
                  <p className="font-semibold">
                    Occupation :{" "}
                    <span className="font-medium text-gray-500">
                      {user.occupation}
                    </span>
                  </p>
                  <p className="font-semibold">
                    Marital Status:{" "}
                    <span className="font-medium text-gray-500">
                      {user.maritalStatus}
                    </span>
                  </p>
                  <p className="font-semibold">
                    Social relations with family:{" "}
                    <span className="font-medium text-gray-500">
                      {user.familyRelation}
                    </span>
                  </p>
                  <h4 className="font-semibold">
                    Social relations with friends:{" "}
                    <span className="font-medium text-gray-500">
                      {user.friendsRelation}
                    </span>
                  </h4>
                  <h4 className="font-semibold">
                    Social relations with community:{" "}
                    <span className="font-medium text-gray-500">
                      {user.communityRelation}
                    </span>
                  </h4>
                  <h4 className="font-semibold">
                    Social relations with people at work :{" "}
                    <span className="font-medium text-gray-500">
                      {user.colleagueRelation}
                    </span>
                  </h4>
                </div>
                <div className="ml-auto font-medium text-sm mt-2 flex justify-end text-gray-800">
                  check up date :{" "}
                  {user.givenDate?.slice(0, 11)}
                </div>
                <hr className="my-4 border-gray-300" />
              </div>
            ))
            : null}
        </div>
        <div className="footer h-[10%]  bg-brand-primaryColor rounded-md"></div>
      </div>
      <Modal
        title={<span className="font-bold text-base">Add Social History</span>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <AddSocialHistory
          onClose={handleCancel}
          handleLatestUpdate={handleLatestUpdate}
          permissionData={permissionData}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </Modal>
    </>
  );
}

export default SocialHistory;
