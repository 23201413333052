import moment from "moment";
const convertUTCDateLocal = (utcDate, format) => {
    if (!format) {
      format = "DD/MM/YYYY, HH:mm:ss";
    }

    // Convert UTC date to local date and time
    const localDate = moment.utc(utcDate).local();

    return localDate.format(format);
  };

  export default convertUTCDateLocal;