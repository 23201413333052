import React from 'react';
import Layout from "../../../Layouts";
import PrescriptionDetails from '../VitalTabs/ViewPrescription/PrescriptionDetails';
import { useHistory, useLocation } from 'react-router-dom';
import ConsultationService from '../../../../Services/ConsultationService';
import { APP_ROUTES } from '../../../../PageRouting/approutes';

function Index() {
    const state = useLocation();
    const data = localStorage.getItem('patient');
    const history = useHistory();
    const patientData = data ? JSON.parse(data) : null;

    console.log("Hiiii from epresc ", state.state);

    const handleFinalSubmit = async () => {
        await ConsultationService.ConsultationNotes(state?.state.prescription);
        history.push(APP_ROUTES.DASHBOARD);
    }


    return (
        <Layout>
            <div className='w-11/12'>
                <h1 className='text-2xl font-semibold text-brand-textDarkgray'>Preview</h1>
                <PrescriptionDetails prescription={state?.state.prescription} selectedPatient={patientData} />
                <div className='text-end'>
                    <button className="text-white bg-brand-primaryColor w-24 p-2  rounded-lg ">
                        Download
                    </button>
                   
                </div>
            </div>
        </Layout>
    )
}

export default Index;