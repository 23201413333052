import http from "./http-common";

class AppointmentService {
  TodayAppointment(payload) {
    return http.get(
      `/patientAppointment/list/filter?fromDate=${payload.fromDate}&toDate=${payload.toDate}`
    );
  }

  AppointmentList(payload) {
    return http.get(`/patientAppointment/list/filter`, payload);
  }

  AppointmentType(value) {
    return http.get(`/patientAppointment/list/filter?serviceCode=${value}`);
  }

  AppointmentFilter(payload) {
    const queryString = Object.entries(payload)
      .filter(
        ([_, value]) => value !== undefined && value !== null && value !== ""
      )
      .map(([key, value]) => `${key}=${value}`)
      .join("&");

    return http.get(`/patientAppointment/list/filter?${queryString}`);
  }

  GetAppointmentId(id) {
    return http.get(`invitationDetails/list/filter?appointmentId=${id}`);
  }

  AppointmentCancel(id) {
    return http.put(`/patientAppointment/cancel?appointmentId=${id}`);
  }

  AppointmentUpdate(data) {
    return http.put(`/invitationDetails/update-status/${data.id}`, data);
  }
}
export default new AppointmentService();
