import React, { useEffect, useState } from "react";
import plusIcon from "../../../../../Assets/Images/Svg/plusIcon.svg";
import AddAllergyDetails from "./AddAllergyDetails";
import { Divider, Modal } from "antd";
import moment from "moment";
import MedicalHistoryService from "../../../../../Services/MedicalHistoryService";

function AllergyDetails({ permissionData }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [patientAlleryData, setPatientAlleryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log("patient re-render");
    if (!isLoading) {
      patientAllergies();
    }
  }, [permissionData, isLoading]);

  const patientAllergies = () => {
    let patientData = JSON.parse(localStorage.getItem("patient"));
    const userObj = localStorage.getItem("login_result");
    const userData = userObj ? JSON.parse(userObj) : null;

    if (permissionData) {
      let payload = {
        // account_Id: userData?.user?.account_Id,
        // clinic_Id: userData?.user?.clinic_Id,
        userId:
          permissionData === null || permissionData?.medicalHistory === "N"
            ? userData?.user?.userId
            : "",
        patientCode: patientData.patientId,
        status: 1,
      };
      MedicalHistoryService.PatientAllergiesList(payload)
        .then((result) => {
          setPatientAlleryData(result.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const handleLatestUpdate = (data) => {
    setPatientAlleryData(data);
  };

  const convertUTCDate = (date, format) => {
    if (!format) {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
          " " +
          d.getHours() +
          ":" +
          d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return moment(new Date(date)).utc().format(format);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div className=" h-[300px] m-5 border rounded-lg">
        <div className="h-[20%] bg-brand-primaryColor flex items-center justify-between rounded-lg p-2">
          <h1 className="font-bold text-normal text-white mt-1">
            Allergy Details
          </h1>
          <img
            src={plusIcon}
            onClick={showModal}
            className="h-10 w-10 cursor-pointer"
            alt=""
          />
        </div>
        <div className=" h-[70%] overflow-y-auto items-center bg-brand-lightBg">
          {patientAlleryData?.map((user, i) => (
            <div key={i} className="mt-3">
              <div className="text-sm">
                <p className="font-semibold mx-3">
                  Drug Allergies:
                  <span className="font-medium text-gray-500">
                    {user.drugAllergy}
                  </span>
                </p>
              </div>
              <div className="text-sm">
                <p className="font-semibold mx-3">
                  Food Allergies:
                  <span className="font-medium text-gray-500">
                    {user.foodAllergy}
                  </span>
                </p>
              </div>
              <div className="text-sm">
                <p className="font-semibold mx-3">
                  Chemical Allergies:
                  <span className="font-medium text-gray-500">
                    {user.chemicalAllergy}
                  </span>
                </p>
              </div>
              <div className="text-sm">
                <p className="font-semibold mx-3">
                  Other Allergies:
                  <span className="font-medium text-gray-500">
                    {user.otherAllergy}
                  </span>
                </p>
              </div>
              <div className="flex justify-end">
                <div className="font-medium text-sm mt-2">
                  Check up date : {user.givenDate?.slice(0, 11)}
                </div>
              </div>
              {i !== patientAlleryData.length - 1 && (
                <hr className="my-4 border-gray-300" />
              )}
            </div>
          ))}
        </div>
        <div className="footer h-[10%]  bg-brand-primaryColor rounded-md"></div>
      </div>

      <Modal
        title={<span className="font-bold text-base">Add Allergy Details</span>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <AddAllergyDetails
          handleOk={handleOk}
          handleCancel={handleCancel}
          patientAllergies={patientAllergies}
          onClose={handleCancel}
          handleLatestUpdate={handleLatestUpdate}
          permissionData={permissionData}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </Modal>
    </div>
  );
}

export default AllergyDetails;
