import http from "./http-common";

class StateMasterService {
  StateMaster(payload) {
    const queryString = Object.entries(payload)
      .filter(
        ([_, value]) => value !== undefined && value !== null && value !== ""
      )
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
    return http.get(`/stateMaster/list/filter?${queryString}`);
  }

  CountryMaster(payload) {
    const queryString = Object.entries(payload)
      .filter(
        ([_, value]) => value !== undefined && value !== null && value !== ""
      )
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
    return http.get(`/country_Master/list/filter?${queryString}`);
  }
}

export default new StateMasterService();
