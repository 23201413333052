import React from "react";

function PharmacyHeader({
  clinicDetails,
  doctorData,
  hospitalAccountData,
}) {
  return (
    <div className="border-b-2 border-gray-300 md:flex justify-between items-start">
      <div>
        <div className="flex">
          <img
            src={
              process.env.REACT_APP_IMAGE_URL + hospitalAccountData?.logoName
            }
            alt="Clinic Logo"
            className="md:w-24 w-12 md:h-24 h-12 mx-4"
          />
          <div className="text-center mt-3">
            <label className="md:text-4xl text-sm font-serif font-medium text-[#154A6B]">
              {clinicDetails?.clinic_Name}
            </label>
          </div>
        </div>
        <div className="md:flex gap-3">
          <p className="text-sm text-[#A7527E] font-medium mt-4">
            Morning:
            <span className="text-[#184B67]"> 8:00 am - 11:00 am</span>
          </p>
          <p className="text-sm text-[#A7527E] font-medium mt-4">
            Evening:
            <span className="text-[#184B67]"> 5:30 pm - 9:00 pm </span>
          </p>
        </div>
      </div>
      <div className="text-right text-[#184B67]">
        <label className="md:text-2xl text-sm font-bold text-[#244D67]">
          {doctorData?.userSalutation} {doctorData?.userFirstName}{" "}
          {doctorData?.userLastName}
        </label>
        <p className=" text-[#244D67] font-medium">
          <span className="text-xs"> {doctorData?.qualification}</span>
          <br />
          <span className="text-xs font-medium text-[#244D67]">
            {doctorData?.speciality}
          </span>
          <br />
          Reg.no.{doctorData?.registrationNumber}
        </p>
      </div>
    </div>
  );
}

export default PharmacyHeader;
