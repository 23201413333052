import React, { useEffect, useState } from "react";
import PatientService from "../../../../../Services/PatientService";
import ConsultationService from "../../../../../Services/ConsultationService";
import jsPDF from "jspdf";
import { SpaceContextProvider } from "antd/es/space/context";
import logo from "../../../../../Assets/Images/hospital logo.webp";
import DoctorService from "../../../../../Services/DoctorService";
import PrescriptionHeader from "./PrescriptionHeader";
import PrescriptionFooter from "./PrescriptionFooter";

const PrescriptionDetails = ({ prescription, selectedPatient }) => {
  const [doctorData, setDoctorData] = useState();
  const [hospitalAccountData, setHospitalAccountData] = useState(null);
  const [selectedPatientDetails, setSelectedPatientDetails] = useState([]);
  const [clinicDetails, setClinicDetails] = useState(null);
  const [age, setAge] = useState();
  const [isLoading, setIsLoading] = useState(true);

  console.log(prescription, "    ");
  useEffect(() => {
    let payload = {
      patientId: selectedPatient?.patientId,
    };
    PatientService.PatientListFilter(payload)
      .then((result) => {
        console.log("sel from pres", result.data[0]);
        setSelectedPatientDetails(result.data[0]);
        const dobYear = result?.data[0]?.dob?.slice(6);
        console.log("dobYear ", dobYear);
        const currYear = new Date().getFullYear();
        console.log("age ", Number(dobYear));
        if (dobYear) {
          const patientAge = currYear - Number(dobYear);
          console.log("age ", patientAge);
          setAge(patientAge);
        }
      })
      .catch((error) => {
        console.error("Error fetching patient list:", error);
      });
  }, [selectedPatient?.patientId]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Start loader

      try {
        const response = await getDoctorName(prescription.userId);
        console.log(response);

        setDoctorData(response?.data[0]);
        fetchHospitalData(response?.data[0]?.account_Id);
        fetchClinicData(response?.data[0]?.clinic_Id);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false); // Stop loader
      }
    };

    fetchData();
  }, [prescription.userId]);

  const getDoctorName = async (id) => {
    try {
      const response = await DoctorService.getDoctorData(id);
      return response;
    } catch (e) {
      console.log(e);
    }
  };

  const fetchHospitalData = async (id) => {
    const response = await DoctorService.getHospitalAccountData(id);
    setHospitalAccountData(response.data[0]);
  };

  const fetchClinicData = async (AccId) => {
    console.log(AccId);
    const response = await ConsultationService.getClinicData(AccId);
    console.log(response);
    setClinicDetails(response.data[0]);
  };

  // const Download = () => {
  //   const input = document.getElementById("prescription");
  //   const imag = document.getElementById("imag");
  //   console.log(imag);
  //   // const pdf = new jsPDF({
  //   //   orientation: "portrait",
  //   //   unit: "px",
  //   //   format: "A4",
  //   //   userUnit: "px",
  //   // });
  //   // pdf
  //   //   .html(input, {
  //   //     html2canvas: { scale: 0.5, allowTaint: false },
  //   //     margin: [1, 1, 1, 6],
  //   //     autoPaging: true,
  //   //   })
  //   //   .then(() => {
  //   //     const fileName =
  //   //       "e-Prescription-" + selectedPatient?.patientName + ".pdf";
  //   //     pdf.save(fileName);
  //   //   });
  // }

  return (
    <section>
      <div
        id="prescription"
        className="w-full mb-4 border rounded overflow-x-auto cursor-pointer"
      >
        {isLoading ? (
          <div className="w-full flex h-screen justify-center items-center">
            <div className="spinner"></div>
          </div>
        ) : (
          <>
            <div className="md:p-3 p-4">
              <div className="md:flex justify-between"></div>
              <PrescriptionHeader
                clinicDetails={clinicDetails}
                doctorData={doctorData}
                hospitalAccountData={hospitalAccountData}
              />
              {/* <hr className="hr-line mt-2 border-t-2 border-gray-400" /> */}
              <div className="mt-3">
                <div className="md:flex justify-end gap-1">
                  <div className="text-sm font-bold">Date:</div>
                  <h6 className="text-sm text-gray-700 font-semibold">
                    {prescription?.consultationDate
                      ? `${prescription?.consultationDate
                          .slice(0, 11)
                          .split("-")[2]
                          .trim()}-${
                          prescription?.consultationDate
                            .slice(0, 11)
                            .split("-")[1]
                        }-${
                          prescription?.consultationDate
                            .slice(0, 11)
                            .split("-")[0]
                        }`
                      : ""}
                  </h6>
                </div>
              </div>
              <div className="md:flex gap-5  my-2">
                <div className="text-sm font-bold flex gap-1">
                  Name:
                  <span className="text-sm text-gray-500 font-semibold">
                    {selectedPatient?.patientName
                      ? selectedPatient?.patientName
                      : selectedPatient?.firstName +
                        " " +
                        selectedPatient?.lastName}
                  </span>
                </div>
                <div className="text-sm font-bold md:flex gap-1">
                  Age/Gender:
                  <span className="text-sm text-gray-500 font-semibold">
                    {age ? age : "NA"}/
                    {selectedPatientDetails?.gender
                      ? selectedPatientDetails?.gender
                      : "NA"}
                  </span>
                </div>
                <div className="text-sm font-bold md:flex gap-1">
                  Phone Number:
                  <span className="text-sm text-gray-500 font-semibold">
                    {selectedPatientDetails?.mobile}
                  </span>
                </div>

                <div className="text-sm font-bold flex gap-1">
                  Visit Type:
                  <span className=" text-sm text-gray-500 font-semibold">
                    {
                      // selectedPatient?.serviceCode === "APPT_VIDEO"
                      //   ? "Video"
                      //   : "In-person"
                      prescription.consultationType
                    }
                  </span>
                </div>
              </div>
              <hr />
              <div className="md:flex gap-3 my-3 md:flex-wrap justify-stretch">
                <div
                  className={`gap-1 md:w-[20%] ${
                    prescription?.patientVitals?.find(
                      (vital) => vital.vitalCode === "BPS"
                    )?.value
                      ? "flex"
                      : "hidden"
                  }`}
                >
                  <label className="font-medium text-sm">BP:</label>
                  <span className="text-gray-500 text-sm">
                    {`${
                      prescription?.patientVitals.find(
                        (vital) => vital.vitalCode === "BPS"
                      )?.value
                    }/${
                      prescription?.patientVitals.find(
                        (vital) => vital.vitalCode === "BPD"
                      )?.value
                    }`}{" "}
                    mmHg
                  </span>
                </div>
                <div
                  className={`gap-1 md:w-[20%] ${
                    prescription?.patientVitals.find(
                      (vital) => vital.vitalCode === "BPD"
                    )?.value
                      ? "flex"
                      : "hidden"
                  }`}
                >
                  <label className="font-medium text-sm">Pulse Ox:</label>
                  <span className="text-gray-500 text-sm">
                    {
                      prescription?.patientVitals.find(
                        (vital) => vital.vitalCode === "PO"
                      )?.value
                    }{" "}
                    %
                  </span>
                </div>
                <div
                  className={`gap-1 md:w-[20%] ${
                    prescription?.patientVitals.find(
                      (vital) => vital.vitalCode === "HR"
                    )?.value
                      ? "flex"
                      : "hidden"
                  }`}
                >
                  <label className="font-medium text-sm">Heart Rate:</label>
                  <span className="text-gray-500 text-sm">
                    {
                      prescription?.patientVitals.find(
                        (vital) => vital.vitalCode === "HR"
                      )?.value
                    }{" "}
                    Beats/min
                  </span>
                </div>
                <div
                  className={`gap-1 md:w-[30%] ${
                    prescription?.patientVitals.find(
                      (vital) => vital.vitalCode === "RR"
                    )?.value
                      ? "flex"
                      : "hidden"
                  }`}
                >
                  <label className="font-medium text-sm">
                    Respiration Rate:
                  </label>
                  <span className="text-gray-500 text-sm">
                    {
                      prescription?.patientVitals.find(
                        (vital) => vital.vitalCode === "RR"
                      )?.value
                    }{" "}
                    Breaths/min
                  </span>
                </div>
                <div
                  className={`gap-1 md:w-[20%] ${
                    prescription?.patientVitals.find(
                      (vital) => vital.vitalCode === "TP"
                    )?.value
                      ? "flex"
                      : "hidden"
                  }`}
                >
                  <label className="font-medium text-sm">Temperature:</label>
                  <span className="text-gray-500 text-sm">
                    {
                      prescription?.patientVitals.find(
                        (vital) => vital.vitalCode === "TP"
                      )?.value
                    }{" "}
                    °F
                  </span>
                </div>
                <div
                  className={`gap-1 md:w-[20%] ${
                    prescription?.patientVitals.find(
                      (vital) => vital.vitalCode === "HI"
                    )?.value
                      ? "flex"
                      : "hidden"
                  }`}
                >
                  <label className="font-medium text-sm">Height:</label>
                  <span className="text-gray-500 text-sm">
                    {
                      prescription?.patientVitals.find(
                        (vital) => vital.vitalCode === "HI"
                      )?.value
                    }{" "}
                    cm
                  </span>
                </div>
                <div
                  className={`gap-1 md:w-[20%] ${
                    prescription?.patientVitals.find(
                      (vital) => vital.vitalCode === "WE"
                    )?.value
                      ? "flex"
                      : "hidden"
                  }`}
                >
                  <label className="font-medium text-sm">Weight:</label>
                  <span className="text-gray-500 text-sm">
                    {
                      prescription?.patientVitals.find(
                        (vital) => vital.vitalCode === "WE"
                      )?.value
                    }{" "}
                    kg
                  </span>
                </div>
                <div
                  className={`gap-1 md:w-[20%] ${
                    prescription?.patientVitals.find(
                      (vital) => vital.vitalCode === "BMI"
                    )?.value
                      ? "flex"
                      : "hidden"
                  }`}
                >
                  <label className="font-medium text-sm">BMI:</label>
                  <span className="text-gray-500 text-sm">
                    {
                      prescription?.patientVitals.find(
                        (vital) => vital.vitalCode === "BMI"
                      )?.value
                    }{" "}
                    Kg/m<sup>2</sup>
                  </span>
                </div>
              </div>

              <hr className="hr-line mt-3" />

              <div className="md:grid grid-cols-1 my-2">
                <p>
                  <b className=" font-bold">Chief Complaint : </b>
                  <span className="text-gray-500 font-semibold">{`${prescription?.consultationReason
                    ?.charAt(0)
                    .toUpperCase()}${prescription?.consultationReason?.slice(
                    1
                  )}`}</span>
                </p>
              </div>
              <div className="mb-5">
                <p className="">
                  <span className="font-bold">Symptoms : </span>
                  <span className="text-gray-500 font-semibold">
                    {prescription?.symptoms}
                  </span>
                </p>
              </div>
              {prescription?.patientDiagnosisDetails?.filter(
                (diagnosis) =>
                  diagnosis.diagnosisType === "Primary" &&
                  diagnosis?.status === 1
              )?.length !== 0 && (
                <div className="md:grid grid-cols-1 my-2">
                  <p className="">
                    <b className="font-bold">Primary Diagnosis :</b>
                  </p>

                  <>
                    <ol className="list-decimal ml-5">
                      {prescription?.patientDiagnosisDetails
                        ?.filter(
                          (diagnosis) =>
                            diagnosis?.diagnosisType === "Primary" &&
                            diagnosis?.status === 1
                        )
                        ?.map((primaryDiagnosis) => (
                          <li>
                            <span>
                              {primaryDiagnosis?.diagnosisDescription}
                            </span>
                            <span className="font-bold">
                              ({primaryDiagnosis?.diagnosisCode})
                            </span>
                          </li>
                        ))}
                    </ol>
                  </>
                </div>
              )}

              {prescription?.patientDiagnosisDetails?.filter(
                (diagnosis) =>
                  diagnosis?.diagnosisType === "Secondary" &&
                  diagnosis?.status === 1
              )?.length !== 0 && (
                <div className="md:grid grid-cols-1 my-2">
                  <p className="">
                    <b className="font-bold">Secondary Diagnosis :</b>
                  </p>

                  <>
                    <ol className="list-decimal ml-5">
                      {prescription?.patientDiagnosisDetails
                        ?.filter(
                          (diagnosis) =>
                            diagnosis?.diagnosisType === "Secondary" &&
                            diagnosis?.status === 1
                        )
                        ?.map((secondaryDiagnosis) => (
                          <li>
                            <span>
                              {secondaryDiagnosis?.diagnosisDescription}
                            </span>
                            <span className="font-bold">
                              ({secondaryDiagnosis?.diagnosisCode})
                            </span>
                          </li>
                        ))}
                    </ol>
                  </>
                </div>
              )}

              <div className="mb-2 overflow-scroll scrollWidth">
                {prescription?.drugPrescription?.length !== 0 && (
                  <div>
                    <p className="mb-2">
                      <b className="font-bold">Medicines :</b>
                    </p>
                    <table className="table-auto w-full mb-5 overflow-y-scroll">
                      <thead className="border-b-2 border-t-2 border-gray-400">
                        <tr>
                          <th className="px-3 py-1 text-left">Medicines</th>
                          <th className="px-3 py-1 text-left">Dosage</th>
                          <th className="px-3 py-1 text-left">Duration</th>
                          {/* <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left">
                    Notes
                  </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {prescription?.drugPrescription &&
                          prescription?.drugPrescription?.map(
                            (medicine, idx) => {
                              if (medicine?.status !== 0) {
                                return (
                                  <tr className="border-b-2 text-gray-500">
                                    {/* <td className="text-gray-700 font-semibold px-3 py-1">
                            {idx + 1}
                          </td> */}
                                    <td className="text-gray-700 font-semibold px-3 py-1">
                                      {medicine?.drugName}
                                    </td>
                                    <td className="text-gray-700 font-semibold px-3 py-1 flex flex-col">
                                      {medicine?.dosage}
                                      <br />(
                                      {medicine?.afterBeforeFood === "AF"
                                        ? "After Food"
                                        : "Before Food"}
                                      )
                                    </td>
                                    <td className="text-gray-700 font-semibold px-3 py-1">
                                      {medicine?.duration} days
                                      <br />
                                      {medicine?.instruction?.length > 20
                                        ? `(${medicine?.instruction?.slice(
                                            0,
                                            20
                                          )}...)`
                                        : medicine?.instruction}
                                    </td>
                                    {/* <td className="border dark:border-slate-700 px-3 py-1">
                       {medicine?.notes?.length > 20 ? `${medicine?.instruction?.slice(0, 20)}...` : medicine?.instruction}
                      </td> */}
                                  </tr>
                                );
                              }
                            }
                          )}
                      </tbody>
                    </table>
                  </div>
                )}

                {prescription?.labPrescription?.length !== 0 && (
                  <div className="mt-2  mb-5">
                    <p className="mb-2">
                      <b className="font-bold">Lab Test :</b>
                    </p>

                    <div className="md:w-[60%]">
                      <div className="text-gray-700 font-semibold">
                        {prescription?.labPrescription &&
                          prescription?.labPrescription
                            ?.filter((item) => item.status !== 0)
                            .map((lab, idx) => {
                              if (lab?.status !== 0) {
                                return (
                                  <div
                                    key={idx}
                                    className="text-sm border text-gray-700 px-3 py-1"
                                  >
                                    {lab?.labTestName?.length > 100
                                      ? lab?.labTestName?.slice(0, 100)
                                      : lab?.labTestName}
                                  </div>
                                );
                              }
                            })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="my-2">
                <div className="md:w-6/6  my-2">
                  <div className="mb-5">
                    <p>
                      <b className="mr-3">Recommendation :</b>
                      <span className="text-gray-700 font-semibold">
                        {prescription?.recommendation}
                      </span>
                    </p>
                    {/* <p className="text-base font-montserrat font-thin ">-----</p> */}
                  </div>

                  <div>
                    <p className=" my-2">
                      <b className="mr-3">Scheduled follow up visit date :</b>
                      <span className="text-gray-700 font-semibold">
                        {prescription?.followupVisitDate
                          ? `${
                              prescription?.followupVisitDate
                                ?.slice(0, 10)
                                .split("/")[1]
                            }/${
                              prescription?.followupVisitDate
                                ?.slice(0, 10)
                                .split("/")[0]
                            }/${
                              prescription?.followupVisitDate
                                ?.slice(0, 10)
                                .split("/")[2]
                            }`
                          : ""}
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="md:flex justify-between px-4">
                <div className="md:flex  items-center">
                  <img
                    id="imag"
                    src={`${process.env.REACT_APP_IMAGE_URL}${doctorData?.doctorStampName}`}
                    alt="Stamp"
                    className="md:h-32 h-28 md:w-36 w-30 rounded-full"
                  />
                </div>

                <div className="">
                  <img
                    src={`${process.env.REACT_APP_IMAGE_URL}${doctorData?.doctorSignatureName}`}
                    alt="Signature"
                    className="md:w-28 w-20 md:h-28 h-20  rounded-full"
                  />
                  <p className="font-bold text-sm mb-1">
                    Dr.{doctorData?.firstName}
                  </p>
                  <p className="font-semibold text-gray-700 text-sm mb-1">
                    {doctorData?.qualification}
                    <br />
                    {doctorData?.speciality}
                  </p>
                  <p className="font-semibold text-gray-700 text-sm mb-1">
                    Regn No. {doctorData?.registrationNumber}
                  </p>
                </div>
              </div>
            </div>
            <div className=" ">
              {/* <div className="px-1 py-1">
                <p className="text-sm">
                  <b className="font-bold mx-2">Disclaimer :</b>
                  <ul className="list-disc ml-8 mt-2">
                    <li>
                      The information and advice provided here is provisional in
                      nature as it is based on the limited information made
                      available by the patient.
                    </li>
                  </ul>
                </p>
              </div> */}

              <PrescriptionFooter clinicDetails={clinicDetails} />
            </div>
            <div className="grid grid-cols-1 ">
              <div className="px-1 py-1">
                <p className="text-gray-700 text-sm "></p>
              </div>
            </div>
          </>
        )}
      </div>
      {/* <div className="flex items-center justify-end gap-2">
        <button className="text-white bg-brand-primaryColor w-44 h-12  rounded-lg " onClick={Download}>
          Download
        </button>
      </div> */}
    </section>
  );
};

export default PrescriptionDetails;
