import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import VideoConsultation from "./VideoConsultation";
import VitalTabs from "./VitalTabs";
import Layouts from "../../Layouts";
import ConsultationService from "../../../Services/ConsultationService";
import { APP_ROUTES } from "../../../PageRouting/approutes";
import { HiMiniArrowTopRightOnSquare } from "react-icons/hi2";
import { FaRegShareFromSquare } from "react-icons/fa6";
import { Button, Form, Input, Modal, message } from "antd";
import { useForm } from "antd/es/form/Form";
import enrollmentServices from "../../../Services/enrollmentServices";

function ConsultaionNotes() {
  const location = useLocation(null);
  const [isEditScreenEnabled, setIsEditScreenEnabled] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [selectedPat, setSelectedPat] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [videoUrl, setUrl] = useState('');
  const [form, setForm] = useForm();
  const history = useHistory();
  const selectedPatient = location?.state?.selectedPatient;

  useEffect(() => {
    handleEditScreen();
  }, []);

  const fetchUrl = (value) => {
    setUrl(value)
  }

  console.log("selected patient from index ", selectedPatient);

  const handleEditScreen = async () => {
    if (selectedPatient.consultationId) {
      setIsEditScreenEnabled(true);
    }
  }

  const handleFullScreen = () => {
    window.open(videoUrl, "_blank").focus();
  }

  const showShareModal = (record) => {
    setShareModal(true);
    setSelectedPat(record);
  };

  const handleShareLink = (value) => {
    console.log(value);
    const { enteredVal } = value;
    var validation = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;
    var number = /^\d+$/;
    let payload = {};
    setIsLoading(true);
    if (validation.test(enteredVal)) {
      if (number.test(enteredVal)) {
        payload.mobile = enteredVal;
      } else {
        payload.email = enteredVal;
      }
      payload.patientId = selectedPatient.patientId;
      payload.userId = selectedPatient.userId;
      payload.serviceCode = selectedPatient.serviceCode;
      payload.appointmentId = selectedPatient.appointmentId;

      enrollmentServices
        .InvitationShare(payload)
        .then((res) => {
          console.log("res", res.data);
          message.success("Invitation sent successfully");
          setShareModal(false);
          setIsLoading(false);
          form.resetFields();
        })
        .catch((e) => {
          message.error("An error occurred, Try again");
          setIsLoading(false);
        });
    } else {
      message.error("Please enter the valid email or phone");
      setIsLoading(false);
    }
  }




  return (
    <Layouts>
      <div className="flex flex-col">
        {/* <div className="flex justify-end ">
          <button className="bg-brand-primaryColor disabled:opacity-50  rounded-md py-4 px-2 text-white" onClick={handleEndCall} >
            End Consultation
          </button>
        </div> */}
        {
          !isEditScreenEnabled && (
            <div className="flex items-center justify-between mx-2">
              <HiMiniArrowTopRightOnSquare className="text-brand-primaryColor text-xl font-medium cursor-pointer" onClick={handleFullScreen} />
              <button className="border-brand-primaryColor border bg-brand-primaryColor rounded-md p-2 text-white flex items-center" onClick={showShareModal}><FaRegShareFromSquare className="mx-3" /><span>Share Link</span></button>
            </div>
          )
        }
        {
          !isEditScreenEnabled && (
            <VideoConsultation selectedPatient={selectedPatient} fetchUrl={fetchUrl} />
          )
        }

        <div>
          <VitalTabs selectedPatient={selectedPatient} />
        </div>
      </div>
      <Modal
        title={
          <div className="text-2xl flex gap-2  items-center">
            <span>Share</span>
            {/* <span className="flex gap-2 cursor-pointer">
              <img
                src={whatsappIcon}
                alt="WhatsApp"
                className="w-8 h-8"
                onClick={() => handleShareoption("wp")}
              />
              <img
                src={Gmail}
                alt="Gmail"
                className="w-8 h-8"
                onClick={() => handleShareoption("mail")}
              />
              <img
                src={Message}
                alt="Message"
                className="w-8 h-8"
                onClick={() => handleShareoption("mobile")}
              />
            </span> */}
          </div>
        }
        open={shareModal}
        onCancel={() => {
          setShareModal(false);
          form.resetFields();
        }}
        footer={[]}
        height="15%"
      >
        <Form form={form} onFinish={handleShareLink} layout="vertical">
          {/* {selectShare === "wp" && (
            <Form.Item name="whatsApp" label="WhatsApp">
              <Input
                placeholder="Enter WhatsApp no."
                className="h-10"
                disabled
              />
            </Form.Item>
          )} */}

          {/* {selectShare === "mobile" && (
            <Form.Item name="mobile" label="Moblie">
              <Input placeholder="Enter Mobile no." className="h-10" />
            </Form.Item>
          )} */}

          {/* {selectShare === "mail" && (
            <Form.Item name="email" label="Email">
              <Input placeholder="Enter email" className="h-10" />
            </Form.Item>
          )} */}
          <Form.Item name='enteredVal'>
            <Input placeholder="Enter" className="h-10" />
          </Form.Item>
          <Form.Item>
            <div className="flex justify-end">
              <Button
                disabled={isLoading}
                htmlType="submit"
                className="h-10 mt-2 text-white font-semibold p-2 rounded-md disabled:cursor-not-allowed"
              >
                Send
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </Layouts>
  );
}

export default ConsultaionNotes;
