import { Drawer } from "antd";
import React from "react";

function PatientChat({ onClose, open, selectedChat }) {
  return (
    <div>
      <Drawer
        className="chat"
        // props={record}
        title={
          <div className="flex flex-col text-white">
            <div>{selectedChat.firstName}</div>
            <div>{selectedChat.patientId}</div>
          </div>
        }
        onClose={onClose}
        open={open}
        closable={false}
      >
        <div className="w-full h-full bg-blue-300 relative">
          <div className="absolute bottom-0 w-full p-4">
            <div className="flex items-center w-full">
              <input
                type="text"
                className="flex-grow p-3 border border-gray-300 rounded-l-full outline-none"
                placeholder="Type a message..."
              />
              <button className="p-3 bg-blue-500 text-white rounded-r-full hover:bg-blue-600">
                Send
              </button>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default PatientChat;
