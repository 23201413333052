import React, { useRef, useState } from "react";
import {
  Button,
  Col,
  Radio,
  Collapse,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Modal,
  Space,
  Table,
  message,
  Spin,
} from "antd";
import { useForm } from "antd/es/form/Form";
import ConsultationService from "../../../Services/ConsultationService";
import { useEffect } from "react";
import AddMedicine from "./AddMedicines";
import LabReports from "./LabTest";
import { useHistory, useLocation } from "react-router-dom";
import deleteicon from "../../../Assets/Images/Svg/deleteicon.svg";
import { APP_ROUTES } from "../../../PageRouting/approutes";
import VitalService from "../../../Services/VitalService";
import dayjs from "dayjs";
import { FixedSizeList as List } from 'react-window';
const { Panel } = Collapse;

const months = {
  Jan: "01",
  Feb: "02",
  Mar: "03",
  Apr: "04",
  May: "05",
  Jun: "06",
  Jul: "07",
  Aug: "08",
  Sep: "09",
  Oct: "10",
  Nov: "11",
  Dec: "12",
};
const ConsultationNote = ({ consulationData, handleDiagonsisArr, handleChiefComplaint, handleSymptoms }) => {
  const formRef = useRef();
  const [selectedRadio, setSelectedRadio] = useState("primary");
  const [diagnosticList, setDiagnosticList] = useState([]);
  const [currPrimaryDiagList, setCurrPrimaryDiagList] = useState([]);
  const [currSecondaryDiagList, setCurrSecondaryDiagList] = useState([]);
  const [selectedPrimaryDiag, setSelectedPrimaryDiag] = useState({});
  const [selectedSecondaryDiag, setSelectedSecondaryDiag] = useState({});
  const [selectedPrimaryDiagVal, setSelectedPrimaryDiagVal] = useState("");
  const [selectedSecondaryDiagVal, setSelectedSecondaryDiagVal] = useState("");
  const [selectedDiagArr, setSelectedDiagArr] = useState([]);
  const [selectedOriginalDiagArr, setSelectedOriginalDiagArr] = useState([]);
  const [selectedCode, setSelectedCode] = useState(null);
  const [selectedSecondaryCode, setSelectedSecondaryCode] = useState(null);
  const [secondaryDiag, setSecondaryDiag] = useState([]);
  const [icdCode, setIcdCode] = useState();
  const [primdiag, setPrimdiag] = useState();
  const [tabledata, setTabledata] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [secKey, setSecKey] = useState(0);
  const { state } = useLocation();
  const history = useHistory();
  const location = useLocation();
  const [searchedValue, setSearchedValue] = useState("");
  const [activeKey, setActiveKey] = useState("");
  const [key, setKey] = useState(0);
  const selectedPatient = location?.state?.selectedPatient;
  const [form] = useForm();

  useEffect(() => {
    if (consulationData?.patientDiagnosisDetails?.length) {
      console.log("lollsss", consulationData?.patientDiagnosisDetails)
      setSelectedDiagArr(consulationData?.patientDiagnosisDetails);
      setSelectedOriginalDiagArr(consulationData?.patientDiagnosisDetails);
      setShowTable(true);
    }
  }, [consulationData])

  useEffect(() => {
    fetchICDData();
  }, []);



  const fetchICDData = async () => {
    try {
      const response = await ConsultationService.ICDData();
      setDiagnosticList(response.data);
      setCurrPrimaryDiagList(response.data);
      setSecondaryDiag(response.data);
    } catch (e) {
      console.log(e);
    }
  };


  const handleDelete = (record) => {
    const diagArr = selectedDiagArr;
    const diagOrgArr = selectedOriginalDiagArr;
    const updatedTableData = diagArr.filter((item) => item.id !== record.id);
    const itemToBeDeletedIdx = diagOrgArr.findIndex(
      (item) => item.id === record.id
    );
    if (consulationData && itemToBeDeletedIdx !== -1) {
      diagOrgArr[itemToBeDeletedIdx].status = 0;
    }
    setSelectedDiagArr(updatedTableData);
    console.log('consu ',consulationData);
    if (consulationData) {
      setSelectedOriginalDiagArr(diagOrgArr);
      handleDiagonsisArr([...diagOrgArr]);
    }
    else{
      console.log('hiiii ',updatedTableData);
      setSelectedOriginalDiagArr(updatedTableData);
      handleDiagonsisArr([...updatedTableData]);
    }

  };

  const handleSecDelete = (record) => {
    const updatedTableData = selectedDiagArr.filter(
      (item) => item.id !== record.id
    );
    setSelectedDiagArr(updatedTableData);
  };

  const Columns = [
    {
      title: "Type",
      dataIndex: "diagnosisType",
      key: "diagnosisType",
    },
    {
      title: "Description",
      dataIndex: "diagnosisDescription",
      key: "diagnosisDescription",
      render: (_, record) => (
        <Space className="text-left">
          {record?.diagnosisDescription?.length > 20
            ? ` ${record?.diagnosisDescription?.slice(0, 20)}..`
            : record.diagnosisDescription}
        </Space>
      ),
    },
    {
      title: "ICD Code",
      dataIndex: "diagnosisCode",
      key: "diagnosisCode",
    },
    {
      title: "",
      dataIndex: "delete",
      render: (_, record) => (
        <Space size="middle" className="">
          <div className="flex items-center gap-2 ">
            <div className="">
              <img
                src={deleteicon}
                alt="img"
                className="w-6 h-6"
                onClick={() => handleDelete(record)}
              />
            </div>
          </div>
        </Space>
      ),
    },
  ];

  const customHeaderContent = (
    <thead className="">
      <tr>
        <th className="bg-blue-100 text-left px-3 py-3">Type</th>
        <th className="bg-blue-100 py-3 px-3 text-left">ICD Description</th>
        <th className="bg-blue-100 py-3 px-3 text-left">ICD Code</th>
        <th className="bg-blue-100 py-3 px-3 text-left"></th>
      </tr>
    </thead>
  );


  const handlePrimaryDiagVal = (value) => {
    setSelectedPrimaryDiagVal(value);
    const selectedDiag = currPrimaryDiagList?.find(
      (diagnosis) => diagnosis.code === value
    );
    console.log("hiiii ", selectedDiag);
    setSelectedCode(selectedDiag);
  };

  const handleSecondaryDiagVal = (value) => {
    setSelectedSecondaryDiagVal(value);
    const selectedDiag = currPrimaryDiagList?.find(
      (diagnosis) => diagnosis.code === value
    );
    console.log("From secondary ", selectedDiag);
    setSelectedCode(selectedDiag);
  };

  const handleDiag = (type) => {
    if (type === "Primary") {
      if (selectedPrimaryDiagVal && selectedPrimaryDiagVal.length !== 0) {
        console.log(selectedPrimaryDiagVal, diagnosticList);
        const selected = diagnosticList.filter(
          (item) => item.code === selectedPrimaryDiagVal
        )[0];
        console.log("selected ", selected);
        setSelectedPrimaryDiag(null);
        selected.diagnosisType = type;
        selected.diagnosisCode = selected.code;
        selected.diagnosisDescription = selected.description;
        console.log("final   ", selected);
        setSelectedDiagArr((prevState) => [...prevState, selected]);
        setSelectedOriginalDiagArr((prevState) => [...prevState, selected]);
        handleDiagonsisArr([...selectedOriginalDiagArr, selected]);
        setShowTable(true);
        setSelectedCode(null);
        setKey(key + 1);
      } else {
        setSelectedPrimaryDiag(null);
      }
    } else {
      if (selectedSecondaryDiagVal && selectedSecondaryDiagVal.length !== 0) {
        const selected = diagnosticList.filter(
          (item) => item.code === selectedSecondaryDiagVal
        )[0];
        setSelectedSecondaryDiag(null);
        console.log(" selected ", selected);
        selected.diagnosisType = type;
        selected.diagnosisCode = selected.code;
        selected.diagnosisDescription = selected.description;
        setSelectedDiagArr((prevState) => [...prevState, selected]);
        setSelectedOriginalDiagArr((prevState) => [...prevState, selected]);
        handleDiagonsisArr([...selectedOriginalDiagArr, selected]);
        setShowTable(true);
        setSelectedCode(null);
        setSecKey(secKey + 1);
      } else {
        setSelectedSecondaryDiag(null);
      }
    }
  };

  const onSearch = async (value) => {
    try {
      setIsLoading(true);
      const filtered = diagnosticList.filter((item) =>
        item.description.includes(value)
      );
      // const filtered = await ConsultationService.ICDCode(value);
      setCurrPrimaryDiagList(filtered);
      if (filtered && filtered.length !== 0) {
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const primaryIcdCodeSearch = async (value) => {
    try {
      setIsLoading(true);
      const filtered = diagnosticList.filter((item) =>
        item.code.includes(value.toUpperCase())
      );
      // const filtered = await ConsultationService.ICDCode(value.toUpperCase());
      setCurrPrimaryDiagList(filtered);
      if (filtered && filtered.length !== 0) {
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handlePrimaryCode = (value) => {
    if (value && value.length !== 0) {
      const selected = diagnosticList.filter((item) => item.code === value)[0];
      console.log("filtered from 248 ", selected);
      setSelectedPrimaryDiag(selected);
      setSelectedDiagArr((prevState) => [...prevState, selected]);
    } else {
      setSelectedPrimaryDiag({});
    }
  };

  const onSearch2 = async (value) => {
    try {
      setIsLoading(true);
      const filtered = diagnosticList.filter((item) =>
        item.description.includes(value)
      );
      // const filtered = await ConsultationService.ICDCode(value);
      setCurrSecondaryDiagList(filtered);
      if (filtered && filtered.length !== 0) {
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const secondaryIcdCodeSearch = async (value) => {
    try {
      setIsLoading(true);
      console.log("value from sec value.toUpperCase() ", value.toUpperCase());
      const filtered = diagnosticList.filter((item) =>
        item.code.includes(value.toUpperCase())
      );
      // const filtered = await ConsultationService.ICDCode(value.toUpperCase());
      console.log("filtered ", filtered);
      setCurrSecondaryDiagList(filtered);
      if (filtered && filtered.length !== 0) {
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSecondaryCode = (value) => {
    const selected = diagnosticList.filter((item) => item.code === value)[0];
    setSelectedSecondaryDiag(selected);
  };

  const handleRadioChange = (e) => {
    setSelectedRadio(e.target.value);
  };



  return (
    <>
      <Form
        key={consulationData}
        layout="vertical"
        ref={formRef}
        form={form}
        initialValues={{
          // followupVisitDate:consulationData?.
          symptoms: consulationData?.symptoms,
          complaint: state?.selectedPatient.appointmentReason,
        }}
      >
        <Row gutter={24} className="w-full mt-2 flex items-center">
          <Col span={23}>
            <Form.Item
              label="Chief Complaint"
              name="complaint"
              className="mx-2 w-full"
            >
              <Input className="h-12" onChange={handleChiefComplaint} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} className="w-full mt-2 flex items-center">
          <Col span={23}>
            <Form.Item label="Symptoms" name="symptoms" className="mx-2 w-full">
              <Input className="h-12" onChange={handleSymptoms} />
            </Form.Item>
          </Col>
        </Row>
        <div className="flex justify-between items-center ">
          <div className="font-bold my-2 mx-4 ">Diagnosis</div>
        </div>
        <div className="m-4">
          <Radio.Group value={selectedRadio} onChange={handleRadioChange}>
            <Radio value="primary">Primary</Radio>
            <Radio value="secondary">Secondary</Radio>
          </Radio.Group>
        </div>
        <div className="mx-4">
          {selectedRadio === "primary" ? (
            <>
              <Row gutter={24} className="w-full mt-2 flex items-center">
                <Col span={12}>
                  <Form.Item
                    label="Primary"
                    // name="primaryDiagnosis"
                    className="mx-2"
                  >
                    <Select
                      key={`primary-${key}`}
                      showSearch
                      placeholder="Select Primary Diagnosis"
                      className="h-10"
                      onSearch={onSearch}
                      onChange={handlePrimaryDiagVal}
                      value={selectedCode?.description}
                      options={currPrimaryDiagList?.map((des) => {
                        return {
                          label: des.description,
                          value: des.code,
                        };
                      })}
                      filterOption={(input, option) =>
                        option &&
                        option.label &&
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                      notFoundContent={
                        isLoading ? (
                          <div className="text-center">
                            <Spin size="small" />
                          </div>
                        ) : null
                      }
                    // dropdownRender={menu => (
                    //   <List
                    //     height={200}
                    //     itemCount={currPrimaryDiagList.length}
                    //     itemSize={35}
                    //     width="100%"
                    //     itemData={currPrimaryDiagList}
                    //   >
                    //     {({ index, style }) => (
                    //       <div style={style}>
                    //         {currPrimaryDiagList[index].label}
                    //       </div>
                    //     )}
                    //   </List>
                    // )}
                    />


                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="ICD-10 code" className="mx-2">
                    <Select
                      showSearch
                      key={`primary-icd-${key}`}
                      placeholder="Select ICD Code"
                      className="h-10"
                      onSearch={primaryIcdCodeSearch}
                      onChange={handlePrimaryDiagVal}
                      value={selectedCode?.code}
                      options={currPrimaryDiagList?.map((des) => {
                        return {
                          label: des.code,
                          value: des.code,
                        };
                      })}
                      filterOption={(input, option) =>
                        option &&
                        option.label &&
                        option.label
                          .toUpperCase()
                          .indexOf(input.toUpperCase()) >= 0
                      }
                      allowClear
                      notFoundContent={
                        isLoading ? (
                          <div className="text-center">
                            <Spin size="small" />
                          </div>
                        ) : null
                      }
                    />
                  </Form.Item>
                </Col>

                <div className="flex w-full justify-end items-end">
                  <button
                    className="bg-blue-600 border-2 border-blue-600 rounded-md p-2 w-24 text-white"
                    onClick={() => handleDiag("Primary")}
                  >
                    Add
                  </button>
                </div>
              </Row>
            </>
          ) : (
            <>
              <Row gutter={24} className="my-0 flex items-center">
                <Col span={12}>
                  <Form.Item
                    label="Secondary"
                    // name="secondaryDiagnosis"
                    className="mx-2"
                  >
                    <Select
                      key={`secondary-${secKey}`}
                      showSearch
                      className="h-10"
                      allowClear
                      value={selectedCode?.description}
                      placeholder="Select Secondary"
                      onChange={handleSecondaryDiagVal}
                      // onChange={(value) => handleDiag(value, "Secondary")}
                      onSearch={onSearch2}
                      options={secondaryDiag?.map((des) => {
                        return {
                          label: des.description,
                          value: des.code,
                        };
                      })}
                      filterOption={(input, option) =>
                        option &&
                        option.label &&
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      notFoundContent={
                        isLoading ? (
                          <div className="text-center">
                            <Spin size="small" />
                          </div>
                        ) : null
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="ICD-10 code" className="mx-2">
                    <Select
                      key={`secondary-icd-${secKey}`}
                      showSearch
                      placeholder="Select ICD Code"
                      className="h-10"
                      onSearch={secondaryIcdCodeSearch}
                      onChange={handleSecondaryDiagVal}
                      // onChange={(value) => handleDiag(value, "Secondary")}
                      value={selectedCode?.code}
                      options={currSecondaryDiagList?.map((des) => {
                        return {
                          label: des.code,
                          value: des.code,
                        };
                      })}
                      filterOption={(input, option) =>
                        option &&
                        option.label &&
                        option.label
                          .toUpperCase()
                          .indexOf(input.toUpperCase()) >= 0
                      }
                      allowClear
                      notFoundContent={
                        isLoading ? (
                          <div className="text-center">
                            <Spin size="small" />
                          </div>
                        ) : null
                      }
                    />
                  </Form.Item>
                </Col>

                <div className="flex w-full justify-end items-end">
                  <button
                    className="bg-blue-600 border-2 border-blue-600 rounded-md p-2 w-24 text-white"
                    onClick={() => handleDiag("Secondary")}
                  >
                    Add
                  </button>
                </div>
              </Row>
            </>
          )}

          <Row gutter={24}>
            <Col span={24} className="mx-3">
              <div
                className={`border border-brand-textGray w-full ${showTable && selectedDiagArr.length !== 0 ? "block" : "hidden"
                  } rounded-lg my-6 relative`}
              >
                <Table
                  className=""
                  columns={Columns}
                  dataSource={selectedDiagArr}
                  pagination={false}
                  components={{
                    header: {
                      wrapper: (props) => customHeaderContent,
                    },
                  }}
                  scroll={{ x: 300 }}
                />
              </div>
            </Col>
          </Row>
        </div>


      </Form>
    </>
  );
};

export default ConsultationNote;