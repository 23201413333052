import http from "./http-common"

class ChronicConditionService {
    queryString(payload) {
        return Object.keys(payload)
            .map((key) => (key && payload[key])? key + "=" + payload[key]:'')
            .join("&");
    }
    async AddChronicConditions(data) {
        const response = await http.post(`patientChronicConditions/save/`, data);
        if (response.data === 1) {
            let payload = {
                account_Id: data.account_Id,
                // clinic_Id: data.clinic_Id,
                patientId: data.patientId,
            };
            var queryString = this.queryString(payload);
            return http.get(`patientChronicConditions/list/filter?${queryString}`);
        }
    }
    chronicConditions(payload) {
        var queryString = this.queryString(payload)
        return http.get(`chronicConditions/list/filter?${queryString}`);
    }
    PatientchronicConditions(payload) {
        var queryString = this.queryString(payload)
        return http.get(`patientChronicConditions/list/filter?${queryString}`);
    }

}

export default new ChronicConditionService();