export function generateUniqueId() {
    const currentTimeMs = Date.now();
    const randomPart = Math.floor(Math.random() * 1e5);
    const uniqueId = (currentTimeMs * 1e5) + randomPart;
   
    const maxSafeInteger = Number.MAX_SAFE_INTEGER;
    // if (uniqueId > maxSafeInteger) {
    //     throw new Error("Generated ID exceeds the limits of a 64-bit integer");
    // }
    
    return uniqueId;
}
