import React, { useState } from "react";
import Header from "../Shared/Header";
import Sidemenu from "../Shared/Sidemenu";

const Layouts = ({ children }) => {
  const [isMenuOpen, setMenuOpen] = useState(true);

  const handleSideBarMenu = () => {
    setMenuOpen((prevState) => !prevState);
  };

  return (
      <div className="h-full flex flex-col bg-[#FFFFFF]">
      <Header handleSideBarMenu={handleSideBarMenu} />
      <div className="w-full flex ">
        <div className={`${(isMenuOpen ? 'w-[17%]' : 'w-[4%]')} my-1 border-[1px] rounded-lg`}>
          <Sidemenu isMenuOpen={isMenuOpen} handleSideBarMenu={handleSideBarMenu} />
        </div>
        <div className={`${(isMenuOpen ? 'w-[86%]' : 'w-[96%]')} h-screen mt-20 p-3 overflow-auto rounded scrollWidth bg-[#f6f6f6]`}>{children}</div>
      </div>
    </div>
  );
};

export default Layouts;
