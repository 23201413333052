import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Form, Input, DatePicker, Select, Modal, message } from "antd";
import moment from "moment";
import MedicalService from "../../../../../Services/MedicalHistoryService";
import MedicalHistoryService from "../../../../../Services/MedicalHistoryService";

function AddSurgicalHistory(props) {
  const [form] = Form.useForm();
  const activePatient = JSON.parse(localStorage.getItem("patient"));
  const patientCode = activePatient?.patientId;
  const [surgicalValue, setSurgicalValue] = useState();
  const [isLoading,setIsLoading] = useState(false);
  const [givenDate,setGivenDate] = useState();
  const [manualSurg, setManualSurg] = useState('');
  const formRef = useRef();
  const InputRef = useRef()
  const loginObj = localStorage.getItem('login_result');
  const userData = loginObj? JSON.parse(loginObj): null;

  useEffect(() => {
    if (manualSurg && InputRef.current) InputRef.current.focus();
  }, [manualSurg]);

  // useEffect(() => {
  //   formRef.current.resetFields();
  //   setGivenDate('');
  // },[props]);

  const handleCancel = () => {
    formRef.current.resetFields();
    setGivenDate('');
    props.onClose();
  };

  const handleDate = (date, dateString) => {
    if(dateString){
    const constructedTime = dateString + " " + "00:00:00";
    console.log(dateString);
    setGivenDate(constructedTime);
    }
    else{
      setGivenDate('');
    }
  }

  const disabledFutureDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const handlehealthChange = (value) => {
    setSurgicalValue(value);
  };
  const surgicalProcedures = [
    { name: "Cholecystectomy (gall bladder removal)", value: "Cholecystectomy (gall bladder removal)" },
    { name: "Appendectomy (appendix removal)", value: "Appendectomy (appendix removal)" },
    { name: "Vasectomy (vas deferens)", value: "Vasectomy (vas deferens)" },
    { name: "Cardiac surgery (open heart)", value: "Cardiac surgery (open heart)" },
    { name: "Bypass surgery - stent in the heart", value: "Bypass surgery - stent in the heart" },
    { name: "Hysterectomy (uterus removal)", value: "Hysterectomy (uterus removal)" },
    { name: "Hernia surgery", value: "Hernia surgery" },
    { name: "Angioplasty", value: "Angioplasty" },
    { name: "Caesarean section", value: "Caesarean section" },
    { name: "Knee replacement", value: "Knee replacement" },
    { name: "Spine Surgery", value: "Spine Surgery" },
    { name: "Cataract surgery", value: "Cataract surgery" },
    { name: "Laser surgery for retina (eye)", value: "Laser surgery for retina (eye)" },
    { name: "Hip replacement surgery", value: "Hip replacement surgery" },
    { name: "Cancer surgery", value: "Cancer surgery" },
    { name: "Ear surgery", value: "Ear surgery" },
    { name: "Tonsillectomy (tonsil removal)", value: "Tonsillectomy (tonsil removal)" },
    { name: "Others", value: "Others" }
  ];

  const convertUTCDate = (date, format) => {
    if (!format) {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
        " " +
        d.getHours() +
        ":" +
        d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return moment(new Date(date)).utc().format(format);
  };

  const onFinish = async(e) => {
    e.preventDefault();
    setIsLoading(true);
    const currDate = new Date().toISOString();
    const date = currDate.split('T')[0];
    const time = currDate.split('T')[1].slice(0, 8);
    const constructedTime = date + " " + time;

    const loginObj = localStorage.getItem("login_result");
    const userData = loginObj ? JSON.parse(loginObj) : null;

    const {pastInfo} = formRef.current.getFieldValue();
    if(!(surgicalValue || manualSurg) || !givenDate){
      message.error("Please fill all fields");
      setIsLoading(false);
      return;
    }
    let payload = {
      account_Id:userData?.user?.account_Id,
      clinic_Id:userData?.user?.clinic_Id,
      userId:userData?.user?.userId,
      createdDate: constructedTime,
      givenDate: givenDate,
      createdBy: userData.user.userId,
      modifiedBy: userData.user.userId,
      status: 1,
      patientId: patientCode,
      surgicalInfo: surgicalValue?surgicalValue:manualSurg,
      description: null,
      patientBloodGroup: "",
      patientDob: "",
      patientEmail: "",
      patientName: "",
      modifiedDate: constructedTime,
      fromDate: givenDate,
      toDate: givenDate,
    };
    // try{
    //   const saveResponse = await  MedicalService.AddSurgicalHistory(payload);
    //   if(saveResponse.data === 1){
    //     debugger
    //     let getPayload = {
    //       account_Id:payload.account_Id,
    //       // clinic_Id:data.clinic_Id,
    //       userId:(props.permissionData === null || props.permissionData === "N") ? userData?.user?.userId : '',
    //       patientId: payload.patientId,
    //       status: 1,
    //     };
    //     const getResponse = await MedicalHistoryService.PatientSurgicalHistory(getPayload);
    //     formRef.current.resetFields();
    //     props.handleLatestUpdate(getResponse.data);
    //     setIsLoading(false);
    //     props.onClose();
    //   }
    // }
    // catch(e){
    //   console.log(e);
    //   setIsLoading(false);
    // }
    MedicalService.AddSurgicalHistory(payload)
      .then((result) => {
        formRef.current.resetFields();
        props.handleLatestUpdate(result.data);
        setIsLoading(false);
        props.onClose();
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleSurgSearch = (value) => {
    const isPresent = surgicalProcedures.findIndex((item) => item.name.includes(value));
    if (isPresent === -1) {
      setManualSurg(value);
    }
  }

  const handleManualSurg = (e) => {
    const value = e.target.value;
    if (value) {
      setManualSurg(value);
    }
    else {
      setManualSurg('');
    }
  }

  return (
    <div>
      <Form
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        ref={formRef}
      >
        <Row gutter={20}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span className="font-medium">
                  Surgical History{" "}
                  <span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="pastInfo"
            >
              <Select
                style={{ width: "100%" }}
                showSearch
                onSearch={handleSurgSearch}
                onChange={handlehealthChange}
                placeholder="Select"
                // value={surgicalValue}
                options={surgicalProcedures}
                filterOption={(input, option) =>
                  option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                className={`${manualSurg ? 'hidden' : 'block'}`}
                allowClear
              />
               <Input type="text"
                className={`${manualSurg ? 'block' : 'hidden'}`}
                value={manualSurg}
                onChange={(e) => handleManualSurg(e)}
                ref={InputRef}
              />
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={12}>
            <Form.Item label={<span className="font-medium">Date{" "}<span style={{ color: "#FF000F" }}>*</span></span>} name="givenDate">
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Select"
                format="YYYY-MM-DD"
                onChange={handleDate}
                disabledDate={disabledFutureDate}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Col className="gutter-row" span={24}>
        <div className="flex items-center w-full justify-end my-5">
          <button
            onClick={handleCancel}
            className="border-brand-primaryColor border rounded bg-white text-brand-primaryColor text-sm px-6 py-1.5"
          >
            Cancel
          </button>
          <button disabled={isLoading} onClick={onFinish} className="bg-brand-primaryColor rounded px-5 py-1.5 text-white text-sm ml-3 disabled:cursor-not-allowed disabled:opacity-50">
            Submit
          </button>
        </div>
      </Col>
    </div>
  );
}

export default AddSurgicalHistory;
