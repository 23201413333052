import React, { useEffect, useState } from "react";
import Layouts from "../../Layouts";
import { useHistory, useParams } from "react-router";
import PaymentService from "../../../Services/PaymentService";
import { Base64 } from "js-base64";
import { BsPatchCheck } from "react-icons/bs";
import moment from "moment";
import { Modal } from "antd";
import NavigationPrompt from "react-router-navigation-prompt";

const PaymentInitiation = () => {
  const params = useParams();
  const history = useHistory();
  const [status, setStatus] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getpaymentStatus();
  }, [params]);
  const getpaymentStatus = async () => {
    let decodedValues = Base64.decode(params.transactionId);
    let splitValues = decodedValues.split("|");
    const data = {
      transactionId: splitValues[0],
      appointmentId: splitValues[3],
      patientId: splitValues[1],
      userId: splitValues[2],
      account_Id: splitValues[4],
      clinic_Id: splitValues[5],
    };
    const result = await PaymentService.getTransactionStatus(data);
    if (result) {
      setStatus(result?.data);
    }
  };

  const convertUTCDateLocal = (utcDate, format = "DD/MM/YYYY, HH:mm:ss") => {
    if (!utcDate) return "";
    const localDate = moment.utc(utcDate).local();
    return localDate.format(format);
  };

  const handlepayment = () => {
    history.push("/dashboard");
  };

  const [promptClick, setPromptClick] = useState(false);

  return (
    <Layouts>
      {/* <NavigationPrompt renderIfNotActive={true} when={!promptClick}>
        {({ isActive, onCancel, onConfirm }) => {
          if (isActive) {
            alert("jkdfhjkdsf");
          }
        }}
      </NavigationPrompt> */}
      <div>
        {status?.code == "PAYMENT_SUCCESS" ? (
          <div>
            <div className="bg-white p-6 rounded-xl shadow-lg mt-10 max-w-sm mx-auto border">
              <div className="text-center">
                <div className="bg-[#E6F2EE] w-12 h-12 rounded-full mx-auto mb-4 flex items-center justify-center">
                  <div className="bg-blue-500 w-7 h-7 rounded-full mx-auto flex items-center justify-center">
                    <BsPatchCheck className="text-white" />
                  </div>
                </div>
                <div>
                  <h2 className="text-xl text-gray-600 font-medium mb-2">
                    Payment Success!
                  </h2>
                  <p className=" mb-4 font-bold">₹ {status?.paidAmount}</p>
                </div>
              </div>
              <div className="border border-b-2"></div>
              <div className="m-3 ">
                <div className="flex justify-between ">
                  <p className="text-gray-600 font-medium text-base">
                    Transaction ID
                  </p>
                  <p className="text-gray-600 font-medium text-base">
                    {status?.transactionId}
                  </p>
                </div>
                <div className="flex justify-between mt-2">
                  <p className="text-gray-600 font-medium text-base">
                    Payment Time
                  </p>
                  <p className="text-gray-600 font-medium text-base">
                    {convertUTCDateLocal(status?.paidDate)}
                  </p>
                </div>
                <div className="">
                  <button
                    onClick={() => handlepayment()}
                    className="text-center border rounded-lg mt-5 w-full p-2 bg-blue-500 text-white text-lg font-medium"
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Layouts>
  );
};

export default PaymentInitiation;
