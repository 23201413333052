import React, { useState } from "react";
import { Menu, Modal, Tooltip } from "antd";
import { useHistory, useLocation } from "react-router-dom";

import { APP_ROUTES } from "../../PageRouting/approutes";
import { MdDashboard } from "react-icons/md";
import { FaCalendarAlt } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscCreditCard } from "react-icons/vsc";
import { IoSettings } from "react-icons/io5";
import { IoLogOutSharp } from "react-icons/io5";
import { BiSolidReport } from "react-icons/bi";

function Sidemenu({ isMenuOpen }) {
  const location = useLocation();
  const history = useHistory();
  const [pageRoute, setPageRoute] = useState("");

  const [showWarning, setShowWarning] = useState(false);
  const [LogoutWarning, setLogOutWarning] = useState(false);

  const handleLogOutOk = () => {
    localStorage.clear();
    history.push(pageRoute);
  };
  const handleLogOutCancel = () => {
    setLogOutWarning(false);
  };

  const handleOk = () => {
    setShowWarning(false);
  };
  const handleCancel = () => {
    setShowWarning(false);
  };

  const isMenuItemActive = (path) => {
    return location.pathname === path;
  };

  const handleNavigation = (path) => {
    if (location.pathname === APP_ROUTES.VIDEOCALL) {
      setPageRoute(path);
      setShowWarning(true);
    } else if (path === "/") {
      localStorage.clear();
      setPageRoute("");
      history.push(path);
    } else {
      setPageRoute("");
      history.push(path);
    }
  };

  const handleLogout = (path) => {
    if (location.pathname === APP_ROUTES.VIDEOCALL) {
      setPageRoute(path);
      setShowWarning(true);
    } else {
      setLogOutWarning(true);
      setPageRoute(path);
    }
  };

  return (
    <div className="min-h-screen fixed mt-20">
      <ul className={`flex gap-3 flex-col overflow-y-auto scrollBarWidth p-2`}>
        <Modal
          title="Alert!"
          open={showWarning}
          onOk={handleOk}
          onCancel={handleCancel}
          width={"30%"}
          footer={
            <div className="">
              <button
                className="mx-1 p-1 w-36 font-semibold  rounded-md  bg-white border border-blue-600"
                onClick={handleCancel}
              >
                No, Continue
              </button>
              <button
                className="mx-1 p-1 w-36 font-semibold text-white rounded-md"
                onClick={() => history.push(pageRoute)}
              >
                Yes, Leave
              </button>
            </div>
          }
        >
          You haven't saved your changes. Are you sure you want to proceed?
        </Modal>

        <Modal
          title="Logout"
          open={LogoutWarning}
          onOk={handleLogOutOk}
          onCancel={handleLogOutCancel}
          width={"20%"}
          footer={
            <div className="flex">
              <button
                className="mx-1 p-1 w-36 font-semibold bg-white border-blue-600 border  rounded-md"
                onClick={handleLogOutCancel}
              >
                Cancel
              </button>
              <button
                className="mx-1 p-1 w-36 font-semibold  border border-blue-600 text-white rounded-md"
                onClick={handleLogOutOk}
              >
                Yes
              </button>
            </div>
          }
        >
          Are you sure to log out?
        </Modal>
        <li>
          <div
            onClick={() => handleNavigation(APP_ROUTES.DASHBOARD)}
            className={`flex items-center gap-3 md:px-2 py-2  cursor-pointer  ${
              isMenuItemActive(APP_ROUTES.DASHBOARD)
                ? "bg-[#E6F4FF]  rounded-lg"
                : " "
            } `}
          >
            <div>
              <Tooltip placement="right" title={"Dashboard"}>
                <MdDashboard
                  className={` h-5 w-5 ${
                    isMenuItemActive(APP_ROUTES.DASHBOARD)
                      ? "text-white bg-[#007AFC] p-[3px] rounded-lg"
                      : " text-[#0283C6] "
                  }`}
                />
              </Tooltip>
            </div>
            <span
              className={`${
                isMenuItemActive(APP_ROUTES.DASHBOARD)
                  ? "text-[#007AFC] font-bold"
                  : "text-[#041346] "
              }  font-semibold md:text-base text-sm ${
                !isMenuOpen ? "hidden" : ""
              }`}
            >
              Dashboard
            </span>
          </div>
        </li>
        <li>
          <div
            onClick={() => handleNavigation(APP_ROUTES.APPOINTMENTS)}
            className={`flex items-center gap-3 md:px-2 py-2  cursor-pointer  ${
              isMenuItemActive(APP_ROUTES.APPOINTMENTS)
                ? "bg-[#E6F4FF]  rounded-lg"
                : " "
            } `}
          >
            <div>
              <Tooltip placement="right" title={"Appointments"}>
                <FaCalendarAlt
                  className={` h-5 w-5 ${
                    isMenuItemActive(APP_ROUTES.APPOINTMENTS)
                      ? "text-white bg-[#007AFC] p-[3px] rounded-lg"
                      : " text-[#0283C6] "
                  }`}
                />
              </Tooltip>
            </div>
            <span
              className={`${
                isMenuItemActive(APP_ROUTES.APPOINTMENTS)
                  ? "text-[#007AFC] font-bold"
                  : "text-[#041346]"
              } font-semibold md:text-base text-sm ${
                !isMenuOpen ? "hidden" : ""
              }`}
            >
              Appointments
            </span>
          </div>
        </li>
        <li>
          <div
            onClick={() => handleNavigation(APP_ROUTES.PATIENTS)}
            className={`flex items-center gap-3 md:px-2 py-2  cursor-pointer  ${
              isMenuItemActive(APP_ROUTES.PATIENTS)
                ? "bg-[#E6F4FF]  rounded-lg"
                : " "
            } `}
          >
            <div>
              <Tooltip placement="right" title={"Patients"}>
                <FiUsers
                  className={` h-5 w-5 ${
                    isMenuItemActive(APP_ROUTES.PATIENTS)
                      ? "text-white bg-[#007AFC] p-[3px] rounded-lg"
                      : "text-[#0283C6]  "
                  }`}
                />
              </Tooltip>
            </div>
            <span
              className={`${
                isMenuItemActive(APP_ROUTES.PATIENTS)
                  ? "text-[#007AFC] font-bold"
                  : "text-[#041346]"
              } font-semibold md:text-base text-sm ${
                !isMenuOpen ? "hidden" : ""
              }`}
            >
              Patients
            </span>
          </div>
        </li>
        <li>
          <div
            onClick={() => handleNavigation(APP_ROUTES.PAYMENTS)}
            className={`flex items-center gap-3 md:px-2 py-2  cursor-pointer  ${
              isMenuItemActive(APP_ROUTES.PAYMENTS)
                ? "bg-[#E6F4FF]  rounded-lg"
                : " "
            } `}
          >
            <div>
              <Tooltip placement="right" title={"Payments"}>
                <VscCreditCard
                  className={` h-5 w-5 ${
                    isMenuItemActive(APP_ROUTES.PAYMENTS)
                      ? "text-white bg-[#007AFC] p-[3px] rounded-lg"
                      : "text-[#0283C6]  "
                  }`}
                />
              </Tooltip>
            </div>
            <span
              className={`${
                isMenuItemActive(APP_ROUTES.PAYMENTS)
                  ? "text-[#007AFC] font-bold"
                  : "text-[#041346]"
              } font-semibold md:text-base text-sm ${
                !isMenuOpen ? "hidden" : ""
              }`}
            >
              Payments
            </span>
          </div>
        </li>
        <li>
          <div
            onClick={() => handleNavigation(APP_ROUTES.BILLINGREPORTS)}
            className={`flex items-center gap-3 md:px-2 py-2  cursor-pointer  ${
              isMenuItemActive(APP_ROUTES.BILLINGREPORTS)
                ? "bg-[#E6F4FF] rounded-lg"
                : " "
            } `}
          >
            <div>
              <Tooltip placement="right" title={"Billing Reports"}>
                <BiSolidReport
                  className={` h-5 w-5 ${
                    isMenuItemActive(APP_ROUTES.BILLINGREPORTS)
                      ? "text-white bg-[#007AFC] p-[3px] rounded-lg"
                      : "text-[#0283C6]  "
                  }`}
                />
              </Tooltip>
            </div>
            <span
              className={`${
                isMenuItemActive(APP_ROUTES.BILLINGREPORTS)
                  ? "text-[#007AFC] font-bold"
                  : "text-[#041346]"
              } font-semibold md:text-base text-sm ${
                !isMenuOpen ? "hidden" : ""
              }`}
            >
              Billing Reports
            </span>
          </div>
        </li>
        <li>
          <div
            onClick={() => handleNavigation(APP_ROUTES.SETTINGS)}
            className={`flex items-center gap-3 md:px-2 py-2  cursor-pointer  ${
              isMenuItemActive(APP_ROUTES.SETTINGS)
                ? "bg-[#E6F4FF]  rounded-lg"
                : " "
            } `}
          >
            <div>
              <Tooltip placement="right" title={"Change Password"}>
                <IoSettings
                  className={` h-5 w-5 ${
                    isMenuItemActive(APP_ROUTES.SETTINGS)
                      ? "text-white bg-[#007AFC] p-[3px] rounded-lg"
                      : " text-[#0283C6] "
                  }`}
                />
              </Tooltip>
            </div>
            <span
              className={`${
                isMenuItemActive(APP_ROUTES.SETTINGS)
                  ? "text-[#007AFC] font-bold"
                  : "text-[#041346]"
              } font-semibold md:text-base text-sm ${
                !isMenuOpen ? "hidden" : ""
              }`}
            >
              Change Password
            </span>
          </div>
        </li>
        <li>
          <div
            onClick={() => handleLogout(APP_ROUTES.LOGINFORM)}
            className={`flex items-center gap-3 md:px-2 py-2  cursor-pointer  ${
              isMenuItemActive(APP_ROUTES.LOGINFORM)
                ? "bg-[#E6F4FF]  rounded-lg"
                : " "
            } `}
          >
            <div>
              <Tooltip placement="right" title={"Logout"}>
                <IoLogOutSharp
                  className={` h-5 w-5 ${
                    isMenuItemActive(APP_ROUTES.LOGINFORM)
                      ? "text-white bg-[#007AFC] p-[3px] rounded-lg"
                      : "text-[#0283C6]  "
                  }`}
                />
              </Tooltip>
            </div>
            <span
              className={`${
                isMenuItemActive(APP_ROUTES.LOGOUT)
                  ? "text-[#007AFC] font-bold"
                  : "text-[#041346]"
              } font-semibold md:text-base text-sm ${
                !isMenuOpen ? "hidden" : ""
              }`}
            >
              Logout
            </span>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default Sidemenu;
