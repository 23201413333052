import React, { useEffect, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import AppointmentService from "../../../Services/AppointmentService";
import "./calendarStyle.css";
import { FaVideo } from "react-icons/fa";
import { IoPerson } from "react-icons/io5";

const localizer = momentLocalizer(moment);

function AppointmentCalender() {
  const [events, setEvents] = useState([]);

  let loginData = localStorage.getItem("login_result");
  let loginDataObject = loginData ? JSON.parse(loginData) : null;

  useEffect(() => {
    let payload = {
      userId: loginDataObject.user.userId,
    };
    AppointmentService.AppointmentFilter(payload).then((res) => {
      const events = res.data.map((appointment) => ({
        title: appointment.patientName,
        start: new Date(
          `${moment(appointment.appointmentDate, "MM/DD/YYYY").format(
            "YYYY-MM-DD"
          )}T${appointment.startTime}`
        ),
        end: new Date(
          `${moment(appointment.appointmentDate, "MM/DD/YYYY").format(
            "YYYY-MM-DD"
          )}T${appointment.endTime}`
        ),
        data: {
          status: appointment.status,
          serviceCode: appointment.serviceCode,
        },
      }));
      setEvents(events);
    });
  }, []);

  const CustomToolbar = ({ label, onNavigate, onView }) => (
    <div className="rbc-toolbar margin">
      <span className="rbc-btn-group">
        <div className="cbtnstyle">
          <button
            type="button"
            onClick={() => onNavigate("PREV")}
            className="button1"
          >
            <FaArrowLeft className="text-white" />
          </button>

          <button
            type="button"
            onClick={() => onNavigate("NEXT")}
            className="button1"
          >
            <FaArrowRight className="text-white" />
          </button>
        </div>
      </span>
      <span className="rbc-toolbar-label ">{label}</span>
      <div className="flex items-center gap-2">
        <button onClick={() => onView("week")} className="calenderbutton">
          Week
        </button>
        <button onClick={() => onView("day")} className="calenderbutton">
          Day
        </button>
        <button onClick={() => onView("month")} className="calenderbutton">
          Month
        </button>
        {/* <button onClick={() => onView("agenda")} className="calenderbutton">
          Agenda
        </button> */}
      </div>
    </div>
  );

  const components = {
    event: (props) => {
      const today = new Date();
      const eventStart = new Date(props.event.start);
      let bgColor = "";

      if (eventStart.toDateString() === today.toDateString()) {
        bgColor = "bg-green-500 h-full  px-1";
      } else if (eventStart > today) {
        bgColor = "bg-blue-500 h-full px-1";
      } else {
        bgColor = "bg-yellow-500 h-full px-1";
      }

      return (
        <div
          className={`${bgColor} text-white rounded font-semibold flex gap-2 pt-1`}
        >
          <span> {props.title}</span>
          <span>
            {props.event.data.serviceCode === "APPT_VIDEO" ? (
              <FaVideo className="text-blue-600" />
            ) : (
              <IoPerson className="text-blue-600" />
            )}
          </span>
        </div>
      );
    },
  };

  const scrollToTime = moment().set({ hour: 8, minute: 0 }).toDate();

  return (
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        selectable={false}
        components={{
          toolbar: CustomToolbar,
          event: components.event,
        }}
        scrollToTime={scrollToTime}
        defaultView="week"
        views={["month", "week", "day", "agenda"]}
      />
    </div>
  );
}

export default AppointmentCalender;
