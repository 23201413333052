import React, { useRef, useState } from "react";
import { Col, Row, Form, Input, DatePicker, Select, Modal, message } from "antd";
import moment from "moment";
import MedicalService from "../../../../../Services/MedicalHistoryService";
import MedicalHistoryService from "../../../../../Services/MedicalHistoryService";

function AddFamilyHistory(props) {
  const [form] = Form.useForm();
  const activePatient = JSON.parse(localStorage.getItem("patient"));
  const patientCode = activePatient?.patientId;
  const [pastValue, setPastValue] = useState();
  const [givenDate, setGivenDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   formRef.current.resetFields();
  //   setGivenDate('');
  // },[props]);

  const formRef = useRef();
  const { TextArea } = Input;

  const handleCancel = () => {
    formRef.current.resetFields();
    setGivenDate('');
    props.onClose();
  };

  const handleDate = (date, dateString) => {
    if (dateString) {
      const constructedTime = dateString + " " + "00:00:00";
      console.log(dateString);
      setGivenDate(constructedTime);
    }
    else{
      setGivenDate('');
    }
  }

  const disabledFutureDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const handlehealthChange = (value) => {
    setPastValue(value);
  };

  const convertUTCDate = (date, format) => {
    if (!format) {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
        " " +
        d.getHours() +
        ":" +
        d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return moment(new Date(date)).utc().format(format);
  };

  const onFinish = async(e) => {
    e.preventDefault();
    setIsLoading(true);

    const userObj = localStorage.getItem('login_result')
    const userData = userObj ? JSON.parse(userObj) : null;

    const currDate = new Date().toISOString();
    const date = currDate.split('T')[0];
    const time = currDate.split('T')[1].slice(0, 8);
    const constructedTime = date + " " + time;

    const { description } = formRef.current.getFieldValue();
    if (!description || !givenDate) {
      message.error("Please fill all fields");
      setIsLoading(false);
      return;
    }
    let payload = {
      account_Id: userData?.user?.account_Id,
      clinic_Id: userData?.user?.clinic_Id,
      userId:userData?.user?.userId,
      createdDate: constructedTime,
      givenDate: givenDate,
      createdBy: userData?.user?.userId,
      modifiedBy: userData?.user?.userId,
      status: 1,
      patientId: patientCode,
      description: description,
      patientBloodGroup: activePatient?.bloodGroup,
      patientDob: activePatient?.dob,
      patientEmail: activePatient?.email,
      patientName: activePatient?.firstName,
      modifiedDate: constructedTime,
      fromDate: givenDate,
      toDate: givenDate,
    };
    try{
      const saveResponse = await MedicalService.AddFamilyHistory(payload);
      if(saveResponse.data === 1){
        let getPayload = {
          account_Id:payload.account_Id,
          // clinic_Id:data.clinic_Id,
          userId: (props.permissionData === null || props.permissionData === "N") ? userData?.user?.userId : '',
          patientId: payload.patientId,
          status: 1,
        };
        const getResponse =  await MedicalHistoryService.PatientFamilyHistory(getPayload);
        formRef.current.resetFields();
        props.handleLatestUpdate(getResponse.data);
        setIsLoading(false);
        props.onClose();
      }
    }
    catch(e){
      console.log(e);
      setIsLoading(false);
    }
    // MedicalService.AddFamilyHistory(payload)
    //   .then((result) => {
    //     formRef.current.resetFields();
    //     props.handleLatestUpdate(result.data);
    //     setIsLoading(false);
    //     props.onClose();
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     setIsLoading(false);
    //   });
  };

  return (
    <div>
      <Form
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        form={form}
        ref={formRef}
      >
        <Row gutter={20}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span className="font-medium">
                  Family History{" "}
                  <span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="description"
            >
              <TextArea rows={3} placeholder="Enter Family History" />

            </Form.Item>
          </Col>

          <Col className="gutter-row" span={12}>
            <Form.Item label={<span className="font-medium">Date{" "}<span style={{ color: "#FF000F" }}>*</span></span>} name="givenDate">
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Select"
                format="YYYY-MM-DD"
                onChange={handleDate}
                disabledDate={disabledFutureDate}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Col className="gutter-row" span={24}>
        <div className="flex items-center w-full justify-end my-5">
          <button
            onClick={handleCancel}
            className="rounded text-white text-sm px-6 py-1.5"
          >
            Cancel
          </button>
          <button disabled={isLoading} onClick={onFinish} className="bg-brand-primaryColor rounded px-5 py-1.5 text-white text-sm ml-3 disabled:cursor-not-allowed disabled:opacity-50">
            Submit
          </button>
        </div>
      </Col>
    </div>
  );
}
export default AddFamilyHistory

