import {
  Col,
  Input,
  Row,
  Form,
  DatePicker,
  Select,
  TimePicker,
  Button,
  message,
  Radio,
  Checkbox,
} from "antd";
import React, { useState } from "react";
import enrollmentServices from "../../../Services/enrollmentServices";
import CommonFunctions from "../../Shared/CommonFunctions";
import dayjs from "dayjs";

function NewPatientInvite({
  paymentType,
  setPaymentType,
  appointmentsList,
  handleOk,
  form,
  handleKeyPress,
  disableRequired,
  setDisableRequired,
  quickInvite,
  setQuickInvite,
  paymentReceived,
  setPaymentReceived,
  callType,
  setCallType,
}) {
  const [save, setSave] = useState(false);
  const [endTime, setEndTime] = useState(null);
  const [submitType, setSubmitType] = useState(null);

  const disabledDate = (current) => {
    return current && current < new Date().setHours(0, 0, 0, 0);
  };

  const storedLoginResult = localStorage.getItem("login_result");
  const loginResultObject = storedLoginResult
    ? JSON.parse(storedLoginResult)
    : null;

  const handleCallTypeChange = (value) => {
    setCallType(value);
  };

  const handleCancelClick = () => {
    setPaymentType("Cash");
    setCallType("APPT_VIDEO");
    handleOk();
  };

  const handleClick = async (payload) => {
    payload.createdDate = CommonFunctions.convertUTCDate(
      new Date(),
      "YYYY-MM-DD HH:mm:ss"
    );
    payload.modifiedDate = CommonFunctions.convertUTCDate(
      new Date(),
      "YYYY-MM-DD HH:mm:ss"
    );
    payload.appointmentDate = CommonFunctions.convertDate(
      payload.appointmentDate
    );

    payload.firstName =
      payload.firstName.charAt(0).toUpperCase() + payload.firstName.slice(1);

    payload.lastName =
      payload?.lastName.charAt(0).toUpperCase() + payload?.lastName.slice(1);
    payload.startTime = CommonFunctions.ConvertTime(payload.startTime);
    payload.endTime = CommonFunctions.ConvertTime(payload.endTime);
    payload.createdBy = loginResultObject.user.userId;

    payload.modifiedBy = loginResultObject.user.userId;
    payload.dob = payload.dob
      ? dayjs(payload?.dob, "MM/DD/YYYY").format("MM/DD/YYYY")
      : "";
    payload.userId = loginResultObject.user.userId;
    payload.account_Id = loginResultObject.user.account_Id;
    payload.clinic_Id = loginResultObject.user.clinic_Id;
    payload.patientType = "N";
    payload.specialityCode = loginResultObject.user?.specialityCode;
    payload.serviceName =
      payload.serviceCode === "APPT_VIDEO"
        ? "Video Consultation"
        : payload.serviceCode === "APPT_INPERSON"
        ? "Inperson Consultation"
        : "";
    if (payload.startTime > payload.endTime) {
      message.error("Select Valid Start time and End time");
      setSave(false);
      return;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (emailRegex.test(payload.email)) {
      console.log("");
    } else {
      message.error("enter valid email in the send invite");
      return;
    }

    const currentTime = CommonFunctions.ConvertTime(dayjs());

    if (
      payload.startTime < currentTime &&
      dayjs().format("MM/DD/YYYY") == payload.appointmentDate
    ) {
      message.error("Scheduled time has already ended");
      setSave(false);
      return;
    }

    if (submitType === "1") {
      setSave(true);
      payload.status = 0;
      payload.paymentReceived = "N";
      let data = JSON.stringify(payload);

      CommonFunctions.NewPatientPayment(data)
        .catch((error) => {
          console.error("An error occurred:", error);
          message.error("An Error occurred, try again");
        })
        .finally(() => {
          setSave(false);
        });
    } else if (submitType === "2") {
      if (payload.paymentType == "Cash" && !paymentReceived) {
        message.error("Confirm Payment received by clicking the check box");
        setSave(false);
        return;
      }

      setSave(true);
      try {
        payload.paymentReceived = paymentReceived ? "Y" : "N";
        payload.status = 1;
        let data = JSON.stringify(payload);
        const result = await enrollmentServices.Invite(data);
        if (result.data.code === "FAILED") {
          message.error(result.data.message);
          setSave(false);
          return;
        }
        if (result.data.code === "SUCCESS") {
          message.success("New Patient Registration Successfull");
          message.success("Invitation sent Successfully");
          appointmentsList();
          handleOk();
        }
      } catch (error) {
        console.error("An error occurred:", error);
        message.error("An Error occured please try again");
      } finally {
        setSave(false);
      }
    } else if (submitType == "3") {
      setSave(true);
      try {
        if (
          payload.paymentType == "Digital" &&
          payload.paymentConsultation == "AC"
        ) {
          message.error(
            "Share payment link is not available for After Consultation"
          );
          return;
        }
        payload.status = 0;
        payload.paymentType = "Share_Link";
        let data = JSON.stringify(payload);
        const result = await enrollmentServices.Invite(data);
        if (result.data.code === "FAILED") {
          message.error(result.data.message);
          setSave(false);
          return;
        }
        if (result.data.code === "SUCCESS") {
          message.success("New Patient Registration Successfull");
          message.success("Payment Link and invitation sent Successfully");
          appointmentsList();
          handleOk();
        }
      } catch (error) {
        console.error("An error occurred:", error);
        message.error("An Error occured please try again");
      } finally {
        setSave(false);
      }
    }
  };

  const handleStartTimeChange = (time) => {
    if (time) {
      const endTime = dayjs(time).add(15, "minutes");
      setEndTime(endTime);
      form.setFieldsValue({ endTime });
    } else {
      setEndTime(null);
    }
  };

  const genders = [
    {
      value: "M",
      label: "Male",
    },
    {
      value: "F",
      label: "Female",
    },
  ];
  const salutationOptions = [
    {
      value: "Mr.",
      label: "Mr.",
    },
    {
      value: "Mrs.",
      label: "Mrs.",
    },
    {
      value: "Miss.",
      label: "Miss.",
    },
    {
      value: "Ms.",
      label: "Ms.",
    },
  ];

  const disabledFutureDate = (current) => {
    return current && current > new Date().setHours(23, 59, 59, 999);
  };

  const onPaymentStatusChange = () => {
    setPaymentReceived(!paymentReceived);
  };

  const onPaymentTypeChange = (value) => {
    setPaymentType(value);
    if (value === "Cash") {
      setPaymentReceived(true);
      form.setFieldsValue({ paymentConsultation: "BC" });
    } else if (value === "Digital") {
      form.setFieldsValue({ paymentConsultation: "BC" });
      setPaymentReceived(false);
    } else {
      form.setFieldsValue({ paymentConsultation: null });
      setPaymentReceived(false);
    }
  };

  const onQuickInviteChange = () => {
    setQuickInvite(!quickInvite);
    setDisableRequired(!disableRequired);
  };

  const handleSubmitType = (type) => {
    setSubmitType(type);
  };

  const [send, setSend] = useState("");

  const isValidEmail = (emailValue) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(emailValue);
  };

  const isValidMobile = (mobileValue) => {
    const mobilePattern = /^\d+$/;
    return mobilePattern.test(mobileValue);
  };

  const onSendChange = (e) => {
    let value = e.target.value;
    setSend(value);

    if (isValidEmail(value)) {
      console.log("value", value);
      form.setFieldsValue({
        email: value,
        mobile: "",
      });
    } else if (isValidMobile(value)) {
      form.setFieldsValue({
        mobile: value,
        email: "",
      });
    } else {
      form.setFieldsValue({
        mobile: "",
        email: "",
      });
    }
  };

  return (
    <div className="my-2">
      {/* <div>
        <Checkbox
          checked={quickInvite}
          className="mr-2"
          onChange={onQuickInviteChange}
        />
        Quick Invite
      </div> */}
      <Form
        className="formDesign mt-7 mb-10"
        layout="vertical"
        form={form}
        onFinish={handleClick}
        initialValues={{
          serviceCode: "APPT_VIDEO",
          paymentType: "Cash",
          totalAmount: "500",
          paymentConsultation: "BC",
          appointmentDate: dayjs(),
        }}
      >
        <Form.Item
          label={
            <span>
              Send Invite <span className="text-red-500">*</span>
            </span>
          }
          name="sendInvite"
          rules={[
            {
              required: true,
              message: "Please Enter Mail or Mobile Number to send Invite!",
            },
          ]}
        >
          <Input
            type="text"
            placeholder="Send Invite (Email or Mobile)"
            value={send}
            onChange={(e) => onSendChange(e)}
          />
        </Form.Item>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label={
                <span>
                  First Name
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "Please Enter First Name!",
                },
              ]}
            >
              <Input className="w-full" placeholder="Enter First Name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={<span>Last Name</span>} name="lastName">
              <Input className="w-full" placeholder="Enter Last Name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={<span>D.O.B</span>} name="dob">
              <DatePicker
                className="w-full"
                format={"DD/MM/YYYY"}
                disabledDate={disabledFutureDate}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={<span>Gender</span>} name="gender">
              <Select className="w-full" options={genders} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={
                <span>
                  Mobile Number
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="mobile"
              rules={[
                {
                  required: true,
                  message: "Please Enter Mobile Number!",
                },
              ]}
            >
              <Input
                type="text"
                maxLength={15}
                onKeyDown={handleKeyPress}
                placeholder="Mobile"
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label={<span>Email ID</span>} name="email">
              <Input type="email" placeholder="Enter Email" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <span>
                  Appointment Date
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="appointmentDate"
              rules={[
                {
                  required: true,
                  message: "Please Enter appointmentDate!",
                },
              ]}
            >
              <DatePicker
                format={"DD/MM/YYYY"}
                className="w-full"
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <span>
                  Start Time <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="startTime"
              rules={[
                {
                  required: true,
                  message: "Please Enter Start Time!",
                },
              ]}
            >
              <TimePicker
                use12Hours
                format="h:mm a"
                className="w-full"
                onChange={handleStartTimeChange}
                minuteStep={10}
                needConfirm={false}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <span>
                  End Time <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="endTime"
              rules={[
                {
                  required: true,
                  message: "Please Enter End Time!",
                },
              ]}
            >
              <TimePicker
                use12Hours
                format="h:mm a"
                className="w-full"
                value={endTime}
                minuteStep={10}
                needConfirm={false}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <span>
                  Service Type
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="serviceCode"
              rules={[
                {
                  required: true,
                  message: "Please Enter Consultation Type!",
                },
              ]}
            >
              <Select
                showSearch
                className="w-full"
                placeholder="Search to Consltations"
                onChange={handleCallTypeChange}
              >
                <Select.Option value="APPT_INPERSON">In-person</Select.Option>
                <Select.Option value="APPT_VIDEO">Video</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={
                <span>
                  Payment Type <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="paymentType"
              rules={[
                {
                  required: true,
                  message: "Please Enter Payment Type!",
                },
              ]}
            >
              <Select
                showSearch
                className="w-full"
                placeholder="Search to Payment mode"
                onChange={onPaymentTypeChange}
              >
                <Select.Option value="Cash">Cash</Select.Option>
                <Select.Option value="Digital">Digital Payment</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span>Reason for Consultation</span>}
              name="consultationReason"
            >
              <Input placeholder="Enter Consultation Reason" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={
                <span>
                  Enter Fees <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="totalAmount"
              rules={[
                {
                  required: true,
                  message: "Please Enter Total Amount!",
                },
              ]}
            >
              <Input placeholder="Enter Amount" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={
                <span>
                  Payment <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="paymentConsultation"
              rules={[
                {
                  required: true,
                  message: "Please Enter Payment Consultation!",
                },
              ]}
            >
              <Radio.Group
                disabled={
                  paymentType === "Cash" || paymentType === "Digital"
                    ? true
                    : false
                }
              >
                <Radio value="BC" className="text-black">
                  Before Consultation
                </Radio>
                <Radio value="AC" className="text-black">
                  After Consultation
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col span={8}>
            {paymentType == "Cash" && (
              <Form.Item label={<span></span>} name="">
                <div>
                  <Checkbox
                    checked={paymentReceived}
                    className="mr-2"
                    onChange={onPaymentStatusChange}
                  />
                  Payment Received
                </div>
              </Form.Item>
            )}
          </Col>
        </Row>
        <Form.Item>
          <div className="flex gap-2 justify-end">
            <Button className="h-10 Btnwhite px-8" onClick={handleCancelClick}>
              Cancel
            </Button>
            {paymentType == "Digital" ? (
              <>
                <Button
                  className="h-10 bg-blue-600 text-white px-4"
                  type="primary"
                  disabled={save}
                  htmlType="submit"
                  onClick={() => handleSubmitType("3")}
                >
                  Share Payment Link
                </Button>
                <Button
                  className="h-10 bg-blue-600 text-white px-4"
                  type="primary"
                  disabled={save}
                  htmlType="submit"
                  onClick={() => handleSubmitType("1")}
                >
                  {callType === "APPT_VIDEO"
                    ? "Pay & Send Invite"
                    : "Pay & Schedule"}
                </Button>
                <Button
                  className="h-10 px-10"
                  type="primary"
                  htmlType="submit"
                  disabled={save}
                  onClick={() => handleSubmitType("2")}
                >
                  {callType === "APPT_VIDEO" ? "Send Invite" : "Schedule"}
                </Button>
              </>
            ) : (
              <>
                <Button
                  className="h-10 px-10"
                  type="primary"
                  htmlType="submit"
                  disabled={save}
                  onClick={() => handleSubmitType("2")}
                >
                  {callType === "APPT_VIDEO" ? "Send Invite" : "Schedule"}
                </Button>
              </>
            )}
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default NewPatientInvite;
