import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Form, Input, DatePicker, Select, Modal } from "antd";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { message } from "antd";
import MedicalService from "../../../../../Services/MedicalHistoryService";
import MedicalHistoryService from "../../../../../Services/MedicalHistoryService";

export default function AddAllergy(props) {
  const activePatient = JSON.parse(localStorage.getItem("patient"));
  const patientCode = activePatient?.patientId;
  const [PatientAlleryData, setPatientAlleryData] = useState([]);
  const [ICDCodedata, setICDCodedata] = useState([]);
  const [ICDCodefood, setICDCodedatafood] = useState([]);
  const [selectedHealthConditions, setSelectedHealthConditions] = useState([]);
  const [selectedHealthCondition, setSelectedHealthCondition] = useState(null);

  const [drugValue, setDrugValue] = useState();
  const [foodValue, setFoodValue] = useState();
  const [chemicalValue, setChemicalValue] = useState();
  const [otherValue, setOtherValue] = useState();
  const [givenDate, setGivenDate] = useState();
  const [manualDrug, setManualDrug] = useState('');
  const [manualFood, setManualFood] = useState('');
  const [manualChemical, setManualChemical] = useState('');
  const [manualOther, setManualOther] = useState('');
  const drugInputRef = useRef(null);
  const foodInputRef = useRef(null);
  const chemicalInputRef = useRef(null);
  const otherInputRef = useRef(null);
  const formRef = useRef();

  // useEffect(() => {
  //   formRef.current.resetFields();
  //   setGivenDate('');
  // },[props]);

  useEffect(() => {
    if (manualDrug && drugInputRef.current) drugInputRef.current.focus();
  }, [manualDrug]);

  useEffect(() => {
    if (manualFood && foodInputRef.current) foodInputRef.current.focus();
  }, [manualFood]);

  useEffect(() => {
    if (manualChemical && chemicalInputRef.current) chemicalInputRef.current.focus();
  }, [manualChemical]);

  useEffect(() => {
    if (manualOther && otherInputRef.current) otherInputRef.current.focus();
  }, [manualOther]);

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  const handleDrug = (values) => {
    setDrugValue(values);
  };

  const handleFood = (values) => {
    setFoodValue(values);
  };

  const handleChemical = (values) => {
    setChemicalValue(values);
  };

  const handleOther = (values) => {
    setOtherValue(values);
  };

  const convertUTCDate = (date, format) => {
    if (!format) {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
        " " +
        d.getHours() +
        ":" +
        d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY"; // YYYY-MM-DD hh:mm:ss
    return moment(new Date(date)).utc().format(format);
    // return moment(new Date(date)).add(moment(date).utcOffset(), 'm').utc().seconds(30).format(format);
  };

  const DrugOption = [
    { name: "Penicillin", value: "Penicillin" },
    { name: "Sulfonamide", value: "Sulfonamide" },
    { name: "ASA", value: "ASA" },
    { name: "NSAIDs", value: "NSAIDs" },
    { name: "Aspirin", value: "Aspirin" },
    { name: "Ibuprofen", value: "Ibuprofen" },
    { name: "Naproxen", value: "Naproxen" },
  ];

  const ChemicalOption = [
    { name: "Dyes", value: "Dyes" },
    { name: "Colour Additives", value: "Colour Additives" },
    { name: "Cosmetics", value: "Cosmetics" },
    { name: "Soap", value: "Soap" },
    { name: "Moisturiser", value: "Moisturiser" },
    { name: "Perfume", value: "Perfume" },
  ];
  const FoodOption = [
    { name: "Egg", value: "Egg" },
    { name: "Peanut", value: "Peanut" },
    { name: "Peanut butter", value: "Peanut butter" },
    { name: "Milk", value: "Milk" },
    { name: "Nuts", value: "Nuts" },
    { name: "Soy", value: "Soy" },
    { name: "Fish", value: "Fish" },
    { name: "Animal meat", value: "Animal meat" },
    { name: "Wheat", value: "Wheat" },
    { name: "Animal Protein", value: "Animal Protein" },
  ];

  const OtherOption = [
    { name: "Animal Dander", value: "Animal Dander" },
    { name: "Insect Bite", value: "Insect Bite" },
    { name: "Insect Sting", value: "Insect Sting" },
    { name: "Pollen", value: "Pollen" },
    { name: "Insect Faeces", value: "Insect Faeces" },
    { name: "House Dust Mites", value: "House Dust Mites" },
    { name: "Mite Faeces", value: "Mite Faeces" },
    { name: "Natural Rubber Latex", value: "Natural Rubber Latex" },
  ];

  const disabledFutureDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const { TextArea } = Input;

  const onFinish = async (e) => {
    e.preventDefault();
    props.setIsLoading(true);

    const userObj = localStorage.getItem('login_result')
    const userData = userObj ? JSON.parse(userObj) : null;

    const currDate = new Date().toISOString();
    const date = currDate.split('T')[0];
    const time = currDate.split('T')[1].slice(0, 8);
    const constructedTime = date + " " + time;

    // const { drugAllergy, foodAllergy, chemicalAllergy, otherAllergy } = formRef.current.getFieldValue();
    // console.log("hiii ", formRef.current.getFieldValue());
    if (!(drugValue || manualDrug) || !(foodValue || manualFood) || !(chemicalValue || manualChemical) || !(otherValue || manualOther) || !givenDate) {
      message.error("Please fill mandatory fields");
      props.setIsLoading(false);
      return;
    }

    let payload = {
      account_Id: userData?.user?.account_Id,
      clinic_Id: userData?.user?.clinic_Id,
      userId: userData?.user?.userId,
      patientCode: patientCode,
      createdBy: userData?.user?.userId,
      status: 1,
      chemicalAllergy: chemicalValue?chemicalValue:manualChemical,
      foodAllergy: foodValue?foodValue:manualFood,
      drugAllergy: drugValue?drugValue:manualDrug,
      otherAllergy: otherValue?otherValue:manualOther,
      fromDate: null,
      toDate: null,
      modifiedBy: userData?.user?.userId,
      modifiedDate: constructedTime,
      createdDate: constructedTime,
      givenDate: givenDate
    };
    console.log(payload);

    try {
      const saveResponse = await MedicalService.AddPatientAllery([payload]);
      if (saveResponse.data === 1) {
        let allergyGetPayload = {
          account_Id: payload.account_Id,
          userId: (props.permissionData === null || props.permissionData === "N") ? userData?.user?.userId : '',
          // clinic_Id:data[0].clinic_Id,
          patientCode: payload.patientCode,
          status: 1
        }
        const getResponse = MedicalHistoryService.PatientAllergiesList(allergyGetPayload);
        setPatientAlleryData(getResponse.data);
        formRef.current.resetFields();
        props.handleLatestUpdate(getResponse.data);
        props.setIsLoading(false);
        props.onClose();
      }

    }
    catch (e) {
      console.log(e);
      props.setIsLoading(false);
    }
    // const response = await MedicalService.AddPatientAllery([payload]).then((result) => {
    //   setPatientAlleryData(result.data);
    //   formRef.current.resetFields();
    //   props.handleLatestUpdate(result.data);
    //   setIsLoading(false);
    //   props.onClose();
    //   message.success({
    //     content: "Allergy Details added successfully",
    //     duration: 3,
    //     className: "custom-toast-success",
    //   });
    // }).catch((e) => {
    //   console.log(e);
    //   setIsLoading(false);
    // })
  };

  const handleCancel = () => {
    formRef.current.resetFields();
    setGivenDate('');
    props.onClose();
  };

  const handleDate = (date, dateString) => {
    if (dateString) {
      const constructedTime = dateString + " " + "00:00:00";
      console.log(dateString);
      setGivenDate(constructedTime);
    }
    else {
      setGivenDate('');
    }
  }

  const handleDrugSearch = (value) => {
    const isPresent = DrugOption.findIndex((item) => item.name.includes(value));
    if (isPresent === -1) {
      setManualDrug(value);
    }
  }

  const handleFoodSearch = (value) => {
    const isPresent = FoodOption.findIndex((item) => item.name.includes(value));
    if (isPresent === -1) {
      setManualFood(value);
    }
  }

  const handleChemSearch = (value) => {
    const isPresent = ChemicalOption.findIndex((item) => item.name.includes(value));
    if (isPresent === -1) {
      setManualChemical(value);
    }
  }

  const handleOthSearch = (value) => {
    const isPresent = OtherOption.findIndex((item) => item.name.includes(value));
    if (isPresent === -1) {
      setManualOther(value);
    }
  }

  const handleManualDurg = (e) => {
    const value = e.target.value;
    if(value){
      setManualDrug(value);
    }
    else{
      setManualDrug('');
    }
  }

  const handleManualFood = (e) => {
    const value = e.target.value;
    if(value){
      setManualFood(value);
    }
    else{
      setManualFood('');
    }
  }

  const handleManualChem = (e) => {
    const value = e.target.value;
    if(value){
      setManualChemical(value);
    }
    else{
      setManualChemical('');
    }
  }

  const handleManualOth = (e) => {
    const value = e.target.value;
    if(value){
      setManualOther(value);
    }
    else{
      setManualOther('');
    }
  }

  return (
    <>
      <Form
        ref={formRef}
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}

      >
        <Row gutter={20}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span>
                  Drug Allergies <span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="drugAllergy"
            >
              <Select
                showSearch
                onSearch={handleDrugSearch}
                placeholder="Select"
                // value={drugValue}
                onChange={handleDrug}
                options={DrugOption}
                className={`${manualDrug ? 'hidden' : 'block'}`}
                filterOption={(input, option) =>
                  option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                allowClear
              />
              <Input type="text"
                className={`${manualDrug ? 'block' : 'hidden'}`}
                value={manualDrug}
                onChange={(e) => handleManualDurg(e)}
                ref={drugInputRef}
              />
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span>
                  Food Allergies <span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="foodAllergy"
            >
              <Select
                showSearch
                onSearch={handleFoodSearch}
                placeholder="Select"
                // value={foodValue}
                onChange={handleFood}
                options={FoodOption}
                filterOption={(input, option) =>
                  option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                className={`${manualFood ? 'hidden' : 'block'}`}
                allowClear
              />
               <Input type="text"
                className={`${manualFood ? 'block' : 'hidden'}`}
                value={manualFood}
                onChange={(e) => handleManualFood(e)}
                ref={foodInputRef}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span>
                  Chemical Allergies <span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="chemicalAllergy"
            >
              <Select
                showSearch
                onSearch={handleChemSearch}
                placeholder="Select"
                // value={chemicalValue}
                onChange={handleChemical}
                options={ChemicalOption}
                filterOption={(input, option) =>
                  option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                className={`${manualChemical ? 'hidden' : 'block'}`}
                allowClear
              />
              <Input type="text"
                className={`${manualChemical ? 'block' : 'hidden'}`}
                value={manualChemical}
                onChange={(e) => handleManualChem(e)}
                ref={chemicalInputRef}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span>
                  Other Allergies <span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="otherAllergy"
            >
              <Select
                showSearch
                onSearch={handleOthSearch}
                placeholder="Select"
                // value={otherValue}
                onChange={handleOther}
                options={OtherOption}
                filterOption={(input, option) =>
                  option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                className={`${manualOther ? 'hidden' : 'block'}`}
                allowClear
              />
              <Input type="text"
                className={`${manualOther ? 'block' : 'hidden'}`}
                value={manualOther}
                onChange={(e) => handleManualOth(e)}
                ref={otherInputRef}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span>
                  Date <span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="givenDate"
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Select"
                format="YYYY-MM-DD"
                onChange={handleDate}
                disabledDate={disabledFutureDate}
              />
            </Form.Item>
          </Col>

        </Row>
      </Form>
      <Col className="gutter-row" span={24}>
        <div className="flex items-center w-full justify-end my-5">
          <button
            onClick={handleCancel}
            className="rounded text-white text-sm px-6 py-1.5"
          >
            Cancel
          </button>
          <button disabled={props.isLoading} onClick={onFinish} className="bg-brand-primaryColor rounded px-5 py-1.5 text-white text-sm ml-3 disabled:cursor-not-allowed disabled:opacity-50">
            Submit
          </button>
        </div>
      </Col>
    </>
  );
}
