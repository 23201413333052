import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Input, Row, Table } from "antd";
import { useForm } from "antd/es/form/Form";

const Addvitals = ({consulationData, handleAddVitals, vitalsArrObj }) => {
  const [sys, setSys] = useState('');
  const [dia, setDia] = useState("");
  const [bmiValue, setBmiValue] = useState('');
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [pulse, setPulse] = useState('');
  const [heartRate, setHeartRate] = useState('');
  const [respirationRate, setRespirationRate] = useState('');
  const [temp, setTemp] = useState('');
  const [activeKey, setActiveKey] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const formRef = useRef();
  const [form] = useForm();

  // useEffect(() => {
 
  //   form.setFieldsValue({
  //     BPS:vitalsArrObj?.BPS || '',
  //     BPD:vitalsArrObj?.BPD || '',
  //     PO:vitalsArrObj?.PO || '',
  //     HR:vitalsArrObj?.HR || '',
  //     RR:vitalsArrObj?.RR || '',
  //     TP:vitalsArrObj?.TP || '',
  //     HI:vitalsArrObj?.HI || '',
  //     WE:vitalsArrObj?.WE || '',
  //     BMI:vitalsArrObj?.BMI || '',
  //     symptoms:vitalsArrObj?.symptoms || '',
  //     recommendation:vitalsArrObj?.recommendation || ''
  //    });
  // }, [vitalsArrObj]);
  
  useEffect(() => {
    form.setFieldsValue({
      BPS: consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "BPS")?.value : '',
      BPD: consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "BPD")?.value : '',
      PO: consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "PO")?.value : '',
      HR: consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "HR")?.value : '',
      RR: consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "RR")?.value : '',
      TP: consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "TP")?.value : '',
      HI: consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "HI")?.value : '',
      WE: consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "WE")?.value : '',
      BMI: consulationData?.patientVitals ? consulationData?.patientVitals?.find((item) => item.vitalCode === "BMI")?.value : '',
      symptoms: consulationData?.symptoms ? consulationData?.symptoms : '',
      recommendation: consulationData?.recommendation ? consulationData?.recommendation : ''
    });

  }, [consulationData])


  const handleFormSubmit = () => {
    handleAddVitals({
      BPS: sys,
      BPD: dia,
      PO: pulse,
      HR: heartRate,
      RR: respirationRate,
      TP: temp,
      HI: height,
      WE: weight,
      BMI: bmiValue
    });
    setShowTable(true);
    // formRef.current.resetFields();
    // setBmiValue('');
    // setHeight('');
    // setWeight('');
    // setSys('');
    // setDia('');
    // setTemp('');
    // setRespirationRate('');
    // setHeartRate('');
    // setPulse('');


  }


  const onChange = (key) => {
    setActiveKey(key);
  };

  const handleSysChange = (e) => {
    console.log(typeof e.target.value);
    if (e.target.value) {
      // setSys(value);
      handleAddVitals({BPS: e.target.value});
    }
    else {
      setSys('');
    }
  };

  const handleDiaChange = (e) => {
    console.log(e.target.value);
    if (e.target.value) {
      // setDia(value);
      handleAddVitals({BPD: e.target.value});
    }
    else {
      setDia('');
    }
  };

  const handleHeight = (e) => {
    const newHeight = Number(e.target.value);
    setHeight(newHeight);
    handleAddVitals({HI: newHeight});

    if (weight && newHeight) {
      const heightInMeters = newHeight / 100;
      const bmi = weight / (heightInMeters * heightInMeters);
      console.log(bmi.toFixed(1));
      setBmiValue(bmi.toFixed(1));
      handleAddVitals({ BMI: bmi.toFixed(1) });
    }
  }

  const handleWeight = (e) => {
    const newWeight = Number(e.target.value);
    setWeight(newWeight);
    handleAddVitals({ WE: newWeight });

    if (height && newWeight) {
      const heightInMeters = height / 100;
      const bmi = newWeight / (heightInMeters * heightInMeters);
      console.log(bmi)
      setBmiValue(bmi.toFixed(1));
      handleAddVitals({ BMI: bmi.toFixed(1)});
    }
  }

  const handlePulse = (e) => {
    if (e.target.value) {
      // setPulse(e.target.value);
      handleAddVitals({ PO: e.target.value });
    }
    else {
      setPulse('');
    }
  }

  const handleHeartRate = (e) => {
    if (e.target.value) {
      // setHeartRate(e.target.value);
      handleAddVitals({ HR: e.target.value });
    }
    else {
      setHeartRate('');
    }
  }

  const handleRespirationRate = (e) => {
    if (e.target.value) {
      // setRespirationRate(e.target.value);
      handleAddVitals({ RR: e.target.value });
    }
    else {
      setRespirationRate('');
    }
  }

  const handleTemp = (e) => {
    if (e.target.value) {
      // setTemp(e.target.value);
      handleAddVitals({ TP: e.target.value });
    }
    else {
      setTemp('');
    }
  }



  // const columns = [
  //   {
  //     title: "Blood Pressure(Sys)",
  //     dataIndex: "BPS",
  //     key: "BPS",
  //   },
  //   {
  //     title: "Blood Pressure(Dia)",
  //     dataIndex: "BPD",
  //     key: "BPD"
  //   },
  //   {
  //     title: "Pulse Rate",
  //     dataIndex: "PO",
  //     key: "PO",
  //   },
  //   {
  //     title: "Heart Rate",
  //     dataIndex: "HR",
  //     key: "HR",
  //   },
  //   {
  //     title: "Respiration Rate",
  //     dataIndex: "RR",
  //     key: "RR",
  //   },
  //   {
  //     title: "Temperature",
  //     dataIndex: "TP",
  //     key: "TP",
  //   },
  //   {
  //     title: "Height",
  //     dataIndex: "HI",
  //     key: "HI",
  //   },
  //   {
  //     title: "Weight",
  //     dataIndex: "WE",
  //     key: "WE",
  //   },
  //   {
  //     title: "BMI",
  //     dataIndex: "BMI",
  //     key: "BMI",
  //   }

  // ];

  // const customHeaderContent = (
  //   <thead className="">
  //     <tr>
  //       <th className="bg-blue-100 py-3 text-B">BP(Sys)</th>
  //       <th className="bg-blue-100 py-3 text-B">BP(Dia)</th>
  //       <th className="bg-blue-100 py-3 text-B">Pulse</th>
  //       <th className="bg-blue-100 py-3 text-B">Heart</th>
  //       <th className="bg-blue-100 py-3 text-B">Respiration</th>
  //       <th className="bg-blue-100 py-3 text-B">Temp.</th>
  //       <th className="bg-blue-100 py-3 text-B">Height</th>
  //       <th className="bg-blue-100 py-3 text-B">Weight</th>
  //       <th className="bg-blue-100 py-3 text-B">BMI</th>
  //     </tr>
  //   </thead>
  // );

  return (
    <div className="mt-6 border rounded-xl  p-1.5">
      <div className="font-bold py-2 text-lg">Vitals</div>
      <Form form={form} ref={formRef} layout="vertical">
        <div className="w-full">
          <div className="w-full flex items-center">
            <Col span={9}>
              <Form.Item
                label="Blood Pressure"
                name="bloodPressure"
                className="mx-2"

              >
                <div className="w-full flex rounded-lg border border-brand-textGray h-10">
                  <Form.Item name="BPS" className="outline-none">
                    <Input
                      value={sys}
                      onChange={handleSysChange}
                      maxLength={3}
                      placeholder="SYS"
                      className="outline-none border-none placeholder:text-end text-end"
                    // style={{ width: "10%" }}
                    />
                  </Form.Item>

                  <p className="text-xl text-center flex items-center justify-center mt-3">/</p>
                  <Form.Item name="BPD">
                    <Input
                      value={dia}
                      onChange={handleDiaChange}
                      maxLength={3}
                      placeholder="DIA"
                      className="outline-none border-none"
                      // style={{ width: "20%" }}
                      suffix={<span style={{ color: "#BEC0C3" }}>mmHg</span>}
                    />
                  </Form.Item>
                </div>
                {/* <Input.Group compact>
                    <Input
                      style={{ width: "50%" }}
                      className="h-10"
                      placeholder="Sys"
                      suffix={<span style={{ color: "#BEC0C3" }}>mmHg</span>}
                      // value={sys}
                      onChange={handleSysChange}
                    />
                    <Input
                      style={{ width: "50%" }}
                      className="h-10"
                      placeholder="Dia"
                      suffix={<span style={{ color: "#BEC0C3" }}>mmHg</span>}
                      // value={dia}
                      onChange={handleDiaChange}
                    />
                </Input.Group> */}
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name="PO" label="Pulse Ox" className="mx-2">
                <Input
                  className="h-10"
                  onChange={handlePulse}
                  suffix={<span style={{ color: "#BEC0C3" }}>%</span>}
                ></Input>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name="HR" label="Heart Rate" className="mx-2">
                <Input
                  className="h-10"
                  onChange={handleHeartRate}
                  suffix={<span style={{ color: "#BEC0C3" }}>Beats/min</span>}
                ></Input>
              </Form.Item>
            </Col>
          </div>
          <div className="w-full flex items-center">
            <Col span={7}>
              <Form.Item name="RR" label="Respiration Rate" className="mx-2">
                <Input
                  className="h-10"
                  onChange={handleRespirationRate}
                  suffix={<span style={{ color: "#BEC0C3" }}>Breaths/min</span>}
                ></Input>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name="TP" label="Temperature" className="mx-2">
                <Input
                  className="h-10"
                  onChange={handleTemp}
                  suffix={<span style={{ color: "#BEC0C3" }}>°F</span>}
                ></Input>
              </Form.Item>
            </Col>
           
          </div>
          <hr className="my-4 border-gray-400" />
            <h2 className="font-bold mx-2 text-lg">Anthropometry</h2>
          <div className="w-full flex items-center">
          <Col span={7}>
              <Form.Item name="HI" label="Height" className="mx-2">
                <Input
                  className="h-10"
                  onChange={handleHeight}
                  suffix={<span style={{ color: "#BEC0C3" }}>Cm</span>}
                ></Input>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name="WE" label="Weight" className="mx-2">
                <Input
                  onChange={handleWeight}
                  className="h-10"
                  suffix={<span style={{ color: "#BEC0C3" }}>Kg</span>}
                ></Input>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label="BMI" className="mx-2">
                <Input
                  className="h-10"
                  disabled
                  value={bmiValue}
                  suffix={<span style={{ color: "#BEC0C3" }}>Kg/m<sup>2</sup></span>}
                ></Input>
              </Form.Item>
            </Col>
          </div>
        </div>
      </Form>
      {/* <Row>
        <div className="w-full flex justify-end">
          <button
            onClick={handleFormSubmit}
            className="bg-blue-600 border-2 border-blue-600 rounded-md p-2.5 w-32 text-white"
          >
            Add Vitals
          </button>
        </div>
      </Row> */}
      {/* <Row gutter={24}>
        <Col span={24}>
          <div
            className={`border border-brand-textGray ${showTable ? "block" : "hidden"
              } rounded-lg mx-4 my-6 relative`}
          >
            <Table
              className="Vitals"
              columns={columns}
              dataSource={[{
                BPS: sys,
                BPD: dia,
                PO: pulse,
                HR: heartRate,
                RR: respirationRate,
                TP: temp,
                HI: height,
                WE: weight,
                BMI: bmiValue
              }]}
              pagination={false}
              components={{
                header: {
                  wrapper: (props) => customHeaderContent,
                },
              }}
            />
          </div>
        </Col>
      </Row> */}
    </div>
  );
};

export default Addvitals;
