import React, { useEffect, useState } from "react";
import plusIcon from "../../../../../Assets/Images/Svg/plusIcon.svg";
import AddMedication from "./AddMedication";
import { Modal } from "antd";
import moment from "moment";
import MedicalHistoryService from "../../../../../Services/MedicalHistoryService";

function Medication({permissionData}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [medicationData, setMedication] = useState([]);

  let patientData = JSON.parse(localStorage.getItem("patient"));
  console.log("inside medical history", patientData);

  useEffect(() => {
    medication();
  }, [permissionData]);

  const handleLatestUpdate = (data) => {
    setMedication(data);
  }


  const medication = () => {
    const userObj = localStorage.getItem('login_result')
    const userData = userObj ? JSON.parse(userObj) : null;
    if(permissionData){
      let payload = {
        // account_Id: userData?.user?.account_Id,
        // clinic_Id: userData?.user?.clinic_Id,
        userId:(permissionData === null || permissionData?.medicalHistory === 'N')?userData?.user?.userId:'',
        patientCode: patientData.patientId,
        status: 1,
      };
      MedicalHistoryService.PatientMedication(payload).then((result) => {
        console.log(result.data);
        setMedication(result.data);
      });
   }
    
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const convertUTCDate = (date, format) => {
    if (!format) {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
        " " +
        d.getHours() +
        ":" +
        d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return moment(new Date(date)).utc().format(format);
  };

  return (
    <>
      <div className="card h-[300px] m-5 border rounded-lg">
        <div className="header h-[20%] bg-brand-primaryColor flex items-center justify-between  rounded-lg p-2">
          <h1 className="font-bold text-normal  text-white ">Medication</h1>
          <img
            src={plusIcon}
            onClick={showModal}
            className="h-10 w-10 cursor-pointer"
            alt=""
          />
        </div>
        <div className="body h-[70%] overflow-y-auto  bg-brand-lightBg p-2">
          {medicationData.length > 0
            ? medicationData.map((user, i) => (
              <div>
                <div key={i} className="items-center mt-3 text-sm">
                  <p>Drug Name : {user.drugName}</p>
                  <p>Composition : {user.composition} </p>
                  <p>Dosage : {user.dosage} </p>
                  <p>Frequency : {user.frequency} </p>
                  <p>Duration : {user.duration}{" "}{user.durationType} </p>
                  <p>Indication : {user.indication} </p>
                  <p>Side Effects : {user.sideEffects} </p>
                  <p>Compliance : {user.compliance} </p>
                  <p>Adverse Effects : {user.adverseEffects} </p>
                  <p>
                    Advice related to medicines : {user.adviceRelatedMedicine}{" "}
                  </p>
                  <p className="text-gray-500">
                    check up date :{" "}
                    {user.givenDate?.slice(0, 11)}
                  </p>
                </div>
                <hr className="my-4 border-gray-300" />
              </div>
            ))
            : null}
        </div>
        <div className="footer h-[10%]  bg-brand-primaryColor rounded-md"></div>
      </div>
      <Modal
        title="Add Medication Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <AddMedication onClose={handleCancel} handleLatestUpdate={handleLatestUpdate} permissionData={permissionData} />
      </Modal>
    </>
  );
}

export default Medication;
