import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Modal, Row, Select, Table, message } from "antd";
import Layouts from "../../../../Layouts";
import bp from "../../../../../Assets/Images/bloodpressure.png";
import heart from "../../../../../Assets/Images/heart.png";
import temp from "../../../../../Assets/Images/temp.png";
import oxygen from "../../../../../Assets/Images/Svg/oxygen.svg";
import heightImage from "../../../../../Assets/Images/height.png";
import weightImage from "../../../../../Assets/Images/weight.png";
import bmi from "../../../../../Assets/Images/bmi.png";
import pulserate from "../../../../../Assets/Images/pulserate.png";
import Vector from "../../../../../Assets/Images/Svg/Vector.png";
import EditIcon from "../../../../../Assets/Images/Svg/edit.svg"
import { PlusOutlined } from "@ant-design/icons";
import AddVitals from "../AddVitals";
import VitalService from "../../../../../Services/VitalService";
import NoDataFound from "../../../../../Assets/Images/Svg/No data-found.svg";
import convertUTCDateLocal from "../../../../../utils/convertToUtc";
import ViewVital from "./ViewVital";
import VitalChart from "./VitalChart";
import { MdModeEdit } from "react-icons/md";
import { IoIosClose } from "react-icons/io";

const months = {
  Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06', Jul: '07',
  Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12'
};

const PatientVitals = ({ selectedPatient }) => {
  const [vitalsData, setVitalsData] = useState([]);
  const [latestVitalData, setLatestVitalData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [vitalsHistory, setVitalsHistory] = useState([]);
  const patientData = JSON.parse(localStorage.getItem("patient"));
  const [selectedVitalType, setSelectedVitalType] = useState(null);
  const [selectedVitalData, setSelectedVitalData] = useState([]);
  const [vitalCode, setVitalCode] = useState(null);
  const [viewGraph, setViewGraph] = useState(false);
  const [selectedVitalCode, setSelectedVitalCode] = useState("");
  const [vitalName, setVitalName] = useState("");
  const [viewModal, setViewModal] = useState(false);
  const [isEditing, setIsEditing] = useState('');
  const [editedValue, setEditedValue] = useState();
  const [vitalMasterData, setVitalMasterData] = useState([]);
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [vitalsObj, setVitalsObj] = useState({
    bloodPressureSYS: 0,
    bloodPressureDIA: 0,
    pulseOx: 0,
    heartRate: 0,
    respirationRate: 0,
    Temperature: 0,
    Hight: 0,
    Weight: 0,
    BMI: "",
  });

  const userObj = localStorage.getItem('login_result')
  const userData = userObj ? JSON.parse(userObj) : null;
  const activePatient = JSON.parse(localStorage.getItem("patient"));
  const patientCode = activePatient?.patientId;

  useEffect(() => {
    MyVitalList();
    VitalMaster();
  }, [selectedPatient]);

  const VitalMaster = () => {
    let payload = {
      status: 1,
    };
    VitalService.VitalMasterlist(payload).then((result) => {
      setVitalMasterData(result.data);
    });
  };

  async function fetchLatestVitals(vitals) {
    let payload = {
      patientId: selectedPatient?.patientId,
      status: 1
    }
    const response = await VitalService.getPatientRecentVitals(payload);
    const groupedByIdMap = response.data.reduce((acc, current) => {
      if (!acc.has(current.recordedDateTime)) {
        acc.set(current.id, []);
      }
      acc.get(current.id).push(current);
      return acc;
    }, new Map());

    const groupedById = Array.from(groupedByIdMap.values());
    setVitalsHistory(groupedById);

  }

  const MyVitalList = async () => {

    let payload = {
      // account_Id: userData?.user?.account_Id,
      // clinic_Id: userData?.user?.clinic_Id,
      patientId: patientData.patientId,
      status: 1,
    };
    try {
      const response = await VitalService.getVitalsData(payload);
      console.log(response.data);
      setVitalsData(response.data);
      if (response.data) {
        console.log("from date ");
        let payload = {
          account_Id: userData?.user?.account_Id,
          // clinic_Id: userData?.user?.clinic_Id,
          patientId: patientData.patientId,
          fromDate: response.data[0].recordedDateTime,
          toDate: response.data[0].recordedDateTime,
          status: 1,
        };
        const latestVitalsResponse = await VitalService.getSpecificVitalData(
          payload
        );
        if (latestVitalsResponse.data) {
          setLatestVitalData(latestVitalsResponse.data);
          latestVitalsResponse.data.filter((vital) => {
            if (vital.vitalCode === "HI") {
              setHeight(vital.value);
            }
            else if (vital.vitalCode === "WE") {
              setWeight(vital.value);
            }
          })
        }
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const handleVitalUpdate = (value, vitalkey) => {
    const vitalsVal = vitalsObj;
    vitalsVal[vitalkey] = value;

    if (vitalkey === 'Height') {
      handleHeight(value);
    }
    else if (vitalkey === 'Weight') {
      handleWeight(value);
    }
    else {
      setVitalsObj(vitalsVal);
    }
  }

  
  const handleVitalDateChange = async (value) => {
    const userObj = localStorage.getItem('login_result');
    const userData = userObj ? JSON.parse(userObj) : null;

      let payload = {
       account_Id: userData?.user?.account_Id,
       // clinic_Id: userData?.user?.clinic_Id,
       patientId: patientData.patientId,
       fromDate: value,
       toDate: value,
       status: 1,
     };
    setLoading(true)
     await VitalService.getSpecificVitalData(payload)
      .then((result) => {
        setLatestVitalData(result.data);
      })
      .catch((error) => {
        console.error("Failed to fetch reports:", error);
        setLatestVitalData([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // const handleVitalDateChange = async (value) => {
  //   const userObj = localStorage.getItem('login_result')
  //   const userData = userObj ? JSON.parse(userObj) : null;

  //   let payload = {
  //     account_Id: userData?.user?.account_Id,
  //     // clinic_Id: userData?.user?.clinic_Id,
  //     patientId: patientData.patientId,
  //     fromDate: value,
  //     toDate: value,
  //     status: 1,
  //   };
  //   const latestVitalsResponse = await VitalService.getSpecificVitalData(
  //     payload
  //   );
  //   if (latestVitalsResponse.data) {
  //     setLatestVitalData(latestVitalsResponse.data);
  //   }
  // };

  const updatedVitalsList = (data) => {
    setLatestVitalData(data);
  };

  const handleViewAll = (type1, type2) => {
    console.log(type1, 'type1');
    console.log(type2, 'type2');
    setSelectedVitalCode(`${type1},${type2}`);
    const vital =
      type1 === "BPS"
        ? "Blood Pressure"
        : type1 === "TP"
          ? "Temperature"
          : type1 === "HI"
            ? "Height"
            : type1 === "WE"
              ? "Weight"
              : type1 === "BMI"
                ? "BMI"
                : type1 === "HR"
                  ? "Heart Rate"
                  : type1 === "RR"
                    ? "Respiration Rate"
                    : type1 === "PO"
                      ? "Pulse Oxygen"
                      : null;
    setVitalName(vital);
    setViewModal(true);
  };

  const handleUpdate = async () => {
    console.log(latestVitalData);
    const currDate = new Date().toUTCString();
    console.log(currDate);
    const dateArr = currDate.split(' ');
    const month = months[dateArr[2]];
    const date = dateArr[1];
    const year = dateArr[3];
    const constructedDate = year + '-' + month + '-' + date;
    const time = dateArr[4];
    const constructedDateTime = constructedDate + " " + time;
    const updatedVitalObj = [];
    Object.entries(vitalsObj).forEach(([fieldName, value]) => {
      if (value !== 0 && value !== '') {
        const changedVitalIndex = latestVitalData.findIndex((vital) => vital.vitalName === fieldName);
        if (changedVitalIndex !== -1) {
          latestVitalData[changedVitalIndex].value = value;
          latestVitalData[changedVitalIndex].modifiedDate = constructedDateTime
          console.log("latestVitalData[changedVitalIndex] ", latestVitalData[changedVitalIndex]);
          updatedVitalObj.push(latestVitalData[changedVitalIndex]);
        }
      }
    });


    await VitalService.updateVital(updatedVitalObj).then((response) => {
      if (response.data[0] === 1) {
        MyVitalList();
        setIsEditing('');
        message.success("Vital Update Successfully", 4);
      }
    })
      .catch((error) => {
        console.error("Error sending data:", error);
        message.error("Failed to update vital", 4);
      });
    // console.log(response);
    // if (response.data[0] === 1) {
    //  MyVitalList();
    //   setIsEditing('');
    // }



  }

  const handleHeight = (value) => {
    const newHeight = Number(value);
    setHeight(newHeight);
    console.log(vitalsObj['Weight'], newHeight);
    if (weight && newHeight) {
      const heightInMeters = Number(newHeight) / 100;
      const bmi = weight / (heightInMeters * heightInMeters);
      console.log(bmi.toFixed(1));
      const vitalsVal = vitalsObj;
      vitalsVal['BMI'] = bmi.toFixed(1);
    }
  }

  const handleWeight = (value) => {
    const newWeight = Number(value);
    setWeight(newWeight);
    console.log(newWeight, vitalsObj['Height']);
    if (height && newWeight) {
      const heightInMeters = Number(height) / 100;
      const bmi = newWeight / (heightInMeters * heightInMeters);
      console.log(bmi)
      const vitalsVal = vitalsObj;
      vitalsVal['BMI'] = bmi.toFixed(1);
    }
  }

  const handleGraph = (type, code, data) => {
    setSelectedVitalType(type);
    setSelectedVitalData(data);
    setVitalCode(code);
    setViewGraph(true);
  };

  const handleEditClick = (selectedItem) => {
    setIsEditing(selectedItem);
    // setEditedValue(vital);
    // setVital();
  };

  const handleClose = () => {
    setIsEditing('');
  }

  const handleClosemodal = () => {
    setIsModalVisible(false);
    MyVitalList();
  };
  const handleCombinedUpdate = (value) => {
    
    const [sys, dia] = value.split('/');
    

    handleVitalUpdate(sys, "Blood Pressure(SYS)");
    handleVitalUpdate(dia, "Blood Pressure(DIA)");
  }
  return (
    <>
      <div className="border rounded shadow-md p-2 bg-slate-100">
        <div>
          <div className="w-full flex justify-between items-center">
            {/* <div>
            <p><span className="font-semibold">Date</span> : {latestVitalData[0]?.recordedDateTime?.slice(0, 11)}</p>
          </div> */}
            <div className="w-full flex items-center justify-end my-6 gap-3">
              <Select
                placeholder="Select Date"
                className="w-56 mx-1"
                defaultValue={vitalsData[0]?.recordedDateTime}
                options={vitalsData?.map((record) => {
                  return {
                    label: convertUTCDateLocal(record.recordedDateTime),
                    value: record.recordedDateTime,
                  };
                })}
                onChange={handleVitalDateChange}
              />
              <Button
                type="default"
                onClick={() => {
                  setIsModalVisible(true);
                }}
                // icon={<PlusOutlined />}
                className="border-blue-800 text-white hover:border-blue-700 hover:text-blue-700"
              >
                Add Vitals
              </Button>
            </div>
          </div>
        </div>
        {loading ? (
            <div className="w-full flex h-screen justify-center items-center">
              <div className="spinner"></div> 
            </div>
          ) : latestVitalData && latestVitalData.length > 0 ? (
          <>
            <div className="w-full flex flex-wrap items-center gap-2">
              {latestVitalData.filter(
                (vital) => vital.vitalCode === "BPS"
              )[0]?.value || latestVitalData.filter(
                (vital) => vital.vitalCode === "BPD"
              )[0]?.value ?
                (
                  < div className="min-w-[32%] w-max">
                    <div
                      className="border shadow-lg rounded-lg my-2 p-1  bg-white"
                    // style={{ width: "92%", height: "70px" }}
                    >
                      
                       {isEditing !== 'bloodPressure' ? (
                        <div className="flex justify-around items-center gap-6">
                          <div className="flex gap-2">
                            <img src={bp} className="w-7 h-7" alt="Blood Pressure" />
                            <label className="font-semibold text-base">Blood Pressure</label>
                          </div>
                          <div className="flex items-center mt-3">
                          <p className="font-medium text-base">
                              {
                                latestVitalData.filter((vital) => vital.vitalCode === "BPS")[0]?.value
                              }{" "}
                              /{" "}
                              {
                                latestVitalData.filter((vital) => vital.vitalCode === "BPD")[0]?.value
                              }{" "}
                              mmHg
                            </p>
                          </div>
                          <MdModeEdit className="w-5 cursor-pointer" onClick={() => handleEditClick('bloodPressure')} />
                        </div>
                      ) : (
                        <div className="flex items-center justify-between mt-3">
                          <div className="flex gap-2 items-center justify-between">
                            <div className="flex gap-2">
                              <img src={bp} className="w-7 h-7" alt="Blood Pressure" />
                              <label className="font-semibold text-base">Blood Pressure</label>
                            </div>
                            <Input
                              style={{ marginLeft: "36px" }}
                              onChange={(e) => handleCombinedUpdate(e.target.value)}
                              onBlur={handleUpdate}
                              placeholder="SYS/DIA"
                              className="w-[4rem]"
                            />
                            <span className="text-base text-gray-900">mmHg</span>
                          </div>
                        </div>
                      )}

                      {/* {isEditing !== "bloodPressure" ?

                        (<div className="flex justify-around items-center gap-6 mt-3">
                          <div className="flex gap-2">
                            <img src={bp} className="w-7 h-7" alt="Blood Pressure" />
                            <label className="font-semibold text-base">Blood Pressure</label>
                          </div>
                          <div className="flex items-center mt-3">
                            <p className="font-medium text-base">
                              {
                                latestVitalData.filter(
                                  (vital) => vital.vitalCode === "BPS"
                                )[0]?.value
                              }{" "}
                              /{" "}
                              {
                                latestVitalData.filter(
                                  (vital) => vital.vitalCode === "BPD"
                                )[0]?.value
                              }{" "}
                              mmHg
                            </p>
                          </div>
                          <MdModeEdit className="w-5 cursor-pointer" onClick={() => handleEditClick('bloodPressure')} />
                        </div>) :
                        (
                          (
                            <div className="gap-2 flex items-center justify-between mt-3">
                              <div className="flex gap-2 items-center justify-between">
                                <div className="flex items-center rounded-lg border border-brand-textGray h-10">
                                  <Input
                                    // value={sys}
                                    onChange={(e) => handleVitalUpdate(e.target.value, "Blood Pressure(SYS)")}
                                    maxLength={3}
                                    placeholder="SYS"
                                    className="outline-none border-none placeholder:text-end"
                                  // style={{ width: "10%" }}
                                  />
                                  <span style={{ width: "10%", textAlign: "center" }}>/</span>
                                  <Input
                                    // value={dia}
                                    onChange={(e) => handleVitalUpdate(e.target.value, "Blood Pressure(DIA)")}
                                    maxLength={3}
                                    placeholder="DIA"
                                    className="outline-none border-none"
                                  // style={{ width: "20%" }}
                                  />
                                </div>
                                <button onClick={handleUpdate} className="p-2 text-white rounded-lg bg-brand-primaryColor">Update</button>
                              </div>
                              <div className="w-1/12 flex justify-end">
                                <IoIosClose className="text-xl" onClick={handleClose} />
                              </div>
                            </div>
                          )
                        )
                      } */}
                      <div
                        className="flex justify-between"
                        style={{ marginTop: "8px" }}
                      >
                        {/* Additional content can be added here */}
                      </div>
                      <hr className="my-4 border-gray-300" />
                      <div className="flex justify-between px-2" style={{ marginTop: "20px" }}>
                        <div className="">
                          <img
                            src={Vector}
                            className="cursor-pointer"
                            onClick={() =>
                              handleGraph("Blood Pressure", "BPD", "BPS", [
                                vitalsData[4],
                                vitalsData[5],
                              ])
                            }
                            alt="vector"
                          />
                          <label className="font-medium"></label>
                        </div>
                        <div className="">
                          <label
                            onClick={() => handleViewAll("BPS", "BPD")}
                            className="font-medium cursor-pointer text-blue-600"
                          >
                            View All
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>)
                : null}

              {
                latestVitalData.filter(
                  (vital) => vital.vitalCode === "TP"
                )[0]?.value ?
                  (<div className="min-w-[32%] w-max">
                    <div
                      className="border shadow-lg p-3 rounded-lg my-2  bg-white"
                    // style={{ width: "92%", height: "70px" }}
                    >
                      {isEditing !== 'temp' ? (
                        <div className="flex justify-around items-center gap-6">
                          <div className="flex gap-2">
                            <img src={temp} className="w-7 h-7" alt="temp" />
                            <label className="font-semibold text-base">Temperature</label>
                          </div>
                          <div className="flex items-center mt-3">
                            <p className="font-medium text-base">
                              {latestVitalData.filter((vital) => vital.vitalCode === "TP")[0]?.value} °F
                            </p>
                          </div>
                          <MdModeEdit className="w-5 cursor-pointer" onClick={() => handleEditClick('temp')} />
                        </div>
                      ) : (
                        <div className="flex items-center justify-between mt-3">
                          <div className="flex gap-2 items-center justify-between">
                            <div className="flex gap-2">
                              <img src={heart} className="w-7 h-7" alt="temp" />
                              <label className="font-semibold text-base">Temperature</label>
                            </div>
                            <Input
                              style={{ marginLeft: "36px" }}
                              onChange={(e) => handleVitalUpdate(e.target.value, "Temperature")}
                              onBlur={handleUpdate}
                              className="w-[4rem]"
                              
                            />
                            <span className="text-base text-gray-900">°F</span>
                          </div>
                        </div>
                      )}
                      {/* {isEditing !== 'temp' ?
                        (<div className="flex justify-around items-center gap-6">
                          <div className="flex gap-2">
                            <img src={temp} className="w-7 h-7" alt="temp" />
                            <label className="font-semibold text-base">Temperature</label>
                          </div>
                          <div className="flex items-center mt-3">
                            <p className="font-medium text-base">
                              {
                                latestVitalData.filter(
                                  (vital) => vital.vitalCode === "TP"
                                )[0]?.value
                              }{" "}
                              °F
                            </p>
                          </div>
                          <MdModeEdit className="w-5 cursor-pointer" onClick={() => handleEditClick('temp')} />
                        </div>) :
                        (
                          <div className="flex items-center justify-between mt-3">
                            <div className="flex gap-2 items-center justify-between">
                              <Input
                                className="h-10"
                                onChange={(e) => handleVitalUpdate(e.target.value, "Temperature")}
                              />
                              <button onClick={handleUpdate} className="p-2 text-white rounded-lg bg-brand-primaryColor">Update</button>
                              <div className="w-full flex  justify-end">
                                <IoIosClose className="text-lg" onClick={handleClose} />
                              </div>
                            </div>

                          </div>
                        )} */}
                      <hr className="my-4 border-gray-300" />
                      <div className="flex justify-between" style={{ marginTop: "20px" }}>
                        <div className="">
                          <img
                            src={Vector}
                            className="cursor-pointer"
                            onClick={() => handleGraph("Temperature", "TP", [vitalsData[0]])}
                            alt="vector"
                          />
                          <label className="font-medium"></label>
                        </div>
                        <div className="">
                          <label
                            onClick={() => handleViewAll("TP")}
                            className="font-medium cursor-pointer text-blue-600"
                          >
                            View All
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>)
                  : null}
              {
                latestVitalData.filter(
                  (vital) => vital.vitalCode === "HR"
                )[0]?.value ?
                  (<div className="min-w-[32%] w-max">
                    <div
                      className="border shadow-lg p-3 rounded-lg my-2  bg-white"
                    // style={{ width: "92%", height: "70px" }}
                    >
                      {isEditing !== 'heart' ? (
                        <div className="flex justify-around items-center gap-6">
                          <div className="flex gap-2">
                            <img src={heart} className="w-7 h-7" alt="heart" />
                            <label className="font-semibold text-base">Heart Rate</label>
                          </div>
                          <div className="flex items-center mt-3">
                            <p className="font-medium text-base">
                              {latestVitalData.filter((vital) => vital.vitalCode === "HR")[0]?.value} Beats/min
                            </p>
                          </div>
                          <MdModeEdit className="w-5 cursor-pointer" onClick={() => handleEditClick('heart')} />
                        </div>
                      ) : (
                        <div className="flex items-center justify-between mt-3">
                          <div className="flex gap-2 items-center justify-between">
                            <div className="flex gap-2">
                              <img src={heart} className="w-7 h-7" alt="heart" />
                              <label className="font-semibold text-base">Heart Rate</label>
                            </div>
                            <Input
                              style={{ marginLeft: "36px" }}
                              onChange={(e) => handleVitalUpdate(e.target.value, "Heart Rate")}
                              onBlur={handleUpdate}
                              className="w-[4rem]"
                            />
                            <span className="text-base text-gray-900">Beats/min</span>
                          </div>
                        </div>
                      )}
                      {/* {isEditing !== 'heart' ?
                        (<div className="flex justify-around items-center gap-6">
                          <div className="flex gap-2">
                            <img src={heart} className="w-7 h-7" alt="heart" />
                            <label className="font-semibold text-base">Heart Rate</label>
                          </div>
                          <div className="flex items-center mt-3">
                            <p className="font-medium text-base">
                              {
                                latestVitalData.filter(
                                  (vital) => vital.vitalCode === "HR"
                                )[0]?.value
                              }{" "}
                              Beats/min
                            </p>
                          </div>
                          <MdModeEdit className="w-5 cursor-pointer" onClick={() => handleEditClick('heart')} />
                        </div>) :
                        (<div className="w-80 flex items-center justify-between mt-3">
                          <div className="w-full flex gap-2 items-center justify-between">
                            <Input
                              className="h-10"
                              onChange={(e) => handleVitalUpdate(e.target.value, "Heart Rate")}
                            />
                            <button onClick={handleUpdate} className="p-2 text-white rounded-lg bg-brand-primaryColor">Update</button>
                          </div>
                          <div className="w-full flex  justify-end">
                            <IoIosClose className="text-lg" onClick={handleClose} />
                          </div>
                        </div>)} */}
                      <hr className="my-4 border-gray-300" />
                      <div className="flex justify-between" style={{ marginTop: "20px" }}>
                        <div className="">
                          <img
                            src={Vector}
                            className="cursor-pointer"
                            onClick={() => handleGraph("Heart Rate", "HR", [vitalsData[7]])}
                            alt="vector"
                          />
                          <label className="font-medium"></label>
                        </div>
                        <div className="">
                          <label
                            onClick={() => handleViewAll("HR")}
                            className="font-medium cursor-pointer text-blue-600"
                          >
                            View All
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>)
                  : null}

              {
                latestVitalData.filter(
                  (vital) => vital.vitalCode === "PO"
                )[0]?.value ?
                  (<div className="min-w-[32%] w-max">
                    <div
                      className="border shadow-lg p-3 rounded-lg my-2  bg-white"
                    // style={{ width: "92%", height: "70px" }}
                    >
                      {isEditing !== 'pulse' ? (
                        <div className="flex justify-around items-center gap-6">
                          <div className="flex gap-2">
                            <img src={pulserate} className="w-7 h-7" alt="pulse" />
                            <label className="font-semibold text-base">Pulse Ox</label>
                          </div>
                          <div className="flex items-center mt-3">
                            <p className="font-medium text-base">
                              {latestVitalData.filter((vital) => vital.vitalCode === "PO")[0]?.value} %
                            </p>
                          </div>
                          <MdModeEdit className="w-5 cursor-pointer" onClick={() => handleEditClick('pulse')} />
                        </div>
                      ) : (
                        <div className="flex items-center justify-between mt-3">
                          <div className="flex gap-2 items-center justify-between">
                            <div className="flex gap-2">
                              <img src={pulserate} className="w-7 h-7" alt="pulse" />
                              <label className="font-semibold text-base">Pulse Ox</label>
                            </div>
                            <Input
                              style={{ marginLeft: "36px" }}
                              onChange={(e) => handleVitalUpdate(e.target.value, "Pulse Ox")}
                              onBlur={handleUpdate}
                              className="w-[4rem]"
                            />
                            <span className="text-base text-gray-900">%</span>
                          </div>
                        </div>
                      )}
                      {/* {isEditing !== 'pulse' ? (<div className="flex justify-around items-center gap-6">
                        <div className="flex gap-2">
                          <img src={pulserate} className="w-7 h-7" alt="pulse" />
                          <label className="font-semibold text-base">Pulse Ox</label>
                        </div>
                        <div className="flex items-center mt-3">
                          <p className="font-medium text-base">
                            {
                              latestVitalData.filter(
                                (vital) => vital.vitalCode === "PO"
                              )[0]?.value
                            }{" "}
                            %
                          </p>
                        </div>
                        <MdModeEdit className="w-5 cursor-pointer" onClick={() => handleEditClick('pulse')} />
                      </div>) :
                        (
                          <div className="w-80 flex items-center justify-between mt-3">
                            <div className="flex gap-2 items-center justify-between">
                              <Input
                                className="h-10"
                                onChange={(e) => handleVitalUpdate(e.target.value, "Pulse Ox")}
                              />
                              <button onClick={handleUpdate} className="p-2 text-white rounded-lg bg-brand-primaryColor">Update</button>
                            </div>
                            <div className="w-full flex  justify-end">
                              <IoIosClose className="text-lg" onClick={handleClose} />
                            </div>
                          </div>
                        )} */}

                      <hr className="my-4 border-gray-300" />
                      <div className="flex justify-between" style={{ marginTop: "20px" }}>
                        <div className="">
                          <img
                            src={Vector}
                            className="cursor-pointer"
                            onClick={() => handleGraph("Pulse Oxygen", "PO", [vitalsData[6]])}
                            alt="vector"
                          />
                          <label className="font-medium"></label>
                        </div>
                        <div className="">
                          <label
                            onClick={() => handleViewAll("PO")}
                            className="font-medium cursor-pointer text-blue-600"
                          >
                            View All
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>)
                  : null}

              {
                latestVitalData.filter(
                  (vital) => vital.vitalCode === "RR"
                )[0]?.value ?
                  (<div className="min-w-[32%] w-max">
                    <div
                      className="border shadow-lg p-3 rounded-lg my-2  bg-white"
                    // style={{ width: "92%", height: "70px" }}
                    >
                      {isEditing !== 'respiration' ? (
                        <div className="flex justify-around items-center gap-6">
                          <div className="flex gap-2">
                            <img src={oxygen} className="w-7 h-7" alt="oxygen" />
                            <label className="font-semibold text-base">Respiration Rate</label>
                          </div>
                          <div className="flex items-center mt-3">
                            <p className="font-medium text-base">
                              {latestVitalData.filter((vital) => vital.vitalCode === "RR")[0]?.value} Breaths/min
                            </p>
                          </div>
                          <MdModeEdit className="w-5 cursor-pointer" onClick={() => handleEditClick('respiration')} />
                        </div>
                      ) : (
                        <div className="flex items-center justify-between mt-3">
                          <div className="flex gap-2 items-center justify-between">
                            <div className="flex gap-2">
                              <img src={oxygen} className="w-7 h-7" alt="oxygen" />
                              <label className="font-semibold text-base">Respiration Rate</label>
                            </div>
                            <Input
                              style={{ marginLeft: "36px" }}
                              onChange={(e) => handleVitalUpdate(e.target.value, "Respiration Rate")}
                              onBlur={handleUpdate}
                              className="w-[4rem]"
                            />
                            <span className="text-base text-gray-900">Breaths/min</span>
                          </div>
                        </div>
                      )}
                      {/* {isEditing !== 'respiration' ? (<div className="flex justify-around items-center gap-6">
                        <div className="flex gap-2">
                          <img src={oxygen} className="w-7 h-7" alt="oxygen" />
                          <label className="font-semibold text-base">Respiration Rate</label>
                        </div>
                        <div className="flex items-center mt-3">
                          <p className="font-medium text-base">
                            {
                              latestVitalData.filter(
                                (vital) => vital.vitalCode === "RR"
                              )[0]?.value
                            }{" "}
                            Breaths/min
                          </p>
                        </div>
                        <MdModeEdit className="w-5 cursor-pointer" onClick={() => handleEditClick('respiration')} />
                      </div>) :
                        (
                          <div className="flex items-center justify-between mt-3">
                            <div className="flex gap-2 items-center justify-between">
                              <Input
                                className="h-10"
                                onChange={(e) => handleVitalUpdate(e.target.value, "Respiration Rate")}
                              />
                              <button onClick={handleUpdate} className="p-2 text-white rounded-lg bg-brand-primaryColor">Update</button>
                            </div>
                            <div className="w-full flex  justify-end">
                              <IoIosClose className="text-lg" onClick={handleClose} />
                            </div>
                          </div>
                        )} */}
                      <hr className="my-4 border-gray-300" />
                      <div className="flex justify-between" style={{ marginTop: "20px" }}>
                        <div className="">
                          <img
                            src={Vector}
                            className="cursor-pointer"
                            onClick={() =>
                              handleGraph("Respiration Rate", "RR", [vitalsData[8]])
                            }
                            alt="vector"
                          />
                          <label className="font-medium"></label>
                        </div>
                        <div className="">
                          <label
                            onClick={() => handleViewAll("RR")}
                            className="font-medium cursor-pointer text-blue-600"
                          >
                            View All
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>)
                  : null}
            </div>
            <hr className="my-4 border-gray-400" />
            <h2 className="font-bold mx-2 text-lg">Anthropometry</h2>
            <div className="w-full flex flex-wrap items-center gap-2">
              {
                latestVitalData.filter(
                  (vital) => vital.vitalCode === "HI"
                )[0]?.value ?
                  (<div className="min-w-[32%] w-max">
                    <div
                      className="border shadow-lg p-3 rounded-lg my-2  bg-white"
                    // style={{ width: "92%", height: "70px" }}
                    >
                      {/* {isEditing !== 'height' ?
                        (<div className="flex justify-around items-center gap-6">
                          <div className="flex gap-2">
                            <img src={height} className="w-7 h-7" alt="height" />
                            <label className="font-semibold text-base">Height</label>
                          </div>
                          <div className="flex items-center mt-3">
                            <p className="font-medium text-base">
                              {
                                latestVitalData.filter(
                                  (vital) => vital.vitalCode === "HI"
                                )[0]?.value
                              }{" "}
                              cm
                            </p>
                          </div>
                          <MdModeEdit className="w-5 cursor-pointer" onClick={() => handleEditClick('height')} />
                        </div>) : (
                          <div className="flex items-center justify-between mt-3">
                            <div className="flex gap-2 items-center justify-between">
                              <Input
                                className="h-10"
                                onChange={(e) => handleVitalUpdate(e.target.value, "Height")}
                              />
                              <button onClick={handleUpdate} className="p-2 text-white rounded-lg bg-brand-primaryColor">Update</button>
                            </div>
                            <div className="w-full flex  justify-end">
                              <IoIosClose className="text-lg" onClick={handleClose} />
                            </div>
                          </div>
                        )} */}
                      {isEditing !== 'height' ? (
                        <div className="flex justify-around items-center gap-6">
                          <div className="flex gap-2">
                            <img src={heightImage} className="w-7 h-7" alt="height" />
                            <label className="font-semibold text-base">Height</label>
                          </div>
                          <div className="flex items-center mt-3">
                            <p className="font-medium text-base">
                              {latestVitalData.filter((vital) => vital.vitalCode === "HI")[0]?.value} cm
                            </p>
                          </div>
                          <MdModeEdit className="w-5 cursor-pointer" onClick={() => handleEditClick('height')} />
                        </div>
                      ) : (
                        <div className="flex items-center justify-between mt-3">
                          <div className="flex gap-2 items-center justify-between">
                            <div className="flex gap-2">
                              <img src={heightImage} className="w-7 h-7" alt="height" />
                              <label className="font-semibold text-base">Height</label>
                            </div>
                            <Input
                              style={{ marginLeft: "36px" }}
                              onChange={(e) => handleVitalUpdate(e.target.value, "Height")}
                              onBlur={handleUpdate}
                              className="w-[4rem]"
                            />
                            <span className="text-base text-gray-900">cm</span>
                          </div>
                        </div>
                      )}

                      <hr className="my-4 border-gray-300" />
                      <div className="flex justify-between" style={{ marginTop: "8px" }}>
                        <div className="">
                          <img
                            src={Vector}
                            className="cursor-pointer"
                            onClick={() => handleGraph("Height", "HI", [vitalsData[1]])}
                            alt="vector"
                          />
                          <label className="font-medium"></label>
                        </div>
                        <div className="">
                          <label
                            onClick={() => handleViewAll("HI")}
                            className="font-medium cursor-pointer text-blue-600"
                          >
                            View All
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>)
                  : null}
              {
                latestVitalData.filter(
                  (vital) => vital.vitalCode === "WE"
                )[0]?.value ?
                  (<div className="min-w-[32%] w-max">
                    <div
                      className="border shadow-lg p-3 rounded-lg my-2  bg-white"
                    // style={{ width: "92%", height: "70px" }}
                    >
                      {isEditing !== 'weight' ? (
                        <div className="flex justify-around items-center gap-6">
                          <div className="flex gap-2">
                            <img src={weightImage} className="w-7 h-7" alt="weight" />
                            <label className="font-semibold text-base">Weight</label>
                          </div>
                          <div className="flex items-center mt-3">
                            <p className="font-medium text-base">
                              {latestVitalData.filter((vital) => vital.vitalCode === "WE")[0]?.value} kg
                            </p>
                          </div>
                          <MdModeEdit className="w-5 cursor-pointer" onClick={() => handleEditClick('weight')} />
                        </div>
                      ) : (
                        <div className="flex items-center justify-between mt-3">
                          <div className="flex gap-2 items-center justify-between">
                            <div className="flex gap-2">
                              <img src={weightImage} className="w-7 h-7" alt="weight" />
                              <label className="font-semibold text-base">Weight</label>
                            </div>
                            <Input
                              style={{ marginLeft: "36px" }}
                              onChange={(e) => handleVitalUpdate(e.target.value, "Weight")}
                              onBlur={handleUpdate}
                              className="w-[4rem]"
                            />
                            <span className="text-base text-gray-900">Kg</span>
                          </div>
                        </div>
                      )}

                      <hr className="my-4 border-gray-300" />
                      <div className="flex justify-between" style={{ marginTop: "8px" }}>
                        <div className="">
                          <img
                            src={Vector}
                            className="cursor-pointer"
                            onClick={() => handleGraph("Weight", "WE", [vitalsData[2]])}
                            alt="vector"
                          />
                          <label className="font-medium"></label>
                        </div>
                        <div className="">
                          <label
                            onClick={() => handleViewAll("WE")}
                            className="font-medium cursor-pointer text-blue-600"
                          >
                            View All
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>)
                  : null}

              {
                latestVitalData.filter(
                  (vital) => vital.vitalCode === "BMI"
                )[0]?.value ?
                  (<div className="min-w-[32%] w-max">
                    <div
                      className="border shadow-lg p-3 rounded-lg my-2  bg-white"
                    // style={{ width: "92%", height: "70px" }}
                    >
                      <div className="flex justify-around items-center gap-6">
                        <div className="flex gap-2">
                          <img src={bmi} className="w-7 h-7" alt="bmi" />
                          <label className="font-semibold text-base">BMI</label>
                        </div>
                        <div className="flex items-center mt-3">
                          <p className="font-medium text-base">
                            {
                              latestVitalData.filter(
                                (vital) => vital.vitalCode === "BMI"
                              )[0]?.value
                            }{" "}
                            kg/m2
                          </p>
                        </div>
                      </div>
                      <hr className="my-4 border-gray-300" />
                      <div className="flex justify-between" style={{ marginTop: "8px" }}>
                        <div className="">
                          <img
                            src={Vector}
                            className="cursor-pointer"
                            onClick={() => handleGraph("BMI", "BMI", [vitalsData[3]])}
                            alt="vector"
                          />
                          <label className="font-medium"></label>
                        </div>
                        <div className="">
                          <label
                            onClick={() => handleViewAll("BMI")}
                            className="font-medium cursor-pointer text-blue-600"
                          >
                            View All
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>)
                  : null}
            </div>
          </>
        ) : (
          <div className="flex h-screen justify-center items-center">
            <div>
              <img src={NoDataFound} alt="No Data" className="w-24 h-24" />
              <p className="text-center ">No Vitals Found</p>
            </div>
          </div>
        )
        }
        {
          isModalVisible && (
            <Modal
              title={<span className="font-bold text-base">Add Vitals</span>}
              visible={isModalVisible}
              onOk={() => {
                setIsModalVisible(false);
              }}
              centered={true}
              footer={[]}
              onCancel={() => {
                console.log("Cancel button clicked");
                setIsModalVisible(false);
              }}
              okText="Submit"
              cancelText="Cancel"
              width={800}
              maskClosable={false}
            >
              <AddVitals
                onClose={handleClosemodal}
                updatedVitalsList={updatedVitalsList}
                handleClosemodal={handleClosemodal}
              />
            </Modal>
          )
        }
      </div >
      {viewModal && (
        <Modal
          title={`Vitals - ${vitalName}`}
          visible={viewModal}
          onOk={() => {
            setViewModal(false);
          }}
          centered={true}
          footer={null}
          onCancel={() => {
            console.log("Cancel button clicked");
            setViewModal(false);
          }}
          okText="Submit"
          cancelText="Cancel"
          width={400}
          maskClosable={false}
        >
          <ViewVital
            selectedVitalCode={selectedVitalCode}
            vitalName={vitalName}
            onClose={() => handleClosemodal()}
          />
        </Modal>
      )}

      {viewGraph && (
        <VitalChart
          title={`${selectedVitalType} Graph`}
          data={selectedVitalCode}
          type={selectedVitalType}
          vitalCode={vitalCode}
          closePopup={() => setViewGraph(false)}
        />
      )}
    </>

  );
};

export default PatientVitals;