import { Checkbox, Col, DatePicker, Form, Input, Row, Select } from "antd";
import React, { useState } from "react";
import moment from "moment";
import { useForm } from "antd/es/form/Form";
import { BsCloudUpload } from "react-icons/bs";
import { encodeBase64File } from "../../../utils/encodeBase64";
import DoctorService from "../../../Services/DoctorService";

function Index({ closeModal }) {
  const [form, setForm] = useForm();
  const [componentDisabled, setComponentDisabled] = useState(true);
  const [doctorStamp, setDoctorStamp] = useState(null);
  const [doctorSignature, setDoctorSignature] = useState(null);
  const [isDoctorStampEnabled, setIsDoctorStampEnabled] = useState(false);
  const [isDoctorSignEnabled, setIsDoctorSignEnabled] = useState(false);

  let loginData = localStorage.getItem("login_result");
  let loginDataObject = loginData ? JSON.parse(loginData) : null;

  const handleChange = (type) => {
    if (type === "Stamp") {
      setIsDoctorStampEnabled(!isDoctorStampEnabled);
    } else {
      setIsDoctorSignEnabled(!isDoctorSignEnabled);
    }
  };

  const salutationOptions = [
    {
      value: "Mr.",
      label: "Mr.",
    },
    {
      value: "Mrs.",
      label: "Mrs.",
    },
    {
      value: "Miss",
      label: "Miss",
    },
    {
      value: "Ms.",
      label: "Ms.",
    },
  ];

  const handleKeyPress = (e) => {
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ];
    if (!allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const maritalStatusOptions = [
    { label: "Married", value: "M" },
    { label: "Unmarried", value: "U" },
  ];

  const initialValues = {
    userId: loginDataObject ? loginDataObject.user.userId : "",
    salutation: loginDataObject ? loginDataObject.user.salutation : "",
    firstName: loginDataObject ? loginDataObject.user.firstName : "",
    lastName: loginDataObject ? loginDataObject.user.lastName : "",
    mobile: loginDataObject ? loginDataObject.user.mobile : "",
    dob:
      loginDataObject && loginDataObject.user.dob
        ? moment(loginDataObject.user.dob, "MM/DD/YYYY")
        : null,
    email: loginDataObject ? loginDataObject.user.email : "",
    gender: loginDataObject ? loginDataObject.user.gender : "",
    address1: loginDataObject ? loginDataObject.user.address1 : "",
    city: loginDataObject ? loginDataObject.user.city : "",
    state: loginDataObject ? loginDataObject.user.state : "",
    country: loginDataObject ? loginDataObject.user.country : "",
    pincode: loginDataObject ? loginDataObject.user.pincode : "",
    qualification: loginDataObject ? loginDataObject.user.qualification : "",
    userType: loginDataObject
      ? loginDataObject.user.userType === "P"
        ? "Physician"
        : "Nurse"
      : "",
  };

  const handleFileType = (e) => {
    if (e.target.name === "sign") {
      setDoctorSignature(e.target.files[0]);
    } else {
      setDoctorStamp(e.target.files[0]);
    }
  };

  const handleSignatureUpdate = async () => {
    let payload = loginDataObject.user;
    if (doctorSignature) {
      const res = await encodeBase64File(doctorSignature);
      payload.doctorSignatureName = doctorSignature.name;
      if (doctorSignature.type.includes("pdf")) {
        payload.doctorSignatureType = "pdf";
        payload.doctorSignature = res;
      } else if (doctorSignature.type.includes("png")) {
        payload.doctorSignatureType = "png";
        payload.doctorSignature = res;
      } else if (doctorSignature.type.includes("jpg")) {
        payload.doctorSignatureType = "jpg";
        payload.doctorSignature = res;
      } else if (doctorSignature.type.includes("jpeg")) {
        payload.doctorSignatureType = "jpeg";
        payload.doctorSignature = res;
      }

      try {
        await DoctorService.editDoctorDetails(payload, payload.id);
        setDoctorSignature(null);
        setIsDoctorSignEnabled(false);
        closeModal();
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleStampUpdate = async () => {
    let payload = loginDataObject.user;
    if (doctorStamp) {
      const res = await encodeBase64File(doctorStamp);
      payload.doctorStampName = doctorStamp.name;
      if (doctorStamp.type.includes("pdf")) {
        payload.doctorStampType = "pdf";
        payload.doctorStamp = res;
      } else if (doctorStamp.type.includes("png")) {
        payload.doctorStampType = "png";
        payload.doctorStamp = res;
      } else if (doctorStamp.type.includes("jpg")) {
        payload.doctorStampType = "jpg";
        payload.doctorStamp = res;
      } else if (doctorStamp.type.includes("jpeg")) {
        payload.doctorStampType = "jpeg";
        payload.doctorStamp = res;
      }
    }
    try {
      await DoctorService.editDoctorDetails(payload, payload.id);
      setDoctorStamp(null);
      setIsDoctorStampEnabled(false);
      closeModal();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <Form
        className="formDesign patientForm mt-7 mb-10"
        name="basic"
        layout="vertical"
        autoComplete="off"
        form={form}
        initialValues={initialValues}
        disabled={componentDisabled}
        // onFinish={handleSubmit}
      >
        <p className="font-semibold text-lg mb-4">Personal Details</p>
        <Row gutter={20}>
          <Col span={6}>
            <Form.Item
              label={
                <span>
                  User Id
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="userId"
              rules={[
                {
                  required: true,
                  message: "Please input id!",
                },
              ]}
            >
              <Input></Input>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={
                <span>
                  Salutation
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="salutation"
              rules={[
                {
                  required: true,
                  message: "Please input salutation!",
                },
              ]}
            >
              <Select options={salutationOptions} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={
                <span>
                  First Name
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "Please Enter your First Name!",
                },
              ]}
            >
              <Input className="w-full" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={
                <span>
                  Last Name
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "Please Enter Last Name!",
                },
              ]}
            >
              <Input className="w-full" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={
                <span>
                  Profession
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="userType"
              rules={[
                {
                  required: true,
                  message: "Please Enter Profession !",
                },
              ]}
            >
              <Input className="w-full" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={
                <span>
                  D.O.B
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="dob"
              rules={[
                {
                  required: true,
                  message: "Please Enter your D.O.B!",
                },
              ]}
            >
              <DatePicker className="w-full" />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label={<span>Marital Status</span>} name="maritalStatus">
              <Select className="w-full">
                {maritalStatusOptions.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label={
                <span>
                  Mobile Number
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="mobile"
              rules={[
                {
                  required: true,
                  message: "Please Enter your Mobile Number!",
                },
              ]}
            >
              <Input
                className="w-full"
                maxLength={15}
                onKeyPress={handleKeyPress}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={
                <span>
                  Email ID
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please Enter your Email ID!",
                },
              ]}
            >
              <Input className="w-full" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={
                <span>
                  Gender
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="gender"
              rules={[
                {
                  required: true,
                  message: "Please Enter your Gender!",
                },
              ]}
            >
              <Select className="w-full"></Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={
                <span>
                  Qualification
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="qualification"
              rules={[
                {
                  required: true,
                  message: "Please Enter your Qualification!",
                },
              ]}
            >
              <Input />
              {/* <Select className="w-full"></Select> */}
            </Form.Item>
          </Col>
        </Row>
        <div className="font-semibold text-lg mb-4">Address Details</div>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label={
                <span>
                  House No/Street Name
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="house no"
              rules={[
                {
                  required: true,
                  message: "Please Enter your House No!",
                },
              ]}
            >
              <Input className="w-full" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={
                <span>
                  Address
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="address1"
              rules={[
                {
                  required: true,
                  message: "Please Enter your Address",
                },
              ]}
            >
              <Input className="w-full" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={
                <span>
                  City
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="city"
              rules={[
                {
                  required: true,
                  message: "Please Enter your City",
                },
              ]}
            >
              <Input className="w-full" />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label={
                <span>
                  State
                  <span style={{ color: "#FF000F" }}> *</span>
                </span>
              }
              name="state"
              rules={[
                {
                  required: true,
                  message: "Please Enter your State",
                },
              ]}
            >
              <Select className="w-full"></Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Country" name="country">
              <Select className="w-full"></Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label={<span>Pincode</span>}
              name="pincode"
              rules={[
                {
                  required: true,
                  message: "Please Enter your Pincode",
                },
              ]}
            >
              <Select
                className="w-full"
                maxLength={6}
                // onKeyPress={handleKeyPress}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        <p className="font-semibold text-lg mb-4">Image Details</p>
        <p className="font-normal text-base mb-4">
          Update your latest Stamp & Signature.
        </p>
        <div>
          <Checkbox
            disabled={false}
            checked={isDoctorStampEnabled}
            onChange={() => handleChange("Stamp")}
          >
            Stamp
          </Checkbox>
          <Checkbox
            disabled={false}
            checked={isDoctorSignEnabled}
            onChange={() => handleChange("Signature")}
          >
            Signature
          </Checkbox>
        </div>
        <div className="w-full">
          <div className="my-3 w-full flex items-center gap-4">
            <div className="my-2 w-[30%]">
              <div className="my-2">
                <label className="md:text-lg text-base font-medium">
                  Signature
                </label>
                <span className="text-red-500">*</span>
              </div>
              <Form.Item
                className="mb-0 outline-none rounded-lg"
                name="signature"
              >
                <div
                  className={`flex flex-col justify-center border-2 ${
                    isDoctorSignEnabled
                      ? "border-brand-primaryColor"
                      : "border-brand-textGray"
                  } relative md:h-12 h-10 p-2 rounded-lg`}
                >
                  <BsCloudUpload
                    className={`mx-auto text-lg ${
                      isDoctorSignEnabled
                        ? "text-brand-primaryColor"
                        : "text-brand-textGray"
                    }`}
                  />
                  <input
                    type="file"
                    name="sign"
                    disabled={!isDoctorSignEnabled}
                    className="w-[inherit] border-none outline-none ml-[2.75rem] absolute left-8  opacity-0"
                    onChange={handleFileType}
                  />
                </div>
              </Form.Item>
              {(doctorSignature ||
                loginDataObject.user.doctorSignatureName) && (
                <p>
                  {doctorSignature
                    ? doctorSignature.name
                    : loginDataObject.user.doctorSignatureName
                    ? loginDataObject.user.doctorSignatureName
                    : "No Image Found"}
                </p>
              )}
            </div>
            <button
              disabled={!isDoctorSignEnabled}
              className={`min-w-28 w-36 p-3 text-white font-normal rounded-lg ${
                isDoctorSignEnabled
                  ? "bg-brand-primaryColor"
                  : "bg-brand-textGray"
              }`}
              onClick={handleSignatureUpdate}
            >
              Update
            </button>
          </div>
          <div className="my-3 w-full flex items-center gap-4">
            <div className="my-3 w-[30%]">
              <div className="my-2">
                <div className="my-2">
                  <label className="md:text-lg text-base font-medium">
                    Stamp
                  </label>
                  <span className="text-red-500">*</span>
                </div>
                <Form.Item
                  className="mb-0 w-full outline-none rounded-lg"
                  name="stamp"
                >
                  <div
                    className={`flex flex-col justify-center border-2 ${
                      isDoctorStampEnabled
                        ? "border-brand-primaryColor"
                        : "border-brand-textGray"
                    } relative md:h-12 h-10 p-2 rounded-lg`}
                  >
                    <BsCloudUpload
                      className={`mx-auto text-lg ${
                        isDoctorStampEnabled
                          ? "text-brand-primaryColor"
                          : "text-brand-textGray"
                      }`}
                    />
                    <input
                      type="file"
                      name="stamp"
                      disabled={!isDoctorStampEnabled}
                      className="w-[inherit] border-none outline-none ml-[2.75rem] absolute left-8  opacity-0"
                      onChange={handleFileType}
                    />
                  </div>
                </Form.Item>
                {(doctorStamp || loginDataObject.user.doctorStampName) && (
                  <p>
                    {doctorStamp
                      ? doctorStamp.name
                      : loginDataObject.user.doctorStampName
                      ? loginDataObject.user.doctorStampName
                      : "No Image Found"}
                  </p>
                )}
              </div>
            </div>
            <button
              disabled={!isDoctorStampEnabled}
              className={`min-w-28 w-36 p-3 text-white font-normal rounded-lg ${
                isDoctorStampEnabled
                  ? "bg-brand-primaryColor"
                  : "bg-brand-textGray"
              }`}
              onClick={handleStampUpdate}
            >
              Update
            </button>
          </div>
        </div>
        <Row gutter={24}>
          <Col span={24}>
            <div
              className={`flex justify-end ${
                !componentDisabled ? "block" : "hidden"
              } gap-3 `}
            >
              <button className="px-2 py-1 rounded bg-transparent border shadow">
                Cancel
              </button>
              <button className="px-2 py-1 rounded text-white ">Save</button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default Index;
