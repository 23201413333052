import React, { useEffect, useState } from "react";
import { Button, Modal, Space, Table, Tooltip } from "antd";
import PaidIcon from "../../../Assets/Images/Svg/paid.svg";
import AppointmentService from "../../../Services/AppointmentService";
import PatientService from "../../../Services/PatientService";
import { APP_ROUTES } from "../../../PageRouting/approutes";
import { useHistory } from "react-router-dom";
import Edit from "../../../Assets/Images/Svg/EditIcon.svg";
import Preview from "../../../Assets/Images/Svg/PreviewIcon.svg";
import moment from "moment";
import ConsultationService from "../../../Services/ConsultationService";
import PrescriptionDetails from "../ConsultationNotes/VitalTabs/ViewPrescription/PrescriptionDetails";
import { RWebShare } from "react-web-share";
import videoicon from "../../../Assets/Images/videoicon.png";
import close from "../../../Assets/Images/close.png";
import share from "../../../Assets/Images/share.png";
import shareIcon from "../../../Assets/Images/Svg/shareIcon.svg";
import { IoPersonSharp } from "react-icons/io5";
import { Base64 } from "js-base64";

function UpcomeAppointment({
  upcomingList,
  setUpcomingList,
  upcomingAppointmentList,
}) {
  const [selectedPrescription, setSelectedPrescription] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [preview, setPreview] = useState(false);

  const download = () => {
    setPreview(false);
  };
  const cancel = () => {
    setPreview(false);
  };

  const encodeBase64 = (str) => {
    let id = Base64.encode(str);
    return id;
  };

  const handlePrescriptionPreview = async (record) => {
    let patientPayload = {
      patientId: record?.patientId,
    };
    let consultationPayload = {
      consultationId: record?.consultationId,
      detailsYN: "Y",
    };
    try {
      const patientResponse = await PatientService.PatientListFilter(
        patientPayload
      );
      if (patientResponse) {
        setSelectedPatient(patientResponse.data[0]);
      }
      const consultationResponse = await ConsultationService.getPrescriptions(
        consultationPayload
      );
      if (consultationResponse.data) {
        setSelectedPrescription(consultationResponse.data[0]);
        setPreview(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const storedLoginResult = localStorage.getItem("login_result");
  const loginResultObject = storedLoginResult
    ? JSON.parse(storedLoginResult)
    : null;

  // useEffect(() => {
  //   upcomingAppointmentList();
  // }, []);

  const history = useHistory();

  const handleClick = (record) => {
    let payload = {
      patientId: record.patientId,
    };
    PatientService.PatientListFilter(payload).then((res) => {
      localStorage.setItem("patient", JSON.stringify(res.data[0]));
    });
    history.push(APP_ROUTES.VCPAGE, { selectedPatient: record });
  };

  // const upcomingAppointmentList = () => {
  //   const today = new Date();
  //   const tomorrow = new Date(today);
  //   tomorrow.setDate(tomorrow.getDate() + 1);
  //   const tomorrowDate = tomorrow.toLocaleDateString("en-US", {
  //     month: "2-digit",
  //     day: "2-digit",
  //     year: "numeric",
  //   });
  //   const months = new Date();
  //   months.setDate(months.getDate() + 30);
  //   const futureDate = months.toLocaleDateString("en-US", {
  //     month: "2-digit",
  //     day: "2-digit",
  //     year: "numeric",
  //   });

  //   let payload = {
  //     userId: loginResultObject.user.userId,
  //     fromDate: tomorrowDate,
  //     toDate: futureDate,
  //   };
  //   AppointmentService.AppointmentFilter(payload)
  //     .then((result) => {
  //       setUpcomingList(result.data);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  const currentDate = new Date();

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "firstName",
      render: (_, record) => (
        <Space size="middle" className="">
          <div className="flex items-center gap-4">
            <div>
              <div className="text-base font-medium">{record.patientName}</div>
              <p className="text-brand-textDarkgray text-sm mb-0">
                <span>{record.patientId}</span>
              </p>
            </div>
          </div>
        </Space>
      ),
      sorter: {
        compare: (a, b) => a.patientName.localeCompare(b.patientName),
      },
      width: 200,
    },
    {
      title: "Appt.ID",
      dataIndex: "appointmentId",
      width: 150,
    },
    {
      title: "Chief Complaints",
      dataIndex: "appointmentReason",
      width: 150,
    },

    {
      title: "Date & Time",
      width: 200,
      dataIndex: "startTime",
      render: (_, record) => {
        const [hours, minutes] = record.startTime.split(":");
        const date = new Date();
        date.setHours(parseInt(hours, 10));
        date.setMinutes(parseInt(minutes, 10));
        const formattedTime = date.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });

        const formattedDate = moment
          .utc(record.appointmentDate)
          .local()
          .format("DD/MM/YYYY");
        return (
          <span>
            {formattedDate}-{formattedTime}
          </span>
        );
      },
      sorter: {
        compare: (a, b) => a.startTime.localeCompare(b.startTime),
      },
    },
    {
      title: "Payment Status",
      dataIndex: "transactionId",
      width: 150,
      render: (_, record) => {
        return record.transactionId ? (
          <span className="text-green-500 font-semibold">Completed</span>
        ) : (
          <span className="text-blue-500 font-semibold">Pending</span>
        );
      },
    },
    {
      title: "Call & Type",
      width: 150,
      dataIndex: "actions",
      // render: (_, record) => (
      //   <>
      //     {record.status === 1 ? (
      //       <button
      //         onClick={() => handleClick(record, "1")}
      //         className="flex items-center justify-center gap-2 w-full px-1 py-2 border border-black rounded-lg bg-white text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      //       >
      //         {record.serviceCode === "APPT_VIDEO" ? (
      //           <img src={videoicon} alt="videoicon" className="w-5 h-5" />
      //         ) : (
      //           <IoPersonSharp className="text-green-500" />
      //         )}
      //         Join call
      //       </button>
      //     ) : (
      //       <Button
      //         className="flex items-center justify-center gap-2 w-full px-1 py-2 border border-black rounded-lg bg-white text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      //         disabled
      //       >
      //         <img src={videoicon} alt="videoicon" className="w-5 h-5" />
      //         Join call
      //       </Button>
      //     )}
      //   </>
      // ),
      render: (_, record) => {
        const appointmentDate = new Date(record.appointmentDate);
        const isToday =
          appointmentDate.getFullYear() === currentDate.getFullYear() &&
          appointmentDate.getMonth() === currentDate.getMonth() &&
          appointmentDate.getDate() === currentDate.getDate();

        return (
          <Space size="middle">
            {record.status === 1 && isToday ? (
              <button
                onClick={() => handleClick(record, "1")}
                className="flex items-center justify-center gap-2 w-[100px] px-1 py-2 border border-black rounded-lg bg-white text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                {record.serviceCode === "APPT_VIDEO" ? (
                  <>
                    <img src={videoicon} alt="videoicon" className="w-5 h-5" />{" "}
                    Join call
                  </>
                ) : (
                  <>
                    <IoPersonSharp className="text-green-500" /> Consult
                  </>
                )}
              </button>
            ) : (
              <button className="flex bg-gray-300 items-center cursor-not-allowed w-[100px] justify-center gap-2 px-1 py-2 border border-black rounded-lg  text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                {record.serviceCode === "APPT_VIDEO" ? (
                  <>
                    <img src={videoicon} alt="videoicon" className="w-5 h-5" />{" "}
                    Join call
                  </>
                ) : (
                  <>
                    <IoPersonSharp className="text-green-500" /> Consult
                  </>
                )}
              </button>
            )}
          </Space>
        );
      },
    },
    {
      title: "Rx",
      width: 100,
      dataIndex: "actions",
      render: (_, record) => {
        // Prepare the Base64-encoded string
        const concatenatedIds = `${record?.patientId}|${record?.consultationId}|${record?.userId}`;
        const encodedIds = encodeBase64(concatenatedIds);
        const shareUrl = `http://localhost:3000/prescription/${encodedIds}`;

        return (
          <Space size="middle" className="">
            <div className="flex items-center gap-2">
              <div size="middle" className="">
                {record.status === 2 ? (
                  <div className="flex gap-1 items-center cursor-pointer">
                    <img
                      onClick={() => handleClick(record, "2")}
                      src={Edit}
                      alt="Edit"
                      className="w-6 h-6"
                    />
                    <img
                      src={Preview}
                      alt="Preview"
                      className="w-6 h-6"
                      role="button"
                      onClick={() => handlePrescriptionPreview(record)}
                    />
                    <RWebShare
                      data={{
                        text: "Appointment",
                        url: shareUrl,
                        title: "Share Prescription",
                      }}
                      onClick={() => console.log("shared successfully!")}
                    >
                      <img
                        src={shareIcon}
                        alt="Share"
                        className="w-6 h-6"
                        role="button"
                      />
                    </RWebShare>
                  </div>
                ) : (
                  <div className="flex gap-1 items-center cursor-pointer">
                    <img
                      src={Edit}
                      alt="Edit"
                      className="w-6 h-6 bg-gray-400 rounded-md cursor-not-allowed"
                    />
                    <img
                      src={Preview}
                      alt="Preview"
                      className="w-6 h-6 bg-gray-400 rounded-md cursor-not-allowed"
                      role="button"
                    />
                    <img
                      src={shareIcon}
                      alt="Share"
                      className="w-6 h-6 bg-gray-400 rounded-md cursor-not-allowed"
                      role="button"
                    />
                  </div>
                )}
              </div>
            </div>
          </Space>
        );
      },
    },
    {
      title: "Status",
      width: 120,
      dataIndex: "status",
      render: (_, record) => (
        <div size="middle" className="">
          {record.status === 0 && (
            <span className=" text-red-500 " disabled>
              Cancelled
            </span>
          )}
          {record.status === 1 && (
            <span className=" text-blue-500 ">Pending</span>
          )}
          {record.status === 2 && (
            <span className=" text-green-500 ">Completed</span>
          )}
        </div>
      ),
    },
    {
      title: "Action",
      width: 100,
      dataIndex: "action",
      render: (_, record) => (
        <Space size="middle" className="">
          {record.status === 1 && (
            <div className="flex justify-center items-center gap-2">
              <Tooltip placement="left" title={"Share"}>
                <div className="border  border-gray-500 p-1 cursor-pointer rounded-lg">
                  <div className="flex items-center gap-2">
                    <RWebShare
                      data={{
                        text: "Appointment",
                        url:
                          "https://patient.doxzon.com/vc/" +
                          record?.appointmentId +
                          "/V",
                        title: "Share Link",
                      }}
                      onClick={() => console.log("shared successfully!")}
                    >
                      <img className="w-4" src={share} alt="shareImage" />
                    </RWebShare>
                  </div>
                </div>
              </Tooltip>
              <Tooltip placement="right" title={"Cancel"}>
                <div
                  onClick={() => showModal(record)}
                  className="border  border-gray-500 p-1 cursor-pointer rounded-lg"
                >
                  <img className="w-4" src={close} alt="closeImage" />
                </div>
              </Tooltip>
            </div>
          )}
          {record.status === 2 && (
            <div className="flex justify-center items-center gap-2">
              <div className="border  border-gray-500 bg-gray-500 cursor-not-allowed p-1  rounded-lg">
                <div className="flex items-center gap-2">
                  <img className="w-4" src={share} alt="shareImage" />
                </div>
              </div>
              <div className="border  border-gray-500 bg-gray-500 p-1 cursor-not-allowed rounded-lg">
                <img className="w-4" src={close} alt="closeImage" />
              </div>
            </div>
          )}
          {record.status === 0 && (
            <div className="flex justify-center items-center gap-2">
              <div className="border  border-gray-500 bg-gray-500 cursor-not-allowed p-1  rounded-lg">
                <div className="flex items-center gap-2">
                  <img className="w-4" src={share} alt="shareImage" />
                </div>
              </div>
              <div className="border  border-gray-500 bg-gray-500 p-1 cursor-not-allowed rounded-lg">
                <img className="w-4" src={close} alt="closeImage" />
              </div>
            </div>
          )}
        </Space>
      ),
    },
  ];

  const [cancelAppointmentID, setCancelAppointmentID] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = (record) => {
    setIsModalOpen(true);
    setCancelAppointmentID(record);
  };

  const cancelAppointment = () => {
    setIsModalOpen(false);
  };

  const handleOk = () => {
    AppointmentService.AppointmentCancel(
      cancelAppointmentID.appointmentId
    ).then((res) => {
      setIsModalOpen(false);
      upcomingAppointmentList();
    });
    setCancelAppointmentID({});
  };

  return (
    <div className="relative w-full">
      <Table
        className="p-0 customTable"
        columns={columns}
        dataSource={upcomingList}
        pagination={true}
        scroll={{ x: 1500 }}
      />
      <Modal
        title="Cancel Appointment"
        open={isModalOpen}
        // onOk={handleOk}
        onCancel={cancelAppointment}
        footer={[]}
      >
        Do you want to cancel this appointment
        <div className="flex justify-end border-brand-colorBorder gap-3 mt-10">
          <Button className="h-10 Btnwhite px-8" onClick={cancelAppointment}>
            Cancel
          </Button>
          <Button onClick={handleOk} className="h-10 px-10" type="primary">
            Ok
          </Button>
        </div>
      </Modal>
      <Modal
        title="Prescription"
        open={preview}
        onOk={download}
        onCancel={cancel}
        style={{
          top: 40,
        }}
        width={1000}
        footer={null}
      >
        <PrescriptionDetails
          prescription={selectedPrescription}
          selectedPatient={selectedPatient}
        />
      </Modal>
    </div>
  );
}

export default UpcomeAppointment;
