import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Form, Input, DatePicker, Select, Modal, message } from "antd";
import moment from "moment";
import MedicalService from "../../../../../Services/MedicalHistoryService";
import MedicalHistoryService from "../../../../../Services/MedicalHistoryService";

function AddPastMedicalHistory(props) {
  const [givenDate, setGivenDate] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [manualPast, setManualPast] = useState('');
  const [pastValue, setPastValue] = useState()
  const [form] = Form.useForm();
  const activePatient = JSON.parse(localStorage.getItem("patient"));
  const patientCode = activePatient?.patientId;
  const formRef = useRef();
  const InputRef = useRef()
  // useEffect(() => {
  //   formRef.current.resetFields();
  //   setGivenDate('');
  // },[props]);

  useEffect(() => {
    if (manualPast && InputRef.current) InputRef.current.focus();
  }, [manualPast]);

  const handleCancel = () => {
    formRef.current.resetFields();
    setGivenDate('');
    props.onClose();
  };

  const handleDate = (date, dateString) => {
    if (dateString) {
      const constructedTime = dateString + " " + "00:00:00";
      console.log(dateString);
      setGivenDate(constructedTime);
    }
    else {
      setGivenDate('');
    }
  }

  const disabledFutureDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const healthConditions = [
    { name: "Diabetes", value: "Diabetes" },
    { name: "Hypertension", value: "Hypertension" },
    { name: "Thyroid", value: "Thyroid" },
    { name: "Tuberculosis", value: "Tuberculosis" },
    {
      name: "Myocardial infarction/ Heart attack",
      value: "Myocardial infarction/ Heart attack",
    },
    { name: "Cholesterol high", value: "Cholesterol high" },
    { name: "Jaundice", value: "Jaundice" },
    { name: "Kidney disease", value: "Kidney disease" },
    {
      name: "COPD (Chronic Obstructive Pulmonary disease)",
      value: "COPD (Chronic Obstructive Pulmonary disease)",
    },
    { name: "Asthma", value: "Asthma" },
    { name: "Cancers", value: "Cancers" },
    { name: "HIV", value: "HIV" },
    { name: "Hepatitis B", value: "Hepatitis B" },
    { name: "Hepatitis C", value: "Hepatitis C" },
    { name: "Typhoid", value: "Typhoid" },
    { name: "Malaria", value: "Malaria" },
    { name: "Dengue", value: "Dengue" },
    { name: "Anemia", value: "Anemia" },
    { name: "RE Cataract", value: "RE Cataract" },
    { name: "LE Cataract", value: "LE Cataract" },
    { name: "BE Cataract", value: "BE Cataract" },
    { name: "Eye - Pterygium", value: "Eye - Pterygium" },
    { name: "Eye - DCR", value: "Eye - DCR" },
    { name: "Others", value: "Others" },
  ];
  const convertUTCDate = (date, format) => {
    if (!format) {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
        " " +
        d.getHours() +
        ":" +
        d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return moment(new Date(date)).utc().format(format);
  };

  const onFinish = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const currDate = new Date().toISOString();
    const date = currDate.split('T')[0];
    const time = currDate.split('T')[1].slice(0, 8);
    const constructedTime = date + " " + time;

    const loginObj = localStorage.getItem("login_result");
    const userData = loginObj ? JSON.parse(loginObj) : null;

    const { pastinfo } = formRef.current.getFieldValue();
    console.log("hiii ", formRef.current.getFieldValue());
    if (!(pastValue || manualPast) || !givenDate) {
      message.error("Please fill mandatory fields");
      setIsLoading(false);
      return;
    }
    let payload = {
      account_Id: userData?.user?.account_Id,
      clinic_Id: userData?.user?.clinic_Id,
      userId: userData?.user?.userId,
      createdDate: constructedTime,
      givenDate: givenDate,
      createdBy: userData?.user?.userId,
      modifiedBy: userData?.user?.userId,
      status: 1,
      patientId: patientCode,
      pastInfo: pastValue?pastValue:manualPast,
      description: null,
      modifiedDate: constructedTime,
      fromDate: givenDate,
      toDate: givenDate,
    };
    try {
      const saveResponse = await MedicalService.AddPastMedicalHistory(payload);
      if (saveResponse && saveResponse.data === 1) {
        let getPayload = {
          account_Id: payload.account_Id,
          // clinic_Id:data.clinic_Id,
          userId: (props.permissionData === null || props.permissionData === "N") ? userData?.user?.userId : '',
          patientId: payload.patientId,
          status: 1,
        };
        const getResponse = await MedicalHistoryService.PastMedicalHistory(getPayload);
        formRef.current.resetFields();
        props.handleLatestUpdate(getResponse.data);
        setIsLoading(false);
        props.onClose();
      }


    }
    catch (e) {
      console.log(e);
      setIsLoading(false);
    }
    // MedicalService.AddPastMedicalHistory(payload)
    //   .then((result) => {
    //     formRef.current.resetFields();
    //     props.handleLatestUpdate(result.data);
    //     setIsLoading(false);
    //     props.onClose();
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     setIsLoading(false);
    //   });
  };
 
  const handlehealthChange = (values) => {
    setPastValue(values)
  }

  const handleHealthSearch = (value) => {
    const isPresent = healthConditions.findIndex((item) => item.name.includes(value));
    if (isPresent === -1) {
      setManualPast(value);
    }
  }

  const handleManualPast = (e) => {
    const value = e.target.value;
    if (value) {
      setManualPast(value);
    }
    else {
      setManualPast('');
    }
  }

  return (
    <div>
      <Form
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        ref={formRef}
      >
        <Row gutter={20}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span className="font-medium">
                  Past Medical History{" "}
                  <span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="pastinfo"
            >
              <Select
                style={{ width: "100%" }}
                showSearch
                onSearch={handleHealthSearch}
                onChange={handlehealthChange}
                placeholder="Select"
                //  value={pastValue}
                options={healthConditions}
                filterOption={(input, option) =>
                  option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                className={`${manualPast ? 'hidden' : 'block'}`}
                allowClear
              />
              <Input type="text"
                className={`${manualPast ? 'block' : 'hidden'}`}
                value={manualPast}
                onChange={(e) => handleManualPast(e)}
                ref={InputRef}
              />
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={12}>
            <Form.Item name="givenDate" label={<span className="font-medium">Date{" "}<span style={{ color: "#FF000F" }}>*</span></span>}>
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Select"
                format="YYYY-MM-DD"
                onChange={handleDate}
                disabledDate={disabledFutureDate}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Col className="gutter-row" span={24}>
        <div className="flex items-center w-full justify-end my-5">
          <button
            onClick={handleCancel}
            className="rounded bg-white border-brand-primaryColor border text-brand-primaryColor text-sm px-6 py-1.5"
          >
            Cancel
          </button>
          <button disabled={isLoading} onClick={onFinish} className="bg-brand-primaryColor rounded px-5 py-1.5 text-white text-sm ml-3 disabled:cursor-not-allowed disabled:opacity-50">
            Submit
          </button>
        </div>
      </Col>
    </div>
  );
}

export default AddPastMedicalHistory;
