import http from "./http-common";

class DoctorService {
    editDoctorDetails(payload, id) {
        return http.put(`/user/${id}`, payload);
    }

    getHospitalAccountData(id){
        return http.get(`/accountMaster/list/filter?account_Id=${id}`);
    }

    getDoctorData(id){
        return http.get(`/user/list/filter?userId=${id}`);
    }

}
export default new DoctorService();