import React, { useEffect, useState } from "react";
import Layouts from "../../../Layouts";
import FilterIcon from "../../../../Assets/Images/Svg/FilterIcon.svg";
import { Button, Col, DatePicker, Divider, Form, Input, Modal, Row, Select } from 'antd';
import Dragger from "antd/es/upload/Dragger";
import { CloudUploadOutlined } from "@ant-design/icons";
import AuthService from "../../../../Services/AuthService";
import AddReport from "./AddReport";
import PatientService from "../../../../Services/PatientService";
import NoDataFound from "../../../../Assets/Images/Svg/No data-found.svg";

const Reports = () => {
  const activePatient = JSON.parse(localStorage.getItem("patient"));
  const patientCode = activePatient?.patientId;
  const [myReport, setMyReport] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [viewDoc, setviewDoc] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);

  console.log(myReport, " heyyyy buddy");
  useEffect(() => {
    const fetchPermittedData = async () => {
      await fetchPatientPermission();
    }
    fetchPermittedData();
  }, []);

  const fetchPatientPermission = async () => {
    const userObj = localStorage.getItem('login_result');
    const userData = userObj ? JSON.parse(userObj) : null;
    const patientObj = localStorage.getItem("patient");
    const patientData = patientObj ? JSON.parse(patientObj) : null;

    const currDate = new Date().toISOString();
    const date = currDate.split('T')[0];
    const time = currDate.split('T')[1].slice(0, 8);
    const constructedTime = date + " " + time;

    let permissionPayload = {
      patientId: patientData.patientId,
      userId: userData?.user?.userId,
      accessDate: constructedTime,
      status: 1
    }
    try {
      const response = await PatientService.isPermissionGranted(permissionPayload);

      if (response?.data?.length !== 0 && response?.data[0]?.reports === "Y") {
        PatientReport(response?.data[0]?.reports);
      }
      else if (response?.data?.length !== 0 && response?.data[0]?.reports === "N") {
        PatientReport(response?.data[0]?.reports);
      }
      else {
        PatientReport(null);
      }

    }
    catch (e) {
      console.log(e);
    }
  }

  const handleCloseModal = () => {
    setIsModalVisible(false);
    fetchPatientPermission();
  };

  const showModal = () => {
    setIsModalOpen(true);
    setIsModalVisible(true)
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal1 = () => {
    setIsModalOpen1(true);
  };
  const handleOk1 = () => {
    setIsModalOpen1(false);
  };
  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };

  const handleViewDoc = (report) => {
    setSelectedDoc(report);
    setviewDoc(true);
  }




  const handleDocOk = () => {
    setviewDoc(false);
  };
  const handleDocCancel = () => {
    setviewDoc(false);
  };

  const PatientReport = (permission) => {
    const patient = JSON.parse(localStorage.getItem('patient'));
    const userObj = localStorage.getItem('login_result');
    const userData = userObj ? JSON.parse(userObj) : null;

    let payload = {
      userId: (permission === null || permission === "N") ? userData?.user?.userId : '',
      patientId: patient.patientId,
      documentType: "MY REPORT",
      status: 1,
    };
    setLoading(true);

    AuthService.getMyReport(payload)
      .then((result) => {
        setMyReport(result.data);
      })
      .catch((error) => {
        console.error("Failed to fetch reports:", error);
        setMyReport([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // const PatientReport = (permission) => {
  //   const patient = JSON.parse(localStorage.getItem('patient'));
  //   const userObj = localStorage.getItem('login_result')
  //   const userData = userObj ? JSON.parse(userObj) : null;

  //     let payload = {
  //       // account_Id: userData?.user?.account_Id,
  //       // clinic_Id: userData?.user?.clinic_Id,
  //       userId: (permission === null || permission === "N") ? userData?.user?.userId : '',
  //       patientId: patient.patientId,
  //       documentType: "MY REPORT",
  //       status: 1,
  //     };
  //     AuthService.getMyReport(payload).then((result) => {
  //       setMyReport(result.data);
  //     });
  // };

  return (
    <>
      <div className="p-4 border rounded shadow-md bg-slate-100">
        <div className="flex justify-between ">
          <div className="font-bold text-xl">MyReports</div>
          <div className="flex  gap-3">
            <Button onClick={showModal}
              className="h-9 px-2 flex justify-center text-white items-center gap-2 custom_filter" >
              + New
            </Button>
            <Button
              className="h-9 px-2 flex justify-center text-white items-center gap-2 custom_filter"
              onClick={showModal1}
            >
              <span>
                <img src={FilterIcon} alt="Icon" className="custom_filter hover:text-brand-primaryColor" />
              </span>
              <span className=""> Filter</span>
            </Button>
          </div>
        </div>
        <div className="my-6 flex">
          {loading ? (
            <div className="w-full flex h-screen justify-center items-center">
              <div className="spinner"></div> 
            </div>
          ) : myReport && myReport.length > 0 ? (
            <div className="w-full grid grid-cols-2 gap-2">
              {myReport.map((report, i) => (
                <div key={i} className="w-full">
                  <div className="border shadow-lg p-3 rounded-lg my-2 bg-white">
                    <div className="flex justify-between">
                      <div className="flex flex-col gap-1">
                        <label className="font-medium">Date</label>
                        <p className="font-normal">{report.documentDate}</p>
                      </div>
                      <div className="flex flex-col gap-1">
                        <label className="font-medium">Title</label>
                        <p className="font-normal">{report.title}</p>
                      </div>
                      <div className="flex flex-col gap-1">
                        <label className="font-medium">Document Type</label>
                        <p className="font-normal">{report.documentType}</p>
                      </div>
                    </div>
                    <div
                      className="underline cursor-pointer text-brand-primaryColor my-1 font-medium"
                      onClick={() => handleViewDoc(report)}
                    >
                      View Document
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="w-full flex h-screen justify-center items-center">
              <div>
                <img src={NoDataFound} alt="No Data" className="w-24 h-24" />
                <p className="text-center">No Reports Found</p>
              </div>
            </div>
          )}
        </div>

      </div>
      {/* <div className={`container w-[600px]  rounded-md shadow-gray-500 shadow-md mx-auto item-center justify-center p-4 ${isMessageModalOpen ? 'flex' : 'hidden'}`}>
        <div className="h-full">
          <span className="bg-yellow-300 text-black font-bold p-1 rounded-lg w-6 h-6">
            !
          </span>{" "}
          Patient needs to provide permission to view Reports
        </div>
      </div> */}

      <Modal
        title={<span className="font-bold">Add Report</span>}
        visible={isModalVisible}
        onOk={() => handleCloseModal()}
        centered={true}
        footer={null}
        onCancel={() => handleCloseModal()}
        okText="Submit"
        cancelText="Cancel"
        width={600}
        maskClosable={false}
      >
        <AddReport onClose={handleCloseModal} />
      </Modal>

      <Modal
        title={<span className="font-bold">Filter Reports</span>}
        className="commonModal"
        footer={false}
        open={isModalOpen1}
        onOk={showModal1}
        onCancel={handleCancel1}
      >
        <Form
          layout="vertical"
          autoComplete="off"
          className=" grid grid-cols-2 gap-5"
        >
          <Form.Item className="mb-0" label={<span className="font-medium">From Date</span>} name="fromDate">
            <DatePicker
              placeholder="fromDate "
              className="w-full"
              format="MM/DD/YYYY"
            />
          </Form.Item>
          <Form.Item className="mb-0" label={<span className="font-medium">To Date</span>} name="toDate">
            <DatePicker
              placeholder="toDate"
              className="w-full"
              format="MM/DD/YYYY"
            />
          </Form.Item>
        </Form>
        <div className=" flex justify-end gap-3 mt-3 ">
          <button
            onClick={handleCancel1}
            className="bg-White border-primaryColor border rounded-md text-base text-white p-2"
          >
            Cancel
          </button>
          <button
            onClick={handleOk1}
            className="bg-brand-primaryColor p-2 text-base rounded-md text-white"
          >
            Submit
          </button>
        </div>
      </Modal>

      <Modal
        title={selectedDoc?.title}
        open={viewDoc}
        footer={[]}
        onOk={handleDocOk}
        onCancel={handleDocCancel}
      >
        {console.log(`${process.env.REACT_APP_IMAGE_URL}/${selectedDoc?.documentName}`)}
        {
          (selectedDoc?.documentName.endsWith(".jpg") || selectedDoc?.documentName.endsWith(".jpeg") || selectedDoc?.documentName.endsWith(".png")) ?
            (<div className="flex justify-center items-center h-[inherit] w-[inherit]">
              <img src={`${process.env.REACT_APP_IMAGE_URL}${selectedDoc?.documentName}`} alt="report" />
            </div>)
            : (
              <iframe
                src={`${process.env.REACT_APP_IMAGE_URL}${selectedDoc?.documentName}`}
                title="report"
                style={{ width: "100%", height: "500px" }} // Adjust height as needed
              />
            )
        }

      </Modal>

    </>

  );
};

export default Reports;
