import React, { useEffect, useState } from "react";
import plusIcon from "../../../../../Assets/Images/Svg/plusIcon.svg";
import { Modal } from "antd";
import AddChronicCondition from "./AddChronicCondition";
import moment from "moment";
import MedicalHistoryService from "../../../../../Services/MedicalHistoryService";


function ChronicCondition({permissionData}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chronicConditions, setChronicConditions] = useState([]);

  let patientData = JSON.parse(localStorage.getItem("patient"));
  console.log("inside medical history", patientData);

  useEffect(() => {
    PatientchronicConditions();
  }, [permissionData]);

  const handleLatestUpdate = (data) => {
    setChronicConditions(data);
  }

  const PatientchronicConditions = () => {
    const userObj = localStorage.getItem('login_result')
    const userData = userObj ? JSON.parse(userObj) : null;
    if(permissionData){
      let payload = {
        patientId: patientData.patientId,
        // account_Id: userData?.user?.account_Id,
        // clinic_Id: userData?.user?.clinic_Id,
        userId:(permissionData === null || permissionData?.medicalHistory === 'N')?userData?.user?.userId:'',
        status:1
      };
      MedicalHistoryService.PatientchronicConditions(payload).then((result) => {
        console.log(result.data);
        setChronicConditions(result.data);
      });
   }
    
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const convertUTCDate = (date, format) => {
    if (!format) {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
        " " +
        d.getHours() +
        ":" +
        d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return moment(new Date(date)).utc().format(format);
  };

  return (
    <>
      <div className=" h-[300px] m-5 border rounded-lg">
        <div className=" h-[20%] bg-brand-primaryColor flex items-center justify-between  rounded-lg p-2">
          <h1 className="font-bold text-normal text-white mt-3">
            Chronic Conditions
          </h1>
          <img
            src={plusIcon}
            onClick={showModal}
            className="h-10 w-10 cursor-pointer"
            alt=""
          />
        </div>
        <div className=" h-[70%] overflow-y-auto  bg-brand-lightBg p-2">
          {chronicConditions?.length > 0
            ? chronicConditions?.map((user, i) => (
              <div>
                <div key={i} className="items-center mt-3 font-light text-sm">
                  <p className="font-semibold">Condition : <span className="ml-2 font-medium text-gray-500">{user.chronicDescription}</span></p>
                </div>
                <div className="ml-auto flex justify-end font-medium text-sm">
                  check up date :{" "}
                  {user.givenDate?.slice(0, 11)}
                </div>
                <hr className="my-4 border-gray-300" />
              </div>
            ))
            : null}
        </div>
        <div className="footer h-[10%]  bg-brand-primaryColor rounded-md"></div>
      </div>
      <Modal
        title={<span className="font-bold text-base">Add Chronic Condition</span>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <AddChronicCondition onClose={handleCancel} handleLatestUpdate={handleLatestUpdate} permissionData={permissionData} />
      </Modal>
    </>
  );
}

export default ChronicCondition;
