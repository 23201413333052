// import React from "react";
// import PatientInfo from "./PatientInfo";
// import AddPrescription from "./AddPrescription";
// import AddVitals from "./AddVitals";
// import PhysicalExamination from "./PhysicalExamination";
// import { Tabs } from "antd";
// import ViewPrescription from "./ViewPrescription/index";
// import MedicalHistory from "./MedicalHistory";

// function VitalTabs({ selectedPatient }) {

//   let patientData = JSON.parse(localStorage.getItem("patient"));

//   const onChange = (key) => {
//     console.log(key);
//     console.log(patientData, "ffh");
//   };

//   const items = [
//     {
//       key: "1",
//       label: "Patient info",
//       children: <PatientInfo selectedPatient={selectedPatient} />,
//     },
//     {
//       key: "2",
//       label: "Add Prescriptions",
//       children: <AddPrescription selectedPatient={selectedPatient}/>,
//     },
//     {
//       key: "3",
//       label: "Medical History",
//       children: <MedicalHistory />,
//     },
//     {
//       key: "4",
//       label: "View Prescription",
//       children: <ViewPrescription />,
//     },
//     // {
//     //   key: "5",
//     //   label: "Add Vitals",
//     //   children: <AddVitals />,
//     // },
//     // {
//     //   key: "6",
//     //   label: "HPI",
//     //   children: <HPI />,
//     // },
//     // {
//     //   key: "7",
//     //   label: "Physical Examination",
//     //   children: <PhysicalExamination />,
//     // },
//   ];

//   return <Tabs defaultActiveKey="2" items={items} onChange={onChange} />;
// }

// export default VitalTabs;

import React from "react";
import PatientInfo from "./PatientInfo";
import AddPrescription from "./AddPrescription";
import AddVitals from "./AddVitals";
import PhysicalExamination from "./PhysicalExamination";
import { Tabs } from "antd";
import ViewPrescription from "./ViewPrescription/index";
import MedicalHistory from "./MedicalHistory";
import MedicalRecords from "./MedicalRecords";

function VitalTabs({selectedPatient,handlePayload}) {

  const onChange = (key) => {
    console.log(key);
  };

  
  const items = [
    {
      key: "1",
      label: <span className="items-center font-bold ">Patient Info</span>,
      children: <PatientInfo selectedPatient={selectedPatient}/>,
    },
    {
      key: "2",
      label: <span className="items-center font-bold ">Add Prescriptions</span>,
      children: <AddPrescription handlePayload={handlePayload} selectedPatient={selectedPatient} />,
    },
    // {
    //   key: "3",
    //   label: "Medical History",
    //   children: <MedicalHistory />,
    // },
    // {
    //   key: "4",
    //   label: "View Prescription",
    //   children: <ViewPrescription selectedPatient={selectedPatient}/>,
    // },
    {
      key: "3",
      label: <span className="items-center font-bold ">Medical Records</span>,
      children: <MedicalRecords selectedPatient={selectedPatient}/>,
    },
    // {
    //   key: "5",
    //   label: "Add Vitals",
    //   children: <AddVitals />,
    // },
    // {
    //   key: "6",
    //   label: "HPI",
    //   children: <HPI />,
    // },
    // {
    //   key: "7",
    //   label: "Physical Examination",
    //   children: <PhysicalExamination />,
    // },
  ];

  return <Tabs defaultActiveKey="2" items={items} onChange={onChange}  />;
}

export default VitalTabs;

