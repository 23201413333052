import React, { useEffect, useState } from "react";
import plusIcon from "../../../../../Assets/Images/Svg/plusIcon.svg";
import AddFamilyHistory from "./AddFamilyHistory";
import { Modal } from "antd";
import moment from "moment";
import MedicalHistoryService from "../../../../../Services/MedicalHistoryService";


function FamilyHistory({permissionData}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [familyData, setFamliyData] = useState([]);

  useEffect(() => {
    familyHistory();
  }, [permissionData]);

  let patientData = JSON.parse(localStorage.getItem("patient"));
  console.log("inside medical history", patientData);

  const familyHistory = () => {
    const userObj = localStorage.getItem('login_result')
    const userData = userObj ? JSON.parse(userObj) : null;
    if(permissionData){
      let payload = {
        // account_Id: userData?.user?.account_Id,
        // clinic_Id: userData?.user?.clinic_Id,
        userId:(permissionData === null || permissionData?.medicalHistory === 'N')?userData?.user?.userId:'',
        patientId: patientData.patientId,
        status: 1,
      };
      MedicalHistoryService.PatientFamilyHistory(payload).then((result) => {
        setFamliyData(result.data);
      });
    }
    
  };

  const handleLatestUpdate = (data) => {
    setFamliyData(data);
  }

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const convertUTCDate = (date, format) => {
    if (!format) {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
        " " +
        d.getHours() +
        ":" +
        d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return moment(new Date(date)).utc().format(format);
  };

  return (
    <>
      <div className="card h-[300px] m-5 border rounded-lg">
        <div className="header h-[20%] bg-brand-primaryColor flex items-center justify-between  rounded-lg p-2">
          <h1 className="font-bold text-normal text-white mt-3">Family History</h1>
          <img
            src={plusIcon}
            onClick={showModal}
            className="h-10 w-10 cursor-pointer"
            alt=""
          />
        </div>
        <div className="body h-[70%] overflow-y-auto  bg-brand-lightBg p-2">
          {familyData.length > 0
            ? familyData.map((user, i) => (
              <div>
                <div key={i} className="items-center mt-3 font-light text-sm">
                  <div className="font-semibold">{user?.description}</div>
                  <div className="ml-auto font-medium flex justify-end mt-2">
                    check up date :{" "}
                    {user.givenDate?.slice(0, 11)}
                  </div>
                </div>
                <hr className="my-4 border-gray-300" />
              </div>
            ))
            : null}
        </div>
        <div className="footer h-[10%]  bg-brand-primaryColor rounded-md"></div>
      </div>
      <Modal
        title={<span className="font-bold text-base">Add Family History</span>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <AddFamilyHistory onClose={handleCancel} handleLatestUpdate={handleLatestUpdate} permissionData={permissionData} />
      </Modal>
    </>
  );
}

export default FamilyHistory;
