import { Col, Row } from "antd";
import React from "react";
import ConsultationNotes from "./ConsultationNotes";
import AddMedicine from "./AddMedicine";
import LabReports from "./LabReports";

function AddPrescription({selectedPatient,handlePayload}) {


 console.log("selectedPatient presccc ",selectedPatient );

  return (
    <div>
      <Row gutter={24}>
        <Col span={24}>
          <ConsultationNotes handlePayload={handlePayload}/>
        </Col>
        {/* <Col span={24} className=" ">
          <div className=" border border-brand-textGray rounded-xl relative p-1">
            <AddMedicine />
          </div>
          <div className="mt-6 border border-brand-textGray rounded-xl relative p-1">
            <LabReports />
          </div>
        </Col> */}
        
      </Row>
    </div>
  );
}

export default AddPrescription;