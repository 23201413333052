import React, { useRef, useState } from "react";
import { Col, Row, Form, Input, DatePicker, Select, Modal, message } from "antd";
import moment from "moment";
import MedicalService from "../../../../../Services/MedicalHistoryService";
import MedicalHistoryService from "../../../../../Services/MedicalHistoryService";

function AddSocialHistory(props) {
  const [maritalValue, setMaritalValue] = useState([]);
  const [socialFamilyValue, setSocialFamilyValue] = useState([]);
  const [socialFriendsValue, setSocialFriendsValue] = useState([]);
  const [socialCommunityValue, setSocialCommunityValue] = useState([]);
  const [socialColleagueValue, setSocialColleagueValue] = useState([]);
  const [givenDate, setGivenDate] = useState();
  const formRef = useRef();
  const [form] = Form.useForm();
  const activePatient = JSON.parse(localStorage.getItem("patient"));
  const patientCode = activePatient?.patientId;
  const loginObj = localStorage.getItem('login_result');
  const userData = loginObj ? JSON.parse(loginObj) : null;

  // useEffect(() => {
  //   formRef.current.resetFields();
  //   setGivenDate('');
  // },[props]);

  const handleCancel = () => {
    formRef.current.resetFields();
    setGivenDate('');
    props.onClose();
  }

  const handleMaritalChange = (value) => {
    setMaritalValue(value);
  };

  const handleFamilyValueChange = (value) => {
    setSocialFamilyValue(value);
  };

  const handleFriendsValueChange = (value) => {
    setSocialFriendsValue(value);
  };

  const handleCommunityValueChange = (value) => {
    setSocialCommunityValue(value);
  };

  const handleColleagueValueChange = (value) => {
    setSocialColleagueValue(value);
  };

  const socialOption = [
    { value: "Normal", label: "Normal" },
    { value: "Stressed", label: "Stressed" },
  ];

  const MaritalStatus = [
    { value: "Single", label: "Single" },
    { value: "Married", label: "Married" },
    { value: "Separated", label: "Separated" },
    { value: "Divorced", label: "Divorced" },
    { value: "Widowed", label: "Widowed" },
  ];

  const convertUTCDate = (date, format) => {
    if (!format) {
      const d = new Date();
      date = new Date(
        moment(date, "MM-DD-YYYY").format("MM/DD/YYYY") +
        " " +
        d.getHours() +
        ":" +
        d.getMinutes()
      );
    }
    format = format || "MM/DD/YYYY";
    return moment(new Date(date)).utc().format(format);
  };

  const handleDate = (date, dateString) => {
    if (dateString) {
      const constructedTime = dateString + " " + "00:00:00";
      console.log(dateString);
      setGivenDate(constructedTime);
    }
    else {
      setGivenDate('');
    }
  }

  const disabledFutureDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const onFinish = async (e) => {
    e.preventDefault();
    props.setIsLoading(true);
    const currDate = new Date().toISOString();
    const date = currDate.split('T')[0];
    const time = currDate.split('T')[1].slice(0, 8);
    const constructedTime = date + " " + time;

    const loginObj = localStorage.getItem("login_result");
    const userData = loginObj ? JSON.parse(loginObj) : null;

    const { description, occupation, maritalStatus, familyRelation, friendsRelation, communityRelation, colleagueRelation } = formRef.current.getFieldValue();
    if (!description || !occupation || !maritalStatus || !familyRelation || !friendsRelation || !communityRelation || !colleagueRelation || !givenDate) {
      message.error("Please fill all fields");
      props.setIsLoading(false);
      return;
    }
    let payload = {
      account_Id: userData?.user?.account_Id,
      clinic_Id: userData?.user?.clinic_Id,
      userId: userData?.user?.userId,
      createdDate: constructedTime,
      givenDate: givenDate,
      createdBy: userData?.user.userId,
      modifiedBy: userData?.user.userId,
      status: 1,
      patientId: patientCode,
      modifiedDate: constructedTime,
      fromDate: givenDate,
      toDate: givenDate,
      description,
      colleagueRelation,
      communityRelation,
      familyRelation,
      friendsRelation,
      maritalStatus,
      occupation,
    };
    // try {
    //   const saveResponse = await MedicalService.AddSocialHistory(payload);
    //   if (saveResponse.data === 1) {
    //     let getPayload = {
    //       account_Id: userData?.user?.account_Id,
    //       // clinic_Id: userData?.user?.clinic_Id,
    //       userId: (props.permissionData === null || props.permissionData === "N") ? userData?.user?.userId : '',
    //       patientId: payload.patientId,
    //       status: 1,
    //     };
    //     const getResponse = await MedicalHistoryService.PatientSocialHistory(getPayload);
    //     formRef.current.resetFields();
    //     props.handleLatestUpdate(getResponse.data);
    //     props.setIsLoading(false);
    //     props.onClose();

    //   }
    // }
    // catch (e) {

    // }
    MedicalService.AddSocialHistory(payload)
      .then((result) => {
        formRef.current.resetFields();
        props.handleLatestUpdate(result.data);
        props.setIsLoading(false);
        props.onClose();
      })
      .catch((error) => {
        console.log(error);
        props.setIsLoading(false);
      });
  };

  return (
    <div>
      <Form
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        ref={formRef}
      >
        <Row gutter={20}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span className="font-medium">
                  Social History <span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="description"
            >
              <Input rows={2} placeholder="Enter " />
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span className="font-medium">
                  Occupation<span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="occupation"
            >
              <Input rows={2} placeholder="Enter " />
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span className="font-medium">
                  Marital status<span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="maritalStatus"
            >
              <Select
                style={{ width: "100%" }}
                onChange={handleMaritalChange}
                placeholder="Select"
                value={maritalValue}
                options={MaritalStatus.map((ele) => ({
                  value: ele.value,
                  label: ele.label,
                  key: ele.value,
                }))}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span className="font-medium">
                  Social relationship with family<span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="familyRelation"
            >
              <Select
                style={{ width: "100%" }}
                onChange={handleFamilyValueChange}
                placeholder="Select"
                value={socialFamilyValue}
                options={socialOption.map((ele) => ({
                  value: ele.value,
                  label: ele.label,
                  key: ele.value,
                }))}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span className="font-medium">
                  Social relationship with friends<span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="friendsRelation"
            >
              <Select
                style={{ width: "100%" }}
                onChange={handleFriendsValueChange}
                placeholder="Select"
                value={socialFriendsValue}
                options={socialOption.map((ele) => ({
                  value: ele.value,
                  label: ele.label,
                  key: ele.value,
                }))}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span className="font-medium">
                  Social relationship with community<span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="communityRelation"
            >
              <Select
                style={{ width: "100%" }}
                onChange={handleCommunityValueChange}
                placeholder="Select"
                value={socialCommunityValue}
                options={socialOption.map((ele) => ({
                  value: ele.value,
                  label: ele.label,
                  key: ele.value,
                }))}
              />
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span className="font-medium">
                  Social relationship with people at work<span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="colleagueRelation"
            >
              <Select
                style={{ width: "100%" }}
                onChange={handleColleagueValueChange}
                placeholder="Select"
                value={socialColleagueValue}
                options={socialOption.map((ele) => ({
                  value: ele.value,
                  label: ele.label,
                  key: ele.value,
                }))}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={
                <span className="font-medium">
                  Date <span style={{ color: "#FF000F" }}>*</span>
                </span>
              }
              name="givenDate"
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Select"
                format="YYYY-MM-DD"
                onChange={handleDate}
                disabledDate={disabledFutureDate}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Col className="gutter-row" span={24}>
        <div className="flex items-center w-full justify-end my-5">
          <button
            onClick={handleCancel}
            className="border-brand-primaryColor border bg-white rounded text-brand-primaryColor text-sm px-6 py-1.5"
          >
            Cancel
          </button>
          <button disabled={props.isLoading} onClick={onFinish} className="bg-brand-primaryColor rounded px-5 py-1.5 text-white text-sm ml-3 disabled:cursor-not-allowed disabled:opacity-50">
            Submit
          </button>
        </div>
      </Col>
    </div>
  );
}

export default AddSocialHistory;
